import * as React from "react";
import {Modal} from "react-bootstrap";
import {observable} from "mobx";
import {observer} from "mobx-react";

@observer
class ViewImageModal extends React.Component<any, {}> {
    @observable open:boolean = false;
    close=()=>{
        this.open = false;
    };

    show=()=>{
        this.open = true;
    };

    render() {
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

        return (
            <Modal show={this.open} onHide={this.close} dialogClassName={"modal-dialog-centered modal-sm"}>
                <Modal.Header className="b-none p-0">
                    <button type="button" className="btn ml-auto pb-0" onClick={this.close}>
                        <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="text-center">
                         <img src={this.props.imgUrl} style={{width:"200px"}} alt="Notification Image"/>
                    </div>
                </Modal.Body>        
            </Modal>
        )
    }
}

export {ViewImageModal};
