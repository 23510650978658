import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {Notification} from "../../store/Notification";
import {LabelDto} from "../../store/LabelDto";
import {Label} from "../../store/Label";
import {UserScanData} from "../../store/UserScanData";
import {BaseNotification} from "../../config/HttpClients";
import {BaseLabelWrite} from "../../config/HttpClients";
import {Button, ButtonToolbar} from "react-bootstrap";
import Form from "../../constants/Form";
import {NotificationDetailsPagination} from "../../constants/pagination/NotificationDetailsPagination";
import {Customer} from "../../store/Customer";
import {ScannedImage} from "./ScannedImage";
import {stringToDateFormat, viewDateFormat, viewDateTimeFormat} from "../../constants/CommonMethods";
import {StatusAudit} from "../../store/StatusAudit";
import {Stores} from "../../store/Stores";
import moment from 'moment';

@observer
class AlertDetails extends React.Component<any, any> {

    @observable notification = new Notification();
    @observable labelDto = new LabelDto();
    @observable label = new Label();
    @observable userScanData = new UserScanData();
    @observable comment:string = '';
    @observable justificationError: boolean = false;
    @observable totalPage = 0;
    @observable pageNo = 0;
    @observable customer:Customer = new Customer();
    @observable paginationFormat:any;
    @observable alertType:string = '';
    @observable userType:string = '';
    @observable search:string = '';
    @observable status:string = '';
    @observable secondaryId:string = '';
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable scannedImgUrl:any;
    @observable referenceImgUrl:any;
    @observable showScannedImg:boolean = false;
    @observable showReferenceImg:boolean = false;
    @observable alreadyReleased:boolean = false;
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.pageNo = this.props.currentPageNo;
        this.totalPage = this.props.totalPage;
        this.fromDateValue = this.props.fromDateValue;
        this.toDateValue = this.props.toDateValue;
        this.search = this.props.search;
        this.alertType = this.props.alertType;
        this.userType = this.props.userType;
        this.secondaryId = this.props.secondaryId;
        this.status = this.props.status;
        this.getNotificationsBySecondaryId();
    }

    getNotificationsBySecondaryId= () => {
        let search;
        let status;
        let userType;
        let alertType;
        if(!this.customer.id){this.customer.id = 'null';}
        if(this.search !== ''){
            search = this.search;
        } else {
            search = 'null';
        }
        if(this.status !== ''){
            status = this.status;
        } else {
            status = 'null';
        }
        if(this.userType !== ''){
            userType = this.userType;
        } else {
            userType = 'null';
        }
        if(this.alertType !== ''){
            alertType = this.alertType;
        } else {
            alertType = 'null';
        }
        BaseNotification.get("/notification/secondaryId?pageNo="+this.pageNo+"&limit=1&fromDate="+this.fromDateValue+"&toDate="+this.toDateValue+"&secondaryId="+this.secondaryId+"&type=Alert&alertType="+alertType+"&from="+userType+"&customer="+this.customer.id+"&status="+status+"&search="+search).then(response=>{
            this.notification = response.data.payload[0] || {};
            if(this.notification.id){
                this.notification.labelGeneratedDateConvert = viewDateFormat(this.notification.labelGeneratedDate);
                this.notification.createdDateConvert = viewDateTimeFormat(this.notification.createdDate);
            }
            this.paginationFormat.totalRecordsCount();
            this.showScannedImg = false;
            this.scannedImgUrl = '';
            if(this.notification.scannedImageKey1){
                this.getScannedImage(this.notification.scannedImageKey1,true);
            }
            if(this.notification.referenceImageKey){
                this.getScannedImage(this.notification.referenceImageKey,false);
            }
            this.alreadyReleased = false;
            if(this.notification.notificationStatusAudits && this.notification.notificationStatusAudits.length > 0) {
                this.notification.notificationStatusAudits.map((comment,index) => {
                    if(comment.status === "Released") {
                        this.alreadyReleased = true;
                    }        
                })
            }
        });
    }

    getScannedImage= (fileName,isScanImage) => {
        BaseNotification.get("/notification/image?fileName="+fileName,{headers:{Accept:"*/*"},responseType: "blob"}).then(response=>{
            let blob = new Blob([response.data], { type:response.headers["content-type"]});
            if(isScanImage){
                this.scannedImgUrl = URL.createObjectURL(blob);
            } else {
                this.referenceImgUrl = URL.createObjectURL(blob);
            }

        });
    }

    updateNotification = () => {
        this.setDateFormet(this.notification);
        let statusAudit = new StatusAudit();
        statusAudit.status = this.notification.status;
        statusAudit.comment = this.comment;
        statusAudit.updatedUser = Stores.currentUser.firstName;
        statusAudit.createdAt = new Date().toUTCString();
        if(this.notification.notificationStatusAudits&&this.notification.notificationStatusAudits.length > 0) {
            this.notification.notificationStatusAudits.push(statusAudit);
        } else {
           let statusAudits:StatusAudit[] = [];
            statusAudits.push(statusAudit);
            this.notification.notificationStatusAudits = statusAudits;
        }
        BaseNotification.put("/notification", this.notification).then(response => {
            if(this.notification.status == "Released") {
                this.updateRelease(this.notification.secondaryId);
            }
            this.getNotificationsBySecondaryId();
            this.comment = '';
        });
    }

    updateRelease = (secondaryId) => {
        var dateTime = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.label.secondaryId = secondaryId;
        this.label.lastScannedActionType = "Released";
        this.label.lastScannedBranchId = Stores.currentBranch.country.name;
        this.label.lastScannedDate = dateTime;
        this.label.lastScannedLocation = '';
        this.label.lastScannedUserId = Stores.currentUser.id;
        
        this.userScanData.userId =  Stores.currentUser.id;
        this.userScanData.currentBranchCountry = Stores.currentBranch.country.name;
        this.userScanData.scannedDateTime = dateTime;
        this.userScanData.actionType = "Released";
        this.userScanData.userName = Stores.currentUser.userName;

        this.label.userScanData[0] = this.userScanData;

        this.labelDto.labelList[0] = this.label;

        BaseLabelWrite.put("/labelWrite/releaseLabel", this.labelDto).then(response => {
            
        });
    }

    handleComment = (product, event) => {
        this.comment = event.target.value;
    };

    setDateFormet=(notification)=>{
        notification.labelGeneratedDateConvert = stringToDateFormat(notification.labelGeneratedDateConvert);
    }

    handleChangePage=(pageNo)=>{
        this.pageNo = pageNo;
        this.getNotificationsBySecondaryId();
    }

    handleBack = () => {
        this.props.setOption('ALERT');
    }

    handleScannedImageShow = () => {
        this.showScannedImg = !this.showScannedImg;
    }

    handleReferenceImageShow=()=>{
        this.showReferenceImg = !this.showReferenceImg;
    }

    handleUserInput = (targetObject, event) => {
        targetObject[event.target.name] = event.target.value;
    };

    navigate = (url: any) => {
        this.history.push(url);
    };


    render() {
        let isConsumer = false;
        if (this.notification && this.notification.userType === 'CONSUMER') {
            isConsumer = true;
        }
        let isVerifiedOrAuth = false;
        if (this.notification && this.notification.scanType && (this.notification.scanType === 'Verified' || this.notification.scanType === 'Authenticated')) {
            isVerifiedOrAuth = true;
        }
        return (
            <div className="col-12 pt-4 h-100 product-details">
                <div className="pb-2 row mx-0">
                    <div className='col-6  h-42'>
                        <b style={{letterSpacing: "1px", fontSize: "20px"}}>Alert Details </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0" style={{height:'calc(100% - 50px)'}}>
                    <div className="col-8 pl-0 pb-3 h-100">
                        <div className="card b-none h-100">
                            <div className="card-body h-100 pr-2" >
                                <div className="row mx-0 mb-2">
                                    <NotificationDetailsPagination totalPage={this.totalPage}
                                                                   pageNo={this.pageNo}
                                                                   partialPageCount={5} handleChangePage={this.handleChangePage}
                                                                   ref={ref => {
                                                                       this.paginationFormat = ref
                                                                   }}/>
                                </div>
                                <div className="notification-details-height">
                                <Form submit={this.updateNotification}>
                                    <div className="h-100 custom-scroll pr-2" style={{overflow:'auto'}}>
                                    <div className="row mx-0">
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Evdens UID</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Evdens UID Type</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Alert Id</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Alert Type</p>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-3 pl-0">
                                            <p className="mb-1 w-100 header-value">{this.notification.secondaryId}</p>
                                            {
                                                (this.notification.alertType==="Invalid" || this.notification.alertType==="Cloned")&&
                                                <p className="mb-1">({this.notification.primaryId})</p>
                                            }
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-1 header-value">{this.notification.codeType?this.notification.codeType:<span>-</span>}</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-1 header-value">{this.notification.notificationId}</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-1 header-value">{this.notification.alertType}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Alert Date</p>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-3 px-0">
                                            <p className="mb-1 header-value">{this.notification.createdDateConvert}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <p className="my-1 inner-header">Alert Triggered By</p>
                                        <div className="col-12 card shadow-none" style={{background: "#F7F7F7"}}>
                                            <div className="card-body p-2">
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">User Type</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.userType}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        {
                                                            isVerifiedOrAuth &&
                                                            <React.Fragment>
                                                                <div className="row mx-0">
                                                                    <p className="mb-0 header">Scan Type</p>
                                                                </div>
                                                                <div className="row mx-0">
                                                                    <p className="mb-0 header-value">{this.notification.scanType?this.notification.scanType:<span>-</span>}</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    !isConsumer&&
                                                    <div className="row">
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Customer/Partner Name</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.customerName?this.notification.customerName:<span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Branch Location Name</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.branchLocationName?this.notification.branchLocationName:<span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">User Identity</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            {
                                                                !isVerifiedOrAuth&&
                                                                <p className="mb-0 header-value">{this.notification.firstName} {this.notification.lastName}</p>
                                                            }
                                                            {
                                                                (isConsumer || isVerifiedOrAuth) ?
                                                                <p className="mb-0 header-value">{this.notification.userName?this.notification.userName:<span>-</span>}</p> :
                                                                <React.Fragment></React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Scan Location</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.location?<a href={'http://maps.google.com/?q='+this.notification.location} target="_blank" rel="noopener noreferrer">{this.notification.location}</a>:<span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Scanned Image</p>
                                                            <button type="button"
                                                                    className="btn btn-sm button-grey-color ml-2"
                                                                    onClick={this.handleScannedImageShow}
                                                            >View
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">User Country</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.userCountry? this.notification.userCountry :<span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-6 pl-0">*/}
                                                        {/*<div className="row mx-0">*/}
                                                        {/*    <p className="mb-0 view-header">Reference Image</p>*/}
                                                        {/*    <button type="button"*/}
                                                        {/*            className="btn btn-sm button-grey-color ml-2"*/}
                                                        {/*            onClick={this.handleReferenceImageShow}*/}
                                                        {/*    >View*/}
                                                        {/*    </button>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.notification.alertType==="Misshipment"&&
                                        <div className="row mx-0 mt-2">
                                            <p className="mb-1 inner-header">EvdensCode Details</p>
                                            <div className="col-12 card shadow-none" style={{background: "#F7F7F7"}}>
                                                <div className="card-body p-2">
                                                    <div className="row">
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Customer Name</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.labelCustomer?this.notification.labelCustomer:<span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Branch Location Name</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.productionLocation?this.notification.productionLocation:<span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">User Identity</p>
                                                            </div>
                                                            <div className="row mx-0 header-value">
                                                                {this.notification.lastAggregatedUser?this.notification.lastAggregatedUser:<span>-</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Label Generated By</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.labelGeneratedPartnerName?this.notification.labelGeneratedPartnerName:<span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6"></div>
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Date Generated</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.labelGeneratedDateConvert?this.notification.labelGeneratedDateConvert:<span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mx-0 mt-2">
                                        <div className="col-6 pl-0">
                                            <div className="row mx-0">
                                                <p className="mb-0 header">Status</p>
                                            </div>
                                            <div className="row mx-0">
                                                <select className="form-control" value={this.notification.status}
                                                        name="status"
                                                        onChange={this.handleUserInput.bind(this, this.notification)}
                                                        required={true} id="status">
                                                    <option value={''}>Status</option>
                                                    <option className="select-option" value={'New'}>New</option>
                                                    <option className="select-option" value={'ActionInitiated'}>Action Initiated</option>
                                                    <option className="select-option" value={'Resolved'}>Resolved</option>
                                                    {(this.notification.alertType == 'Denied' && !this.alreadyReleased) ?
                                                        <option className="select-option" value={'Released'}>Released</option> : null}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 px-0">
                                            <div className="row mx-0">
                                                <p className="mb-0 header">Comments</p>
                                            </div>
                                            <div className="row mx-0">
                                                    <textarea className="form-control"
                                                              name="justification" style={{height: '80px',maxHeight:'80px',resize: 'none'}}
                                                              required={this.notification.status==='Released' || this.notification.status==='Resolved' ||this.notification.status==='ActionInitiated' }
                                                              id="justification"
                                                              onChange={this.handleComment.bind(this, this.comment)}
                                                              value={this.comment}
                                                              maxLength={50}
                                                              rows={5}/>
                                                <div className="invalid error"/>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.notification&&this.notification.notificationStatusAudits &&  this.notification.notificationStatusAudits.length > 0 &&
                                        <div className="row mx-0 mb-3">
                                            <div className="col-12 px-0">
                                                <div className="row mx-0">
                                                    <p className="mb-0 header">Activity Log</p>
                                                </div>
                                                <div className="row mx-0">
                                                    {
                                                        this.notification.notificationStatusAudits.map((comment,index) =>
                                                            <p className="mb-0 w-100" key={index}>{comment.status} {comment.comment?<span> - {comment.comment}</span>:<React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    <ButtonToolbar className="justify-content-center mt-2">
                                        <Button className="btn btn-app-secondary" type="button"
                                                onClick={this.handleBack}> BACK</Button>
                                        <Button className="btn btn-app-primary ml-2" type="submit">SUBMIT</Button>
                                    </ButtonToolbar>
                                </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 px-0">
                        <div className="card">
                            <div className="card-body" style={{minHeight: "200px"}}>
                                <div className="pt-2">
                                    {
                                        (this.showScannedImg && this.scannedImgUrl)&&
                                        <React.Fragment>
                                            <p className="mb-0">Scanned Image</p>
                                            <ScannedImage imgUrl={this.scannedImgUrl}/>
                                        </React.Fragment>
                                    }
                                    {
                                        this.showReferenceImg&&this.referenceImgUrl&&
                                        <React.Fragment>
                                            <p className="mb-0">Reference Image</p>
                                            <ScannedImage imgUrl={this.referenceImgUrl} />
                                        </React.Fragment>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const AlertDetailsComponent = withRouter(AlertDetails);
export {AlertDetailsComponent as AlertDetails};
