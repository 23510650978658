import {action, observable} from 'mobx';

class EvdensContact {
    @observable id:string = '';
    @observable medium:string = '';
    @observable value:string = '';
    @observable status:string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';

    @action
    setEvdensContact = (evdensContact: any) => {
        this.id = evdensContact.id;
        this.medium = evdensContact.medium;
        this.value = evdensContact.value;
        this.status = evdensContact.status;
        this.createdBy = evdensContact.createdBy;
        this.updatedBy = evdensContact.updatedBy;
        return this;
    }
    @action reset() {
        this.id                = '';
        this.medium      = '';
        this.value           = '';
        this.status            = '';
        this.createdBy         = '';
        this.updatedBy         = '';
    }
}

export {EvdensContact};
