import * as React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import Select from 'react-select'
import Form from "../../constants/Form";
import { FormGroup, ControlLabel, ButtonToolbar, Button } from "react-bootstrap";
import { BaseCustomer } from "../../config/HttpClients";
import { PartnerType } from "../../store/PartnerType";
import { CustomerType } from "../../store/CustomerType";
import { CustomerVertical } from "../../store/CustomerVertical";
import { CustomerPartnerType } from "../../store/CustomerPartnerType";
import { Stores } from "../../store/Stores";
import { LoadToast, ToastType } from "../../config/Toast";
import { CustomSwitch } from "../../constants/formfields/CustomSwitch";
import { viewDateTimeFormat } from '../../constants/CommonMethods';

@observer
class CustomerForm extends React.Component<any, any> {

    @observable partnerTypes: PartnerType[] = [];
    @observable customerTypes: CustomerType[] = [];
    @observable customerVerticals: CustomerVertical[] = [];
    @observable isFileTooLarge: boolean = false;
    @observable fileInput: any;

    constructor(props) {
        super(props);
        this.getPartnerTypes();
        this.getCustomerTypes();
    }

    getPartnerTypes = () => {
        let status = 'null';
        BaseCustomer.get("/partnerType?userType=VGLENS&type=Customer&status=" + status).then(response => {
            this.partnerTypes = response.data.payload;
        });
    }

    getCustomerTypes = () => {
        BaseCustomer.get("/customerType/active").then(response => {
            this.customerTypes = response.data.payload;
        });
    }

    getCustomerVertical = (customer) => {
        if (customer.type && customer.type.id) {
            BaseCustomer.get("/customerVertical/customerType/active?customerType=" + customer.type.id).then(response => {
                this.customerVerticals = response.data.payload;
            });
        }
    }

    handleCustomerType = (customer, event) => {
        customer.type = { id: event.target.value };
        if (event.target.value) {
            let type = this.customerTypes.find(customerType => customerType.id === event.target.value);
            customer.type = type;
        }
    };

    handleCustomerPartnerType = (customer, event) => {
        let customerPartnerType = new CustomerPartnerType();
        customerPartnerType.partnerType.id = event.target.value;
        if (event.target.value) {
            let partnerType = this.partnerTypes.find(partnerType => partnerType.id === event.target.value);
            if (partnerType)
                customerPartnerType.partnerType = partnerType;
        }
        customer.partnerTypes[0] = customerPartnerType;
    };

    handlePartner = (customer, event) => {
        customer.partner = event.target.value;
    };

    handleCustomerVertical = (customer, event) => {
        customer.vertical = { id: event.target.value };
    };

    handleUIDType = (customer, event) => {
        customer.evdensUIDType = event.target.value;
    };

    handleSolution = (customer, values) => {
        customer.solutions = values;
    };

    handleGSTin = (customer, event) => {
        customer.gstin = event.target.value;
    };

    handleInputFile = (files, event) => {
        this.props.setFilesEmpty();
        this.handleFileObject(event.target.files, files)
    };

    handleFileObject = (data, files) => {
        let dataObj = data.length !== 0 ? this.handleFile(data) : {};
        Object.keys(dataObj).map(key => {
            files[key] = data[key];
            return true;
        });
        if (!this.isFileTooLarge) {
            this.props.getImage();
        }
    };

    handleFile = (files) => {
        this.isFileTooLarge = false;
        if (this.checkImageType(files)) {
            LoadToast(ToastType.WARN, "File Type is not allowed ,Fotmats jpeg, jpg, png");
        } else {
            if (!this.checkFileSize(files)) {
                this.isFileTooLarge = true;
                return {};
            }
            return files;
        }
        return {};
    };
    checkImageType = (files) => {
        let data;
        let allowedExtension = ['jpeg', 'jpg', 'png'];
        Object.keys(files).map(key => {
            data = allowedExtension.filter((data) => {
                if (Object.keys(files).length !== 0) {
                    return files[key].type.includes(data);
                }
            });
        });
        return data.length === 0;
    };

    checkFileSize = (files) => {
        let fileSize = 0;
        Object.keys(files).map(key => {
            fileSize += files[key].size;
            return true;
        });
        return fileSize < 102400;
    };

    validate = () => {
        if (this.isFileTooLarge) {
            return;
        }
        this.props.submit();
    }

    render() {
        let printingPartners = this.props.printingPartners && this.props.printingPartners.map(printingPartner => {
            return { label: printingPartner['name'], value: printingPartner['id'] }
        });

        let solutionOptions = [
            { value: 'Anti-counterfeiting', label: 'Anti-counterfeiting' },
            { value: 'Track-n-Trace', label: 'Track-n-Trace' },
            { value: 'Warranty Protection', label: 'Warranty Protection' },
            { value: 'Secure Delivery', label: 'Secure Delivery' },
            { value: 'Brand Promotion', label: 'Brand Promotion' },
            { value: 'Asset Protection', label: 'Asset Protection' },
        ];

        let isUpdate = false;
        if (this.props.customer.hasOwnProperty('id')) {
            if (this.props.customer.id) {
                isUpdate = true;
            }
        }
        const isNotSupplyChainOrPartner = this.props.customer.customerType !== "SUPPLYCHAIN" && this.props.customer.customerType !== "PARTNER";
        const s = this.props.customer.solution;
        return (
            <Form submit={this.validate}>
                <div className="d-flex flex-wrap">
                    <div className="col-4">
                        <FormGroup controlId="name" bsSize="large">
                            <ControlLabel className="font-weight-bold">Company Name</ControlLabel>
                            <input type="text" className="form-control"
                                value={this.props.customer && this.props.customer.name}
                                disabled={this.props.disableInput}
                                placeholder={"Company Name"}
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)} maxLength={255}
                                id="name" required={true} />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>

                    <div className="col-4">
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">Display Name</ControlLabel>
                            <input type="text" className="form-control"
                                value={this.props.customer && this.props.customer.displayName}
                                disabled={this.props.disableInput}
                                placeholder={"Display Name"}
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)} maxLength={255}
                                id="displayName" required={true} />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="partnerType" bsSize="large">
                            <ControlLabel className="font-weight-bold">Sales Channel</ControlLabel>
                            <select className="form-control"
                                name="color"
                                value={this.props.customer && this.props.customer.partnerTypes && this.props.customer.partnerTypes.length > 0 && this.props.customer.partnerTypes[0]
                                    && this.props.customer.partnerTypes[0].partnerType && this.props.customer.partnerTypes[0].partnerType.id}
                                id="partnerType" disabled={this.props.disableInput}
                                onChange={this.handleCustomerPartnerType.bind(this, this.props.customer)}
                                required={true}
                                onClick={() => this.props.getPartners(this.props.customer && this.props.customer.partnerTypes && this.props.customer.partnerTypes[0])}>
                                <option className="place-holder" value={''}>Select Sales Channel</option>
                                {this.partnerTypes && this.partnerTypes.map((e, key) => {
                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                })
                                }
                            </select>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="onboardThrough" bsSize="large">
                            <ControlLabel className="font-weight-bold">Sales Channel Partner</ControlLabel>
                            <select className="form-control input-height"
                                value={this.props.customer && this.props.customer.partner}
                                onChange={this.handlePartner.bind(this, this.props.customer)} required={true}
                                disabled={this.props.disableInput} id="partner">
                                <option className="place-holder" value={''}>Select Sales Channel Partner</option>
                                {
                                    this.props.partners && this.props.partners.map((e, key) => {
                                        return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                    })
                                }
                                {
                                    this.props.customer && this.props.customer.partnerTypes && this.props.customer.partnerTypes.length > 0 && this.props.customer.partnerTypes[0]
                                    && this.props.customer.partnerTypes[0].partnerType && this.props.customer.partnerTypes[0].partnerType.name === 'Direct' &&
                                    <option className="select-option" value={'Self'}>Self</option>
                                }
                            </select>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">Customer Type</ControlLabel>
                            <select className="form-control"
                                value={this.props.customer && this.props.customer.type && this.props.customer.type.id}
                                disabled={this.props.disableInput}
                                onChange={this.handleCustomerType.bind(this, this.props.customer)}
                                required={true}
                                id="type" onClick={() => this.getCustomerVertical(this.props.customer)}>
                                <option className="place-holder" value={''}>Select Customer Type</option>
                                {this.customerTypes.map((e, key) => {
                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                })
                                }
                            </select>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {(isNotSupplyChainOrPartner && this.customerVerticals.length > 0) && <div className="col-4">
                        <FormGroup controlId="customerVertical" bsSize="large">
                            <ControlLabel className="font-weight-bold">Industry Vertical</ControlLabel>
                            <select className="form-control"
                                value={this.props.customer && this.props.customer.vertical && this.props.customer.vertical.id}
                                disabled={this.props.disableInput}
                                onChange={this.handleCustomerVertical.bind(this, this.props.customer)}
                                id="customerVertical" required={this.customerVerticals.length > 0}>
                                <option className="place-holder" value={''}>Select Industry Vertical</option>
                                {this.customerVerticals.map((e, key) => {
                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                })
                                }
                            </select>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="customerCareNo" bsSize="large">
                            <ControlLabel className="font-weight-bold">Customer Care Phone Number</ControlLabel>
                            <input type="tel" className="form-control"
                                value={this.props.customer && this.props.customer.customerCareNumber}
                                disabled={this.props.disableInput}
                                placeholder={"Customer Care Phone Number"}
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)}
                                maxLength={255}
                                id="customerCareNumber" required={true} />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">Customer Care Email Id</ControlLabel>
                            <input type="text" className="form-control"
                                value={this.props.customer && this.props.customer.customerCareEmailId}
                                disabled={this.props.disableInput}
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                data-x='{"typeMismatch":"Please enter valid email","patternMismatch":"Please enter valid email"}'
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)}
                                placeholder={"Customer Care Email Id"}
                                maxLength={255}
                                id="customerCareEmailId" required={true} />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {
                        isNotSupplyChainOrPartner && <div className="col-4">
                            <FormGroup controlId="partnerType" bsSize="large">
                                <ControlLabel className="font-weight-bold">Label Supplier</ControlLabel>
                                <Select closeOnSelect={false} isMulti
                                    inputProps={{ requird: true }}
                                    isDisabled={this.props.disableInput}
                                    name="name"
                                    value={this.props.userPrintingPartners && this.props.userPrintingPartners}
                                    onChange={this.props.handlePrintingPartners}
                                    options={printingPartners}>
                                </Select>
                            </FormGroup>
                        </div>
                    }
                    {
                        isNotSupplyChainOrPartner && <div className="col-4">
                            <FormGroup controlId="solution" bsSize="large">
                                <ControlLabel className="font-weight-bold">Evdens UID Type</ControlLabel>
                                <select
                                    className="form-control"
                                    value={this.props.customer && this.props.customer.solution}
                                    disabled={this.props.disableInput}
                                    onChange={this.props.handleUserInput.bind(this, this.props.customer)}
                                    id="solution"
                                    required={true}>
                                    <option className="place-holder" value={''}>Select UID Type</option>
                                    <option className="select-option" value={'evdensCodes'}>evdensCodes</option>
                                    <option className="select-option" value={'evdensDigiSign'}>evdensDigiSign</option>
                                    <option className="select-option" value={'Both'}>Both</option>
                                </select>
                                <div className="invalid error" />
                            </FormGroup>
                        </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4 my-auto">
                        <div className='row mx-0'>
                            <div className='col-5 my-auto pl-0'>
                                <ControlLabel className="mb-0 font-weight-bold">GTIN Must?</ControlLabel>
                            </div>
                            <div className='col-5 my-auto px-0'>
                                <CustomSwitch inputId={'productGtin'} inputName={'productGtin'}
                                    inputOnName={'Yes'}
                                    inputOffName={'No'}
                                    inputValue={this.props.customer}
                                    disabled={this.props.disableInput} />
                            </div>
                        </div>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className='col-4 my-auto'>
                        <div className='row mx-0'>
                            <div className='col-10 pl-0 my-auto'>
                                <ControlLabel className="w-100 mb-0 font-weight-bold">Track Grey Market Diversion?</ControlLabel>
                            </div>
                            <div className='col-2 my-auto px-0'>
                                <CustomSwitch inputId={'trackGreyMarket'} disabled={this.props.disableInput}
                                    inputName={'trackGreyMarket'} inputOnName={'Yes'}
                                    inputOffName={'No'}
                                    inputValue={this.props.customer} />
                            </div>
                        </div>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4" hidden={this.props.disableInput ? true : false}>
                        <FormGroup controlId="logo" bsSize="large" onClick={this.props.showProductImage}>
                            <ControlLabel className="w-100 font-weight-bold">Upload Logo</ControlLabel>
                            <label htmlFor="file-upload" className="btn button-grey-color">
                                {this.props.files.length > 0 ? 'Change File' : 'Browse File'}
                            </label>
                            <input id="file-upload" accept=".png, .jpg, .jpeg" type="file" name="files"
                                disabled={this.props.disableInput}
                                ref={instance => {
                                    this.fileInput = instance
                                }}
                                onChange={this.handleInputFile.bind(this, this.props.files)}
                            />
                            <div className="invalid error" />
                        </FormGroup>
                        {
                            this.isFileTooLarge &&
                            <p className="mb-0 error"> File is too large</p>
                        }
                    </div>
                    }
                    {
                        isNotSupplyChainOrPartner && <div className="col-4">
                            <FormGroup controlId="type" bsSize="large">
                                <ControlLabel className="font-weight-bold">Result Page URL</ControlLabel>
                                <input type="text" className="form-control"
                                    disabled={this.props.disableInput}
                                    placeholder={"Customer Result Page"}
                                    id="customerResultPage" />
                                <div className="invalid error" />
                            </FormGroup>
                        </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">Address</ControlLabel>
                            <textarea
                                className="form-control"
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)}
                                disabled={this.props.disableInput}
                                placeholder={"Customer Address"}
                                maxLength={255}
                                value={this.props.customer && this.props.customer.companyAddress}
                                id="companyAddress" />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">GSTIN</ControlLabel>
                            <input
                                type='text'
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)}
                                maxLength={255}
                                className="form-control"
                                value={this.props.customer && this.props.customer.gstin}
                                disabled={this.props.disableInput}
                                placeholder={"Customer GSTIN"}
                                id="gstin" />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    }
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="solution" bsSize="large">
                            <ControlLabel className="font-weight-bold">Solution</ControlLabel>
                            <Select
                                placeholder="Select solution"
                                closeOnSelect={false}
                                isMulti
                                isDisabled={this.props.disableInput}
                                value={this.props.customer.solutions}
                                name="solutions"
                                onChange={this.handleSolution.bind(this, this.props.customer)}
                                options={solutionOptions}>
                            </Select>
                        </FormGroup>
                    </div>}
                    {isNotSupplyChainOrPartner && <div className='col-4'>
                        <FormGroup controlId="attributes" bsSize="large" onClick={this.props.showSocialMedia}>
                            <ControlLabel className="w-100 font-weight-bold">Social Media</ControlLabel>
                            <button type="button" className="btn btn-sm button-grey-color">View/Modify</button>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>}
                    {isNotSupplyChainOrPartner && <div className='col-4'>
                        <FormGroup controlId="attributes" bsSize="large" onClick={this.props.showResultPageImage}>
                            <ControlLabel className="w-100 font-weight-bold">Result Page</ControlLabel>
                            <button type="button" className="btn btn-sm button-grey-color">View/Modify</button>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>}
                    {isNotSupplyChainOrPartner && <div className="col-4">
                        <FormGroup controlId="solution" bsSize="large">
                            <ControlLabel className="font-weight-bold">Pricing Plan</ControlLabel>
                            <select className="form-control"
                                value={this.props.customer && this.props.customer.pricingPlan}
                                disabled={this.props.disableInput}
                                required={true}
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)}
                                id="pricingPlan"
                            >
                                <option className="select-option" value={''}>Select Pricing Plan</option>
                                <option className="select-option" value={'Basic'}>Basic</option>
                                <option className="select-option" value={'Standard'}>Standard</option>
                                <option className="select-option" value={'Advanced'}>Advanced</option>
                                <option className="select-option" value={'Custom'}>Custom</option>
                            </select>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>}
                    {isNotSupplyChainOrPartner &&
                        <div className='col-4 my-auto'>
                            <div className='row'>
                                <div className='col-10 pr-0 my-auto'>
                                    <ControlLabel className="w-100 mb-0 font-weight-bold">Allow Verification &amp; Authentication?</ControlLabel>
                                </div>
                                <div className='col-2 my-auto px-0'>
                                    <CustomSwitch
                                        inputId={'allowVerification'}
                                        disabled={this.props.disableInput}
                                        inputName={'allowVerification'}
                                        inputOnName={'Yes'}
                                        inputOffName={'No'}
                                        inputValue={this.props.customer} />
                                </div>
                            </div>
                        </div>}
                    {isNotSupplyChainOrPartner && <div className='col-12'>
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">Description of Customer</ControlLabel>
                            <textarea
                                onChange={this.props.handleUserInput.bind(this, this.props.customer)}
                                maxLength={255}
                                className="form-control"
                                value={this.props.customer && this.props.customer.companyAbout}
                                disabled={this.props.disableInput}
                                placeholder={"Short Description of Customer"}
                                id="companyAbout" />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>}
                </div>
                {
                    this.props.customer && this.props.customer.customerStatusAudits && this.props.customer.customerStatusAudits.length > 0 &&
                    <div className="row mx-0 mb-3">
                        <div className="col-12 px-0">
                            <div className="row mx-0">
                                <p className="mb-0 font-weight-bold">Activity Log</p>
                            </div>
                            <div className="row mx-0">
                                {
                                    this.props.customer.customerStatusAudits.map((comment, index) =>
                                        <p className="mb-0 w-100" key={index}>{comment.status === "ACTIVE" ? <span>Active</span> : <span>Inactive</span>} {comment.comment ? <span> - {comment.comment}</span> : <React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    ((Stores.currentPermission.create) && !this.props.disableInput && !isUpdate) ? (
                        <ButtonToolbar className="justify-content-center">
                            <Button className="btn btn-app-secondary" type="reset"
                                onClick={this.props.setNewCustomer}> CLEAR</Button>
                            {
                                this.props.customer.status === "ACTIVE" &&
                                <Button className="btn btn-app-secondary ml-2" type="button" onClick={this.props.suspendCustomer}
                                > SUSPEND</Button>
                            }
                            {
                                this.props.customer.status === "INACTIVE" &&
                                <Button className="btn btn-app-secondary ml-2" type="button" onClick={this.props.activateCustomer}
                                > ACTIVATE</Button>
                            }
                            <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                        </ButtonToolbar>
                    ) :
                        (
                            <React.Fragment></React.Fragment>
                        )

                }
                {
                    ((Stores.currentPermission.modify && this.props.customer && this.props.customer.hasOwnProperty('id')) && !this.props.disableInput && isUpdate) ? (
                        (this.props.customer.id) &&
                        <ButtonToolbar className="justify-content-center">
                            <Button className="btn btn-app-secondary" type="reset"
                                onClick={this.props.setNewCustomer}> CLEAR</Button>
                            {
                                this.props.customer.status === "ACTIVE" &&
                                <Button className="btn btn-app-secondary ml-2" type="button" onClick={this.props.suspendCustomer}
                                > SUSPEND</Button>
                            }
                            {
                                this.props.customer.status === "INACTIVE" &&
                                <Button className="btn btn-app-secondary ml-2" type="button" onClick={this.props.activateCustomer}
                                > ACTIVATE</Button>
                            }
                            <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                        </ButtonToolbar>
                    ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }
            </Form>
        )
    };

}

export { CustomerForm };
