import React from 'react';
import { observer } from 'mobx-react';
import {FeedbackStar} from "./FeedbackStar";

@observer
class FeedbackTableDetails extends React.Component<any, any> {

    starList = (count,id) => {
        let stars:any = [];
        for (let i = 0; i < count; i++) {
            let star = {star:'star',key:id+i}
            stars.push(star);
        }
        for (let i = 0; i < 5-count; i++) {
            let star = {star:'star_border',key:id+i+count}
            stars.push(star);
        }
        return stars;
    };
    render() {

        return(
            <React.Fragment>
                {
                    this.props.feedbacks && this.props.feedbacks.map((feedback,index)=>
                        <tr key={index} className="mx-0 table-body-value ">
                            <td className=" text-center px-0" style={{width:'12%'}}>{feedback.feedbackId}</td>
                            <td className="text-center px-0 text-wrap" style={{width:'13%'}}>{feedback.mobileNumber?feedback.mobileNumber:<span>-</span>}</td>
                            <td className="text-center px-0" style={{width:'12%'}}>{feedback.scanLocation?<a href={'http://maps.google.com/?q='+feedback.scanLocation} target="_blank" rel="noopener noreferrer">{feedback.scanLocation}</a>:<span>-</span>}</td>
                            <td className="text-center text-wrap" style={{width:'13%'}}>{feedback.createdDateConvert?feedback.createdDateConvert:<span>-</span>}</td>
                            <td className="text-center text-wrap" style={{width:'12%'}}> <FeedbackStar rating={feedback.rating} stars={this.starList(feedback.rating,feedback.id)}/>   </td>
                            <td className="px-0 text-center text-wrap" style={{width:'13%'}}>{feedback.secondaryId}</td>
                            <td className="px-0 text-wrap text-center" style={{width:'13%'}}>{feedback.productName}</td>
                            <td className="text-center cursor-pointer feedback-description" style={{width:'12%'}}>{feedback.description}</td>
                        </tr>
                    )
                }
                {
                    this.props.feedbacks && this.props.feedbacks.length===0&&
                    <tr >
                        <td className="text-center error" colSpan={8}>No records found ...</td>
                    </tr>
                }
            </React.Fragment>
        )
    }
}


export {FeedbackTableDetails};
