import * as React from "react";
import DatePicker from 'react-datepicker';
import * as ReactDOM from 'react-dom';
import {observer} from "mobx-react";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {setMinutes, setHours} from "date-fns";

@observer
class DateTimePicker extends React.Component<any, {}> {

    readonly now =  moment();
    readonly PLACEHOLDER             = "yyyy-mmm-dd";
    readonly ITEM_LIST_YEAR_DROPDOWN = 15;
    readonly MINDATE = new Date('1950-01-01') ;
    readonly MAXDATE = new Date().setFullYear(new Date().getFullYear()+100) ;
    readonly MAXTIME = setHours(setMinutes(this.MAXDATE, 45), 23);

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this.refs.myInput);
        if (node instanceof HTMLElement) {
            const child = node.querySelector('.date');
            if(child  instanceof HTMLElement){
                 child.insertAdjacentHTML("afterend", '<div class="invalid error"></div>');;
            }
        }
    }

    render() {
     const date = this.props.value && new Date(this.props.value)
        return (

            <DatePicker ref = "myInput"
                        placeholderText={this.PLACEHOLDER}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="yyyy-MMM-d h:mm aa"
                        showYearDropdown
                        scrollableYearDropdown
                         popperModifiers={{
                            preventOverflow: {
                              enabled: true,
                            },
                          }}
                        yearDropdownItemNumber={this.ITEM_LIST_YEAR_DROPDOWN}
                        className={this.props.className?"form-control date "+this.props.className:"form-control date"}
                        selected={date}
                        maxDate={this.props.maxDate?this.props.maxDate:this.MAXDATE}
                        minDate={this.props.minDate?this.props.minDate:this.MINDATE}
                        minTime={this.props.minTime}
                        maxTime={this.MAXTIME}
                        onChange={this.props.inputChangeHandler}
                        disabled={this.props.disabled}
                        required={this.props.isRequired}
            />
        )
    };
}

export {DateTimePicker};
