import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {Button} from "react-bootstrap";
import { BaseCustomer } from "../../config/HttpClients";
import {Pagination} from "../../constants/pagination/Pagination";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {DateToStringFormat,stringToDateFormat, getLoggedInUser} from "../../constants/CommonMethods";
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";
import {Stores} from "../../store/Stores";
import {Product} from "../../store/Product";
import {Customer} from "../../store/Customer";
import {CustomMessage} from "../../store/CustomMessage";
import {CustomMessageForm} from "./CustomMessageForm";
import {CustomMessageTableDetail} from "./CustomMessageTableDetail"

@observer
class CustomMessages extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable paginationFormat: any;
    @observable deleteAlertModal:any;
    @observable customMessageFormCom:any;
    @observable disabled: boolean = true;
    @observable showCustomer:boolean = false;
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    @observable pageOption: string = 'VIEW';
    @observable customMessages:CustomMessage[] = [];
    @observable customMessage:CustomMessage = new CustomMessage();
    @observable messageType:string = 'all';
    @observable status:string = 'all';
    @observable product:Product = new Product();
    @observable customer = new Customer();
    @observable customers:Customer[] = [];
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init()
        }
    }

    init = () => {
        this.fromDate = DateToStringFormat(new Date(new Date().setDate(new Date().getDate() - 30)));
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat(new Date(new Date().setDate(new Date().getDate() - 30)));
        this.toDateValue = DateToStringFormat(new Date());
        this.customer = Stores.currentCustomer;
        if(Stores.currentUser&&Stores.currentUser.userType.name === "CUSTOMER"){
            this.showCustomer = false;
        } else {
            this.showCustomer = true;
            this.getCustomers();
        }
        this.getCustomMessages();
    }

    getCustomMessages= () => {
        if(!this.product.id){
            this.product.id = 'null';
        }
        if(!this.customer.id){
            this.customer.id = 'null';
        }
        BaseCustomer.get("/customMessage?customer="+this.customer.id+"&messageType="+this.messageType+"&status="+this.status+"&product="+ this.product.id +"&fromDate="+this.fromDateValue+"&toDate="+this.toDateValue+"&pageNo="+this.pageNo+"&limit="+this.limit).then(response => {
            this.customMessages = response.data.payload;
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
         });
    }

    getCustomers=() => {
        BaseCustomer.get("/customer/userType?type=CUSTOMER").then(response=>{
                this.customers = response.data.payload;
        });
    }

    deleteCustomMessage=()=>{
        BaseCustomer.delete("/customMessage?id="+this.customMessage.id).then(response => {
            this.customMessage = new CustomMessage();
            this.deleteAlertModal.close();
            this.getCustomMessages();
        });
    }

    deleteAlert=(event,customMessage)=>{
        event.stopPropagation();
        this.customMessage = customMessage;
        this.deleteAlertModal.show();
    }

    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
        this.pageNo = 0;
        this.getCustomMessages();
    };

    handleCustomer= (customer, event) => {
        customer.id =  event.target.value;
        this.pageNo = 0;
        this.getCustomMessages();
    };

    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
        this.pageNo = 0;
        this.getCustomMessages();
    }

    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
        this.pageNo = 0;
        this.getCustomMessages();
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getCustomMessages();
    }

    handleMessageType = (event) => {
        this.messageType = event.target.value;
        this.pageNo = 0;
        this.getCustomMessages();
    }

    handleStatus = (event) => {
        this.status = event.target.value;
        this.pageNo = 0;
        this.getCustomMessages();
    }

    viewCustomMessage = (event,customMessage) => {
        event.stopPropagation();
        this.customMessage = customMessage;
        this.customMessage.fromDate = stringToDateFormat(this.customMessage.fromDate);
        this.customMessage.toDate = stringToDateFormat(this.customMessage.toDate);
        this.setOption("NEW");
    }

    newCustomMessage = () => {
        this.customMessage = new CustomMessage();
        this.setOption("NEW");
    }

    copyAndNewMessage=()=>{
        let message:CustomMessage = this.customMessage;
        this.customMessage = new CustomMessage();
        this.customMessage = message;
        this.customMessage.id = "";
        this.customMessage.status = "";
        this.customMessage.statusAudits = [];
        this.customMessage.sentCount = 0;
        this.customMessage.viewCount = 0;
        this.customMessageFormCom&&this.customMessageFormCom.init&&this.customMessageFormCom.init();
    }

    navigate = (url: any) => {
        this.history.push(url);
    };

    setOption=(option)=>{
        this.pageOption = option;
        if(this.pageOption ==="VIEW"){
            this.getCustomMessages();
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.pageOption === 'VIEW' &&
                    <div className="col-12 py-3 h-100">
                        <div className="row mx-0 h-100">
                            <div className="col-12 h-100 px-0">
                                <div className="card b-none h-100">
                                    <div className="card-body h-100 px-3">
                                        <div className='row mx-0 mb-1'>
                                            <div className='col-3 pl-0'>From Date</div>
                                            <div className='col-3 pl-0'>To Date</div>
                                            <div className='col-3 pl-0'></div>
                                            <div className='col-3 pl-0 float-right'><div className='col-12 px-0 text-right'>Items per page</div></div>
                                        </div>
                                        <div className='row mx-0 mb-1'>
                                            <div className='col-3 pl-0'>
                                                <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleFromDate}isRequired={true}></Datepicker>
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleToDate} isRequired={true}></Datepicker>
                                            </div>
                                            <div className='col-3 pl-0'></div>
                                            <div className='col-3 pl-0'>
                                                <div className='col-4 px-0 float-right'>
                                                    <select className="form-control"
                                                         value={this.limit} id="customer"
                                                         onChange={this.handlePageLimit.bind(this, this.limit)}>
                                                         <option className="select-option" value={10}>10</option>
                                                         <option className="select-option" value={15}>15</option>
                                                         <option className="select-option" value={20}>20</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mx-0 mb-1'>
                                            <div className='col-3 pl-0'>Message type</div>
                                            <div className='col-3 pl-0'>Status</div>
                                            <div className='col-3 pl-0'>{
                                                this.showCustomer&&<span>Customer</span>}
                                            </div>
                                            <div className='col-3 pl-0'></div>
                                        </div>
                                        <div className='row mx-0 mb-2'>
                                            <div className='col-3 pl-0'>
                                                <select className="form-control"value={this.messageType}id="messageType"
                                                   onChange={this.handleMessageType.bind(this)}>
                                                   <option className="select-option" value={'all'}>All</option>
                                                   <option className="select-option" value={'Awareness'}>Awareness</option>
                                                   <option className="select-option" value={'PromotionalCampaign'}>Promotional Campaign</option>
                                                   <option className="select-option" value={'Offer'}>Offers</option>
                                                   <option className="select-option" value={'CrossSelling'}>Cross selling</option>
                                                 </select>
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <select className="form-control"value={this.status}id="status"
                                                    onChange={this.handleStatus.bind(this)}>
                                                    <option className="select-option" value={'all'}>All</option>
                                                    <option className="select-option" value={'Submitted'}>Submitted</option>
                                                    <option className="select-option" value={'Approved'}>Approved</option>
                                                    <option className="select-option" value={'InForce'}>InForce</option>
                                                    <option className="select-option" value={'Expired'}>Expired</option>
                                                    <option className="select-option" value={'Deactivated'}>Deactivated</option>
                                                </select>
                                            </div>
                                            <div className="col-3 pl-0">
                                                {
                                                    this.showCustomer&&
                                                        <select className="form-control" value={this.customer.id}
                                                        id="customer" onChange={this.handleCustomer.bind(this,this.customer)}>
                                                            <option className="select-option" value={''}>All</option>
                                                            {this.customers.map((e, key) => {
                                                                return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                                            })}
                                                        </select>
                                                }
                                            </div>
                                            <div className='col-3 px-0 float-right'>
                                                {
                                                    !this.showCustomer&&
                                                    <div className='col-11 px-0 ml-2 text-right'>
                                                        <Button className="btn btn-app-primary" type="button" onClick={this.newCustomMessage}>CREATE NEW</Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.totalRecords > 0 &&
                                            <div className="row mx-0">
                                                <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                            </div>
                                        }
                                        <div className="row mx-0 mt-2" style={{height: 'calc(100% - 124px)'}}>
                                            <div className="col-12 h-100 px-0">
                                                <div className="row mx-0">
                                                    <table className="table m-0">
                                                        <thead>
                                                        <tr className="row mx-0 text-nowrap table-header" >
                                                            <th className="col-2 text-center px-0">Message Id</th>
                                                            <th className="col-2 text-center px-0">Message Type</th>
                                                            <th className="col-2 pl-0 text-center">Sent Count</th>
                                                            <th className="col-2 pl-0 text-center">View Count</th>
                                                            <th className="col-2 px-0 text-center">Date Created</th>
                                                            <th className="col-2 px-0 text-center">Status</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div className="row mx-0 custom-scroll" style={{
                                                    marginTop: '-2px',
                                                    height: 'calc(100% - 98px)',
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden'
                                                }}>
                                                    <table className="table">
                                                        <tbody>
                                                           <CustomMessageTableDetail customMessages={this.customMessages} viewCustomMessage={this.viewCustomMessage}
                                                            deleteAlert = {this.deleteAlert} />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Pagination totalRecords={this.totalRecords}
                                                            displayRecords={this.limit} pageNo={this.pageNo}
                                                            partialPageCount={5}
                                                            handleChangePage={this.handleChangePage}
                                                            ref={ref => {
                                                                this.paginationFormat = ref
                                                            }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.pageOption === 'NEW' &&
                    <CustomMessageForm customMessage={this.customMessage} newCustomMessage={this.newCustomMessage} setOption={this.setOption}
                     copyAndNewMessage={this.copyAndNewMessage} ref={(instance) => { this.customMessageFormCom = instance; }} />
                }
                <ErrorAlertModal ref={instance => {this.deleteAlertModal = instance}}
                    submit={this.deleteCustomMessage}/>
            </React.Fragment>
        )
    }
}

const CustomMessagesComponent = withRouter(CustomMessages);
export {CustomMessagesComponent as CustomMessages};
