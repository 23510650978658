import {action, observable} from 'mobx';

class Credential {
    @observable email:string = '';
    @observable password:string = '';
    @observable oldPassword:string = '';
    @observable confirmPassword:string = '';
    @observable otp:string = '';

    @action
    setProduct = (forgotPassword: any) => {
        this.email = forgotPassword.email;
        this.password = forgotPassword.password;
        this.oldPassword = forgotPassword.oldPassword;
        this.confirmPassword = forgotPassword.confirmPassword;
        this.otp = forgotPassword.otp;
        return this;
    }
    @action reset() {
        this.email ='';
        this.password = '';
        this.oldPassword = '';
        this.confirmPassword = '';
        this.otp = '';
    }
}

export {Credential};
