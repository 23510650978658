import { observable} from 'mobx';

class EvdensCodeSourcedConsumedReportDto   {
    @observable sourced:number = 0;
    @observable consumed:number = 0;
    @observable sourcedLastMonth:number = 0;
    @observable consumedLastMonth:number = 0;

}

export {EvdensCodeSourcedConsumedReportDto};
