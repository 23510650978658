import React from 'react';
import { observer } from 'mobx-react';

@observer
class DispatchReportTableDetails extends React.Component<any, any> {
    
    render() {
        return(
            <React.Fragment>
            {
                this.props.dispatchreport && this.props.dispatchreport.map((dispatchreport,index)=>
                        <tr key={index} className="row mx-0 text-nowrap  cursor-pointer table-body-value">
                            <td className="col-2 px-0 text-center text-wrap">{dispatchreport.scannedDateTimeConvert}</td>
                            <td className="col-2 px-0 text-center text-wrap">{`${dispatchreport.firstName} ${dispatchreport.lastName}`}</td>
                            <td className="col-2 px-0 text-center text-wrap">{dispatchreport.secondaryId}</td>
                            <td className="col-2 px-0 text-center text-wrap">{dispatchreport.productName}</td>
                            <td className="col-2 px-0 text-center text-wrap">{dispatchreport.labeledPackageLevel}</td>
                            <td className="col-1 px-0 text-center">{dispatchreport.count}</td>
                            <td className="col-1 px-0 text-center text-wrap">{dispatchreport.status}</td>
                        </tr>                       
                )
            }
            {
                this.props.dispatchreport && this.props.dispatchreport.length===0&&
                <tr >
                <td className="text-center error" colSpan={7}>No records found ...</td>
                </tr>
            }
            </React.Fragment>
        )
    }
}


export {DispatchReportTableDetails};
