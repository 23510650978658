import React from 'react';
import { observer } from 'mobx-react';
import {DatepickerSelect} from "../../constants/formfields/DatepickerSelect";
import evdensCodeConsumeIcon from "../../assets/image/evdens_source.png";

@observer
class EvdensCodeConsumedDetail extends React.Component<any, any> {

    render() {
        return(
            <div className='col-6 p-0'>
                <div className="card border-0 profile-card-with-cover h-100">
                    <div className="card-content">
                        <div className="card-body p-0">
                            <div className="pl-2 pt-2 pr-2">
                                <img src={evdensCodeConsumeIcon} alt="evdenscodeconsume"
                                     width="50"
                                     className="float-left img-fluid card-image-position-right-mid" />
                                <h6 className="border-bottom mb-0 mt-1 font-weight-bold" style={{height:"26px",marginLeft:"56px",color:"#f29d37"}}>Evdens Labels Consumed</h6>
                            </div>
                            <div className="card-body p-2">
                                <table className="w-100 text-center" style={{fontSize:"15px",fontWeight:"normal",display:"grid"}}>
                                    <tbody style={{marginTop:"-15px"}}>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}></td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"31%"}}>Assign & Aggregate</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"19%"}}>Scrapped</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"20%"}}>Replaced</td>
                                    </tr>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}} ><span className="font-size-15 dashBoard-secondary-color font-weight-500">On</span> <DatepickerSelect className={"border-0 bg-white font-size-13 black-color font-weight-500 px-0"} maxDate={new Date()}  minDate={this.props.expectedMinDate} value={this.props.evdensCodeConsumedTodayDate} inputChangeHandler={this.props.handleEvdensCodeConsumedTodayDate}  isRequired={true} disableDate={this.props.disableDate}></DatepickerSelect></td>
                                        <td className="font-size-15 app-logo-primary-color font-weight-600" style={{width:"31%"}}>{this.props.evdensCodeConsumedReportDto.assignedOrAggregated}</td>
                                        <td className="font-size-15 app-logo-secondary-color font-weight-600" style={{width:"19%"}}>{this.props.evdensCodeConsumedReportDto.scraped}</td>
                                        <td className="font-size-15 app-logo-third-color font-weight-600" style={{width:"20%"}}>{this.props.evdensCodeConsumedReportDto.replaced}</td>
                                    </tr>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}><span className="font-size-15 dashBoard-secondary-color font-weight-500">Till</span><DatepickerSelect className={"border-0 bg-white font-size-13 black-color font-weight-500 pr-0"} maxDate={new Date()}  minDate={this.props.expectedMinDate} value={this.props.evdensCodeConsumedTillDate} inputChangeHandler={this.props.handleEvdensCodeConsumedTillDate}  isRequired={true} disableDate={this.props.disableDate}></DatepickerSelect></td>
                                        <td className="font-size-15 app-logo-primary-color font-weight-600" style={{width:"31%"}}>{this.props.evdensCodeConsumedReportDto.assignedOrAggregatedTill}</td>
                                        <td className="font-size-15 app-logo-secondary-color font-weight-600" style={{width:"19%"}}>{this.props.evdensCodeConsumedReportDto.scrapedTill}</td>
                                        <td className="font-size-15 app-logo-third-color font-weight-600" style={{width:"20%"}}>{this.props.evdensCodeConsumedReportDto.replacedTill}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {EvdensCodeConsumedDetail };
