import * as React from 'react'

import { withRouter} from "react-router";
import { ProductTableDetails } from './ProductTableDetails';
import { observable } from 'mobx';
import { Product } from '../../store/Product';
import { BaseProduct } from '../../config/HttpClients';
import { observer } from 'mobx-react';
import { PackagingLevelForm } from '../manage-product/PackagingLevelForm';
import { ProductAttributeForm } from '../manage-product/ProductAttributeForm';
import { ProductImage } from '../manage-product/ProductImage';
import {Pagination} from "../../constants/pagination/Pagination";
import {viewDateFormat} from "../../constants/CommonMethods";
import {Stores} from "../../store/Stores";

@observer
class ApproveNewProduct extends React.Component<any,any> {
    @observable products:Product[] = [];
    @observable product:Product = new Product();
    @observable option:string='';
    @observable imgUrl:any;
    @observable search:string = '';
    @observable pageNo:number = 0;
    @observable limit:number = 10;
    @observable totalRecords:number = 0;
    @observable paginationFormat:any;
    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;      
        this.getAllNewProduct(); 
    }

    getAllNewProduct=()=>{
        if(Stores.currentCustomer && Stores.currentCustomer.id) {
            BaseProduct.get("/product/new?pageNo="+this.pageNo+"&limit="+this.limit+"&customerId="+Stores.currentCustomer.id).then(response=>{
                this.products = response.data.payload;
                this.products.map((product)=> {
                    product.createdDate = viewDateFormat(product.createdAt);
                    return true;
                });
                this.totalRecords = response.data.count;
                this.paginationFormat.totalRecordsCount();
            });
        }
    }

    getproductImage= (fileName) => {
        BaseProduct.get("/product/image?fileName="+fileName,{headers:{Accept:"*/*"},responseType: "blob"}).then(response=>{
            let blob = new Blob([response.data], { type:response.headers["content-type"]});
            this.imgUrl = URL.createObjectURL(blob);
        });
     }
    setSelectedProduct=(product)=>{
        this.product = product;
        if(this.product.fileName){
            this.getproductImage(this.product.fileName);
        }
    }
    setOption=(option)=>{
        this.option = option;
        if(this.option === ''){
            this.imgUrl = '';
        }
    }

    handleSearchInput=(event)=>{
        this.search = event.target.value;
        this.getAllNewProduct();
    }

    handleChangePage=(pageNo)=>{
        this.pageNo = pageNo;
        this.getAllNewProduct();
    }
   
    render() {
        return (
            <div className="col-12 p-0">
                <div className="pt-3">
                <div className="row mx-0" >
                    <div className="col-8 pr-0">
                        <div className="card">
                            <div className="card-body pt-0 px-0">
                                <table className="table mb-0">
                                    <thead >
                                        <tr className="row table-header mx-0">
                                            <th className="col-3 text-center">Customer Name</th>
                                            <th className="col-2 px-0 text-center">Date Registered</th>
                                            <th className="col-3 text-center">Product Name</th>
                                            <th className="col-2 text-center">Status</th>
                                            <th className="col-2 text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <ProductTableDetails setSelectedProduct={this.setSelectedProduct} setOption={this.setOption} products={this.products} getAllNewProduct={this.getAllNewProduct}/>
                                </table>
                                <Pagination totalRecords={this.totalRecords}
                                            displayRecords={this.limit} pageNo={this.pageNo}
                                            partialPageCount={5} handleChangePage={this.handleChangePage}
                                            ref={ref => {
                                                this.paginationFormat = ref
                                            }}/>
                            </div>
                        </div> 
                    </div>
                    <div className="col-4"> 
                        <div className="card">
                            <div className="card-body" style={{minHeight:"200px"}}>               <div className="pt-2">
                                    <React.Fragment>
                                    {
                                        this.option==='pacageLevel'&&
                                        <PackagingLevelForm packagingLevels={this.product.packagingLevels} disabled={true}/>
                                    }
                                    {
                                        this.option ==='image'&&
                                        <ProductImage imgUrl={this.imgUrl} disabled={true}/>
                                    }
                                    {
                                        this.option==='attribute'&&
                                        <ProductAttributeForm attributes={this.product.attributes} disabled={true} />
                                    }
                                    </React.Fragment>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

const ApproveNewProductComponent = withRouter(ApproveNewProduct);
export {ApproveNewProductComponent as ApproveNewProduct};

