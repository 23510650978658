import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ViewProductDetails } from './ViewProductDetails';
import {BaseProduct, BaseUser} from '../../config/HttpClients';
import {ProductNotification} from "../../store/ProductNotification";
import MaterialIcon from 'material-icons-react';

@observer
class ProductTableDetails extends React.Component<any, any> {

    @observable productShow:string='';
    @observable productNotification = new ProductNotification();
    setProduct=(product)=>{
        this.productShow = product.id;
        this.props.setOption('');
        this.props.setSelectedProduct(product);
    }
    closeProduct=()=>{
        this.productShow='';
        this.props.setOption('');
    }
    updateProduct=(product)=>{
        BaseProduct.put("/product/requestProduct",product).then(response=>{
            this.productNotification.status = product.productStatus;
            this.getUserById(product.createdBy);
            this.props.getAllNewProduct();
        });
    }
    getUserById=(id)=>{
        BaseUser.get("/user/id/"+id).then(response=>{
            this.productNotification.registeredBy = response.data.payload.userName;
            this.sendProductStatusNotification(this.productNotification);
        });
    }
    sendProductStatusNotification=(productNotification)=>{
        BaseUser.post("/email/productApproval",productNotification).then(response=>{
        });
    }
    render() {
        return(
            <tbody>
            {
                this.props.products&&this.props.products.map((product,index) =>
                <React.Fragment key={product.id}>
                <tr className="row mx-0 table-body-value" key={product.id}>
                    <td className="col-3 text-center">{product.customerName}</td>
                    <td className="col-2 text-center text-nowrap">{product.createdDate}</td>
                    <td className="col-3 text-center">{product.name}</td>
                    <td className="col-2 text-center">{product.productStatus}</td>
                    <td className="col-2 text-center key-arrow">
                        {
                        this.productShow===product.id&&
                            <React.Fragment>
                                <div className="cursor-pointer key-arrow-up">
                                    <MaterialIcon icon="keyboard_arrow_up"  onClick={()=>this.closeProduct()}/>
                                </div>
                            </React.Fragment>

                        }{
                            this.productShow!==product.id&&
                            <React.Fragment>
                                <div className="cursor-pointer">
                                    <MaterialIcon icon="keyboard_arrow_down"  onClick={()=>this.setProduct(product)}/>
                                </div>
                            </React.Fragment>
                        }
                    </td>             
                </tr>
                {
                    this.productShow===product.id&&
                    <tr className="row mx-0" key={index}>
                        <ViewProductDetails updateProduct={this.updateProduct} setOption={this.props.setOption} product={product}/> 
                    </tr>
                }
             </React.Fragment>
            )}
            {
                this.props.products.length===0&&
                <tr>
                <td className="text-center error" colSpan={7}>No records found ...</td>
            </tr>
            }
            </tbody>
        )
    }
}


export {ProductTableDetails};
