import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {CustomSwitch} from '../../constants/formfields/CustomSwitch';
import {Stores} from '../../store/Stores';
import {ControlLabel} from "react-bootstrap";
import {StatusAudit} from "../../store/StatusAudit";
import {viewDateTimeFormat} from "../../constants/CommonMethods";

@observer
class ViewProductDetails extends React.Component<any, any> {
    @observable commentError: boolean = false;
    @observable comment: string = '';
    @observable isCustomerPath: boolean = false;

    constructor(props) {
        super(props);
        let paths = window.location.pathname.split('/');
        if (paths[1] === "customers") {
            this.isCustomerPath = true;
        }
    }

    handleComment = (product, event) => {
        this.comment = event.target.value;
    };
    updateStatus = (status, product) => {
        if (!this.comment) {
            this.commentError = true;
            return;
        }
        this.commentError = false;
        let productStatusAudit = new StatusAudit();
        productStatusAudit.status = status;
        productStatusAudit.comment = this.comment;
        productStatusAudit.updatedUser = Stores.currentUser.firstName;
        productStatusAudit.createdAt = new Date();
        if(product.productStatusAudits&&product.productStatusAudits.length > 0) {
            product.productStatusAudits.push(productStatusAudit);
        } else {
            let statusAudits:StatusAudit[] = [];
            statusAudits.push(productStatusAudit);
            product.productStatusAudits = statusAudits;
        }
        //product.productStatusAudits.push(productStatusAudit);
        product.productStatus = status;
        this.props.updateProduct(product);
        this.comment = '';
    }

    render() {
        let isShow = true;
        if (this.props.product.productStatus !== "" && this.props.product.productStatus === 'Needs Approval' && this.isCustomerPath) {
            isShow = false;
        }

        return (
            <div className="card w-100 shadow-none product-details" style={{background: "#F7F7F7"}}>
                <div className="card-body">
                    <div className="row mx-0 mb-3">
                        <p className="mb-0 header-value">Product Details</p>
                    </div>
                    <div className="row mx-0 mb-3">
                        <div className="col-3 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">Product Category</p>
                            </div>
                            <div className="row mx-0">
                                <p className="mb-0 header-value">{this.props.product.category && this.props.product.category.name}</p>
                            </div>
                        </div>
                        <div className="col-2 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">Sub-category </p>
                            </div>
                            <div className="row mx-0">
                                <p className="mb-0 header-value">{this.props.product.subCategory && this.props.product.subCategory}</p>
                            </div>
                        </div>
                        <div className="col-2 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">Brand Name </p>
                            </div>
                            <div className="row mx-0 ">
                                <p className="mb-0 header-value">{this.props.product.brandName}</p>
                            </div>
                        </div>
                        <div className="col-3 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">Product Name </p>
                            </div>
                            <div className="row mx-0 ">
                                <p className="mb-0 header-value">{this.props.product.name}</p>
                            </div>
                        </div>
                        <div className="col-2 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">GTIN/Product Id </p>
                            </div>
                            <div className="row mx-0">
                                <p className="mb-0 header-value">{(this.props.product.gtin) ? (
                                    <span>{this.props.product.gtin}</span>) : (
                                    <span>-</span>)}/{this.props.product.productId}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 mb-3">
                        <div className="col-3 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">Product Image</p>
                            </div>
                            <div className="row mx-0">
                                <p className="mb-0 cursor-pointer link" onClick={() => this.props.setOption('image')}>
                                    <u>View</u></p>
                            </div>
                        </div>
                        <div className="col-3 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">Product Attributes </p>
                            </div>
                            <div className="row mx-0">
                                <p className="mb-0 cursor-pointer link"
                                   onClick={() => this.props.setOption('attribute')}><u>View</u></p>
                            </div>
                        </div>
                        <div className="col-3 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 header">Packaging Levels</p>
                            </div>
                            <div className="row mx-0 ">
                                <p className="mb-0 cursor-pointer link"
                                   onClick={() => this.props.setOption('pacageLevel')}><u>View</u></p>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className='col-4 my-auto'>
                            <div className='row'>
                                <div className='col-8 px-0 my-auto'>
                                    <ControlLabel className="w-100 mb-0 header-value">Is Dispatch Must?</ControlLabel>
                                </div>
                                <div className='col-4 my-auto pl-0'>
                                    <CustomSwitch inputId={'dispatchMust'}
                                                  inputName={'dispatchMust'} inputOnName={'Yes'}
                                                  inputOffName={'No'}
                                                  inputValue={this.props.product} disabled={true}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 my-auto'>
                            <div className='row'>
                                <div className='col-8 px-0 my-auto'>
                                    <ControlLabel className="w-100 mb-0 header-value">Allow Dispatch of Primary
                                        Products?</ControlLabel>
                                </div>
                                <div className='col-4 my-auto pl-0'>
                                    <CustomSwitch inputId={'allowPrimaryDispatch'}
                                                  inputName={'allowPrimaryDispatch'} inputOnName={'Yes'}
                                                  inputOffName={'No'}
                                                  inputValue={this.props.product} disabled={true}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 my-auto'>
                            <div className='row'>
                                <div className='col-9 px-0 my-auto'>
                                    <ControlLabel className="w-100 mb-0 header-value">Notify Consumer on Grey Market
                                        Product?</ControlLabel>
                                </div>
                                <div className='col-3 my-auto pl-0'>
                                    <CustomSwitch inputId={'notifyGMD'}
                                                  inputName={'notifyGMD'} inputOnName={'Yes'} inputOffName={'No'}
                                                  inputValue={this.props.product} disabled={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mx-0 mt-3'>
                        <ControlLabel className="mb-0 inner-header">Show to Consumer?</ControlLabel>
                        <div className='col-12 p-2' style={{border: '1px solid #e8e6e6'}}>
                            <div className='row mx-0'>
                                <div className='col-4 pl-0'>
                                    <div className="row">
                                        <div className='col-7 my-auto'>
                                            <ControlLabel className="mb-0 header-value">Batch/Lot Number</ControlLabel>
                                        </div>
                                        <div className='col-3 my-auto px-0'>
                                            <CustomSwitch inputId={'batchNo'} inputName={'batchNo'} inputOnName={'Yes'}
                                                          inputOffName={'No'} inputValue={this.props.product}
                                                          disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 pl-0'>
                                    <div className="row">
                                        <div className='col-8 my-auto'>
                                            <ControlLabel className="mb-0 header-value">Manufacturing
                                                Date</ControlLabel>
                                        </div>
                                        <div className='col-3 px-0 my-auto'>
                                            <CustomSwitch inputId={'manufactureDate'} inputName="manufactureDate"
                                                          inputOnName={'Yes'} inputOffName={'No'}
                                                          inputValue={this.props.product} disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 pl-0'>
                                    <div className="row">
                                        <div className='col-6 my-auto'>
                                            <ControlLabel className="mb-0 header-value">Expiry Date</ControlLabel>
                                        </div>
                                        <div className='col-3 my-auto px-0'>
                                            <CustomSwitch inputId={'expiryDate'} inputName={'expiryDate'}
                                                          inputOnName={'Yes'} inputOffName={'No'}
                                                          inputValue={this.props.product} disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mx-0 my-3'>
                        <ControlLabel className="mb-0 inner-header">Warranty Details</ControlLabel>
                        <div className='col-12 p-2' style={{border: '1px solid #e8e6e6'}}>
                            <div className='row mx-0'>
                                <div className='col-6 pl-0'>
                                    <div className='row'>
                                        <div className='col-6 my-auto'>
                                            <ControlLabel className="mb-0 header-value">Warranty
                                                Applicable?</ControlLabel>
                                        </div>
                                        <div className='col-2 px-0 my-auto'>
                                            <CustomSwitch inputId={'warranty'} disabled={true}
                                                          inputName="warranty" inputOnName={'Yes'} inputOffName={'No'}
                                                          inputValue={this.props.product}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 pl-0'>
                                    {
                                        this.props.product.warranty === true &&
                                        <div className='row'>
                                            <div className='col-8 my-auto'>
                                                <ControlLabel className="mb-0 header-value">Enable Warranty
                                                    Registration?</ControlLabel>
                                            </div>
                                            <div className='col-2 px-0 my-auto'>
                                                <CustomSwitch inputId={'warrantyRegistration'} disabled={true}
                                                              inputName={'warrantyRegistration'} inputOnName={'Yes'}
                                                              inputOffName={'No'}
                                                              inputValue={this.props.product}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='row mx-0'>
                                <div className='col-6 pl-0'>
                                    {
                                        this.props.product.warranty === true &&
                                        <React.Fragment>
                                            <div className="row mx-0">
                                                <ControlLabel className="w-100 mb-0 header">Warranty Duration in
                                                    Months</ControlLabel>
                                            </div>
                                            <div className="row mx-0">
                                                <p className="mb-0 header-value"> {this.props.product.warrantyDuration}</p>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className='col-6 pl-0'>
                                    {
                                        this.props.product.warrantyRegistration === true &&
                                        <React.Fragment>
                                            <div className="row mx-0">
                                                <ControlLabel className="w-100 mb-0 header">Warranty Registration URL</ControlLabel>
                                            </div>
                                            <div className="row mx-0">
                                                <p className="mb-0 header-value">{this.props.product.warrantyRegistrationUrl}</p>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 mb-3">
                        <div className="col-6 pl-0">
                            <div className="row mx-0">
                                <p className="mb-0 inner-header">Reason for Approve/Send Back/Reject</p>
                            </div>
                            <div className="row mx-0">
                                <textarea className="form-control inner-header"
                                          name="comment" placeholder="Comments" style={{height: '80px'}}
                                          id="comment" onChange={this.handleComment.bind(this, this.props.product)}
                                          value={this.comment} maxLength={255}
                                          rows={5}/>
                            </div>
                            {
                                ((this.commentError) && (!this.comment)) ? (
                                        <div className="error">
                                            This field is required
                                        </div>
                                    ) :
                                    (<React.Fragment></React.Fragment>
                                    )
                            }
                        </div>
                        <div className="col-6 px-0">
                            <div className="row mx-0">
                                <p className="mb-0 inner-header">Activity Log</p>
                            </div>
                            <div className="row mx-0">
                                {
                                    this.props.product.productStatusAudits&&this.props.product.productStatusAudits.map((comment,index) =>
                                        <p className="mb-0 w-100" key={index}>{comment.status} - {comment.comment} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 mb-3">
                        <div className="col-6 pl-0">
                        </div>
                        <div className="col-6 px-0">
                            <div className="row mx-0">
                                {
                                    (((Stores.currentPermission.create) && (this.props.product && this.props.product.id)) || (Stores.currentPermission.modify && (this.props.product && this.props.product.id))) ? (
                                            <React.Fragment>
                                                <button type="button" className="mb-2 mr-2 btn btn-sm btn-app-secondary"
                                                        onClick={() => this.updateStatus('Rejected', this.props.product)}
                                                        hidden={(this.props.product.productStatus === 'Needs Approval' && isShow) ? false : true}>Reject
                                                </button>
                                                <button type="button" className="mb-2 mr-2 btn btn-sm btn-app-secondary"
                                                        onClick={() => this.updateStatus('Sent Back', this.props.product)}
                                                        hidden={(this.props.product.productStatus === 'Needs Approval' && isShow) ? false : true}>Send
                                                    Back
                                                </button>
                                                <button type="button" className="mb-2 mr-2 btn btn-sm btn-app-secondary"
                                                        onClick={() => this.updateStatus('Suspended', this.props.product)}
                                                        hidden={((this.props.product.productStatus === 'Approved' || this.props.product.productStatus === 'Activated') && isShow && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ? false : true}>Suspend
                                                </button>
                                                <button type="button" className="mb-2 mr-2 btn btn-sm btn-app-secondary"
                                                        onClick={() => this.updateStatus('Activated', this.props.product)}
                                                        hidden={(this.props.product.productStatus === 'Suspended' && isShow && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ? false : true}>Activate
                                                </button>
                                                <button type="button" className="mb-2 mr-2 btn btn-sm btn-app-primary"
                                                        onClick={() => this.updateStatus('Approved', this.props.product)}
                                                        hidden={(this.props.product.productStatus === 'Needs Approval' && isShow) ? false : true}>Approve
                                                </button>
                                            </React.Fragment>
                                        ) :
                                        (
                                            <React.Fragment></React.Fragment>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export {ViewProductDetails};
