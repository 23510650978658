import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {Notification} from "../../store/Notification";
import {BaseNotification} from "../../config/HttpClients";
import {Button, ButtonToolbar} from "react-bootstrap";
import Form from "../../constants/Form";
import {Customer} from "../../store/Customer";
import {NotificationDetailsPagination} from "../../constants/pagination/NotificationDetailsPagination";
import {ScannedImage} from "./ScannedImage";
import {stringToDateFormat, viewDateFormat, viewDateTimeFormat, checkStringIsJson} from "../../constants/CommonMethods";
import {StatusAudit} from "../../store/StatusAudit";
import {Stores} from "../../store/Stores";

@observer
class NotificationDetails extends React.Component<any, any> {
    @observable notification = new Notification();
    @observable comment: string = '';
    @observable justificationError: boolean = false;
    @observable totalPage = 0;
    @observable pageNo = 0;
    @observable customer: Customer = new Customer();
    @observable paginationFormat: any;
    @observable alertId: string = '';
    @observable alertType: string = '';
    @observable userType: string = '';
    @observable search: string = '';
    @observable status: string = '';
    @observable secondaryId: string = '';
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable scannedImgUrl1: any;
    @observable scannedImgUrl2: any;
    @observable scannedImgUrl3: any;
    @observable scannedImgUrl4: any;
    @observable referenceImgUrl: any;
    @observable showScannedImg: boolean = false;
    @observable showReferenceImg: boolean = false;
    history: any;
    @observable isConsumer: boolean = false;
    @observable isVerifiedOrAuth: boolean = false;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.pageNo = this.props.currentPageNo;
        this.totalPage = this.props.totalPage;
        this.fromDateValue = this.props.fromDateValue;
        this.toDateValue = this.props.toDateValue;
        this.search = this.props.search;
        this.alertType = this.props.alertType;
        this.userType = this.props.userType;
        this.secondaryId = this.props.secondaryId;
        this.status = this.props.status;
        this.getNotificationsBySecondaryId();


    }

    getNotificationsBySecondaryId = () => {
        let search;
        let status;
        let userType;
        let alertType;
        let fromDateValue;
        let toDateValue;
        if (!this.customer.id) {
            this.customer.id = 'null';
        }
        if (this.search !== '') {
            search = this.search;
        } else {
            search = 'null';
        }
        if (this.status !== '') {
            status = this.status;
        } else {
            status = 'null';
        }
        if (this.userType !== '') {
            userType = this.userType;
        } else {
            userType = 'null';
        }
        if (this.alertType !== '') {
            alertType = this.alertType;
        } else {
            alertType = 'null';
        }
        if(this.fromDateValue !== ''){
            fromDateValue = this.fromDateValue;
        } else {
            fromDateValue = 'null';
        }
        if(this.toDateValue !== ''){
            toDateValue = this.toDateValue;
        } else {
            toDateValue = 'null';
        }
        BaseNotification.get("/notification/secondaryId?pageNo=" + this.pageNo + "&limit=1&fromDate=" + fromDateValue + "&toDate=" + toDateValue + "&secondaryId=" + this.secondaryId + "&type=Notification&alertType=" + alertType + "&from=" + userType + "&customer=" + this.customer.id + "&status=" + status + "&search=" + search).then(response => {
            this.notification = response.data.payload[0];
            if (this.notification.id) {
                this.notification.labelGeneratedDateConvert = viewDateFormat(this.notification.labelGeneratedDate);
                this.notification.createdDateConvert = viewDateTimeFormat(this.notification.createdDate);
            }
            this.paginationFormat.totalRecordsCount();
            this.showScannedImg = false;
            this.scannedImgUrl1 = '';
            this.scannedImgUrl2 = '';
            this.scannedImgUrl3 = '';
            this.scannedImgUrl4 = '';
            if(this.notification.reasonJson && checkStringIsJson(this.notification.reasonJson)) {
                var reason = JSON.parse(this.notification.reasonJson);
                var distHref = "";
                if(reason["lastScannedLocation"]) {
                    distHref = "<a href=http://maps.google.com/?q=" + reason["lastScannedLocation"] + " target=_blank " +
                                                "rel=noopener noreferrer>" + reason["lastScannedLocation"] + ", </a>";
                }
                var distHtml = "";
                if(reason["distance"]) {
                    var dist = Number(reason["distance"]).toFixed(2);
                    distHtml = "<span>" + dist + ", </span>";
                }
                var dateHtml = "";
                if(reason["lastScannedDate"]) {
                    var date = viewDateTimeFormat(reason["lastScannedDate"]);
                    dateHtml = "<span>" + date + ", </span>";
                }

                var detectedHtml = "";
                if(reason["EdgesDetected"]) {
                    detectedHtml = "<span>" + reason["EdgesDetected"] + "</span>";
                }

                var edgesHtml = "";
                if(reason["Assigned Edge"]) {
                    var edges = reason["Assigned Edge"];
                    edgesHtml = "<span>" + edges["bLeftLeft"] + "," + edges["bLeftRight"] + "," + edges["bRightLeft"] + "," + edges["bRightRight"] + "</span>";
                }
                
                this.notification.reason = "<div>" + distHref + distHtml + dateHtml + detectedHtml + edgesHtml + "</div>";
            }
            if (this.notification.scannedImageKey1) {
                this.getScannedImage(this.notification.scannedImageKey1,  "scannedImgUrl1");
            }
            if (this.notification.scannedImageKey2) {
                this.getScannedImage(this.notification.scannedImageKey2,  "scannedImgUrl2");
            }
            if (this.notification.scannedImageKey3) {
                this.getScannedImage(this.notification.scannedImageKey3,  "scannedImgUrl3");
            }
            if (this.notification.scannedImageKey4) {
                this.getScannedImage(this.notification.scannedImageKey4,  "scannedImgUrl4");
            }
            if (this.notification.referenceImageKey) {
                this.getScannedImage(this.notification.referenceImageKey, "referenceImgUrl");
            }
            if (this.notification.userType === 'CONSUMER') {
                this.isConsumer = true;
            }
            
            if (this.notification.scanType && (this.notification.scanType === 'Verified' || 
                    this.notification.scanType === 'Authenticated')) {
                this.isVerifiedOrAuth = true;
            }
        });
    }

    getScannedImage = (fileName, ImageUrl) => {
        BaseNotification.get("/notification/image?fileName=" + fileName, {
            headers: {Accept: "*/*"},
            responseType: "blob"
        }).then(response => {
            let blob = new Blob([response.data], {type: response.headers["content-type"]});
            if (ImageUrl == "scannedImgUrl1")
                this.scannedImgUrl1 = URL.createObjectURL(blob);
            if (ImageUrl == "scannedImgUrl2")
                this.scannedImgUrl2 = URL.createObjectURL(blob);
            if (ImageUrl == "scannedImgUrl3")
                this.scannedImgUrl3 = URL.createObjectURL(blob);
            if (ImageUrl == "scannedImgUrl4")
                this.scannedImgUrl4 = URL.createObjectURL(blob);
            if (ImageUrl == "referenceImgUrl")
                this.referenceImgUrl = URL.createObjectURL(blob);
        });
    }

    updateNotification = () => {
        this.setDateFormet(this.notification);
        let statusAudit = new StatusAudit();
        statusAudit.status = this.notification.status;
        statusAudit.comment = this.comment;
        statusAudit.updatedUser = Stores.currentUser.firstName;
        statusAudit.createdAt = new Date().toUTCString();
        if(this.notification.notificationStatusAudits&&this.notification.notificationStatusAudits.length > 0) {
            this.notification.notificationStatusAudits.push(statusAudit);
        } else {
            let statusAudits:StatusAudit[] = [];
            statusAudits.push(statusAudit);
            this.notification.notificationStatusAudits = statusAudits;
        }
        BaseNotification.put("/notification", this.notification).then(response => {
            this.comment = '';
            this.getNotificationsBySecondaryId();
        });
    }

    handleComment = (product, event) => {
        this.comment = event.target.value;
    };

    setDateFormet = (notification) => {
        notification.labelGeneratedDateConvert = stringToDateFormat(notification.labelGeneratedDateConvert);
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getNotificationsBySecondaryId();
    }

    handleBack = () => {
        this.props.setOption('NOTIFICATION');
    }

    handleScannedImageShow = () => {
        this.showScannedImg = !this.showScannedImg;
    }

    handleReferenceImageShow = () => {
        this.showReferenceImg = !this.showReferenceImg;
    }

    handleUserInput = (targetObject, event) => {
        targetObject[event.target.name] = event.target.value;
    };

    navigate = (url: any) => {
        this.history.push(url);
    };

    render() {
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

        return (
            <div className="col-12 pt-3 h-100 product-details">
                <div className="pb-2 row mx-0">
                    <div className='col-6  h-42'>
                        <b style={{letterSpacing: "1px", fontSize: "20px"}}>Notification Details </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0" style={{height:'calc(100% - 50px)'}}>
                    <div className="col-8 pl-0 pb-3 h-100">
                        <div className="card b-none h-100">
                            <div className="card-body h-100  pr-2">
                                <div className="row mx-0 mb-2">
                                    <div className="col-8">
                                        <NotificationDetailsPagination totalPage={this.totalPage}
                                            pageNo={this.pageNo} partialPageCount={5}
                                            handleChangePage={this.handleChangePage}
                                            ref={ref => {this.paginationFormat = ref}}/>
                                    </div>
                                    <div className="col-4 my-auto text-right">
                                        <p className="mb-0 header">Success Count: <span className="header-value">{this.notification.successCount}</span> </p>
                                    </div>
                                </div>
                                <div className="notification-details-height">
                                <Form submit={this.updateNotification} >
                                    <div className="h-100 custom-scroll pr-2" style={{overflow:'auto'}}>
                                    <div className="row mx-0">
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Evdens UID</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Evdens UID Type</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Notification Id</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Notification Type</p>
                                        </div>
                                        
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-3 pl-0">
                                            <p className="mb-1 header-value">{this.notification.secondaryId}</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-1 header-value">{this.notification.codeType?this.notification.codeType:<span>-</span>}</p>
                                        </div>
                                        <div className="col-3 px-0">
                                            <p className="mb-1 header-value">{this.notification.notificationId}</p>
                                        </div>
                                        <div className="col-3 px-0">
                                            <p className="mb-1 header-value">{this.notification.notificationType}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-3 px-0">
                                            <p className="mb-0 header">Notification Date</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">Reason</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header">ReasonCode</p>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-3 pl-0">
                                            <p className="mb-1 header-value">{this.notification.createdDateConvert}</p>
                                        </div>
                                        <div className="col-3 pl-0">
                                            {/*<p className="mb-0 header-value">{this.notification.reason?this.notification.reason:<span>-</span>}</p>*/}
                                            <div className="mb-0 header-value">{this.notification.reason?renderHTML(this.notification.reason):<span>-</span>}</div>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <p className="mb-0 header-value">{this.notification.reasonCode?this.notification.reasonCode:<span>-</span>}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <p className="my-1 inner-header">Notification Triggered By</p>
                                        <div className="col-12 card shadow-none" style={{background: "#F7F7F7"}}>
                                            <div className="card-body p-2">
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">User Type</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.userType}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        {
                                                            this.isVerifiedOrAuth &&
                                                            <React.Fragment>
                                                                <div className="row mx-0">
                                                                    <p className="mb-0 header">Scan Type</p>
                                                                </div>
                                                                <div className="row mx-0">
                                                                    <p className="mb-0 header-value">{this.notification.scanType?this.notification.scanType:<span>-</span>}</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    !this.isConsumer &&
                                                    <div className="row">
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Customer/Partner
                                                                    Name</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.customerName ? this.notification.customerName :
                                                                    <span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header">Branch Location Name</p>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <p className="mb-0 header-value">{this.notification.branchLocationName ? this.notification.branchLocationName :
                                                                    <span>-</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">User Identity</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            {
                                                                !this.isVerifiedOrAuth &&
                                                                <p className="mb-0 header-value">{this.notification.firstName} {this.notification.lastName}</p>
                                                            }
                                                            {
                                                                this.isVerifiedOrAuth &&
                                                                <p className="mb-0 header-value">{this.notification.userName ? this.notification.userName :
                                                                    <span>-</span>}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Scan Location</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.location?<a href={'http://maps.google.com/?q='+this.notification.location} target="_blank" rel="noopener noreferrer">{this.notification.location}</a>:<span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Scanned Image</p>
                                                            <button type="button"
                                                                    className="btn btn-sm button-grey-color ml-2"
                                                                    onClick={this.handleScannedImageShow}
                                                            >View
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">User Country</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.userCountry? this.notification.userCountry :<span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-6 pl-0">*/}
                                                        {/*<div className="row mx-0">*/}
                                                        {/*    <p className="mb-0 view-header">Reference Image</p>*/}
                                                        {/*    <button type="button"*/}
                                                        {/*            className="btn btn-sm button-grey-color ml-2"*/}
                                                        {/*            onClick={this.handleReferenceImageShow}*/}
                                                        {/*    >View*/}
                                                        {/*    </button>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">
                                        <p className="mb-1 inner-header">EvdensCode Details</p>
                                        <div className="col-12 card shadow-none" style={{background: "#F7F7F7"}}>
                                            <div className="card-body p-2">
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Customer Name</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.labelCustomer ? this.notification.labelCustomer :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Branch Location Name</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.productionLocation ? this.notification.productionLocation :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Product name</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.productName ? this.notification.productName :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Packaging Level</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.packagedLevel ? this.notification.packagedLevel :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">User Identity</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value"> {this.notification.lastAggregatedUser ? this.notification.lastAggregatedUser :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Label Generated By</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.labelGeneratedPartnerName ? this.notification.labelGeneratedPartnerName :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Destination Country</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.dispatchCountry ? this.notification.dispatchCountry :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header">Date Generated</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                            <p className="mb-0 header-value">{this.notification.labelGeneratedDateConvert ? this.notification.labelGeneratedDateConvert :
                                                                <span>-</span>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">
                                        <div className="col-6 pl-0">
                                            <div className="row mx-0">
                                                <p className="mb-0 header">Status</p>
                                            </div>
                                            <div className="row mx-0">
                                                <select className="form-control" value={this.notification.status}
                                                        name="status"
                                                        onChange={this.handleUserInput.bind(this, this.notification)}
                                                        required={true} id="status">
                                                    <option value={''}>Status</option>
                                                    <option className="select-option" value={'New'}>New</option>
                                                    <option className="select-option" value={'ActionInitiated'}>Action Initiated</option>
                                                    <option className="select-option" value={'Resolved'}>Resolved</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 px-0">
                                            <div className="row mx-0">
                                                <p className="mb-0 header">Comments</p>
                                            </div>
                                            <div className="row mx-0">
                                                    <textarea className="form-control"
                                                              name="justification" style={{
                                                        height: '80px',
                                                        maxHeight: '80px',
                                                        resize: 'none'
                                                    }}
                                                              required={this.notification.status === 'Resolved' || this.notification.status === 'ActionInitiated'}
                                                              id="justification"
                                                              onChange={this.handleComment.bind(this, this.comment)}
                                                              value={this.comment}
                                                              maxLength={50}
                                                              rows={5}/>
                                                <div className="invalid error"/>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.notification&&this.notification.notificationStatusAudits &&  this.notification.notificationStatusAudits.length > 0 &&
                                        <div className="row mx-0 mb-3">
                                            <div className="col-12 px-0">
                                                <div className="row mx-0">
                                                    <p className="mb-0 header">Activity Log</p>
                                                </div>
                                                <div className="row mx-0">
                                                    {
                                                        this.notification.notificationStatusAudits.map((comment,index) =>
                                                            <p className="mb-0 w-100" key={index}>{(comment.status == "ActionInitiated") ? "Action Initiated" : comment.status} {comment.comment?<span> - {comment.comment}</span>:<React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    <ButtonToolbar className="justify-content-center mt-2">
                                        <Button className="btn btn-app-secondary" type="button"
                                                onClick={this.handleBack}> BACK</Button>
                                        <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                                    </ButtonToolbar>

                                </Form>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 px-0">
                        <div className="card">
                            <div className="card-body" style={{minHeight: "200px"}}>
                                <div className="pt-2">
                                    {
                                        (this.showScannedImg && this.scannedImgUrl1) &&
                                        <React.Fragment>
                                            <p className="mb-0">Scanned Image</p>
                                            <ScannedImage imgUrl={this.scannedImgUrl1}/>
                                        </React.Fragment>
                                    }
                                    {
                                        (this.showScannedImg && this.scannedImgUrl2 && (Stores.currentUser && Stores.currentUser.roles.length > 0) && (Stores.currentUser.roles[0].name === "ROLE_VGLENS_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN")) &&
                                            <React.Fragment>
                                                <p className="mb-0">Scanned Image2</p>
                                                <ScannedImage imgUrl={this.scannedImgUrl2}/>
                                            </React.Fragment>
                                        }
                                    {
                                        (this.showScannedImg && this.scannedImgUrl3 && (Stores.currentUser && Stores.currentUser.roles.length > 0) && (Stores.currentUser.roles[0].name === "ROLE_VGLENS_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN")) &&
                                            <React.Fragment>
                                                <p className="mb-0">Scanned Image3</p>
                                                <ScannedImage imgUrl={this.scannedImgUrl3}/>
                                            </React.Fragment>
                                    }
                                    {
                                        (this.showScannedImg && this.scannedImgUrl4 && (Stores.currentUser && Stores.currentUser.roles.length > 0) && (Stores.currentUser.roles[0].name === "ROLE_VGLENS_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN")) &&
                                            <React.Fragment>
                                                <p className="mb-0">Scanned Image4</p>
                                                <ScannedImage imgUrl={this.scannedImgUrl4}/>
                                            </React.Fragment>
                                    }
                                    {
                                        this.showReferenceImg && this.referenceImgUrl &&
                                        <React.Fragment>
                                            <p className="mb-0">Reference Image</p>
                                            <ScannedImage imgUrl={this.referenceImgUrl}/>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const NotificationDetailsComponent = withRouter(NotificationDetails);
export {NotificationDetailsComponent as NotificationDetails};
