import * as React from 'react'
import { withRouter, Switch} from "react-router";
import { AuthRoute } from '../../config/AuthRoute';
import { MyProfileSideNav } from './MyProfileSideNav';
import {MyProfileForm} from "./MyProfileForm";
import {ChangePassword} from './ChangePassword';


class MyProfileLayout extends React.Component<any,any> {

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
    }

    render() {
        return (
            <div className="col-12">
                <div className="custom-height row mx-0">
                    <div className="col-3 pl-0 pt-3">
                        <MyProfileSideNav />
                    </div>
                    <div className="col-9 pl-0">
                        <Switch>
                            <AuthRoute exact path="/myProfile/profile" component={MyProfileForm} />
                            <AuthRoute exact path="/myProfile/changePassword" component={ChangePassword}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}

const MyProfileLayoutLayoutComponent = withRouter(MyProfileLayout);
export {MyProfileLayoutLayoutComponent as MyProfileLayout};

