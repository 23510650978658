import React from 'react';
import { observer } from 'mobx-react';
import { ProductAttribute } from '../../store/ProductAttribute';
import { observable } from 'mobx';
import Form from '../../constants/Form';
import { BaseProduct } from '../../config/HttpClients';


@observer
class ProductAttributeForm extends React.Component<any, any> {
    
    @observable attribute:ProductAttribute = new ProductAttribute();
    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
    }  

    deleteProductAttributeById=(id,index)=>{
        BaseProduct.delete("/product/attribute//"+id).then(response=>{
            this.props.attributes.splice(index, 1);
        });
    }

    addProductAttribute=()=>{
        this.props.attributes.push(this.attribute);
        this.attribute = new ProductAttribute();
        this.attribute.reset();
    }

    deleteProductAttribute=(index)=>{
        if(this.props.attributes[index].id){
            this.deleteProductAttributeById(this.props.attributes[index].id, index);
        } else{
            this.props.attributes.splice(index, 1);
        }
    }

    handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
    };
    handleUserAttributeInput=(index:any, event:any)=>{
        this.props.attributes[index][event.target.id] = event.target.value;
    }

    handleUserShowAuthResult=(event:any)=>{
        this.attribute.showAuth = !this.attribute.showAuth;
    }

    handleUserAttributeShowAuthResult=(index:any, event:any)=>{
       this.props.attributes[index][event.target.id] = !this.props.attributes[index][event.target.id];
    }
    
    render() {
        return(
            <div className="row mx-0">
                <div className="col-12 px-0" style={{height:'300px',overflow:'auto'}}>
                    <div className="header">
                        <h5>Product Attributes</h5>
                    </div>
                    <div>
                        <div className="row mx-0">
                            <div className={this.props.disabled?"col-4 pl-4":"col-3 pl-0"}>
                                <p><b>Attribute Name</b></p>
                            </div>
                            <div className={this.props.disabled?"col-4 pl-1":"col-5 pl-0"}>
                                <p><b>Attribute Value</b></p>
                            </div>
                            <div className={this.props.disabled?"col-4 pl-1 pr-0":"col-2 px-0"}>
                                <p><b>Show to Consumer?</b></p>
                            </div>
                            <div className="col-2 pl-0" hidden={this.props.disabled?true:false}>
                                <p><b>Add/ Remove</b></p>
                            </div>
                        </div>
                        {
                            this.props.attributes.map((attribute,key)=>
                            <div className="row mx-0 mt-2" key={key}>
                                <div className={this.props.disabled?"col-4 pl-4":"col-3 pl-0"}>
                                <input type="text" className="form-control" disabled={this.props.disabled?true:false}
                                       maxLength={255} value={attribute.label} onChange={this.handleUserAttributeInput.bind(this,key)}
                                        id="label" required={true}/>
                                </div>
                                <div className={this.props.disabled?"col-4 pl-1":"col-5 pl-0"}>
                                    <textarea className="form-control" disabled={this.props.disabled?true:false}
                                       maxLength={255} value={attribute.value}onChange={this.handleUserAttributeInput.bind(this,key)}
                                       id="value" required={true}/>
                                </div>
                                 <div className={this.props.disabled?"col-4 pl-1 custom-control custom-checkbox":"col-2 pl-0 custom-control custom-checkbox"} >
                                    <input type="checkbox" id="showAuth" checked={attribute.showAuth} disabled={this.props.disabled?true:false} onChange={this.handleUserAttributeShowAuthResult.bind(this, key)} className="custom-control-input ml-3 mt-2"
                                    />
                                </div>
                                <div className="col-2 pl-0" hidden={this.props.disabled?true:false}>
                                    <img className="cursor-pointer p-2" style={{width:'28px'}} onClick={()=>this.deleteProductAttribute(key)}src={require("../../assets/image/removeIcon.png")} alt="remove"/>
                                     
                                </div>
                            </div>
                        )}
                        <div hidden={this.props.disabled?true:false}>
                        <Form submit={this.addProductAttribute}>
                         <div className="row mx-0 mt-2">
                                <div className="col-3 pl-0">
                                    <input type="text" className="form-control"
                                       maxLength={255} value={this.attribute.label}
                                       onChange={this.handleUserInput.bind(this,this.attribute)}
                                        id="label" required={true}/>
                                       <div className="invalid error"/>
                                </div>
                                <div className="col-5 pl-0">
                                    <textarea  className="form-control"
                                       maxLength={255} value={this.attribute.value}
                                       onChange={this.handleUserInput.bind(this,this.attribute)}
                                       id="value" required={true}/>
                                       <div className="invalid error"/>
                                </div>
                                 <div className={"col-2 pl-0 custom-control custom-checkbox"}>
                                    <input type="checkbox" checked={this.attribute.showAuth} onChange={this.handleUserShowAuthResult.bind(this)} className="custom-control-input ml-3 mt-2"
                                    />
                                </div>
                                <div className="col-32 pl-0">
                                    <button type="submit" style={{background:"transparent",
                                    border: 0}}>
                                        <img className="cursor-pointer" style={{width:'13px'}}
                                        src={require("../../assets/image/addIcon.png")}alt="add"/></button> 
                                </div>
                            </div>
                            </Form>
                            </div>
                    </div>

                    {/* <ButtonToolbar className="justify-content-end">
                        <Button className="btn button-sec-color btn-sm" type="button"> Update</Button>
                    </ButtonToolbar> */}
                </div>
            </div>
        )
    }
}


export {ProductAttributeForm};
