import { observable} from 'mobx';

class NotificationReportDto   {
    @observable statusNew:number = 0;
    @observable statusActionInitiated:number = 0;
    @observable statusResolved:number = 0;
    @observable statusNewTill:number = 0;
    @observable statusActionInitiatedTill:number = 0;
    @observable statusResolvedTill:number = 0;
}

export {NotificationReportDto};
