import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {Button, ButtonToolbar, ControlLabel, FormGroup} from "react-bootstrap";
import Form from '../../constants/Form';
import {  BaseCustomer} from '../../config/HttpClients';
import {observable} from "mobx";
import {Stores} from "../../store/Stores";
import { Role } from '../../store/Role';
import { Branch } from '../../store/Branch';
import InputMask from 'react-input-mask';
import {Customer} from "../../store/Customer";
import {getLoggedInUser,viewDateTimeFormat} from '../../constants/CommonMethods';

@observer
class UserForm extends React.Component<any, any> {

    @observable user = Stores.user;
    @observable roles:Role[] = [];
    @observable  branches:Branch[] = [];
    @observable customer : Customer = new Customer();
    @observable emailMatch:string = "";

    history:any;

    constructor(props) {
        super(props);
        this.history = props.history;
        if(Stores.currentUser.userName === "") {
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }

    }
    componentDidMount() {
        const pasteBox = document.getElementById("confirmEmailId");
        if (pasteBox instanceof HTMLElement) {
            pasteBox.onpaste = e => {
                e.preventDefault();
                return false;
            };
            pasteBox.ondrop = e => {
                e.preventDefault();
                return false;
            };
        }
    }

    init=()=>{
        let paths =  window.location.pathname.split('/');
        if(Stores.currentUser.userType.name === "VGLENS") {
            if(paths[1] === "customers") { this.customer = Stores.customer; }
            if(paths[1] === "partners") { this.customer = Stores.partner; }
            this.getBranches(this.customer.id);
        } else {
            if(paths[1] === "company") { this.customer = Stores.currentCustomer; }
            if(paths[1] === "supplyChain") { this.customer = Stores.partner; }
            this.getBranches( this.customer.id);
        }
    }

    getBranches=(customer)=>{
        if(customer) {
            BaseCustomer.get("/branch/customer?customer="+customer).then(response=>{
                this.branches = response.data.payload;
            });
        }
    }

    handleUserInput = (targetObject:any, event:any) => {
        this.props.setShowSuccess(false);
        targetObject[event.target.id] = event.target.value;
        if(event.target.id === "confirmEmailId") {
            this.checkConfirmEmailId();
        }
    };
    checkConfirmEmailId=()=>{
        this.emailMatch = '';
        if(this.props.user.emailId !== this.props.user.confirmEmailId) {
            this.emailMatch = "Email Id do not match";
        }
    };

    render() {
        let isUpdate = false;
        if(this.props.user.hasOwnProperty('id')) {
            if(this.props.user.id) {
                isUpdate = true;
            }
        }
        return(
            <Form submit={this.props.submit} >
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="firstName" bsSize="large">
                            <ControlLabel className="font-weight-bold">First Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.user&&this.props.user.firstName}
                                   placeholder="First Name" disabled={this.props.disableInput}
                                   onChange={this.props.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                                    id="firstName" required={true}/>
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="lastName" bsSize="large">
                            <ControlLabel className="font-weight-bold">Last Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.user&&this.props.user.lastName}
                                   onChange={this.props.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                                   placeholder="Last Name" disabled={this.props.disableInput}
                                    id="lastName" required={true}/>
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="emailId" bsSize="large">
                            <ControlLabel className="font-weight-bold">Email Id</ControlLabel>
                            <input type="email" className="form-control" value={this.props.user&&this.props.user.emailId}
                                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                   disabled={isUpdate || this.props.disableInput}
                                   placeholder="Email Id"
                                   data-x='{"typeMismatch":"Please enter valid email","patternMismatch":"Please enter valid email"}'
                                   onChange={this.props.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                                   id="emailId" required={true} />
                            <div className="invalid error"/>
                            <div className="inner-header">Use only official Email Id for Admin user</div>
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="emailId" bsSize="large">
                            <ControlLabel className="font-weight-bold">Confirm Email Id</ControlLabel>
                            <input type="email" className="form-control" value={this.props.user&&this.props.user.confirmEmailId}
                                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" disabled={isUpdate || this.props.disableInput}
                                   placeholder="Confirm Email Id"
                                   data-x='{"typeMismatch":"Please enter valid email","patternMismatch":"Please enter valid email"}'
                            onChange={this.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                            id="confirmEmailId" required={true} />
                            <div className="invalid error"/>
                            <div hidden={!this.emailMatch} className="error">{this.emailMatch}</div>
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="mobileNumber" bsSize="large">
                            <ControlLabel className="font-weight-bold">Mobile Number</ControlLabel>
                            <InputMask type="tel" maskChar={null} mask="9999999999"
                                       data-x='{"patternMismatch":"Invalid Mobile Number"}'
                                       required={true} pattern="[0-9]{10}"
                                       className="form-control" name="mobileNumber"
                                       id="mobileNumber" disabled={this.props.disableInput}
                                       placeholder="Mobile Number"
                                       value={this.props.user&&this.props.user.mobileNumber}
                                       onChange={this.props.handleUserInput.bind(this, this.props.user)}
                            />
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="branch" bsSize="large">
                            <ControlLabel className="font-weight-bold">Branch Location</ControlLabel>
                            <select className="form-control" value={this.props.user&&this.props.user.branch} required={true}
                                disabled={isUpdate || this.props.disableInput}
                                onChange={this.props.handleUserInput.bind(this, this.props.user)} id="branch" >
                                <option value={''}>Select Branch Location</option>
                                { this.branches.map((e, key) => {
                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>; }) }
                            </select>
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                </div>
                {
                    this.props.user&&this.props.user.userStatusAudits &&  this.props.user.userStatusAudits.length > 0 && !this.props.hideActivityLog &&
                        <div className="row mx-0 mb-3">
                            <div className="col-12 px-0">
                                <div className="row mx-0">
                                    <p className="mb-0 font-weight-bold">Activity Log</p>
                                </div>
                                <div className="row mx-0">
                                {
                                    this.props.user.userStatusAudits.map((comment,index) =>
                                        <p className="mb-0 w-100" key={index}>{comment.status==="ACTIVE"?<span>Active</span>:<span>Inactive</span>} {comment.comment?<span> - {comment.comment}</span>:<React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    ((Stores.currentPermission.create)  && !this.props.disableInput && !isUpdate) ? (
                        <div className='row mx-0'>
                            <div className='col-3 px-0'>
                                    </div>
                            <div className='col-4 text-right'>
                                {
                                     (this.props.user.status==="ACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ?
                                    <Button className="btn btn-app-secondary" type="button" onClick={this.props.suspendUser}
                                    > SUSPEND</Button> : <React.Fragment></React.Fragment>
                                }
                                {
                                     (this.props.user.status==="INACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ?
                                    <Button className="btn btn-app-secondary" type="button"  onClick={this.props.activateUser}
                                    > ACTIVATE</Button> : <React.Fragment></React.Fragment>
                                }
                                <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                            </div>
                            <div className='col-4 px-0'>
                                {   this.props.showSuccess&&
                                    <p className="mb-0 text-success" style={{fontSize:"13px"}}>Email with instructions to complete the registration has been sent to this user</p>
                                }
                            </div>
                        </div>

                        ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }
                {
                    ((Stores.currentPermission.modify && this.props.user&&this.props.user.hasOwnProperty('id')) && !this.props.disableInput && isUpdate) ? (
                            (this.props.user.id)&&
                            <ButtonToolbar className="justify-content-center">
                                {
                                     (this.props.user.status==="ACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ?
                                    <Button className="btn btn-app-secondary" type="button" onClick={this.props.suspendUser}
                                    > SUSPEND</Button> : <React.Fragment></React.Fragment>
                                }
                                {
                                    (this.props.user.status==="INACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ?
                                    <Button className="btn btn-app-secondary" type="button"  onClick={this.props.activateUser}
                                    > ACTIVATE</Button> : <React.Fragment></React.Fragment>
                                }
                                <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                            </ButtonToolbar>
                        ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }
            </Form>
        )
    }
}

const UserFormComponent = withRouter(UserForm);
export {UserFormComponent as UserForm};
