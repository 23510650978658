import React from 'react';
import { observer } from 'mobx-react';
import {DatepickerSelect} from "../../constants/formfields/DatepickerSelect";
import evdensCodeSourcedIcon from "../../assets/image/evdens_consume.png";

@observer
class EvdensCodeSourcedDetail extends React.Component<any, any> {

    render() {
        return(
            <div className='col-6 pl-0'>
                <div className="card border-0 profile-card-with-cover h-100">
                    <div className="card-content">
                        <div className="card-body p-0">
                            <div className="pl-2 pt-2 pr-2">
                                <img src={evdensCodeSourcedIcon} alt="evdenscodesource"
                                     width="50"
                                     className="float-left img-fluid card-image-position-right-mid" />
                                <h6 className="border-bottom mb-0 mt-1 font-weight-bold" style={{height:"26px",marginLeft:"56px",color:"#3d996f"}}>Evdens Labels Sourced vs Consumed</h6>
                            </div>
                            <div className="card-body p-2">
                                <table className="w-100 text-center" style={{fontSize:"15px",fontWeight:"normal",display:"grid"}}>
                                    <tbody style={{marginTop:"-15px"}}>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}></td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-600" style={{width:"23%"}}>Sourced</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-600" style={{width:"24%"}}>Consumed</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-600" style={{width:"23%"}}>Difference</td>
                                    </tr>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}><span className="font-size-15 dashBoard-secondary-color font-weight-500">Till</span> <DatepickerSelect className={"border-0 bg-white font-size-13 black-color font-weight-500 pr-0"} maxDate={new Date()}  minDate={this.props.expectedMinDate} value={this.props.evdensCodeSourcedTillDate} inputChangeHandler={this.props.handleEvdensCodeSourcedYesterdayDate}  isRequired={true} disableDate={this.props.disableDate}></DatepickerSelect></td>
                                        <td className="font-size-15 app-logo-primary-color font-weight-600" style={{width:"23%"}}>{this.props.evdensCodeSourcedConsumedReportDto.sourced}</td>
                                        <td className="font-size-15 app-logo-secondary-color font-weight-600" style={{width:"24%"}}>{this.props.evdensCodeSourcedConsumedReportDto.consumed}</td>
                                        <td className="font-size-15 app-logo-third-color font-weight-600" style={{width:"23%"}}>{this.props.evdensCodeSourcedConsumedReportDto.sourced - this.props.evdensCodeSourcedConsumedReportDto.consumed}</td>
                                    </tr>
                                    {/*<tr className="row mx-0">*/}
                                    {/*    <td style={{width:"30%"}}><span className="font-size-15 dashBoard-secondary-color font-weight-500">Last Month</span></td>*/}
                                    {/*    <td className="font-size-15 black-color font-weight-500" style={{width:"23%"}}>{this.props.evdensCodeSourcedConsumedReportDto.sourcedLastMonth}</td>*/}
                                    {/*    <td className="font-size-15 black-color font-weight-500" style={{width:"24%"}}>{this.props.evdensCodeSourcedConsumedReportDto.consumedLastMonth}</td>*/}
                                    {/*    <td className="font-size-15 black-color font-weight-500" style={{width:"23%"}}>{this.props.evdensCodeSourcedConsumedReportDto.sourcedLastMonth - this.props.evdensCodeSourcedConsumedReportDto.consumedLastMonth}</td>*/}
                                    {/*</tr>*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {EvdensCodeSourcedDetail };
