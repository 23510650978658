import React from 'react';
import { observer } from 'mobx-react';
import {DatepickerSelect} from "../../constants/formfields/DatepickerSelect";
import evdensCodeConsumerScanIcon from "../../assets/image/consumer_scan.png";

@observer
class ConsumerScannedDetail extends React.Component<any, any> {

    render() {
        return(
            <div className='col-6 p-0'>
                <div className="card border-0 profile-card-with-cover">
                    <div className="card-content">
                        <div className="card-body p-0">
                            <div className="pl-2 pt-2 pr-2">
                                <img src={evdensCodeConsumerScanIcon} alt="consumerscan"
                                     width="50"
                                     className="float-left img-fluid card-image-position-right-mid" />
                                <h6 className="border-bottom mb-0 mt-1 font-weight-bold" style={{height:"26px",marginLeft:"56px",color:"#fd863a"}}>Consumers Scanned</h6>
                            </div>
                            <div className="card-body p-2">
                                <table className="w-100 text-center" style={{fontSize:"15px",fontWeight:"normal",display:"grid"}}>
                                    <tbody style={{marginTop:"-15px"}}>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}></td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"24%"}}>Success</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"23%"}}>Failure</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"23%"}}>Suspicious</td>
                                    </tr>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}><span className="font-size-15 dashBoard-secondary-color font-weight-500">On</span> <DatepickerSelect className={"border-0 bg-white font-size-13 black-color font-weight-500 px-0"} maxDate={new Date()}  minDate={this.props.expectedMinDate} value={this.props.consumerScannedTodayDate} inputChangeHandler={this.props.handleConsumerScannedTodayDate}  isRequired={true} disableDate={this.props.disableDate}></DatepickerSelect></td>
                                        
                                        <td className="font-size-15 app-logo-primary-color font-weight-600" style={{width:"24%"}}>{this.props.consumerScannedReportDto.authentication} ({this.props.consumerScannedReportDto.authenticationTotal})</td>
                                        
                                        <td className="font-size-15 app-logo-secondary-color font-weight-600" style={{width:"23%"}}>{this.props.consumerScannedReportDto.failure} ({this.props.consumerScannedReportDto.failureTotal})</td>
                                        
                                        <td className="font-size-15 app-logo-third-color font-weight-600" style={{width:"23%"}}>{this.props.consumerScannedReportDto.suspicious} ({this.props.consumerScannedReportDto.suspiciousTotal})</td>

                                        {/*<td className="font-size-15 app-logo-third-color font-weight-600" style={{width:"23%"}}>{this.props.consumerScannedReportDto.authentication+this.props.consumerScannedReportDto.failure} ({this.props.consumerScannedReportDto.authenticationTotal+this.props.consumerScannedReportDto.failureTotal})</td>*/}
                                    </tr>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}><span className="font-size-15 dashBoard-secondary-color font-weight-500">Till</span><DatepickerSelect className={"border-0 bg-white font-size-13 black-color font-weight-500 pr-0"} maxDate={new Date()}  minDate={this.props.expectedMinDate} value={this.props.consumerScannedTillDate} inputChangeHandler={this.props.handleConsumerScannedTillDate}  isRequired={true} disableDate={this.props.disableDate}></DatepickerSelect></td>
                                        
                                        <td className="font-size-13 app-logo-primary-color font-weight-600" style={{width:"24%"}}>{this.props.consumerScannedReportDto.authenticationTill} ({this.props.consumerScannedReportDto.authenticationTillTotal})</td>
                                        
                                        <td className="font-size-13 app-logo-secondary-color font-weight-600" style={{width:"23%"}}>{this.props.consumerScannedReportDto.failureTill} ({this.props.consumerScannedReportDto.failureTillTotal})</td>
                                        
                                        <td className="font-size-13 app-logo-third-color font-weight-600" style={{width:"23%"}}>{this.props.consumerScannedReportDto.suspiciousTill} ({this.props.consumerScannedReportDto.suspiciousTillTotal})</td>

                                        {/*<td className="font-size-13 app-logo-third-color font-weight-600" style={{width:"23%"}}>{this.props.consumerScannedReportDto.authenticationTill+this.props.consumerScannedReportDto.failureTill} ({this.props.consumerScannedReportDto.authenticationTillTotal+this.props.consumerScannedReportDto.failureTillTotal})</td>*/}
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {ConsumerScannedDetail };
