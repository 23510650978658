import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Form from '../../constants/Form';
import {BaseUser} from '../../config/HttpClients';
import { ButtonToolbar,Button, FormGroup, ControlLabel } from 'react-bootstrap';
import { Role } from '../../store/Role';
import { Stores } from '../../store/Stores';

@observer
class CreateProfileForm extends React.Component<any, any> {

    @observable role:Role = new Role(); 
    @observable error:string = '';
    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.error = '';
    }  

    saveRole=()=>{
        this.error = '';
        this.role.createdBy = Stores.currentUser.id;
        if(Stores.currentUser.customer){
            this.role.customer = Stores.currentUser.customer;
        }
        BaseUser.post("/role",this.role).then(response=>{
            this.role = new Role();
            this.role.reset();
            this.props.getRoles();
        }).catch(error=>{
            this.error = error.response&&error.response.data&&error.response.data.message;
        });
    }

    handleUserInput=(targetObjecet,event)=>{
        targetObjecet[event.target.id] = event.target.value;
    }
   
    render() {
        return(
            <div className="col-12">
                <Form submit={this.saveRole}>
                    <div className="row mx-0">
                        <FormGroup className="col-12" controlId="labelType"bsSize="large">
                            <ControlLabel className="font-weight-bold">Role Name</ControlLabel>
                            <input type="text" value={this.role.name} onChange={this.handleUserInput.bind(this,this.role)} id="name"
                                   className="form-control" required={true} placeholder="Role Name"/>
                            <div className="invalid error"/>
                        </FormGroup>
                        {
                            this.error&&
                            <div className="error">
                                {this.error}
                            </div>
                        }
                    </div>                  
                    {
                        ( Stores.currentUserRole.name === 'ROLE_VGLENS_ADMIN'|| Stores.currentUserRole.name === 'ROLE_CUSTOMER_ADMIN' || Stores.currentUserRole.name === 'ROLE_PARTNER_ADMIN' 
                        || Stores.currentUserRole.name === 'ROLE_SUPPLY_CHAIN_ADMIN') &&
                        <ButtonToolbar className="justify-content-end mr-2">
                            <Button className="btn button-sec-color btn-sm" type="submit"> Add</Button>
                         </ButtonToolbar>
                    }
                    </Form>
            </div> 
        )
    }
}


export {CreateProfileForm};
