import {action, observable} from 'mobx';
import { PackagingLevel } from './PackagingLevel';


class ProductPackagingLevel {
    @observable id:string = '';
    @observable packagingLevel:PackagingLevel = new PackagingLevel();
    @observable unit:string = '';
    @observable order:string = '';
    @observable levelFixed:boolean = true;


    @action
    setProductPackagingLevel = (productPackagingLevel: any) => {
        this.id = productPackagingLevel.id;
        this.packagingLevel = productPackagingLevel.packagingLevel;
        this.unit = productPackagingLevel.status;
        this.order=productPackagingLevel.order;
        this.levelFixed = productPackagingLevel.levelFixed;
        return this;
    }
    @action reset() {
        this.id                 ='';
        this.packagingLevel     = new PackagingLevel();
        this.unit               = '';
        this.order              = '';
        this.levelFixed         = true;
    }
}

export {ProductPackagingLevel};
