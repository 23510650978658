import {action, observable} from 'mobx';

class PackagedLabelReportDto   {

    @observable id:string = '';
    @observable secondaryId:string = '';
    @observable customerId:string = '';
    @observable customerName:string = '';
    @observable productId:string = '';
    @observable productName:string = '';
    @observable labeledPackageLevel:string = '';
    @observable userId:string = '';
    @observable userType:string = '';
    @observable currentPartnerId:string = '';
    @observable currentLocation:string = '';
    @observable currentBranchId:string = '';
    @observable currentBranchName:string = '';
    @observable currentBranchCountry:string = '';
    @observable firstName:string = '';
    @observable lastName:string = '';
    @observable userTypeName:string = '';
    @observable branchType : string = '';
    @observable scannedDateTime:string = '';
    @observable scannedDateTimeConvert:string = '';
    @observable actionType:string = '';
    @observable actionLocation:string = '';
    @observable actionBranchId:string = '';
    @observable actionBranchType:string = '';
    @observable actionPartnerId:string = '';
    @observable actionPartnerType:string = '';
    @observable actionBranchName:string = '';
    @observable actionBranchCountry:string = '';
    @observable dispatchCountry:string = '';
    @observable seller:string = '';
    @observable distance:number = 0;
    @observable result:number = 0;
    @observable AssignMode:string = '';

    @action
    setPackagedLabelReportDto = (packagedlabelreportdto: any) => {
        this.id = packagedlabelreportdto.id;
        this.secondaryId = packagedlabelreportdto.secondaryId;
        this.customerId = packagedlabelreportdto.customerId;
        this.customerName = packagedlabelreportdto.customerName;
        this.productId = packagedlabelreportdto.productId;
        this.productName = packagedlabelreportdto.productName;
        this.labeledPackageLevel = packagedlabelreportdto.labeledPackageLevel;
        this.userId = packagedlabelreportdto.userId;
        this.userType = packagedlabelreportdto.userType;
        this.currentPartnerId = packagedlabelreportdto.currentPartnerId;
        this.currentLocation = packagedlabelreportdto.currentLocation;
        this.currentBranchId = packagedlabelreportdto.currentBranchId;
        this.currentBranchName = packagedlabelreportdto.currentBranchName;
        this.currentBranchCountry = packagedlabelreportdto.currentBranchCountry;
        this.firstName = packagedlabelreportdto.firstName;
        this.lastName = packagedlabelreportdto.lastName;
        this.userTypeName = packagedlabelreportdto.userTypeName;
        this.branchType = packagedlabelreportdto.branchType;
        this.scannedDateTime = packagedlabelreportdto.scannedDateTime;
        this.actionType = packagedlabelreportdto.actionType;
        this.actionLocation = packagedlabelreportdto.actionLocation;
        this.actionBranchId = packagedlabelreportdto.actionBranchId;
        this.actionBranchType = packagedlabelreportdto.actionBranchType;
        this.actionPartnerId = packagedlabelreportdto.actionPartnerId;
        this.actionPartnerType = packagedlabelreportdto.actionPartnerType;
        this.actionBranchName = packagedlabelreportdto.actionBranchName;
        this.actionBranchCountry = packagedlabelreportdto.actionBranchCountry;
        this.dispatchCountry = packagedlabelreportdto.dispatchCountry;
        this.seller = packagedlabelreportdto.seller;
        this.distance = packagedlabelreportdto.distance;
        this.result = packagedlabelreportdto.result;
        this.AssignMode = packagedlabelreportdto.AssignMode;
    return this;
    }
}

export {PackagedLabelReportDto};
