import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { CreateOrderForm } from './CreateOrderForm';
import { Order } from '../../store/Order';
import { observable } from 'mobx';
import { OrderItem } from '../../store/OrderItem';
import {Customer} from '../../store/Customer';
import {ButtonToolbar,Button, ControlLabel} from 'react-bootstrap';
import { Stores } from '../../store/Stores';
import { BaseCustomer } from '../../config/HttpClients';
import {getLoggedInUser, stringToDateFormat, viewDateFormat} from "../../constants/CommonMethods";
import { OrderConfirmModal } from './OrderConfirmModal';

@observer
class CreateOrder extends React.Component<any, any> {

    @observable order = new Order(); 
    @observable orderItem = new OrderItem();
    @observable partners:Customer[] = [];
    @observable index:any;
    @observable createOrderCom:any;
    @observable partnerError:boolean = false;
    @observable confirmModal:any;
    @observable disableAddButton:boolean = false;
    @observable generateOrder:boolean = false;

    history:any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        this.getPrintingPartners();
    }

    save=(generate)=>{
        if(!this.order.partner){
            this.partnerError = true;
            return;
        }      
        this.generateOrder = generate;
        this.confirmModal.show();
    }

    confirmOrder=() => {
        this.confirmModal.close();
        this.createOrder(this.order);
    };

    createOrder=(order)=>{
        order.createdBy = Stores.currentUser.id;
        order.orderStatus = "OPEN";
        order.status = "ACTIVE";
        order.customer= {id:Stores.currentCustomer.id};
        order.orderItems.map((orderItem) => { orderItem.expectedDeliveryDate=stringToDateFormat(orderItem.expectedDeliveryDate);return true;})
        order.branch= {id:Stores.currentBranch.id };
        BaseCustomer.post("/order",order).then(response=>{
            if(this.generateOrder) {
                let order = response.data.payload;
                this.generateLabel(order);
            } else {
                this.order = new Order();
                this.order.reset();
                this.partnerError = false;
                this.disableAddButton = false;
                this.generateOrder = false;
            }
            
        });
    }

    getPrintingPartners=()=>{
        BaseCustomer.get("/customer/printingPartners?id="+Stores.currentCustomer.id).then(response=>{
            this.partners = response.data.payload;
            this.partners.map((e, key) => {this.order.partner = e.id;return true; });
        });
    }

    handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
    };

    handleUserPartner=(order,event)=>{
        order.partner = event.target.value;
    }

    addOrderItem=()=>{
        if(!isNaN(this.index)) {
            this.orderItem.expectedDeliveryDate = viewDateFormat(this.orderItem.expectedDeliveryDate);
            this.order.orderItems[this.index] = this.orderItem;
        } else { 
            this.orderItem.orderItemStatus = "OPEN";
            this.orderItem.labelSize = "Small";
            this.orderItem.expectedDeliveryDate = viewDateFormat(this.orderItem.expectedDeliveryDate);
            this.order.orderItems.push(this.orderItem);
        }
        this.index = undefined;
        this.orderItem = new OrderItem();
        this.createOrderCom.setNewDetail&&this.createOrderCom.setNewDetail();
        this.disableAddButton = true;
   }

    editOrderitem =(item,index)=>{
        item.expectedDeliveryDate = stringToDateFormat(item.expectedDeliveryDate);
        this.orderItem = Object.assign({},item);
        this.index = index;
        this.createOrderCom.setproduct&&this.createOrderCom.setproduct(this.orderItem.productName);
    }

    deleteOrderitem=(index)=>{
        this.order.orderItems.splice(index, 1);
        this.orderItem = new OrderItem();
        this.disableAddButton = false;
    }

    updateOrder = (orders) => {
        BaseCustomer.put("/order", orders).then(response => {
           this.order = new Order();
            this.order.reset();
            this.partnerError = false;
            this.disableAddButton = false;
            this.generateOrder = false;
        });
    }

    generateLabel = (order) => {
        let orders: Order[] = [];
        order.orderStatus = "INPROGRESS";
        order.orderItems.forEach((orderItem) => {
            orderItem.orderItemStatus = "INPROGRESS";
        });
        orders.push(order);
        if (orders.length > 0) {
            this.updateOrder(orders);
        }
    }
   
    render() {
        return(
                <div className="col-12 pt-3">
                    <div className="row mx-0 pl-0">                    
                        <div className="col-12 px-0">
                            <div className="card b-none">
                                <div className="card-body pt-0">                                   
                                    <div className="pt-2">
                                        <div className="row">
                                            <div className="col-3">
                                            <ControlLabel className="font-weight-bold">Select Label Supplier</ControlLabel>
                                            <select className="form-control" value={this.order&&this.order.partner}
                                                onChange={this.handleUserPartner.bind(this, this.order)} required={true} id="labelSize">
                                                <option value={''}>Select Label Supplier</option>
                                                { this.partners.map((e, key) => {
                                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                                })
                                                }
                                            </select>

                                            {
                                                ((this.partnerError)&&(!this.order.partner))?(
                                                    <div className="error">
                                                    This field is required
                                                    </div>
                                                    ):
                                                (<div >
                                            </div>)
                                            }
                                            
                                            </div>
                                        </div>
                                    <table className="table mt-2">
                                    <thead >
                                        <tr className="row table-header">
                                           {/* <th className="col-3 text-center text-nowrap">Label Size</th> */}
                                           <th className="col-3 text-center text-nowrap">Product</th>
                                            <th className="col-2 text-center text-nowrap">Evdens UID Type</th>
                                            <th className="col-2 text-center text-nowrap">Quantity</th>
                                            <th className="col-3 text-center text-nowrap px-0">Expected Delivery Date</th>
                                            <th className="col-2 text-center text-nowrap pr-0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.order.orderItems.map((orderItem,index) =>
                                            <React.Fragment key={index}>
                                            <tr className="row table-body-value" hidden={(this.index  === index)?true:false}>
                                               {/* <td className="col-3 text-center pr-0 py-1">{orderItem.labelSize}</td>*/}
                                                <td className="col-3 text-center pr-0 py-1">{orderItem.productName?(<span >{orderItem.productName}</span>):(<span>-</span>)}</td>
                                                <td className="col-2 text-center pr-0 py-1"> {(orderItem.ovd==='WithOVD')?(<span >evdensDigiSign</span>):(<React.Fragment></React.Fragment>)}{(orderItem.ovd==='WithoutOVD')?(<span >evdensCodes</span>):(<React.Fragment></React.Fragment>)}</td>
                                                <td className="col-2 text-center pr-0 py-1">{orderItem.quantity}</td>
                                                <td className="col-3 text-center pr-0 py-1">{orderItem.expectedDeliveryDate}</td>
                                                <td className="col-2 text-center px-0 py-1">
                                                    {/* <button className="btn p-1" onClick={()=>this.editOrderitem(this.order.orderItems[index],index)} >Edit</button> */}
                                                    <button className="btn p-0" onClick={()=>this.deleteOrderitem(index)}><img className="cursor-pointer p-2" style={{width:'28px'}} alt="close" src={require("../../assets/image/removeIcon.png")}/></button>
                                                    
                                                </td>             
                                            </tr>
                                            {
                                                (this.index  === index)?(
                                                <tr className="row">
                                                    <div className="col-12 mt-1">
                                                    <CreateOrderForm save={this.addOrderItem} handleUserInput={this.handleUserInput} orderItem={this.orderItem} ref={ref => {
                                                        this.createOrderCom = ref
                                                    }}/>
                                                    </div>
                                                </tr> 
                                                ):
                                                (<React.Fragment></React.Fragment>)
                                            }
                                           
                                        </React.Fragment>
                                        )}
                                    </tbody>
                                </table>
                                {(!this.disableAddButton) ?
                                    (this.index >=0 )? (<React.Fragment></React.Fragment>):(
                                        <CreateOrderForm save={this.addOrderItem} handleUserInput={this.handleUserInput} orderItem={this.orderItem} ref={ref => {
                                            this.createOrderCom = ref
                                            }}/>
                                    ) : (<React.Fragment></React.Fragment>)
                                }
                                {
                                    ((Stores.currentPermission.create ||Stores.currentPermission.modify)&&(this.order.orderItems.length>0))?(
                                        <ButtonToolbar className="justify-content-center">
                                            <Button className="btn btn-app-primary mr-2" type="submit" onClick={() => this.save(false)}> Save</Button>
                                            {/* <Button className="btn btn-app-secondary" type="submit" onClick={() => this.save(true)}>Save & Generate Label</Button> */ }
                                        </ButtonToolbar>
                                    ):
                                    (
                                        <div></div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OrderConfirmModal  ref={instance=>{this.confirmModal=instance}} orderItems={this.order.orderItems} submit={this.confirmOrder}  />
        </div>
        )
    }
}

const CreateOrderComponent = withRouter(CreateOrder);
export {CreateOrderComponent as CreateOrder};
