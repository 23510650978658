import * as React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { authenticate, hasPermission } from "./HttpClients";

const AuthRoute = ({ component: Component, ...rest }) => {
    const pathname = rest.location && rest.location.pathname;
    
    return (<Route
        {...rest}
        render={props =>
            (authenticate() && hasPermission(pathname))? (
                <Component {...props} />
            ) : (
                <Redirect to={{
                    pathname: authenticate() ? "/myProfile/profile" : "/login",
                    state: { from: props.location }
                }}
                />
            )
        }
    />
    )
};

export { AuthRoute }