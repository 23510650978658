import React from 'react';
import {observer} from 'mobx-react';

@observer
class BrandReferralView extends React.Component<any, any> {

    render() {
        return (
            <React.Fragment>
                {
                    this.props.brandReferral.id &&
                    <div className="product-details h-100">
                        <div className="row mb-2">
                            <div className="col-12 header" style={{fontSize: '18px'}}>
                                Referral Details
                            </div>
                        </div>
                        <div className="custom-scroll" style={{height: 'calc(100% - 30px)',overflowY:'auto',overflowX:'hidden'}}>
                            <div className="row mb-2">
                                <span className='col-12 header'>Referral Id</span>
                                <span className='col-12 header-value'> {this.props.brandReferral.referralId ? this.props.brandReferral.referralId :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Consumer Identity</span>
                                <span className='col-12 header-value'> {this.props.brandReferral.mobileNumber ? this.props.brandReferral.mobileNumber :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Consumer Name</span>
                                <span className='col-12 header-value'> {this.props.brandReferral.name ? this.props.brandReferral.name :
                                    <span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'> Email Id</span>
                                <span className='col-12 header-value'> {this.props.brandReferral.emailId ? this.props.brandReferral.emailId :
                                    <span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'> Scan Location</span>
                                <span className='col-12 header-value'>{this.props.brandReferral.scanLocation ?
                                    <a href={'http://maps.google.com/?q=' + this.props.brandReferral.scanLocation}
                                       target="_blank" rel="noopener noreferrer">{this.props.brandReferral.scanLocation}</a> : <span>-</span>} </span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Brand/Company Name</span>
                                <span className='col-12 header-value'> {this.props.brandReferral.brandOrCompany ? this.props.brandReferral.brandOrCompany :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Date</span>
                                <span className='col-12 header-value'> {this.props.brandReferral.createdDateConvert ? this.props.brandReferral.createdDateConvert :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Comments</span>
                                <span className='col-12 header-value'> {this.props.brandReferral.comment}</span>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}


export {BrandReferralView};
