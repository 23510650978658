import {action, observable} from 'mobx';

class PartnerType {
    @observable id:string = '';
    @observable name:string = '';
    @observable status:string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';
    @observable vglensPartner:string = '';
    @observable partnerTypeOrder:number = 0;

    @action
    setPartnerType = (partnerType: any) => {
        this.id = partnerType.id;
        this.name = partnerType.name;
        this.status = partnerType.status;
        this.createdBy = partnerType.createdBy;
        this.updatedBy = partnerType.updatedBy;
        this.vglensPartner = partnerType.vglensPartner;
        this.partnerTypeOrder = partnerType.partnerTypeOrder;
        return this;
    }
    @action reset() {
        this.id        = '';
        this.name      = '';
        this.status    = '';
        this.createdBy = '';
        this.updatedBy = '';
        this.vglensPartner  = '';
        this.partnerTypeOrder = 0;

    }
}

export {PartnerType};
