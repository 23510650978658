import {action, observable} from 'mobx';

class DispatchReportDto   {

    @observable id:string = '';
    @observable secondaryId:string = '';
    @observable customerId:string = '';
    @observable customerName:string = '';
    @observable productId:string = '';
    @observable productName:string = '';
    @observable labeledPackageLevel:string = '';
    @observable userId:string = '';
    @observable userType:string = '';
    @observable currentPartnerId:string = '';
    @observable currentLocation:string = '';
    @observable currentBranchId:string = '';
    @observable currentBranchName:string = '';
    @observable currentBranchCountry:string = '';
    @observable firstName:string = '';
    @observable lastName:string = '';
    @observable userTypeName:string = '';
    @observable branchType : string = '';
    @observable scannedDateTime:string = '';
    @observable scannedDateTimeConvert:string = '';
    @observable actionType:string = '';
    @observable actionLocation:string = '';
    @observable actionBranchId:string = '';
    @observable actionBranchType:string = '';
    @observable actionPartnerId:string = '';
    @observable actionPartnerType:string = '';
    @observable actionBranchName:string = '';
    @observable actionBranchCountry:string = '';
    @observable dispatchCountry:string = '';
    @observable seller:string = '';
    @observable distance:number = 0;
    @observable result:number = 0;
    @observable AssignMode:string = '';

    @action
    setDispatchReportDto = (dispatchreportdto: any) => {
        this.id = dispatchreportdto.id;
        this.secondaryId = dispatchreportdto.secondaryId;
        this.customerId = dispatchreportdto.customerId;
        this.customerName = dispatchreportdto.customerName;
        this.productId = dispatchreportdto.productId;
        this.productName = dispatchreportdto.productName;
        this.labeledPackageLevel = dispatchreportdto.labeledPackageLevel;
        this.userId = dispatchreportdto.userId;
        this.userType = dispatchreportdto.userType;
        this.currentPartnerId = dispatchreportdto.currentPartnerId;
        this.currentLocation = dispatchreportdto.currentLocation;
        this.currentBranchId = dispatchreportdto.currentBranchId;
        this.currentBranchName = dispatchreportdto.currentBranchName;
        this.currentBranchCountry = dispatchreportdto.currentBranchCountry;
        this.firstName = dispatchreportdto.firstName;
        this.lastName = dispatchreportdto.lastName;
        this.userTypeName = dispatchreportdto.userTypeName;
        this.branchType = dispatchreportdto.branchType;
        this.scannedDateTime = dispatchreportdto.scannedDateTime;
        this.actionType = dispatchreportdto.actionType;
        this.actionLocation = dispatchreportdto.actionLocation;
        this.actionBranchId = dispatchreportdto.actionBranchId;
        this.actionBranchType = dispatchreportdto.actionBranchType;
        this.actionPartnerId = dispatchreportdto.actionPartnerId;
        this.actionPartnerType = dispatchreportdto.actionPartnerType;
        this.actionBranchName = dispatchreportdto.actionBranchName;
        this.actionBranchCountry = dispatchreportdto.actionBranchCountry;
        this.dispatchCountry = dispatchreportdto.dispatchCountry;
        this.seller = dispatchreportdto.seller;
        this.distance = dispatchreportdto.distance;
        this.result = dispatchreportdto.result;
        this.AssignMode = dispatchreportdto.AssignMode;
    return this;
    }
}

export {DispatchReportDto};
