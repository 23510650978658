import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import {observable} from "mobx";
import {BaseCustomer} from "../../config/HttpClients";
import {getLoggedInUser, showImage} from '../../constants/CommonMethods';
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";
import { Stores } from '../../store/Stores';
import {Customer} from "../../store/Customer";
import {CustomerPartnerType} from "../../store/CustomerPartnerType";
import {PartnerType} from "../../store/PartnerType";
import {SuspendDto} from '../../store/SuspendDto';
import {PartnerExistAlert} from '../manageSupplyChain/PartnerExistAlert';
import {ProductImage} from "../manage-product/ProductImage";
import {PartnerSearch} from './PartnerSearch';
import {PartnerForm} from './PartnerForm';

@observer
class Partners extends React.Component<any, any> {
    @observable partner = new Customer();
    @observable imgUrl:any;
    @observable userPartnerTypes:PartnerType[] = [];
    @observable partnerTypes:PartnerType[] = [];
    @observable suspendDto:SuspendDto = new SuspendDto();
    @observable files:any[]=[];
    @observable disableInput:boolean = false;
    @observable partnerTypeError:boolean = false;
    @observable isSupplyChain:boolean = false;
    @observable showImageDelete:boolean = false;
    @observable comment:string = '';
    @observable partnerExistAlert:any
    @observable suspendAlertModal:any;
    @observable partnerForm : any;
    
    @observable hideCustomer:boolean = true;
    @observable customer = new Customer();
    @observable customerSearch: any;
    @observable disablePartner:boolean = false;
    @observable customerError:boolean = false;


    @observable errorAlertModal:any;

    constructor(props){
        super(props);
        let paths =  window.location.pathname.split('/');
        if(paths[1] === "supplyChain") { this.isSupplyChain = true; }
        if(Stores.currentUser.userName === "") {
             getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        if((Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS") || (Stores.currentUser&&Stores.currentUser.userType.name === "CUSTOMER" ) || (Stores.currentUser&&Stores.currentUser.userType.name === "SUPPLYCHAIN" )) {
            if(Stores.partner&&Stores.partner.name){
                this.partner = Stores.partner;
                this.setCustomerpartnerTypes(this.partner.partnerTypes);
            } else {
                this.partner = new Customer();
            }
        }
        if(this.isSupplyChain) {
            this.getCustomerPartnerTypes("CUSTOMER");
            if(Stores.currentCustomer&&Stores.currentCustomer.type&&Stores.currentCustomer.type.id==="31000000-0000-0000-0000-000000000000"){
                this.disableInput = true;
            }
            if(this.partner && this.partner.customerType === "CUSTOMER") {
                this.disableInput = true;
            }
        } else {
            this.getCustomerPartnerTypes("VGLENS");
        }
        if(this.partner.fileName){
            this.getCustomerImage(this.partner.fileName);
        }
    }


    handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
    };
    handlePartner = (selectedOption) => {
        this.userPartnerTypes = [selectedOption];
        if(selectedOption) {
            if(selectedOption.label === "Business Customer") {
                this.hideCustomer = false;
            } else {
                this.setNewCustomer();
                this.hideCustomer = true;
            }
        } else {
            this.setNewCustomer();
            this.hideCustomer = true;
        }

    };

    handleComment=(event)=>{
        this.comment = event.target.value;
    };

    getCustomerPartnerTypes= (userType) => {
        let status = 'null';
        BaseCustomer.get("/partnerType?userType="+userType+"&type=partner&status="+status).then(response=>{
            this.partnerTypes = response.data.payload;
        });
    }

    save = (event) => {
        if((this.userPartnerTypes&&this.userPartnerTypes.length === 0) ||this.userPartnerTypes === null) {
            this.partnerTypeError = true;
            return;
        }
        this.partnerTypeError = false;
        this.addCustomerPartnertypes(this.userPartnerTypes);
        if(this.hideCustomer) {
            if(this.partner.hasOwnProperty('id')){
                if(this.partner.id) {
                    this.updateCustomer(this.partner);
                }else {
                    this.createNewCustomer();
                }           
            } else {
               this.createNewCustomer();
            }
        } else {
            this.saveBusinessCustomer();
        }
    }

    saveBusinessCustomer() {
        if(this.customer && this.customer.hasOwnProperty('id') && this.customer.id) {
            this.customerError = false;
            let businesspartnerExists = Stores.currentCustomer&&Stores.currentCustomer.partners.find(supplyChainPartner=>supplyChainPartner.partner===this.partner.id);
            if(businesspartnerExists) {
                this.partnerExistAlert&&this.partnerExistAlert.setMessageAndShow("The same partner has already been added to your supply chain");
            } else {
                this.createNewCustomer();
            }
        } else {
            this.customerError = true;
        }
    }

    createNewCustomer=()=>{
        if(this.partner.hasOwnProperty('vertical')&&this.partner.vertical.id === ""){
                delete this.partner.vertical;
        }
        if(this.partner.hasOwnProperty('type')&&this.partner.type.id === ""){
                delete this.partner.type;
        }
        if(this.hideCustomer) {
            if(this.isSupplyChain) {
                this.partner.customerType = "SUPPLYCHAIN";
            } else {
                this.partner.customerType = "PARTNER";
            }
            this.partner.userTypeId = Stores.currentUser&&Stores.currentUser.userType&&Stores.currentUser.userType.id;
        } else {
            this.partner.customerType = "CUSTOMER";
            this.partner.userTypeId = "31000000-0000-0000-0000-000000000000";
        }
        
        this.partner.createdBy = Stores.currentUser&&Stores.currentUser.id;
        this.partner.status = "ACTIVE";
        if(this.isSupplyChain && this.hideCustomer){
            this.getCustomerByName(this.partner);
        } else {
            this.createCustomer(this.partner);
        }            
    }

    createCustomer=(partner)=>{  
        if(!this.hideCustomer) {
            partner.customer = Stores.currentCustomer.id;
        }
        if(partner.hasOwnProperty('id')) {
            if(partner.id === "")
                delete partner.id;
        }
//         if (this.partner.hasOwnProperty('partners') && this.partner.partners[0].partner === "") {
//             delete this.partner.partners;
//         }
        let formData = new FormData();
        formData.append('customer', JSON.stringify(partner));
        this.files.map((file) => {formData.append('file', file);return true;});
          BaseCustomer.post("/customer", formData, {headers: {'content-type':'multipart/form-data'}}).then(response=>{
           this.setNewPartner();
         });
    }

    updateCustomer=(partner)=>{
        let formData = new FormData();
        formData.append('customer', JSON.stringify(partner));
        this.files.map((file) => {formData.append('file', file);return true;});
        partner.updatedBy = Stores.currentUser&&Stores.currentUser.id;
        BaseCustomer.post("/customer/update",formData,{headers: {'content-type':'multipart/form-data'}}).then(response=>{
           this.setNewPartner();
        });
    }

    updatePartner=()=>{
        this.partner.updatedBy = Stores.currentUser&&Stores.currentUser.id;
        BaseCustomer.put("/customer/partner",this.partner).then(response=>{
           this.setNewPartner();
           this.partnerExistAlert.close();
        });
    }

    getCustomerByName=(partner)=>{
        BaseCustomer.get("/customer/name?name="+partner.name).then(response=>{
            partner.customer = Stores.currentCustomer.id;
            if(response.data.payload === null) {
                this.createCustomer(partner);
            } else {
                let partnerExist = Stores.currentCustomer&&Stores.currentCustomer.partners.find(supplyChainPartner=>supplyChainPartner.partner===response.data.payload.id);
                if(partnerExist) {
                    this.partnerExistAlert&&this.partnerExistAlert.setMessageAndShow("The same partner has already been added to your supply chain");
                } else {
                    this.partnerExistAlert.show();
                }
            }
        });
    }

    suspendPartner=()=>{
            this.suspendDto.id = this.partner.id;
            this.suspendDto.customer =  Stores.currentCustomer.id;
            this.suspendDto.updatedBy =  Stores.currentUser.id;
            this.suspendDto.updatedUser =  Stores.currentUser.firstName;
            this.suspendDto.comment =  this.comment;
            BaseCustomer.put("/customer/suspend/partner",this.suspendDto).then(response=>{
                this.suspendAlertModal.close();
                this.comment = '';
                this.setNewPartner();
                this.suspendDto = new SuspendDto();
            });
    }

    suspendAlert = () => {
        this.suspendAlertModal.show();
    }

    setSelectedPartner=(partner)=>{
        this.imgUrl="";
        this.showImageDelete = false;
        this.partner = new Customer();
        this.partner.reset();
        this.partner = partner;
        Stores.partner = partner;
        if(this.partner.fileName){
            this.getCustomerImage(this.partner.fileName);
            if(Stores.currentUser.roles[0].name === "ROLE_VGLENS_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_SUPPLY_CHAIN_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_PARTNER_ADMIN"){
                this.showImageDelete = true;
            }
        }
        this.files = [];
        this.setCustomerpartnerTypes(this.partner.partnerTypes);
        this.partnerForm.getCustomerVertical(partner);
        this.disableInput = false;
        if(this.partner.customerType === "CUSTOMER") {
            this.showImageDelete = false;
            this.disableInput = true;
        }
    }

    setNewPartner=()=>{
        this.partner = new Customer();
        this.partner.reset();
        this.userPartnerTypes = [];
        this.files = [];
        Stores.partner = new Customer();
        this.partnerForm.fileInput.value="";
        this.imgUrl="";
        this.showImageDelete = false;
        this.hideCustomer = true;
        this.setNewCustomer();
        this.disablePartner = false;
    }

    addCustomerPartnertypes=(userCustomerPartnerTypes)=>{
        if (this.hideCustomer) {
            this.partner.partnerTypes = [];
        }
        userCustomerPartnerTypes.forEach(userCustomerPartnerType => {
            let customerPartnerType = new CustomerPartnerType();
            let partnerType = this.partnerTypes.find(partnerType=>partnerType.id===userCustomerPartnerType.value);

            if(partnerType){
                //customerPartnerType.partnerType = partnerType;
                customerPartnerType.partnerType.id = userCustomerPartnerType.value;
                customerPartnerType.partnerType.name = userCustomerPartnerType.label;
                customerPartnerType.partnerType.status = partnerType.status;
                customerPartnerType.partnerType.vglensPartner = partnerType.vglensPartner;
            }
            customerPartnerType.partnerType.id = userCustomerPartnerType.value;
            
           this.partner.partnerTypes.push(customerPartnerType);
        });
    }

    setCustomerpartnerTypes=(customerPartnerTypes)=>{
        this.userPartnerTypes = [];
        customerPartnerTypes.forEach((customerPartnerType)=>{
            if ((Stores.currentUser&&Stores.currentUser.userType.name === "CUSTOMER"&&customerPartnerType.partnerType.vglensPartner !== "VGLENS") || (Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS" && customerPartnerType.partnerType.vglensPartner === "VGLENS")){
                    customerPartnerType.partnerType.value = customerPartnerType.partnerType.id;
                    customerPartnerType.partnerType.label = customerPartnerType.partnerType.name;
                    customerPartnerType.partnerType.vglensPartner = customerPartnerType.partnerType.vglensPartner;
                    this.userPartnerTypes.push(customerPartnerType.partnerType);
            }
        });      
    }

    getCustomerImage= (fileName) => {
        BaseCustomer.get("/customer/image?fileName="+fileName,{headers:{Accept:"*/*"},responseType: "blob"}).then(response=>{
            let blob = new Blob([response.data], { type:response.headers["content-type"]});
            this.imgUrl = URL.createObjectURL(blob);
        });
    }

    deleteCustomerImage= () => {
        BaseCustomer.delete("/customer/image?id="+this.partner.id,).then(reponse=>{
            this.errorAlertModal.close();
            this.getCustomerById(this.partner.id);
        });
    }
    getCustomerById=(id)=>{
        BaseCustomer.get("/customer/"+this.partner.id,).then(reponse=>{
            this.setSelectedPartner(reponse.data.payload);
        });
     }

    deleteImage=()=>{
        this.errorAlertModal.show();
    }

    getImage=()=>{
        showImage(this.files[0]).then(response=>{
            this.imgUrl = response;
        });
    }

    setFilesEmpty=()=>{
        this.imgUrl = '';
    }

    setNewCustomer = () => {
        if(this.customer && this.customer.hasOwnProperty('id') && this.customer.id) {
            this.partner = new Customer();
            this.partner.reset();
        }
        this.customer = new Customer();
        this.customer.reset();
        this.customerSearch.clearFilter();

    }

    setSelectedCustomer = (customer) => {
        this.customer = new Customer();
        this.customer.reset();
        this.customer = customer;
        this.partner = this.customer;
        this.showImageDelete = false;
        if(this.partner.fileName){
            this.getCustomerImage(this.partner.fileName);
        }
    }

    setCustomerSearch = (customerSearch) => {
        this.customerSearch = customerSearch;
    }

    render() {
        return(
            <div className='col-12 p-0'>
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6  h-42'>
                        {
                            Stores.partner&&
                            <b style={{ fontSize:'18px',fontWeight:'bold', letterSpacing: '1px'}}>
                               {Stores.partner.name}
                            </b>
                        }
                    </div>
                    <div className='col-6'>
                        <div className='float-right' >
                            <PartnerSearch setSelectedPartner={this.setSelectedPartner} setNewPartner={this.setNewPartner} />
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0 pt-3">
                    <div className="col-8 pr-0">
                        <div className="card b-none">
                            <div className="card-body">
                                <div className="pt-2">
                                    <PartnerForm ref={instance=>{this.partnerForm=instance}} partner={this.partner} disableInput={this.disableInput}
                                                 handleUserInput={this.handleUserInput} submit={this.save}
                                                 setNewPartner = {this.setNewPartner}
                                                 files={this.files}
                                                 handlePartner={this.handlePartner}
                                                 userPartnerTypes={this.userPartnerTypes}
                                                 partnerTypes={this.partnerTypes}
                                                 partnerTypeError={this.partnerTypeError} isSupplyChain={this.isSupplyChain}
                                                 showImage={this.getImage} setFilesEmpty={this.setFilesEmpty}
                                                 suspendPartner={this.suspendAlert}
                                                 hideCustomer={this.hideCustomer}
                                                 setNewCustomer={this.setNewCustomer}
                                                 setSelectedCustomer={this.setSelectedCustomer}
                                                 setCustomerSearch ={this.setCustomerSearch}
                                                 disablePartner={this.disablePartner}
                                                 customerError={this.customerError}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="pt-2">
                                    <ProductImage imgUrl={this.imgUrl} showImageDelete={this.showImageDelete}  deleteImage={this.deleteImage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" data-toggle="modal" data-target="#PartnerExistAlert" className="btn d-none"></button>
                <PartnerExistAlert  ref={instance=>{this.partnerExistAlert=instance}} submit={this.updatePartner} id={"PartnerExistAlert"} />
                <ErrorAlertModal  ref={instance=>{this.errorAlertModal=instance}} submit={this.deleteCustomerImage}  />
                <ErrorAlertModal ref={instance => {this.suspendAlertModal = instance}}
                    type={"suspend"} comment={this.comment} handleComment={this.handleComment} submit={this.suspendPartner}/>
            </div>
        )
    }
}

const PartnersComponent = withRouter(Partners);
export {PartnersComponent as Partners};
