import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {UserForm} from './UserForm';
import {observable} from 'mobx';
import {BaseUser} from '../../config/HttpClients';
import {User} from '../../store/User';
import {Auth} from 'aws-amplify';
import {Stores} from "../../store/Stores";
import {BranchSearch} from "../branch/BranchSearch";
import {Branch} from "../../store/Branch";
import {Customer} from "../../store/Customer";
import {Persona} from '../../store/Persona';
import {SuspendDto} from '../../store/SuspendDto';
import {getLoggedInUser} from "../../constants/CommonMethods";
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";

@observer
class Users extends React.Component<any, any> {
    @observable user = new User();
    @observable persona = new Persona();
    @observable branch = new Branch();
    @observable customer: Customer = new Customer();
    @observable branches = [];
    @observable isSupplyChain = false;
    @observable disableInput:boolean = false;
    @observable roles: any;
    @observable role: any;
    @observable userTypes: any;
    @observable userType: any;
    @observable showSuccess = false;
    @observable suspendAlertModal: any;
    @observable activateAlertModal:any;
    @observable comment: string = '';
    @observable suspendDto:SuspendDto = new SuspendDto();
    @observable hideActivityLog:boolean = false;

    constructor(props) {
        super(props);
        let paths = window.location.pathname.split('/');
        if (paths[1] === "supplyChain") {
            this.isSupplyChain = true;
            if(Stores.currentCustomer&&Stores.currentCustomer.type&&Stores.currentCustomer.type.id==="31000000-0000-0000-0000-000000000000"){
                this.disableInput = true;
            }
        }
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init();
        }
    }

    init = () => {
        this.getRoles();
        this.getUserTypes();
    }

    getRoles() {
        BaseUser.get("/role").then(response => {
            this.roles = response.data.payload;
            let paths = window.location.pathname.split('/');
            for (let role of this.roles) {
                if (paths[1] === "customers") {
                    this.customer = Stores.customer;
                    if ('ROLE_CUSTOMER_ADMIN' === role.name) {
                        this.role = role;
                    }
                } else if (paths[1] === "supplyChain") {
                    this.customer = Stores.partner;
                    if(this.customer && this.customer.customerType === "CUSTOMER") {
                        this.disableInput = true;
                        this.hideActivityLog = true;
                        if ('ROLE_CUSTOMER_ADMIN' === role.name) {
                            this.role = role;
                        }
                    } else {
                        if ('ROLE_SUPPLY_CHAIN_ADMIN' === role.name) {
                            this.role = role;
                        }
                    }
                } else if (paths[1] === "partners") {
                    this.customer = Stores.partner;
                    if ('ROLE_PARTNER_ADMIN' === role.name) {
                        this.role = role;
                    }
                } else if (paths[1] === "company") {
                    this.customer = Stores.currentCustomer;
                    if (Stores.currentUser.userType.name === "CUSTOMER") {
                        if ('ROLE_CUSTOMER_BRANCH_ADMIN' === role.name) {
                            this.role = role;
                        }
                    } else if (Stores.currentUser.userType.name === "PARTNER" && Stores.currentUser.persona.name === "PARTNER") {
                        if ('ROLE_PARTNER_BRANCH_ADMIN' === role.name) {
                            this.role = role;
                        }
                    } else if (Stores.currentUser.userType.name === "SUPPLYCHAIN" && Stores.currentUser.persona.name === "SUPPLY CHAIN") {
                        if ('ROLE_SUPPLY_CHAIN_BRANCH_ADMIN' === role.name) {
                            this.role = role;
                        }
                    }
                }
            }
        });
    }

    getUserTypes = () => {
        BaseUser.get("/userType").then(response => {
            this.userTypes = response.data.payload;
            let paths = window.location.pathname.split('/');
            for (let userType of this.userTypes) {
                if (paths[1] === "partners") {
                    if ('PARTNER' === userType.name) {
                        this.userType = userType;
                    }
                }
                if(paths[1] === "supplyChain") {
                    if ('SUPPLYCHAIN' === userType.name) {
                        this.userType = userType;
                    }
                }
                if (paths[1] === "customers") {
                    if ('CUSTOMER' === userType.name) {
                        this.userType = userType;
                    }
                }
                if (paths[1] === "company") {
                    this.userType = Stores.currentUser.userType;
                }
            }
        });
    };

    handleUserInput = (targetObject: any, event: any) => {
        this.showSuccess = false;
        targetObject[event.target.id] = event.target.value;
    };

    handleComment=(event)=>{
        this.comment = event.target.value;
    };

    save = () => {
        if (this.user.id) {
            this.updateUser();
        } else {
            this.createUser();
        }
    };

    createUser = () => {
        this.user.customer = this.customer.id;
        this.user.userName = this.user.emailId;
        this.user.createdBy = Stores.currentUser && Stores.currentUser.id;
        this.user.roles[0] = this.role;
        this.user.userType = this.userType;
        this.user.status = "ACTIVE";
        if (this.user.emailId !== this.user.confirmEmailId) {
            return;
        }
        BaseUser.post("/user/manager", this.user).then(response => {
            let user = {
                username: this.user.emailId,
                password: this.user.emailId,
                attributes: {
                    email: this.user.emailId,
                }
            };
            Auth.signUp(user).then(response => {
                this.showSuccess = true;
            }).catch(error => {
            });
            this.user = new User();
            this.user.reset();
        });
    };

    updateUser = () => {
        if (this.user.emailId !== this.user.confirmEmailId) {
            return;
        }
        this.user.updatedBy = Stores.currentUser.id;
        BaseUser.put("/user", this.user).then(response => {
            this.user = new User();
            this.user.reset();
        });
    };

    suspendUser = () => {
        this.suspendDto.id = this.user.id;
        this.suspendDto.updatedBy =  Stores.currentUser.id;
        this.suspendDto.updatedUser =  Stores.currentUser.firstName;
        this.suspendDto.comment =  this.comment;
        BaseUser.put("/user/suspend",this.suspendDto).then(response => {
            this.user = new User();
            this.user.reset();
            this.suspendAlertModal.close();
            this.comment = '';
            this.suspendDto = new SuspendDto();
        });
    };

    activateUser = () => {
        this.suspendDto.id = this.user.id;
        this.suspendDto.updatedBy =  Stores.currentUser.id;
        this.suspendDto.updatedUser =  Stores.currentUser.firstName;
        BaseUser.put("/user/activate",this.suspendDto).then(response => {
            this.activateAlertModal.close();
            this.user = new User();
            this.user.reset();
            this.suspendDto = new SuspendDto();
        });
    };

    selectedBranch = (branch) => {
        BaseUser.get('/user/branch/' + this.role.id + '/' + branch.id).then(response => {
            if (response.data.payload === null) {
                this.user = new User();
            } else {
                this.user = response.data.payload;
            }
        })
    };

    suspendAlert = () => {
        this.suspendAlertModal.show();
    };

    activateAlert = () => {
        this.activateAlertModal.show();
    };

    setNewBranch = () => {
        this.user = new User();
    };
    setShowSuccess = (status) => {
        this.showSuccess = status;
    };

    render() {
        return (
            <div className="col-12 p-0">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6 h-42'>
                        {
                            ((this.customer && this.customer.id) && !(Stores.currentCustomer && Stores.currentCustomer.id)) ? (
                                    <b style={{fontSize: '18px', fontWeight: 'bold', letterSpacing: '1px'}}>
                                        {this.customer.name}
                                    </b>)
                                : (<React.Fragment></React.Fragment>)
                        }
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <BranchSearch selectedBranch={this.selectedBranch} setNewBranch={this.setNewBranch}/>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0 pt-3">
                    <div className="col-12">
                        <div className="card b-none">
                            <div className="card-body">
                                <div className="pt-2">
                                    <UserForm submit={this.save} user={this.user} setShowSuccess={this.setShowSuccess}
                                              handleUserInput={this.handleUserInput} showSuccess={this.showSuccess}
                                              suspendUser={this.suspendAlert} activateUser={this.activateAlert}
                                              disableInput={this.disableInput}
                                              hideActivityLog={this.hideActivityLog}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorAlertModal ref={instance => {
                    this.suspendAlertModal = instance}}
                    type={"suspend"} comment={this.comment} handleComment={this.handleComment} submit={this.suspendUser}/>
                <ErrorAlertModal ref={instance => {this.activateAlertModal = instance}}
                               type={"activate"}  submit={this.activateUser}/>
            </div>
        )
    }
}

const UserComponent = withRouter(Users);
export {UserComponent as Users};
