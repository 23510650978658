import * as React from "react";
import { observer } from "mobx-react";

@observer
class CustomSwitch extends React.Component<any, any> {

    handleInput = (input, event) => {
        input[this.props.inputName] = !input[this.props.inputName];
        this.props.change&&this.props.change();
    };

    render() {
        return (
            <React.Fragment>
                <label className="pure-material-switch mb-0">
                    <input type="checkbox" className="custom-control-input form-control"
                           id={this.props.inputId}  name={this.props.inputName} disabled={this.props.disabled}
                           checked={this.props.inputValue[this.props.inputName]} onChange={this.handleInput.bind(this,this.props.inputValue)} />
                        <span></span>
                </label>
            <div className="invalid error"/>
            </React.Fragment>
        )
    }
}
export {CustomSwitch};