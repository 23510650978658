import React from 'react';
import { withRouter } from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from "mobx";
import * as ReactDOM from 'react-dom';
import { Verification } from "../../store/Verification";
import { ValidationRequest } from "../../store/ValidationRequest";
import { BaseCustomer, BaseLabelRead, BaseLabelWrite } from "../../config/HttpClients";
import { BaseProduct } from "../../config/HttpClients";
import { BaseNotification } from "../../config/HttpClients";
import { ValidationModal } from "./ValidationModal";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadToast, ToastType } from '../../config/Toast';
import { ValidationScreenContainer } from './ValidationScreenContainer';
import { DateToStringFormat } from '../../constants/CommonMethods';

const ascii = [33, 36, 39, 40, 41, 42, 43, 44, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 61, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 93, 95, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 126];

@observer
class ValidationScreen extends React.Component<any, any> {

    @observable showFirst: boolean = true;
    @observable showSecond: boolean = false;
    @observable showThird: boolean = false;
    @observable verification: any = {};
    @observable validationRequest = new ValidationRequest();

    @observable attributesLabel: string = '';
    @observable attributesValue: string = '';
    @observable withOVD: boolean = true;

    @observable batchNo: string = '';
    @observable mfgDate: string = '';
    @observable expireDate: string = '';

    @observable productImageUrl: string = '';
    @observable customImageUrl: string = '';
    @observable headerImageUrl: string = '';
    @observable footer1ImageUrl: string = '';
    @observable footer2ImageUrl: string = '';
    @observable branchImageURL: string = '';
    @observable onRateUsDisabled: boolean = false;

    @observable modalDialog: any;
    @observable showProductModal: boolean = false;
    @observable showEvdensCodeModal: boolean = false;
    @observable showCustomMessageModal: boolean = false;
    @observable showWarrantyModal: boolean = false;
    @observable checkCaptcha: boolean = true;
    @observable showErrorText: boolean = false;

    @observable statusCode: number = 0;
    @observable rating: number = 0;

    @observable disableButton: boolean = false;
    @observable errorMessage: boolean = false;
    @observable failureMessage: string = '';
    @observable latitude: any = '';
    @observable longitude: any = '';



    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.getGeoLocation();

    }

    setGeoLocation = ({ coords }) => {
        this.latitude = coords.latitude;
        this.longitude = coords.longitude;
    }
    getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.setGeoLocation);
        }
    }

    getDeviceName = () => {
        var isAndriod = false;
        var userAgent = window.navigator.userAgent;
        var Android = userAgent.indexOf("android") > -1;
        if (Android) {
            isAndriod = true;
        }
        return isAndriod;
    }

    getBrowser(window) {
        let currentBrowser = 'Not known';
        if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
        else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
        else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
        else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
        else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
        else { }

        return currentBrowser;
    }

    stringToDecimal = () => {
        var str = this.getPrimaryId();
        var result = '';
        for (var i = 0; i < str.length; i++) {
            switch (str.charCodeAt(i).toString().length) {
                case 1:
                    result = result + "0000" + str.charCodeAt(i);
                    break;
                case 2:
                    result = result + "000" + str.charCodeAt(i);
                    break;
                case 3:
                    result = result + "00" + str.charCodeAt(i);
                    break;
                case 4:
                    result = result + "0" + str.charCodeAt(i);
                    break;
                case 5:
                    result = result + str.charCodeAt(i);
                    break;
            }
        }
        return result;
    }

    getAttempt = () => {
        var primary = this.getPrimaryId();
        var attempt = primary.substring(primary.length - 1);
        var unicode: number = attempt.charCodeAt(0);
        var index = ascii.findIndex(value => value === unicode);
        var decodedAttempt = index % 4;
        if (decodedAttempt === 0 || decodedAttempt === 1 || decodedAttempt === 2 || decodedAttempt === 3 || decodedAttempt === 4 || decodedAttempt === 5) {
            return true;
        }
        return false;
    }

    getPrimaryId = () => {
        //var primaryId = this.props.match.params.primaryId;
        //var search = this.props.location.search;
        //if(search) {
        //    primaryId = primaryId + search;
        //}
        var url = decodeURIComponent(window.location.href);
        var splitUrl = url.split("/")
        var primaryId = splitUrl[splitUrl.length - 1];
        return primaryId;
    }

    verifyLabel = () => {
        this.disableButton = true;
        //this.validationRequest.primaryId = "0004200040000390009400090001110005500099000380007600046000340005300102000340004600037";
        //this.validationRequest.primaryId = "0004100123001200003400057000780010500071000480008300070000340011700108000810003400125";
        //this.validationRequest.primaryId = "0004100123001200003400059000540010900061001110009800054000340011700108000800007700108";
        if (this.checkCaptcha) {
            this.showErrorText = false;
            if (this.getPrimaryId() && this.getPrimaryId().length === 14 && this.getAttempt()) {
                this.validationRequest.qrCode = this.stringToDecimal();
                this.validationRequest.userId = "";
                this.validationRequest.mobileNumber = this.getBrowser(window);
                this.validationRequest.country = "";
                this.validationRequest.location = this.latitude + "," + this.longitude;
                this.validationRequest.withOVD = true;
                this.validationRequest.verified = true;
                this.validationRequest.channel = "Web"
                this.validationRequest.channelName = this.getBrowser(window);
                this.validationRequest.userType = "CONSUMER";
                BaseLabelRead.post("labelRead/validateQrCodeWeb", this.validationRequest).then(response => {
                    this.statusCode = response.status;
                    if (response.status == 200) {
                        this.verification = response.data.payload;
                        this.withOVD = this.verification.withOvd;
                        if (this.verification.attributes && this.verification.attributes.length > 0) {
                            var attributesLabels = this.verification.attributes.map((value) => value.label);
                            this.attributesLabel = attributesLabels.reduce((value, element) => value + '|' + element);
                            var attributesValues = this.verification.attributes.map((value) => value.value);
                            this.attributesValue = attributesValues.reduce((value, element) => value + '|' + element);
                        }
                        if (this.verification.manufactureDetails && this.verification.manufactureDetails.length > 0) {
                            var batchNos = this.verification.manufactureDetails.map((value) => value.batchNo);
                            this.batchNo = batchNos.reduce((value, element) => (value != null) ? value + '|' + element : '');
                            var mfgDates = this.verification.manufactureDetails.map((value) => value.mfgDate);
                            this.mfgDate = mfgDates.reduce((value, element) => (value != null) ? value + '|' + element : '');
                            var expireDates = this.verification.manufactureDetails.map((value) => value.expiryDate);
                            this.expireDate = expireDates.reduce((value, element) => (value != null) ? value + '|' + element : '');
                        }
                        if (this.verification.fileName) {
                            this.getProductImage(this.verification.fileName);
                        }
                        if (this.verification.customMessageImageName) {
                            this.getCustomImage(this.verification.customMessageImageName);
                        }
                        if (this.verification.companyHeaderImage) {
                            this.getCustomerImage(this.verification.companyHeaderImage, 'headerImageUrl');
                        }
                        if (this.verification.companyFooter1Image) {
                            this.getCustomerImage(this.verification.companyFooter1Image, 'footer1ImageUrl');
                        }
                        if (this.verification.companyFooter2Image) {
                            this.getCustomerImage(this.verification.companyFooter2Image, 'footer2ImageUrl');
                        }
                        if (this.verification.branchImage) {
                            this.getBranchImage(this.verification.branchImage);
                        }

                        this.showFirst = false;
                        this.showSecond = true;
                    } else if (response.status == 412) {
                        this.verification.evdensCode = response.data.secondaryId;
                        if (response.data.errorCode === 'DB000') {
                            this.failureMessage = 'This is not Evdens Label';
                            this.errorMessage = true;
                        } else if (response.data.errorCode === 'DB005' || response.data.errorCode === 'DB006') {
                            this.failureMessage = response.data.message;
                            this.errorMessage = true;
                        } else {
                            this.showFirst = false;
                            this.showThird = true;
                        }
                    }
                    this.submitButton();
                });
            } else {
                this.failureMessage = 'This is not Evdens Label';
                this.errorMessage = true;
            }
        } else {
            this.disableButton = false;
            this.showErrorText = true;
        }


    }

    submitButton = () => {
        if (this.statusCode == 200) {

        } else {
            if (this.statusCode == 412) {

            }
        }
    }

    getProductImage = (fileName) => {
        BaseProduct.get("/product/image?fileName=" + fileName, { headers: { Accept: "*/*" }, responseType: "blob" }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this.productImageUrl = URL.createObjectURL(blob);
        });
    }
    getCustomerImage = (fileName, property) => {
        BaseCustomer.get("/customer/image?fileName=" + fileName, { headers: { Accept: "*/*" }, responseType: "blob" }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this[property] = URL.createObjectURL(blob);
        });
    }
    getBranchImage = (fileName) => {
        BaseCustomer.get("/branch/image?fileName=" + fileName, { headers: { Accept: "*/*" }, responseType: "blob" }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this.branchImageURL = URL.createObjectURL(blob);
        });
    }

    getCustomImage = (fileName) => {
        BaseNotification.get("/customMessage/image?fileName=" + fileName, { headers: { Accept: "*/*" }, responseType: "blob" }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this.customImageUrl = URL.createObjectURL(blob);
        });
    }

    showModal = (whichModal) => {
        if (whichModal == 1) {
            this.showProductModal = true;
            this.showEvdensCodeModal = false;
            this.showCustomMessageModal = false;
            this.showWarrantyModal = false;
        } else if (whichModal == 2) {
            this.showProductModal = false;
            this.showEvdensCodeModal = true;
            this.showCustomMessageModal = false;
            this.showWarrantyModal = false;
        } else if (whichModal == 3) {
            this.showProductModal = false
            this.showEvdensCodeModal = false;
            this.showCustomMessageModal = true;
            this.showWarrantyModal = false;
        } else {
            this.showProductModal = false
            this.showEvdensCodeModal = false;
            this.showCustomMessageModal = false;
            this.showWarrantyModal = true;
        }
        this.modalDialog.show();
    }

    openWarrantyLink = () => {
        this.modalDialog.close();
        window.open(this.verification.warrantyRegistrationUrl, '_blank');
    }

    onChange = (value) => {
        this.checkCaptcha = true;
    }

    getRatingPayload = () => {
        let scanLocation = `${this.latitude},${this.longitude}`;
        if (!scanLocation) {
            scanLocation = '';
        }
        return {
            feedbackDate: DateToStringFormat(new Date()),
            scanLocation,
            customerId: this.verification.customerId,
            mobileNumber: this.getBrowser(window),
            rating: Math.round(this.rating / 20),
            secondaryId: this.verification.evdensCode,
            productId: this.verification.productId,
            productName: this.verification.productName,
            description: ''
        };
    }

    onRateUsSubmitHandler = () => {
        // TODO: Call the API
        BaseLabelWrite.post('/feedback', this.getRatingPayload()).then(() => {
            this.onRateUsDisabled = true;
        });
    }
    onRatingChangeHandler = (value) => {
        this.rating = value;
    }

    render() {
        return (
            <div style={{ height: "100vh" }}>
                {this.showFirst &&
                    <div>
                        <div className="cus-container p-3 text-center">
                            <div className="w-100 my-3 position-relative" >
                                <img src={require("../../assets/image/ic_evdens_t_new.png")} style={{ width: "200px" }} alt="evdens" />
                            </div>
                            <h5 className="font-weight-bold navy-blue-text-color">Welcome to Evdens!</h5>
                            <h5 className="font-weight-bold navy-blue-text-color">The Anti-counterfeiting solution provider</h5>
                            <p className="navy-blue-text-color"> Thank you for your interest in validating this Evdens Label</p>
                            <p className="navy-blue-text-color">Verification Channel: Web</p>
                            {!this.disableButton &&
                                <div>
                                    <p className="text-black">Please check the checkbox and click Verify</p>
                                    <div className="row mb-2">
                                        <div className="col-12 text-center">
                                            <div className="g-recaptcha">
                                                <ReCAPTCHA
                                                    size="normal"
                                                    sitekey="6LczfOsZAAAAAKZSDdR6a8RdEkw469GOBA7_VrRJ"
                                                    onChange={this.onChange} />
                                                {this.showErrorText && <label className="text-danger">This field is required</label>}
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" onClick={this.verifyLabel} disabled={this.disableButton} className="mt-3 btn btn-app-primary">Verify</button>
                                </div>
                            }
                            {this.errorMessage &&
                                <div>
                                    <label className="text-danger font-weight-bold">{this.failureMessage}</label>
                                    <p className="text-black my-5">Try Evdens App and witness how it helps in buying authentic products</p>
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-6">
                                            <p className="text-right navy-blue-text-color font-weight-bold m-0">Download Evdens App</p>
                                        </div>
                                        <div className="col-6 text-left">
                                            <a href="https://play.google.com/store/apps/details?id=com.vglens.evdens_con">
                                                <img className="" src={require("../../assets/image/play_store_icon.png")} onClick={this.verifyLabel} style={{ width: "120px" }} alt="Message" />
                                            </a>
                                        </div>
                                    </div>
                                    <p className="my-3 navy-blue-text-color">To know more about Evdens, please visit <a href="https://evdens.com">https://evdens.com</a></p>
                                </div>
                            }
                        </div>
                    </div>
                }
                {this.showSecond && <ValidationScreenContainer {...this.verification}
                    evdensCode={this.verification.evdensCode}
                    marketLabel={this.verification.marketLabel}
                    orderNo={this.verification.orderNo}
                    brandName={this.verification.brandName}
                    branchAddress={this.verification.branchAddress}
                    expireDate={this.expireDate}
                    attributesLabel={this.attributesLabel}
                    attributesValue={this.attributesValue}
                    headerImageUrl={this.headerImageUrl}
                    footer2ImageUrl={this.footer2ImageUrl}
                    footer1ImageUrl={this.footer1ImageUrl}
                    branchImageURL={this.branchImageURL}
                    mfgDate={this.mfgDate}
                    batchNo={this.batchNo}
                    productImageUrl={this.productImageUrl}
                    customImageUrl={this.customImageUrl}
                    showModal={this.showModal}
                    onRateUsSubmit={this.onRateUsSubmitHandler}
                    onRateUsDisabled={this.onRateUsDisabled}
                    rating={this.rating}
                    onRatingChangeHandler={this.onRatingChangeHandler}
                />}

                {this.showThird ?
                    <div className="gray-bg-color">
                        <div className="cus-container p-3 text-center gray-bg-color width-40">
                            <div className="w-100 my-3 position-relative" >
                                <img src={require("../../assets/image/ic_evdens_t_new.png")} style={{ width: "200px" }} alt="evdens" />
                            </div>
                            <p className="navy-blue-text-color">Verification Channel: Web</p>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className="navy-blue-text-color font-weight-bold">Evdens UID Scanned:  {(this.verification) ? this.verification.evdensCode : "NA"}  </p>
                                </div>
                            </div>
                            <img src={require("../../assets/image/ic_suspious.png")} style={{ width: "50px" }} alt="evdens" />
                            <p className="navy-blue-text-color font-weight-bold my-3">Not Authentic! Evdens recommends not to buy/consume this product</p>
                            <p className="text-black my-5">Try Evdens App and witness how it helps in buying authentic products</p>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-6">
                                    <p className="text-right navy-blue-text-colorfont-weight-bold m-0">Download Evdens App</p>
                                </div>
                                <div className="col-6 text-left">
                                    <a href="https://play.google.com/store/apps/details?id=com.vglens.evdens_con">
                                        <img className="" src={require("../../assets/image/play_store_icon.png")} style={{ width: "120px" }} alt="Message" />
                                    </a>
                                </div>
                            </div>
                            <p className="my-3 navy-blue-text-color">To know more about Evdens, please visit <a href="https://evdens.com">https://evdens.com</a></p>
                        </div>
                    </div> : <React.Fragment></React.Fragment>}

                {this.showSecond ? <ValidationModal ref={instance => { this.modalDialog = instance }}
                    value={(this.showProductModal) ? this.productImageUrl : (this.showEvdensCodeModal) ? this.verification.evdensCodes : this.customImageUrl}
                    showProductImage={this.showProductModal} showCustomMessage={this.showCustomMessageModal} showEvdensCodes={this.showEvdensCodeModal} showWarranty={this.showWarrantyModal} submit={this.openWarrantyLink} customHeader={this.verification.customMessageHeader} customBody={this.verification.customMessageBody} /> : <React.Fragment></React.Fragment>}
            </div>
        )
    }
}

const ValidationScreenComponent = withRouter(ValidationScreen);
export { ValidationScreenComponent as ValidationScreen };
