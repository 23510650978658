import React from 'react';
import {withRouter} from "react-router-dom";
import  { Auth} from 'aws-amplify';
import { observer } from 'mobx-react';
import {observable} from "mobx";
import {BaseCustomer, BaseUser, clearCookies, cookies, updateAuthToken} from "../../config/HttpClients";
import Form from '../../constants/Form';
import eyeVision from "../../assets/image/eye-vision.png";
import eyeVisionHide from "../../assets/image/eye-vision-hide.png";
import {Stores} from "../../store/Stores";

@observer
class Login extends React.Component<any, any> {

    @observable email:string = '';
    @observable password:string = '';
    @observable passwordType:boolean = false;
    @observable errorMsg:string= '';
    @observable userNameInput:any;
    @observable passwordLengthError:boolean= false;
    @observable submitButton:any
    @observable isMobile:boolean = false;

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        let check = this.mobileCheck();
        if(check){
            this.errorMsg = "This application is not mobile compatible";
            this.isMobile = true;
        }
    }

    componentDidMount(){
        this.userNameInput.focus(); 
    }

    navigateRoute = (url:any) =>{
        if(!this.isMobile) {
            this.history.push(url);
        }
    };

	updateUsername = (event:any) => {
        this.email = event.target.value;
        this.errorMsg = '';
    };

    updatePassword = (event:any) => {
        this.password = event.target.value;
        this.errorMsg = '';
    };

    changePasswordType =() => {
        this.passwordType = ! this.passwordType;
    }

	handleSubmit = async(event:any) => {
        this.submitButton.focus();
        await Auth.signIn(this.email, this.password).then(response=>{
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED'){
                    this.navigateRoute('/create-password/'+this.email);
            } else {
                cookies.set('token', response.signInUserSession.idToken.jwtToken, { path: '/' })
                updateAuthToken(response.signInUserSession.idToken.jwtToken);
                this.getLoggedInUser();
            } 
        }).catch(error=>{
            if (error.__type === 'UserNotFoundException' || error.name === 'UserNotFoundException') {
                this.errorMsg = "Username does not exist";
            } else if(error&&error.code === 'NetworkError'){
                this.errorMsg = "No internet connection";
            } else{
                this.errorMsg = error.message;
            }
        }); 
    };

    getLoggedInUser=async()=> {
        await Auth.currentAuthenticatedUser().then(response=>{
            BaseUser.get("/user/"+response.username).then(
                response=> {
                    Stores.currentUser = response.data.payload;
                    if(Stores.currentUser === null || Stores.currentUser.status==="INACTIVE"){
                        this.errorMsg = "User account is suspended";
                        this.logout();
                    } else {
                        Stores.currentUserRole = Stores.currentUser.roles[0];
                        if(Stores.currentUser.userType.name !== "VGLENS") {
                            this.getCurrentCustomer();
                        } else {
                            //this.navigateRoute('/dashboard');
                            this.navigateRoute('/myProfile/profile');
                        }
                    }
                }
            )
        });
    }

    getCurrentCustomer=()=>{
        BaseCustomer.get("/customer/"+Stores.currentUser.customer).then(response => {
            Stores.currentCustomer = response.data.payload;
            if(Stores.currentCustomer === null || Stores.currentCustomer.status==="INACTIVE"){
                this.logout();
                this.errorMsg = "User account is suspended";
            } else {
                this.getCurrentBranch();
            }
        });
    }

    getCurrentBranch=()=>{
        BaseCustomer.get("/branch/"+Stores.currentUser.branch).then(response => {
            Stores.currentBranch = response.data.payload ;
            if(Stores.currentBranch  === null || Stores.currentBranch.status==="INACTIVE"){
                this.logout();
                 this.errorMsg = "User account is suspended";
            } else {
//                 if(Stores.currentUser.userType.name === "CUSTOMER"){
//                     this.navigateRoute('/dashboard');
//                 } else {
//                     this.navigateRoute('/company/customer');
//                 }
                this.navigateRoute('/myProfile/profile');
            }
        });
    }

    logout=async ()=>{
        await Auth.signOut();
        clearCookies();
    };

    mobileCheck = function() {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)|| navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)|| navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        return (
            <div className="authentication-wrapper authentication-3">
                <div className="authentication-inner">
                    <div className="d-none d-lg-flex col-lg-8 background-bg px-5" >
                        <div className="ui-bg-overlay bg-dark opacity-50"></div>
                            <div className="w-100  px-5">
                            </div>
                        </div>
                        <div className="d-flex col-lg-4 bg-white p-5 align-items-center">
                            <div className="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                            <div className="w-100">
                                <div className="d-flex justify-content-center align-items-center pb-3">
                                    <div className="ui w-100">
                                        <div className="w-100 text-center position-relative" >
                                            <img src={require("../../assets/image/ic_evdens_t_new.png")} style={{width:"200px"}} alt="evdens"/>
                                        </div>
                                    </div>
                                </div>
                                <Form submit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label className="form-label w-100 text-left">Username</label>
                                        <input type="email" ref={(input) => { this.userNameInput = input; }}  value={this.email} 
                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                        onChange={this.updateUsername} className="form-control" autoComplete="off" disabled={this.isMobile}
                                         required={true}  data-x='{"patternMismatch":"please enter a valid email Id","typeMismatch":"please enter a valid email Id"}' />
                                        <div className="invalid error"/>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label d-flex justify-content-between align-items-end">
                                            <div >Password</div>
                                        </label>
                                        <input type={this.passwordType?'text':'password'} value={this.password} onChange={this.updatePassword} className="form-control" autoComplete="off" required={true}
                                         data-x='{"tooShort":"Password length must be between 8 to 15 characters"}' minLength={8} maxLength={15} disabled={this.isMobile}/>
                                        <img className='eye' src={this.passwordType?eyeVisionHide:eyeVision} alt='Profile' height='20px' width='20px' onClick={this.changePasswordType} style={{marginRight:'5px'}} />
                                        <div className="invalid error"/>
                                    </div>
                                    <div className="d-flex flex-row-reverse mb-1">
                                        <p onClick={()=>this.navigateRoute('forgot-password')} className="d-block active mb-0" style={{textDecoration:'underline',color:'#20a8d8',cursor:'pointer'}}>Forgot Password?</p>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center m-0">
                                        <button type="submit" ref={instance=>{this.submitButton=instance}} className="btn btn-primary" disabled={this.isMobile}>Login</button>
                                    </div>
                                </Form>
                            <div className="card-footer p-1" style={{borderTop:"none"}} hidden={!this.errorMsg}>
                                <div  className="text-center badge-outline-danger error" style={{boxShadow:'none'}}>
                                   {this.errorMsg}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
           
        )
     }
}

const LoginComponent = withRouter(Login);

export {LoginComponent as Login};
