import {action, observable} from "mobx";

class Loader {

    @observable showLoader: boolean= false;
   

    @action setLoader(option) {
        this.showLoader = option;
    }
}

export {Loader}