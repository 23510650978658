import * as React from 'react'
import { withRouter, Switch} from "react-router";
import { AuthRoute } from '../../config/AuthRoute';
import { FeedBackAndComplaintsSideNav } from './FeedBackAndComplaintsSideNav';
import {FeedBacks} from "./FeedBacks";
import {Complaints} from "./Complaints";
import { BrandReferrals } from "./BrandReferrals";

class FeedbackAndComplaintsMainLayout extends React.Component<any,any> {

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
    }

    render() {
        return (
            <div>
                <div className="custom-height row mx-0 custom-scroll">
                    <div className="col-3 pl-0 pt-3">
                        <FeedBackAndComplaintsSideNav />
                    </div>
                    <div className="col-9 h-100 pl-0">
                        <Switch>
                            <AuthRoute exact path="/feedbackAndComplaints/feedback" component={FeedBacks} />
                            <AuthRoute exact path="/feedbackAndComplaints/complaint" component={Complaints} />
                            <AuthRoute exact path="/feedbackAndComplaints/brandReferrals" component={BrandReferrals} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}

const FeedbackAndComplaintsMainLayoutComponent = withRouter(FeedbackAndComplaintsMainLayout);
export {FeedbackAndComplaintsMainLayoutComponent as FeedbackAndComplaintsMainLayout};

