import {Slide, toast} from  'react-toastify';

const options = {
    autoClose: 5000,
    position: toast.POSITION.TOP_RIGHT,
    transition: Slide,
    closeButton: false
};

const ToastType = {
    SUCCESS:toast.TYPE.SUCCESS,
    ERROR:toast.TYPE.ERROR,
    WARN:toast.TYPE.WARNING,
    INFO:toast.TYPE.INFO
}

const LoadToast = (toastType, message) =>{
    switch (toastType) {
        case ToastType.SUCCESS:
            toast.success(message, options);
            break;
        case ToastType.ERROR:
            toast.error(message, options);
            break;
        case ToastType.WARN:
            toast.warn(message, options);
            break;
        case ToastType.INFO:
            toast.info(message, options);
            break;
    }
}

export {toast, LoadToast, ToastType};
