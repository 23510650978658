import * as React from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {Modal} from 'react-bootstrap';

@observer
class PartnerExistAlert extends React.Component<any,{}> {

    @observable open:boolean = false;
    @observable message:string = '';

    setMessageAndShow=(message)=>{
        this.message = message;
        this.open = true;
    }

    close=()=>{
        this.open = false;
    }

    show=()=>{
           this.message = '';
        this.open = true;
    }

    render() {      
        return (
        <Modal
        show={this.open}
        onHide={this.close}
        dialogClassName={"modal-dialog-centered modal-sm"}
      >
        <Modal.Body className="p-2 pt-3">
            <div className="text-center">
            {
                 this.message&&
                 <p>{this.message}</p>
            }
            {
                !this.message&&
                <p>The same partner has already been added by another company.
                Would you like to add this partner and assocaited details to your supply chain?</p>
             }

            </div>
        </Modal.Body>
        <Modal.Footer className="p-1 pb-3 text-center b-none">
            {
                this.message&&
               <button className="btn btn-sm btn-app-secondary mx-auto" onClick={this.close}>Ok</button>
            }
            {
                !this.message&&
                <React.Fragment>
                <button className="btn btn-sm btn-app-secondary ml-auto" onClick={this.close}>No</button>
                <button className="btn btn-sm btn-app-primary mr-auto" onClick={this.props.submit}>Yes</button>
                </React.Fragment>
             }
        </Modal.Footer>
      </Modal>
            
        )
    }
}

export {PartnerExistAlert}

