import React from 'react';
import {withRouter} from "react-router-dom";
import {observable} from "mobx";
import {Order} from "../../store/Order";
import {OrderItem} from "../../store/OrderItem";
import { observer } from 'mobx-react';
import {BaseCustomer} from "../../config/HttpClients";
import {Pagination} from "../../constants/pagination/Pagination";
import {Stores} from "../../store/Stores";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {DateToStringFormat, viewDateFormat, getLoggedInUser} from "../../constants/CommonMethods";
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";


@observer
class ViewOrder extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable orderStatus: string = '';
    @observable orders:Order[] = [];
    @observable customer: string = '';
    @observable order: Order = new Order();
    @observable orderItem: OrderItem = new OrderItem();
    @observable paginationFormat: any;
    @observable fromDate:any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate:any;
    @observable expectedMinDate:any;
    @observable errorAlertModal:any;

    constructor(props: any) {
        super(props);
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01') ;
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        this.getOrders();
    }

    handleFromDate=(date) =>{
        this.fromDate = DateToStringFormat(date);
        if(this.fromDate) { this.fromDateValue = this.fromDate}
        this.pageNo = 0;
        this.getOrders();
    }
    handleToDate=(date)=>{
        this.toDate = DateToStringFormat(date);
        if(this.toDate) { this.toDateValue = this.toDate}
        this.pageNo = 0;
        this.getOrders();
    }
    deleteOrder=() => {
        BaseCustomer.delete("/order/delete?id="+this.order.id).then(res=> {
            this.errorAlertModal.close();
            this.getOrders();
        });
    };

    deleteOrderAlert=(order) => {
        this.order = order;
        this.errorAlertModal.show();
    };

    getOrders = () => {
        let existPartnerId;
        let existPartner;
        if(!this.orderStatus){this.orderStatus = 'null';}
        if(!Stores.currentUser.customer) { this.customer = 'null'; } else {this.customer = Stores.currentUser.customer}
        BaseCustomer.get("/order?pageNo=" + this.pageNo + "&limit=" + this.limit + "&orderStatus=" + this.orderStatus + "&fromDate="+this.fromDateValue + "&toDate="+this.toDateValue + "&customerId=" + this.customer+"&printingPartnerId=null").then(response => {
            this.orders = response.data.payload;
            this.orders.map((order)=> {
                order.orderDate=viewDateFormat(order.orderDate);
                if (existPartnerId !== order.partner) {
                  BaseCustomer.get("/customer/"+order.partner).then(response => {
                        order.customer = response.data.payload;
                        existPartnerId = order.partner;
                        existPartner = order.customer;
                    });
                } else {
                    order.customer = existPartner;
                }
            
                order.orderItems.map((orderItem)=> {
                    orderItem.expectedDeliveryDate = viewDateFormat(orderItem.expectedDeliveryDate);
                    return true;
                });
                return true;
            })
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
        });
    };
    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
        this.pageNo = 0;
        this.getOrders();
    };
    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getOrders();
    };
    handleOrderStatus = (event) => {
        this.orderStatus = event.target.value;
        this.pageNo = 0;
        this.getOrders();
    }
    showOrderDetails(order) {
        this.order = order;
        this.orderItem = new OrderItem();
    };
    closeOrderDetails = () => {
        this.order = new Order();
        this.orderItem = new OrderItem();
    };


    render() {
        return (
            <div className="col-12 pt-3">
                <div className="row mx-0 pl-0">
                    <div className="col-12 px-0">
                        <div className="card b-none">
                            <div className="card-body">
                                <div className='row mx-0 mb-1'>
                                    <div className="col-2 inner-header">  From Date </div>
                                    <div className="col-2 inner-header">  To Date </div>
                                    <div className='col-4 inner-header'>  Status</div>
                                    <div className='col-4 pr-0'>
                                        <div className='float-right inner-header'> Items per Page</div>
                                    </div>
                                </div>
                                <div className='row mx-0 mb-2'>
                                    <div className="col-2">
                                            <Datepicker value={this.fromDate} minDate={this.expectedMinDate} inputChangeHandler={this.handleFromDate}  isRequired={true}></Datepicker>
                                    </div>
                                    <div className="col-2">
                                            <Datepicker value={this.toDate} minDate={this.expectedMinDate} inputChangeHandler={this.handleToDate} isRequired={true}></Datepicker>
                                    </div>
                                    <div className='col-4 pl-0'>
                                        <div className='col-8 '>
                                            <select className="form-control"
                                                    id="orderStatus" onChange={this.handleOrderStatus.bind(this)}
                                                    >
                                                <option className="select-option" value=''>All</option>
                                                <option className="select-option" value='OPEN'>Open</option>
                                                <option className="select-option" value='INPROGRESS'>In progress</option>
                                                <option className="select-option" value='CLOSED'>Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-4 pr-0'>
                                        <div className='col-4 float-right pr-0'>
                                            <select className="form-control"
                                                    value={this.limit}
                                                    id="customer"
                                                    onChange={this.handlePageLimit.bind(this, this.limit)}>
                                                <option className="select-option" value={10}>10</option>
                                                <option className="select-option" value={15}>15</option>
                                                <option className="select-option" value={20}>20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.totalRecords > 0 &&
                                    <div className="row mx-0">
                                        <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                    </div>
                                }
                                <table className="table m-0">
                                    <thead>
                                    <tr className="row text-nowrap table-header">
                                        <th className="col-2 text-center">Order Id</th>
                                        <th className="col-2 text-center ">Order Date</th>
                                        <th className="col-4 text-center">Label Supplier</th>
                                        <th className="col-2 text-center">Status</th>
                                        <th className="col-2 text-center">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                                <table className="table" style={{marginTop: '-2px'}}>
                                    <tbody>
                                    {
                                        this.orders && this.orders.map((order, index) =>
                                            <React.Fragment key={index}>
                                                <tr key={index} className="row text-nowrap table-body-value">
                                                    <td className="col-2 text-center">{order.orderId}</td>
                                                    <td className="col-2 text-center">  {order.orderDate}</td>
                                                    <td className="col-4 text-center">{order.customer.name}</td>
                                                    <td className="col-2 text-center transform">{(order.orderStatus==='INPROGRESS')?(<span >In Progress</span>):(<React.Fragment></React.Fragment>)}{(order.orderStatus==='OPEN')?(<span >Submitted</span>):(<React.Fragment></React.Fragment>)}{(order.orderStatus==='COMPLETED')?(<span >Completed</span>):(<React.Fragment></React.Fragment>)}{(order.orderStatus==='CLOSED')?(<span >Completed</span>):(<React.Fragment></React.Fragment>)}</td>
                                                    <td className={'col-2 text-center'}
                                                    >
                                                        {
                                                            this.order && this.order.id !== order.id &&
                                                            <div className='row mx-0 pl-4'>
                                                                <div className='col-4'>
                                                                <img alt="add"
                                                                     className="cursor-pointer"
                                                                     style={{width: '13px'}}
                                                                     onClick={() => this.showOrderDetails(order)}
                                                                     src={require("../../assets/image/addIcon.png")}/>
                                                                </div>
                                                                {
                                                                    order.orderStatus === 'OPEN' &&
                                                                    <div className='col-4'>
                                                                        <img  style={{width: '13px'}}
                                                                              className="cursor-pointer"
                                                                              alt="close" onClick={() => this.deleteOrderAlert(order)}
                                                                              src={require("../../assets/image/delete.jpg")}/>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            this.order && this.order.id === order.id &&
                                                            <div className='row mx-0 pl-4'>
                                                                <div className='col-4'>
                                                                    <img  alt="add"
                                                                          className="cursor-pointer"
                                                                         style={{width: '13px'}}
                                                                         onClick={() => this.closeOrderDetails()}
                                                                         src={require("../../assets/image/removeIcon.png")}/>
                                                                </div>
                                                                {
                                                                    order.orderStatus === 'OPEN' &&
                                                                    <div className='col-4'>
                                                                        <img  style={{width: '13px'}}
                                                                              className="cursor-pointer"
                                                                              alt="close" onClick={() => this.deleteOrderAlert(order)}
                                                                              src={require("../../assets/image/delete.jpg")}/>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                                {
                                                    this.order && this.order.id === order.id &&
                                                    <tr className="row mx-2 table-header">
                                                        <td className='col-12'
                                                            style={{
                                                                padding: '.65rem'
                                                            }}>
                                                            <div className='row text-nowrap mb-1'>
                                                               {/*<div
                                                                    className='col-3 font-weight-bold text-center'> Label
                                                                </div>*/}
                                                                <div
                                                                    className='col-3 font-weight-bold text-center'> Product
                                                                </div>
                                                                <div
                                                                    className='col-3 font-weight-bold text-center'>Evdens UID Type
                                                                </div>
                                                                <div
                                                                    className='col-3 font-weight-bold text-center'>Quantity
                                                                </div>
                                                                <div
                                                                    className='col-3 font-weight-bold text-center'>Expected Delivery Date
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                }
                                                {
                                                    this.order && this.order.id === order.id &&
                                                    order.orderItems.map((orderItem, key) =>
                                                        <tr className="row mx-2 table-body-value" key={key}>
                                                            <td className='col-12' style={{
                                                                border: '1px solid #E2E2E3',
                                                                padding: '.65rem'
                                                            }}>
                                                                <div className='row text-nowrap'>
                                                                    <div className='col-3 text-center'> {orderItem.productName?(<span >{orderItem.productName}</span>):(<span>-</span>)}</div>
                                                                    <div className='col-3 text-center'> {(orderItem.ovd==='WithOVD')?(<span >evdensDigiSign</span>):(<React.Fragment></React.Fragment>)}{(orderItem.ovd==='WithoutOVD')?(<span >evdensCodes</span>):(<React.Fragment></React.Fragment>)}</div>
                                                                    <div className='col-3 text-center'> {orderItem.quantity}</div>
                                                                    <div className='col-3 text-center'> {orderItem.expectedDeliveryDate}</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    {
                                        this.orders && this.orders.length === 0 &&
                                        <tr>
                                            <td className="text-center error" colSpan={7}>No orders found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                <Pagination totalRecords={this.totalRecords}  displayRecords={this.limit} pageNo={this.pageNo}
                                            partialPageCount={5} handleChangePage={this.handleChangePage}
                                            ref={ref => {this.paginationFormat = ref}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorAlertModal  ref={instance=>{this.errorAlertModal=instance}} submit={this.deleteOrder}  />
            </div>
        )
    }
}


const ViewOrderComponent = withRouter(ViewOrder);
export {ViewOrderComponent as ViewOrder};