import {action, observable} from 'mobx';

class ProductAttribute {
    @observable id:string = '';
    @observable label:string = '';
    @observable value:string = '';
    @observable showAuth:boolean = true;

    @action
    setProductAttribute = (productAttribute: any) => {
        this.id = productAttribute.id;
        this.label = productAttribute.label;
        this.value = productAttribute.label;
        this.showAuth = productAttribute.showAuth;
        return this;
    }
    @action reset() {
        this.id                     = '';
        this.label                  = '';
        this.value                  = '';
        this.showAuth             = true;
    }
}

export {ProductAttribute};
