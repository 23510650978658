import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {ProductSearch} from './ProductSearch';
import {observable} from 'mobx';
import {BaseProduct, BaseUser} from '../../config/HttpClients';
import {Product} from '../../store/Product';
import {ProductSubCategory} from '../../store/ProductSubCategory';
import {ViewProductDetails} from '../approveNewProduct/ViewProductDetails';
import {PackagingLevelForm} from '../manage-product/PackagingLevelForm';
import {ProductAttributeForm} from '../manage-product/ProductAttributeForm';
import {Stores} from '../../store/Stores';
import {ProductImage} from '../manage-product/ProductImage';
import {ProductNotification} from "../../store/ProductNotification";

@observer
class Products extends React.Component<any, any> {

    @observable product = new Product();
    @observable option: string = '';
    @observable subCategories: ProductSubCategory[] = [];
    @observable imgUrl: any;
    @observable productNotification = new ProductNotification();
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        console.log("sadasdasdasd")
    }

    handleUserInput = (targetObject: any, event: any) => {
        targetObject[event.target.id] = event.target.value;
    };

    save = () => {
        if (this.product.id) {
            this.updateProduct(this.product);
        } else {
            this.createProduct();
        }
    }

    createProduct = () => {
        BaseProduct.post("/product", this.product).then(response => {
            this.product = new Product();
            this.product.reset();
        });
    }

    updateProduct = (product) => {
        product.updatedBy = Stores.currentUser.id;
        BaseProduct.put("/product/requestProduct", this.product).then(response => {
            this.productNotification.status = product.productStatus;
            this.getUserById(product.createdBy);
            this.product = new Product();
            this.product.reset();
        });
    }

    getUserById = (id) => {
        BaseUser.get("/user/id/" + id).then(response => {
            this.productNotification.registeredBy = response.data.payload.userName;
            this.sendProductStatusNotification(this.productNotification);
        });
    }
    sendProductStatusNotification = (productNotification) => {
        BaseUser.post("/email/productApproval", productNotification).then(response => {
        });
    }

    setSelectedProduct = (product) => {
        this.product = new Product();
        this.product = product;
        this.imgUrl = '';
        this.option = '';
        if (this.product.fileName) {
            this.getproductImage(this.product.fileName);
        }
    }

    getproductImage = (fileName) => {
        BaseProduct.get("/product/image?fileName=" + fileName, {
            headers: {Accept: "*/*"},
            responseType: "blob"
        }).then(response => {
            let blob = new Blob([response.data], {type: response.headers["content-type"]});
            this.imgUrl = URL.createObjectURL(blob);
        });
    }
    setOption = (option) => {
        this.option = option;
    }

    render() {
        return (
            <div className="col-12 p-0">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6  h-42'>
                        {
                            Stores.customer &&
                            <b style={{ fontSize:'18px',fontWeight:'bold', letterSpacing: '1px'}}>
                                {Stores.customer.name}
                            </b>
                        }
                        {
                            Stores.currentCustomer &&
                            <b style={{ fontSize:'18px',fontWeight:'bold', letterSpacing: '1px'}}>
                                {Stores.currentCustomer.name}
                            </b>
                        }
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <ProductSearch setSelectedProduct={this.setSelectedProduct}/>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 py-3">
                    <div className="col-7">
                        <ViewProductDetails updateProduct={this.updateProduct} product={this.product}
                                            setOption={this.setOption} disabled={true}/>
                    </div>
                    <div className="col-5 pl-0">
                        <div className="card">
                            <div className="card-body" style={{minHeight: "200px"}}>
                                <div className="pt-2">
                                    <React.Fragment>
                                        {
                                            this.option === 'pacageLevel' &&
                                            <PackagingLevelForm packagingLevels={this.product.packagingLevels}
                                                                disabled={true}/>
                                        }
                                        {
                                            this.option === 'image' &&
                                            <ProductImage imgUrl={this.imgUrl} disabled={true}/>
                                        }
                                        {
                                            this.option === 'attribute' &&
                                            <ProductAttributeForm attributes={this.product.attributes} disabled={true}/>
                                        }
                                    </React.Fragment>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ProductComponent = withRouter(Products);
export {ProductComponent as Products};
