import * as React from "react";
import { observer } from "mobx-react";
import Form from "../../constants/Form";
import {
  FormGroup,
  ButtonToolbar, 
  Button,
  ControlLabel
} from "react-bootstrap";


@observer
class PartnerTypeForm extends React.Component<any, any> {

  handleUserInput = (targetObject:any, event:any) => {
		targetObject[event.target.id] = event.target.value;
	};

  handleStatus = (targetObject:any,status:any, event:any) => {
    targetObject[event.target.name] = status;
  }

  	render() {
    return (
      <Form submit={this.props.save}>
        <div className="row">
          <div className="col-6">
              <div className="col-8 pl-0">
            <FormGroup controlId="name" bsSize="large">
              <ControlLabel className="font-weight-bold">Partner Type</ControlLabel>
              <input type="text" className="form-control" value={this.props.partnerType&&this.props.partnerType.name}
                onChange={this.handleUserInput.bind(this, this.props.partnerType)}  maxLength={255}
                id="name" required={true} placeholder="Partner Type"/>
              <div className="invalid error"/>
            </FormGroup>
            </div>
          </div>
          <div className="col-4 pl-0 align-self-center">
            <FormGroup controlId="status" bsSize="large">
              <ControlLabel className="font-weight-bold">State</ControlLabel>
              <div className="row mx-0">
                <div className="ml-1 custom-control custom-radio">
                  <input type="radio" id={'active'} checked={this.props.partnerType&&this.props.partnerType.status === 'ACTIVE'}  onChange={this.handleStatus.bind(this, this.props.partnerType,'ACTIVE')} className="custom-control-input" name="status" required={true}/>
                  <label className="custom-control-label" htmlFor={"active"}> Active</label>
                </div>  
                <div className="ml-2 custom-control custom-radio">
                  <input type="radio" id={'inActive'} checked={this.props.partnerType&&this.props.partnerType.status === 'INACTIVE'}  onChange={this.handleStatus.bind(this, this.props.partnerType,'INACTIVE')} className="custom-control-input" name="status"/>
                  <label className="custom-control-label" htmlFor={"inActive"}> Inactive</label>
                  <div className="invalid error"/>  
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="col-2 pl-0 align-self-center">
          <ButtonToolbar >
          <Button className="btn button-sec-color" type="submit"> Save</Button>
          </ButtonToolbar>
          </div>
        </div>
        	      
      </Form>
    );
  }
}

export { PartnerTypeForm };
