import {action, observable} from 'mobx';

class Role {
    @observable id:string = '';
    @observable name:string = '';
    @observable customer:string = '';
    @observable createdBy:string = '';

    @action
    setRole = (type: any) => {
        this.id = type.id;
        this.name = type.name;
        this.customer = type.customer;
    	this.createdBy = type.createdBy;
        return this;
    }
    @action reset() {
        this.id = '';
        this.name = '';
        this.customer = '';
	    this.createdBy = '';
    }
}
export {Role};
