import * as React from "react";
import { observer } from "mobx-react";
import Form from "../../constants/Form";
import {
    FormGroup,
    ButtonToolbar,
    Button,
    ControlLabel
} from "react-bootstrap";

@observer
class EvdensContactForm extends React.Component<any, any> {

    handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
        if(event.target.id === "medium"){
            this.props.evdensContact.value = '';
        }
    };

    handleStatus = (targetObject:any,status:any, event:any) => {
        targetObject[event.target.name] = status;
    }


    render() {
        return (
            <Form submit={this.props.save}>
                <div className="row">
                    <div className="col-3">
                        <FormGroup controlId="name" bsSize="large">
                            <ControlLabel className="font-weight-bold">Medium</ControlLabel>
                            <select className="form-control"
                                    value={this.props.evdensContact && this.props.evdensContact.medium}
                                    onChange={this.handleUserInput.bind(this, this.props.evdensContact)}
                                    required={true}
                                    id="medium">
                                <option value={''}>Select Medium</option>
                                <option className="select-option" value={'EMAIL'}>Email</option>
                                <option className="select-option" value={'PHONE'}>Phone</option>
                            </select>
                        </FormGroup>
                    </div>
                    <div className="col-4 pl-0">
                        {
                            this.props.evdensContact&&this.props.evdensContact.medium==="EMAIL"&&
                                <FormGroup bsSize="large">
                                    <ControlLabel className="font-weight-bold">Email Id</ControlLabel>
                                    <input type="text" className="form-control" value={this.props.evdensContact&&this.props.evdensContact.value}
                                           pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                           data-x='{"typeMismatch":"Please enter valid email","patternMismatch":"Please enter valid email"}'
                                           onChange={this.handleUserInput.bind(this, this.props.evdensContact)}  maxLength={255}
                                           id="value" required={true} placeholder="Email Id"/>
                                    <div className="invalid error"/>
                                </FormGroup>
                        }
                        {
                            this.props.evdensContact&&this.props.evdensContact.medium==="PHONE"&&
                            <FormGroup controlId="name" bsSize="large">
                                <ControlLabel className="font-weight-bold">Phone Number</ControlLabel>
                                <input type="tel" className="form-control" value={this.props.evdensContact&&this.props.evdensContact.value}
                                       placeholder={"Phone Number"}
                                       onChange={this.handleUserInput.bind(this, this.props.evdensContact)}
                                       maxLength={50} id="value" name="value" required={true} />
                                <div className="invalid error"/>
                            </FormGroup>
                        }
                    </div>
                    <div className="col-3 px-0 align-self-center">
                        <FormGroup controlId="status" bsSize="large">
                            <ControlLabel className="font-weight-bold">State</ControlLabel>
                            <div className="row mx-0">
                                <div className="ml-1 custom-control custom-radio">
                                    <input type="radio" id={'active'} checked={this.props.evdensContact&&this.props.evdensContact.status === 'ACTIVE'}  onChange={this.handleStatus.bind(this, this.props.evdensContact,'ACTIVE')} className="custom-control-input" name="status" required={true}/>
                                    <label className="custom-control-label" htmlFor={"active"}> Active</label>
                                </div>
                                <div className="ml-2 custom-control custom-radio">
                                    <input type="radio" id={'inActive'} checked={this.props.evdensContact&&this.props.evdensContact.status === 'INACTIVE'}  onChange={this.handleStatus.bind(this, this.props.evdensContact,'INACTIVE')} className="custom-control-input" name="status"/>
                                    <label className="custom-control-label" htmlFor={"inActive"}> Inactive</label>
                                    <div className="invalid error"/>
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                    <div className="col-2 pl-0 align-self-center">
                        <ButtonToolbar className="justify-content-center">
                            <Button className="btn button-sec-color" type="submit"> Save</Button>
                        </ButtonToolbar>
                    </div>
                </div>
            </Form>
        );
    }
}

export { EvdensContactForm };
