import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import {observable} from "mobx";
import {Customer} from "../../store/Customer";
import {Notification} from "../../store/Notification";
import { BaseCustomer ,BaseNotification} from "../../config/HttpClients";
import {Pagination} from "../../constants/pagination/Pagination";
import { Product } from '../../store/Product';
import { NotificationTableDetails } from './NotificationTableDetails';
import {Stores} from "../../store/Stores";
import {DateToStringFormat, getLoggedInUser, viewDateTimeFormat} from "../../constants/CommonMethods";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {NotificationDetails} from "./NotificationDetails";
import {Button} from 'react-bootstrap';

@observer
class Notifications extends React.Component<any, any> {

    @observable pageNo:number = 0;
    @observable limit:number = 10;
    @observable totalRecords:number = 0;
    @observable customers:Customer[] = [];
    @observable customer:Customer = new Customer();
    @observable notificationType:string='';
    @observable products:Product[] = [];
    @observable product:Product = new Product();
    @observable notifications:Notification[] = [];
    @observable paginationFormat: any;
    @observable showCustomer: any;
    @observable productStatus: any;
    @observable productSearchCom:any;
    @observable disabled:boolean = true;
    @observable fromDate:any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate:any;
    @observable expectedMinDate:any;
    @observable alertType:string = '';
    @observable codeType:string = '';
    @observable userType:string = '';
    @observable search:string = '';
    @observable status:string = '';
    @observable secondaryId:string = '';
    @observable evdensCode:string = '';
    @observable disableInput:boolean = false;
    @observable disableInputCode:boolean = true;
    @observable pageOption: string = 'NOTIFICATION';
    @observable count: number = 0;
    @observable currentAlertPageNo: number = 0;
    @observable invalidLabel: boolean = false;
    @observable searchByCode: boolean = false;
    @observable searchByFilter: boolean = true;
    history:any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01') ;
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        if(Stores.currentUser&&Stores.currentUser.userType.name === "CUSTOMER") {
            this.showCustomer = false;
            this.customer = Stores.currentCustomer;
            this.getNotifications();
            this.disabled = false;
        } else {
            this.showCustomer = true;
            this.getCustomers();
            this.getNotifications();
        }
    }

    getCustomers=() => {
        BaseCustomer.get("/customer/userType?type=CUSTOMER").then(response=>{
            this.customers = response.data.payload;
        });
    }

    getNotifications= () => {
        let search;
        let status;
        let userType;
        let alertType;
        let codeType;
        let evdensCode;
        let fromDateValue;
        let toDateValue;
        if(!this.customer.id){this.customer.id = 'null';}
        if(this.search !== ''){
            search = this.search;
        } else {
            search = 'null';
        }
        if(this.status !== ''){
            status = this.status;
        } else {
            status = 'null';
        }
        if(this.userType !== ''){
            userType = this.userType;
        } else {
            userType = 'null';
        }
        if(this.alertType !== ''){
            alertType = this.alertType;
        } else {
            alertType = 'null';
        }
        if(this.codeType !== ''){
            codeType = this.codeType;
        } else {
            codeType = 'null';
        }
        if(this.evdensCode !== ''){
            evdensCode = this.evdensCode;
        } else {
            evdensCode = 'null';
        }
        if(this.fromDateValue !== ''){
            fromDateValue = this.fromDateValue;
        } else {
            fromDateValue = 'null';
        }
        if(this.toDateValue !== ''){
            toDateValue = this.toDateValue;
        } else {
            toDateValue = 'null';
        }
        BaseNotification.get("/notification?pageNo="+this.pageNo+"&limit="+this.limit+"&fromDate="+fromDateValue+"&toDate="+toDateValue+"&type=Notification&alertType="+alertType+"&from="+userType+"&customer="+this.customer.id+"&status="+status+"&search="+search+"&codeType="+codeType+"&secondaryId="+evdensCode).then(response=>{
            this.notifications = response.data.payload;
            if(this.notifications) {
                this.notifications.forEach((notification)=>{
                    notification.createdDateConvert = viewDateTimeFormat(notification.createdDate);
                });
            }
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
        });
    }

    handleCustomer= (customer, event) => {
        customer.id =  event.target.value;
    };

    handlePageLimit= (limit, event) => {
        this.limit = event.target.value;
    };

    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
    }

    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
    }

    handleChangePage=(pageNo)=>{
        this.pageNo = pageNo;
        Stores.notificationPageNo = pageNo;
        this.getNotifications();
    }

    handleAlertType = (event) => {
        this.alertType = event.target.value;
    }

    handleCodeType = (event) => {
        this.codeType = event.target.value;
    }

    handleUserType= (event) => {
        this.userType = event.target.value;
    }

    handleStatus= (event) => {
        this.status = event.target.value;
    }

    handleEvdensCode = (event) => {
        this.evdensCode = event.target.value;
    }

    handleSearchByCode = (event) => {
        this.searchByCode = true;
        this.disableInputCode = false;
        this.searchByFilter = false;
        this.disableInput = true;
        this.resetFilters();
    }

    handleSearchByFilter = (event) => {
        this.searchByFilter = true;
        this.disableInput = false;
        this.searchByCode = false;
        this.disableInputCode = true;
        this.resetFilters();
    }

    handleSubmitCode = (event) => {
        this.invalidLabel = false;
        if(this.evdensCode.length > 0 && this.evdensCode.length < 14) {
            this.invalidLabel = true;
            return;
        }
        if(/^[A-Za-z]+$/.test(this.evdensCode.substring(0,3))) {
            this.pageNo = 0;
            this.getNotifications();
        } else {
            this.invalidLabel = true;
        }
    };

    handleSubmitFilter = (event) => {
        this.pageNo = 0;
        this.getNotifications();
    };

    resetFilters() {
        this.limit = 10;
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        if(this.searchByCode) {
            this.fromDateValue = '';
            this.toDateValue = '';
        }
        this.alertType = '';
        this.codeType = '';
        this.userType = '';
        this.search = '';
        this.status = '';
        this.customer.reset();
        this.customer = new Customer();
        this.evdensCode = '';
    }

    viewNotification = (notification) => {
        this.count = notification.count;
        this.secondaryId = notification.secondaryId;
        this.currentAlertPageNo = this.count -1;
        this.setOption("NOTIFICATIONDETAIL");
    }

    setOption=(option)=>{
        this.pageOption = option;
        if(this.pageOption ==="NOTIFICATION"){
            this.getNotifications();
        }
    }

    navigate = (url:any) =>{
        this.history.push(url);
    };
    
    render() {
    const currentSolution = Stores.currentCustomer.solution;
    const currentUser = Stores.currentUser.userType.name;

        return(
            <React.Fragment>
                {
                    this.pageOption === 'NOTIFICATION' &&
            <div className="col-12 py-3 h-100">
                <div className="row mx-0 h-100">
                    <div className="col-12 h-100 px-0">
                        <div className="card b-none h-100">
                            <div className="card-body h-100">
                                <div className='row mx-0 mb-2 align-items-center'>
                                    <div className='col-2 pl-0 font-weight-bold'>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id={'searchByCode'} checked={this.searchByCode}
                                                onChange={this.handleSearchByCode.bind(this)} className="custom-control-input"/>
                                            <label className="custom-control-label" htmlFor={"searchByCode"}> Search By</label>
                                        </div>
                                    </div>
                                    <div className='col-3 pl-0'>
                                        <input type="text" className="form-control"
                                           value={this.evdensCode}
                                           onChange={this.handleEvdensCode} id="evdensCode" required={true} 
                                           autoComplete="off" maxLength={14} placeholder="Evdens UID" disabled={this.disableInputCode}/>
                                           {this.invalidLabel ? <div className="invalid error">Invaild Evdens UID</div> : null}
                                    </div>
                                    <div className='col-3 px-0'>
                                        <Button className="btn btn-app-primary mr-2" type="submit" onClick={this.handleSubmitCode}
                                        disabled={this.disableInputCode}>Search</Button>
                                    </div>
                                </div>
                                <div className='row mx-0 mb-2 align-items-center'>
                                    <div className='col-2 pl-0 font-weight-bold'>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id={'searchByFilter'} checked={this.searchByFilter}
                                                onChange={this.handleSearchByFilter.bind(this)} className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor={"searchByFilter"}> Search By</label>
                                        </div>
                                    </div>
                                    <div className="col-3 pl-0">
                                        <div>From Date</div>
                                        <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleFromDate}
                                                    isRequired={true} disabled={this.disableInput}></Datepicker>
                                    </div>
                                    <div className="col-3 pl-0">
                                        <div>To Date</div>
                                        <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleToDate}
                                                    isRequired={true} disabled={this.disableInput}></Datepicker>
                                    </div>
                                    <div className='col-2 pl-0'>
                                         <div>Status</div>
                                         <select className="form-control"
                                                value={this.status}
                                                id="customer"
                                                onChange={this.handleStatus.bind(this)}
                                                disabled={this.disableInput}>
                                            <option className="select-option" value={''}>All</option>
                                            <option className="select-option" value={'New'}>New</option>
                                            <option className="select-option" value={'ActionInitiated'}>Action Initiated</option>
                                            <option className="select-option" value={'Resolved'}>Resolved</option>
                                        </select>
                                    </div>
                                    <div className='col-2 p-0'>
                                        <div >Items per page</div>
                                        <select className="form-control"
                                                value={this.limit}
                                                id="customer"
                                                onChange={this.handlePageLimit.bind(this, this.limit)}
                                                disabled={this.disableInput}>
                                            <option className="select-option" value={10}>10</option>
                                            <option className="select-option" value={15}>15</option>
                                            <option className="select-option" value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mx-0'>
                                    <div className='col-2 pl-0'></div>
                                    <div className='col-2 pl-0'>Notification Type</div>
                                    <div className='col-2 pl-0'>Notification From</div>
                                    <div className='col-2 pl-0'>Evdens UID Type</div>
                                    <div className='col-2 px-0'>{this.showCustomer&&<span>Customer</span>}</div>
                                    <div className="col-1 pl-0"></div>
                                </div>
                                <div className='row mx-0 mb-2'>
                                    <div className='col-2 pl-0'></div>
                                    <div className='col-2 pl-0'>
                                        <select className="form-control"
                                                value={this.alertType}
                                                id="customer"
                                                onChange={this.handleAlertType.bind(this)}
                                                disabled={this.disableInput}>
                                            <option className="select-option" value={''}>All</option>
                                            <option className="select-option" value={'Diversion'}>Diversion</option>
                                            <option className="select-option" value={'Failure'}>Failure</option>
                                            <option className="select-option" value={'Damaged/Stained'}>Damaged/Stained</option>
                                            <option className="select-option" value={'Pilferage'}>Pilferage</option>
                                            <option className="select-option" value={'Grey Market'}>Grey Market</option>
                                            <option className="select-option" value={'Duplicate'}>Duplicate</option>
                                            <option className="select-option" value={'Repeat Scan'}>Repeat Scan</option>
                                            <option className="select-option" value={'Trial'}>Trial</option>
                                        </select>
                                    </div>
                                    <div className='col-2 pl-0'>
                                        <select className="form-control"
                                                value={this.userType}
                                                id="customer"
                                                onChange={this.handleUserType.bind(this)}
                                                disabled={this.disableInput}>
                                            <option className="select-option" value={''}>All</option>
                                            <option className="select-option" value={'CUSTOMER'}>CUSTOMER</option>
                                            <option className="select-option" value={'PARTNER'}>PARTNER</option>
                                            <option className="select-option" value={'CONSUMER'}>CONSUMER</option>
                                        </select>
                                    </div>
                                    <div className='col-2 pl-0'>
                                        <select className="form-control" value={this.codeType} id="customer"
                                                        onChange={this.handleCodeType.bind(this)}
                                                        disabled={this.disableInput}>
                                            <option className="select-option" value={''}>All</option>
                                            {((currentUser && currentUser=='VGLENS') || (currentSolution && (currentSolution == 'evdensDigiSign' || currentSolution == 'Both'))) && <option className="select-option" value={'DigiSign'}>evdensDigiSign</option>}
                                            {((currentUser && currentUser=='VGLENS') || (currentSolution && (currentSolution == 'evdensCodes' || currentSolution == 'Both'))) && <option className="select-option" value={'PlainCode'}>evdensCodes</option>}
                                        </select>
                                    </div>
                                    <div className="col-3 pl-0">
                                        {this.showCustomer&&<select className="form-control"
                                                    value={this.customer.id}
                                                    id="customer"
                                                    onChange={this.handleCustomer.bind(this, this.customer)}
                                                    disabled={this.disableInput}>
                                                <option className="select-option" value={''}>All</option>
                                                {this.customers.map((e, key) => {
                                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                                })}
                                            </select>
                                        }
                                    </div>
                                    <div className='col-1 text-right p-0'>
                                        <Button className="btn btn-app-primary" type="submit" onClick={this.handleSubmitFilter}
                                        disabled={this.disableInput}>Search</Button>
                                    </div>
                                </div>
                                {
                                    this.totalRecords > 0 &&
                                    <div className="row mx-0">
                                        <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                    </div>
                                }
                                <div className="row mx-0 mt-2" style={{height:'calc(100% - 168px)'}}>
                                    <div className="col-12 h-100 px-0">
                                        <div className="row mx-0">
                                            <table className="table m-0">
                                                <thead >
                                                    <tr className="row mx-0 text-nowrap table-header">
                                                        <th className="col-2 px-0 text-center px-0 text-wrap">Notification Id</th>
                                                        <th className="col-2 px-0 text-center px-0">Evdens UID</th>
                                                        <th className="col-2 px-0 text-center px-0">Evdens UID Type</th>
                                                        <th className="col-1 px-0 text-center">Count</th>
                                                        <th className="col-1 px-0 text-center text-wrap">Notification Type</th>
                                                        <th className="col-1 px-0 text-center">Source</th>
                                                        <th className="col-2 px-0 text-center text-wrap">Notification Date</th>
                                                        <th className="col-1 px-0 text-center">Status</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="row mx-0 custom-scroll" style={{marginTop:'-2px',height:'calc(100% - 98px)',overflowY:'auto', overflowX:'hidden'}}>
                                            <table className="table">
                                                <tbody>
                                                    <NotificationTableDetails notifications={this.notifications} isNotification={true} viewNotification={this.viewNotification}/>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination totalRecords={this.totalRecords}
                                                displayRecords={this.limit} pageNo={this.pageNo}
                                                partialPageCount={5} handleChangePage={this.handleChangePage}
                                                ref={ref => {
                                                    this.paginationFormat = ref
                                                }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                }
                {
                    this.pageOption === 'NOTIFICATIONDETAIL' &&
                    <NotificationDetails totalPage={this.count} fromDateValue={this.fromDateValue} toDateValue={this.toDateValue}
                                  alertType={this.alertType} secondaryId={this.secondaryId}
                                  search={this.search} userType={this.userType} status={this.status}
                                  currentPageNo={this.currentAlertPageNo} setOption={this.setOption}/>
                }
            </React.Fragment>
        )
    }
}

const NotificationsComponent = withRouter(Notifications);
export {NotificationsComponent as Notifications};
