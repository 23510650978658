import * as React from "react";
import {observer} from "mobx-react";
import { observable } from "mobx";
import Autosuggest from 'react-autosuggest';
import { BaseUser } from "../../config/HttpClients";
import {Stores} from "../../store/Stores";

@observer
class UserSearch extends React.Component<any, any> {
    @observable users : any = [];
    @observable customer:string= '';
    constructor(props){
        super(props);
        this.state = {
            nameValue: '',
            users: []
        };
    }

    onSuggestionChange = (event, {newValue, method}) => {
        this.setState({
            nameValue: newValue
        });
    };
    onSuggestionsFetchRequested = ({value}) => {
        this.customer = "null";
        if(Stores.currentUser.userType.name !== "VGLENS") {
            this.customer = Stores.currentUser.customer;
        }
         BaseUser.get("/user/searchByName?createdBy="+Stores.currentUser.id+"&name="+value+"&customer="+this.customer)
           .then(response => {
               if(response.data.payload.length===0 ){
                   this.props.resetUser();
               }
               this.setState({ users: response.data.payload});
           });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            nameValue: '',
            users: []
        });
    }
    getSuggestion = (suggestion) => {
        return suggestion.userName;
    }

    renderSuggestion = (suggestion) => {return ( <span>{suggestion.userName}</span>);};

    onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
        this.props.setSelectedUser(suggestion);
       // this.onSuggestionsClearRequested();
    }

    render() {
        const {nameValue, users} = this.state;

        const branchInputProps =
            {
                placeholder: "Search",
                value: nameValue,
                users: users,
                onChange: this.onSuggestionChange
            };

        const renderInputComponent = inputProps => (
            <div>
                <input {...inputProps} />
                <div className={"react-autosuggest__suggestions-container--open text-center p-2 error"}
                     hidden={!(inputProps.users.length === 0 && inputProps.value !== "")}>No User found...!
                </div>
            </div>
        );
        return (
            <Autosuggest
                suggestions={users}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestion}
                renderSuggestion={this.renderSuggestion}
                inputProps={branchInputProps}
                renderInputComponent={renderInputComponent}
            />
        )
    };
}

export {UserSearch};
