import React from 'react';
import { withRouter } from "react-router-dom";
import { observer } from 'mobx-react';
import { CustomerSearch } from './CustomerSearch';
import { CustomerForm } from './CustomerForm';
import { observable } from 'mobx';
import { Stores } from '../../store/Stores';
import { BaseCustomer, BaseUser } from '../../config/HttpClients';
import { Customer } from '../../store/Customer';
import { Partner } from '../../store/Partner';
import { SuspendDto } from '../../store/SuspendDto';
import { getLoggedInUser, showImage } from '../../constants/CommonMethods';
import { CustomerPrintingPartner } from "../../store/CustomerPrintingPartner";
import { ProductImage } from "../manage-product/ProductImage";
import { ErrorAlertModal } from "../../constants/modal/ErrorAlertModal";
import { SocialMediaForm } from "./SocialMediaForm";
import { ResultPageForm } from './ResultPageForm';

@observer
class Customers extends React.Component<any, any> {
    @observable customer = new Customer();
    @observable imgUrl: any;
    @observable printingPartners: Customer[] = [];
    @observable userPrintingPartners: Customer[] = [];
    @observable printingPartner: any;
    @observable partners: Partner[] = [];
    @observable files: any[] = [];
    @observable customerForm: any;
    @observable disableInput: boolean = false;
    @observable showImageDelete: boolean = false;
    @observable errorAlertModal: any;
    @observable customerSearch: any;
    @observable suspendAlertModal: any;
    @observable activateAlertModal: any;
    @observable comment: string = '';
    @observable suspendDto: SuspendDto = new SuspendDto();
    @observable socialMediaToggle: boolean = false;
    @observable resultPageImageToggle: boolean = false;
    @observable productImageToggle: boolean = true;
    @observable resultPageDetails: any = {};
    @observable selectedDeleteOption: string = '';

    componentDidMount() {
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init();
        }
    }

    init = () => {
        BaseCustomer.get("/customer/printingPartners?id=null").then(response => {
            this.printingPartners = response.data.payload;
            this.setCustomer();
        });
    }

    setCustomer = () => {
        this.showImageDelete = false;
        if ((Stores.currentUser && Stores.currentUser.userType.name === "CUSTOMER") || (Stores.currentUser && Stores.currentUser.userType.name === "SUPPLYCHAIN") || (Stores.currentUser && Stores.currentUser.userType.name === "PARTNER")) {
            this.customer = Stores.currentCustomer;
            this.disableInput = true;
            this.getPartners(this.customer && this.customer.partnerTypes && this.customer.partnerTypes[0]);
            this.customerForm.getCustomerVertical(this.customer);
            this.setPrintingPartners(this.customer && this.customer.printingPartners);
        } else if (Stores.currentUser && Stores.currentUser.userType.name === "VGLENS") {
            if (Stores.customer && Stores.customer.name) {
                this.customer = Stores.customer;
                this.customerForm.getCustomerVertical(this.customer);
                this.getPartners(this.customer && this.customer.partnerTypes && this.customer.partnerTypes[0]);
                this.setPrintingPartners(this.customer && this.customer.printingPartners);
            } else {
                this.customer = new Customer();
            }
        }
        if (this.customer.fileName) {
            this.getCustomerImage(this.customer.fileName);
            if (Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_SUPPLY_CHAIN_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_PARTNER_ADMIN") {
                this.showImageDelete = true;
            }
        }
        this.updateSocialMediaAndResultPage();
    }

    updateResultPageDetails = (formData) => {
        if (this.resultPageDetails && this.resultPageDetails.header) {
            this.customer.companyHeaderImageText = this.resultPageDetails.header.title;
            this.customer.companyHeaderUrl = this.resultPageDetails.header.link;
            this.resultPageDetails.header.files.forEach((file) => {
                formData.append('headerfile', file);
            });
        }

        if (this.resultPageDetails && this.resultPageDetails.footer1) {
            this.customer.companyFooter1ImageText = this.resultPageDetails.footer1.title;
            this.customer.companyFooter1Url = this.resultPageDetails.footer1.link;
            this.resultPageDetails.footer1.files.forEach((file) => {
                formData.append('footer1file ', file);
            });
        }

        if (this.resultPageDetails && this.resultPageDetails.footer2) {
            this.customer.companyFooter2ImageText = this.resultPageDetails.footer2.title;
            this.customer.companyFooter2Url = this.resultPageDetails.footer2.link;
            this.resultPageDetails.footer2.files.map((file) => {
                formData.append('footer2file ', file);
            });
        }
    }

    updateSocialMediaAndResultPage = () => {
        this.customer.socialMediaLinks = this.customer.socialMediaLinks || JSON.parse(this.customer.socialMedia || '[]') || [];
        this.resultPageDetails = {
            header: {
                title: this.customer.companyHeaderImageText,
                fileName: this.customer.companyHeaderImage,
                files: [],
                link: this.customer.companyHeaderUrl,
                url: this.customer.companyHeaderUrl,
            },
            footer1: {
                title: this.customer.companyFooter1ImageText,
                fileName: this.customer.companyFooter1Image,
                files: [],
                link: this.customer.companyFooter1Url,
                url: this.customer.companyFooter1ImageUrl,
            },
            footer2: {
                title: this.customer.companyFooter2ImageText,
                fileName: this.customer.companyFooter2Image,
                files: [],
                link: this.customer.companyFooter2Url,
                url: this.customer.companyFooter2ImageUrl,
            }
        }
    }

    handleUserInput = (targetObject: any, event: any) => {
        targetObject[event.target.id] = event.target.value;
    };
    handlePrintingPartners = (selectedOptions) => {
        this.userPrintingPartners = selectedOptions;
    };

    handleComment = (event) => {
        this.comment = event.target.value;
    };

    save = (event) => {
        this.addCustomerPrintingPartners(this.userPrintingPartners);
        if (this.customer.hasOwnProperty('id')) {
            if (this.customer.id) {
                this.updateCustomer();
            } else {
                this.createCustomer();
            }
        } else {
            this.createCustomer();
        }
    }

    addCustomerPrintingPartners = (userPrintingPartners) => {
        this.customer.printingPartners = [];
        userPrintingPartners.forEach(userPrintingPartner => {
            let customerPrintingPartner = new CustomerPrintingPartner();
            let printingPartner = this.printingPartners.find(printingPartner => printingPartner.id === userPrintingPartner.value);
            if (printingPartner) {
                customerPrintingPartner.printingPartner = userPrintingPartner.value;
            }
            this.customer.printingPartners.push(customerPrintingPartner);
        });
    }
    setPrintingPartners = (customerPrintingPartners) => {
        this.userPrintingPartners = [];
        customerPrintingPartners.forEach((customerPrintingPartner) => {
            if (this.printingPartners && this.printingPartners.length > 0) {
                this.printingPartner = this.printingPartners.find(printingPartners => printingPartners.id === customerPrintingPartner.printingPartner);
                customerPrintingPartner.value = this.printingPartner.id;
                customerPrintingPartner.label = this.printingPartner.name;
                this.userPrintingPartners.push(customerPrintingPartner);
            }
        });
    }

    createCustomer = () => {
        if (this.customer.hasOwnProperty('id')) {
            if (this.customer.id === "")
                delete this.customer.id;
        }
        if (this.customer.hasOwnProperty('vertical') && this.customer.vertical.id === "") {
            delete this.customer.vertical;
        }
        //         if (this.customer.hasOwnProperty('partners') && this.customer.partners[0].partner === "") {
        //             delete this.customer.partners;
        //         }
        this.customer.customerType = "CUSTOMER";
        this.customer.status = "ACTIVE";
        this.customer.userTypeId = Stores.currentUser && Stores.currentUser.userType && Stores.currentUser.userType.id;
        this.customer.createdBy = Stores.currentUser && Stores.currentUser.id;
        let formData = new FormData();
        this.updateResultPageDetails(formData);
        this.customer.socialMedia = JSON.stringify(this.customer.socialMediaLinks);
        formData.append('customer', JSON.stringify(this.customer));
        this.files.forEach((file) => {
            formData.append('file', file);
        });
        BaseCustomer.post("/customer", formData, { headers: { 'content-type': 'multipart/form-data' } }).then(response => {
            this.setNewCustomer();
        });
    }

    updateCustomer = () => {
        this.customer.updatedBy = Stores.currentUser && Stores.currentUser.id;
        let formData = new FormData();
        this.updateResultPageDetails(formData);
        this.customer.socialMedia = JSON.stringify(this.customer.socialMediaLinks);
        formData.append('customer', JSON.stringify(this.customer));
        this.files.forEach((file) => {
            formData.append('file', file);
        });
        BaseCustomer.post("/customer/update", formData, { headers: { 'content-type': 'multipart/form-data' } }).then(response => {
            this.setNewCustomer();
        });
    }

    setSelectedCustomer = (customer) => {
        this.imgUrl = "";
        this.customer = new Customer();
        this.customer.reset();
        this.customer = customer;
        Stores.customer = customer;
        this.getPartners(this.customer.partnerTypes[0]);
        this.setPrintingPartners(this.customer.printingPartners);
        this.showImageDelete = false;
        if (this.customer.fileName) {
            this.getCustomerImage(this.customer.fileName);
            if (Stores.currentUser.roles[0].name === "ROLE_VGLENS_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_SUPPLY_CHAIN_ADMIN" || Stores.currentUser.roles[0].name === "ROLE_PARTNER_ADMIN") {
                this.showImageDelete = true;
            }
        }
        this.files = [];
        this.customerForm.fileInput.value = "";
        this.customerForm.getCustomerVertical(customer);
        this.updateSocialMediaAndResultPage();
        this.showProductImage();
    }

    setNewCustomer = () => {
        this.customer = new Customer();
        this.customer.reset();
        if (Stores.customer.id) {
            this.customerSearch.clearFilter();
        }
        Stores.customer = new Customer();
        this.userPrintingPartners = [];
        this.customerForm.fileInput.value = "";
        this.imgUrl = "";
        this.showImageDelete = false;
        this.resultPageDetails = {};
        this.showProductImage();
    }

    getPartners = (customerPartnerType) => {
        if (customerPartnerType && customerPartnerType.partnerType && customerPartnerType.partnerType.id) {
            BaseCustomer.get("/customer/types?partnerType=" + customerPartnerType.partnerType.id + "&userType=PARTNER").then(response => {
                this.partners = response.data.payload;
            });
        }
    }

    getCustomerImage = (fileName) => {
        BaseCustomer.get("/customer/image?fileName=" + fileName, {
            headers: { Accept: "*/*" },
            responseType: "blob"
        }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this.imgUrl = URL.createObjectURL(blob);
        });
    }

    deleteCurrentItems = () => {
        if (this.selectedDeleteOption) {
            BaseCustomer.delete(`/customer/image?id=${this.customer.id}&imageType=${this.selectedDeleteOption.toUpperCase()}`).then(reponse => {
                this.errorAlertModal.close();
                this.selectedDeleteOption = '';
                this.getCustomerById(this.customer.id);
            });
        } else {
            BaseCustomer.delete(`/customer/image?id=${this.customer.id}&imageType=LOGO`).then(reponse => {
                this.errorAlertModal.close();
                this.getCustomerById(this.customer.id);
            });
        }
    }

    suspendAlert = () => {
        this.suspendAlertModal.show();
    }

    activateAlert = () => {
        this.activateAlertModal.show();
    }

    suspendCustomer = () => {
        this.suspendDto.id = this.customer.id;
        this.suspendDto.updatedBy = Stores.currentUser.id;
        this.suspendDto.updatedUser = Stores.currentUser.firstName;
        this.suspendDto.comment = this.comment;
        BaseCustomer.put("/customer/suspend", this.suspendDto).then(response => {
            this.suspendAlertModal.close();
            this.comment = '';
            this.suspendNotification(this.customer.id, this.customer.name);
            this.setNewCustomer();
            this.suspendDto = new SuspendDto();
        });
    }

    suspendNotification = (customerId, customerName) => {
        BaseUser.post("/email/suspendNotification?customer=" + customerId + "&persona=CUSTOMER&entity=" + customerName).then(response => { });
    }

    activateCustomer = () => {
        this.suspendDto.id = this.customer.id;
        this.suspendDto.updatedBy = Stores.currentUser.id;
        this.suspendDto.updatedUser = Stores.currentUser.firstName;
        BaseCustomer.put("/customer/activate", this.suspendDto).then(response => {
            this.activateAlertModal.close();
            this.activateNotification(this.customer.id, this.customer.name);
            this.setNewCustomer();
            this.suspendDto = new SuspendDto();
        });
    }

    activateNotification = (customerId, customerName) => {
        BaseUser.post("/email/activateNotification?customer=" + customerId + "&persona=CUSTOMER&entity=" + customerName).then(response => { });
    }

    deleteImage = () => {
        this.errorAlertModal.show();
    }

    deleteResultPageDetails = (item) => {
        this.selectedDeleteOption = item;
        this.errorAlertModal.show();
    }

    onAlertModalClose = () => {
        this.selectedDeleteOption = '';
    }

    getCustomerById = (id) => {
        BaseCustomer.get("/customer/" + this.customer.id,).then(reponse => {
            this.setSelectedCustomer(reponse.data.payload);
        });
    }

    getImage = () => {
        showImage(this.files[0]).then(response => {
            this.imgUrl = response;
        });
    }

    showSocialMedia = () => {
        this.socialMediaToggle = true;
        this.productImageToggle = false;
        this.resultPageImageToggle = false;
    }

    showResultPageImage = () => {
        this.resultPageImageToggle = true;
        this.productImageToggle = false;
        this.socialMediaToggle = false;
    }

    showProductImage = () => {
        this.productImageToggle = true;
        this.socialMediaToggle = false;
        this.resultPageImageToggle = false;
    }

    setFilesEmpty = () => {
        this.imgUrl = '';
    }

    render() {

        return (
            <div className='col-12 p-0'>
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6 h-42'>
                        {
                            Stores.customer &&
                            <b style={{ fontSize: '18px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                {Stores.customer.name}
                            </b>
                        }
                    </div>
                    <div className='col-6'>
                        <div className='float-right' hidden={this.disableInput}>
                            <CustomerSearch setSelectedCustomer={this.setSelectedCustomer}
                                setNewCustomer={this.setNewCustomer} ref={instance => {
                                    this.customerSearch = instance
                                }} />
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0 py-3">
                    <div className="col-8 pr-0">
                        <div className="card b-none">
                            <div className="card-body">
                                <div className="pt-2">
                                    <CustomerForm ref={instance => {
                                        this.customerForm = instance
                                    }} customer={this.customer}
                                        disableInput={this.disableInput}
                                        handleUserInput={this.handleUserInput}
                                        handlePrintingPartners={this.handlePrintingPartners}
                                        submit={this.save}
                                        setNewCustomer={this.setNewCustomer}
                                        printingPartners={this.printingPartners}
                                        userPrintingPartners={this.userPrintingPartners}
                                        getPartners={this.getPartners}
                                        partners={this.partners}
                                        files={this.files}
                                        getImage={this.getImage}
                                        showSocialMedia={this.showSocialMedia}
                                        showProductImage={this.showProductImage}
                                        showResultPageImage={this.showResultPageImage}
                                        setFilesEmpty={this.setFilesEmpty}
                                        suspendCustomer={this.suspendAlert} activateCustomer={this.activateAlert} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="pt-2">
                                    {this.productImageToggle && <ProductImage imgUrl={this.imgUrl} showImageDelete={this.showImageDelete}
                                        deleteImage={this.deleteImage} />}
                                    {this.socialMediaToggle && <SocialMediaForm disableInput={this.props.disableInput} details={this.customer.socialMediaLinks} />}
                                    {this.resultPageImageToggle && <ResultPageForm disableInput={this.props.disableInput} details={this.resultPageDetails} deleteItem={this.deleteResultPageDetails} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorAlertModal
                    ref={instance => {
                        this.errorAlertModal = instance
                    }}
                    submit={this.deleteCurrentItems}
                    onClose={this.onAlertModalClose}
                />
                <ErrorAlertModal ref={instance => {
                    this.suspendAlertModal = instance
                }}
                    type={"suspend"} comment={this.comment} handleComment={this.handleComment} submit={this.suspendCustomer} />
                <ErrorAlertModal ref={instance => { this.activateAlertModal = instance }}
                    type={"activate"} submit={this.activateCustomer} />
            </div>
        )
    }
}

const CustomerComponent = withRouter(Customers);
export { CustomerComponent as Customers };
