import {action, observable} from 'mobx';

class Complaint   {

    @observable id:string = '';
    @observable customerId:string = '';
    @observable customerName:string = '';
    @observable brandName:string = '';
    @observable mobileNumber:string = '';
    @observable complaintId:string = '';
    @observable name:string = '';
    @observable emailId:string = '';
    @observable storeName:string = '';
    @observable description:string = '';
    @observable address:string = '';
    @observable imageName:string = '';
    @observable imageUrl:string = '';
    @observable createdDate:string = '';
    @observable createdDateConvert:string = '';
    @observable scanLocation:string = '';

    @action
    setComplaint = (complaint: any) => {
        this.id = complaint.id;
        this.customerId = complaint.customerId;
        this.brandName = complaint.brandName;
        this.mobileNumber = complaint.mobileNumber;
        this.customerName = complaint.customerName;
        this.complaintId = complaint.complaintId;
        this.name = complaint.name;
        this.emailId = complaint.emailId;
        this.storeName = complaint.storeName;
        this.description = complaint.description;
        this.address = complaint.address;
        this.imageName = complaint.imageName;
        this.imageUrl = complaint.imageUrl;
        this.createdDate = complaint.createdDate;
        this.createdDateConvert = complaint.createdDateConvert;
        this.scanLocation = complaint.scanLocation;
        return this;
    }
}

export {Complaint};
