import { observable} from 'mobx';

class BrandReferral   {

    @observable id:string = '';
    @observable brandOrCompany:string = '';
    @observable mobileNumber:string = '';
    @observable referralId:string = '';
    @observable name:string = '';
    @observable emailId:string = '';
    @observable storeName:string = '';
    @observable comment:string = '';
    @observable createdDate:string = '';
    @observable createdDateConvert:string = '';
    @observable scanLocation:string = '';
}

export {BrandReferral};
