import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {BaseCustomer} from "../../config/HttpClients";
import {Pagination} from "../../constants/pagination/Pagination";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {DateToStringFormat, getLoggedInUser, viewDateTimeFormat} from "../../constants/CommonMethods";
import {Stores} from "../../store/Stores";
import {BrandReferral} from "../../store/BrandReferral";
import {BrandReferralTableDetails} from "./BrandReferralTableDetails";
import {BrandReferralView} from "./BrandReferralView";

@observer
class BrandReferrals extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable paginationFormat: any;
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    @observable search: string = '';
    @observable brandReferrals: BrandReferral[] = [];
    @observable brandReferral: BrandReferral = new BrandReferral()
    @observable imgUrl: any;
    @observable showCustomer: boolean = true;

    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init()
        }
    }

    init = () => {
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        this.getBrandReferrals();
    }

    getBrandReferrals = () => {
        let search;
        if (!this.search) {
            search = 'null';
        } else {
            search = this.search;
        }
        BaseCustomer.get("/brandReferral?pageNo=" + this.pageNo + "&limit=" + this.limit + "&fromDate=" + this.fromDateValue + "&toDate=" + this.toDateValue + "&search="+search).then(response => {
            this.brandReferrals = response.data.payload;
            this.brandReferrals.forEach((brandReferral) => {
                brandReferral.createdDateConvert = viewDateTimeFormat(brandReferral.createdDate);
            });
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
        });
    }

    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
        this.pageNo = 0;
        this.getBrandReferrals();
    };

    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
        this.pageNo = 0;
        this.getBrandReferrals();
    }

    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
        this.pageNo = 0;
        this.getBrandReferrals();
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getBrandReferrals();
    }

    handleSearch=(event)=>{
        this.search = event.target.value;
        this.getBrandReferrals();
    }

    viewBrandReferral = (brandReferral) => {
        this.brandReferral = brandReferral;
    }

    navigate = (url: any) => {
        this.history.push(url);
    };

    render() {
        return (
            <div className="col-12 py-3 h-100">
                <div className="row mx-0 h-100">
                    <div className="col-8 h-100 pl-0">
                        <div className="card b-none h-100">
                            <div className="card-body h-100 px-2">
                                {
                                    this.showCustomer &&
                                    <React.Fragment>
                                        <div className='row mx-0 mb-1'>
                                            <div className='col-6 pl-0'>Search Brand/Company Name</div>
                                            <div className='col-3 pl-0'></div>
                                            <div className='col-3 pl-0'>
                                            </div>
                                        </div>
                                        <div className='row mx-0 mb-2'>
                                            <div className="col-6 pl-0">
                                                <input type="text" className="form-control" onChange={this.handleSearch.bind(this)}/>
                                            </div>
                                            <div className='col-3'></div>
                                            <div className='col-3 pr-0'>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                <div className='row mx-0 mb-1'>
                                    <div className='col-3 pl-0'>From Date</div>
                                    <div className='col-3 pl-0'>To Date</div>
                                    <div className='col-3 pl-0'></div>
                                    <div className='col-3 pl-0'>
                                        <div className='float-right'> Items per page</div>
                                    </div>
                                </div>
                                <div className='row mx-0 mb-2'>
                                    <div className='col-3 pl-0'>
                                        <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleFromDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className='col-3 pl-0'>
                                        <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleToDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className='col-3 px-0'>
                                    </div>
                                    <div className="col-3 px-3">
                                        <div className='col-8 px-0 float-right'>
                                            <select className="form-control"
                                                    value={this.limit}
                                                    id="customer"
                                                    onChange={this.handlePageLimit.bind(this, this.limit)}>
                                                <option className="select-option" value={10}>10</option>
                                                <option className="select-option" value={15}>15</option>
                                                <option className="select-option" value={20}>20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.totalRecords > 0 &&
                                    <div className="row mx-0">
                                        <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                    </div>
                                }
                                <div className="row mx-0 mt-2" style={{height: 'calc(100% - 122px)'}}>
                                    <div className="col-12 h-100 px-0">
                                        <div className="row mx-0">
                                            <table className="table m-0">
                                                <thead>
                                                <tr className="row mx-0 text-nowrap table-header">
                                                    <th className="col-3 text-center px-0 text-wrap">Referral Id</th>
                                                    <th className="col-3 text-center px-0 text-wrap">Consumer Identity</th>
                                                    <th className="col-3 text-center px-0 text-wrap">Brand/Company Name</th>
                                                    <th className="col-3 text-center">Date</th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="row mx-0 custom-scroll" style={{
                                            marginTop: '-2px',
                                            height: 'calc(100% - 122px)',
                                            overflowY: 'auto',
                                            overflowX: 'hidden'
                                        }}>
                                            <table className="table mb-0">
                                                <tbody>
                                                <BrandReferralTableDetails brandReferrals={this.brandReferrals}
                                                                       viewBrandReferral={this.viewBrandReferral}/>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination totalRecords={this.totalRecords}
                                                    displayRecords={this.limit} pageNo={this.pageNo}
                                                    partialPageCount={5}
                                                    handleChangePage={this.handleChangePage}
                                                    ref={ref => {
                                                        this.paginationFormat = ref
                                                    }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 px-0 h-100">
                        <div className="card h-100">
                            <div className="card-body" style={{minHeight:'180px'}}>
                                <BrandReferralView brandReferral={this.brandReferral} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const BrandReferralsComponent = withRouter(BrandReferrals);
export {BrandReferralsComponent as BrandReferrals};
