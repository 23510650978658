import React from 'react';
import { withRouter } from "react-router-dom";
import { observer } from 'mobx-react';
import { BranchSearch } from './BranchSearch';
import { BranchForm } from './BranchForm';
import { observable } from "mobx";
import { Branch } from '../../store/Branch';
import { BaseCustomer, BaseUser } from "../../config/HttpClients";
import { Stores } from '../../store/Stores';
import { Customer } from "../../store/Customer";
import { Country } from "../../store/Country";
import { SuspendDto } from '../../store/SuspendDto';
import { getLoggedInUser, showImage } from '../../constants/CommonMethods';
import { LoadToast, ToastType } from "../../config/Toast";
import { ErrorAlertModal } from "../../constants/modal/ErrorAlertModal";


@observer
class Branches extends React.Component<any, any> {

    @observable branch: Branch = new Branch();
    @observable customer: Customer = new Customer();
    @observable countries: Country[] = [new Country()];
    @observable showHeadQuarterOption: boolean = false;
    @observable isSupplyChain = false;
    @observable showBranchType = false;
    @observable countryError = false;
    @observable disableInput: boolean = false;
    @observable suspendAlertModal: any;
    @observable activateAlertModal: any;
    @observable errorAlertModal: any;
    @observable comment: string = '';
    @observable suspendDto: SuspendDto = new SuspendDto();
    @observable hideActivityLog: boolean = false;
    @observable files: any[] = [];
    @observable imgUrl: any;


    constructor(props) {
        super(props);
        let paths = window.location.pathname.split('/');
        if (paths[1] === "supplyChain") {
            this.isSupplyChain = true;
        }
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init();
        }
    }

    init = () => {
        let paths = window.location.pathname.split('/');
        if (Stores.currentUser.userType.name === "VGLENS") {
            this.showHeadQuarterOption = true;
            if (paths[1] === "customers") {
                this.customer = Stores.customer;
                this.showBranchType = true;
            }
            if (paths[1] === "partners") {
                this.customer = Stores.partner;
            }
        } else {
            if (this.isSupplyChain) {
                this.customer = Stores.partner;
                this.showHeadQuarterOption = true;
                if (Stores.currentCustomer && Stores.currentCustomer.type && Stores.currentCustomer.type.id === "31000000-0000-0000-0000-000000000000") {
                    this.disableInput = true;
                }
                if (this.customer && this.customer.customerType === "CUSTOMER") {
                    this.hideActivityLog = true;
                    this.disableInput = true;
                }
            }
            if (Stores.currentUser.userType.name === "CUSTOMER" && paths[1] === "company") {
                this.showBranchType = true;
            }
        }
        this.files = [];
    }


    getBranchImage = (fileName) => {
        BaseCustomer.get("/branch/image?fileName=" + fileName, {
            headers: { Accept: "*/*" },
            responseType: "blob"
        }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this.imgUrl = URL.createObjectURL(blob);
        });
    }

    handleUserInput = (targetObject: any, event: any) => {
        targetObject[event.target.id] = event.target.value;
    };

    handleComment = (event) => {
        this.comment = event.target.value;
    };

    getImage = () => {
        showImage(this.files[0]).then(response => {
            this.imgUrl = response;
        });
    }

    handleChange = (selectedOptions) => {
        this.countries = [];
        if (selectedOptions && selectedOptions.value) {
            this.countries.splice(0, 0, selectedOptions);
        }
    };

    save = () => {
        this.countryError = false;
        if (this.countries && this.countries[0].value === '') {
            this.countryError = true;
            return;
        }
        if (this.branch.id) {
            if (Stores.currentUser.userType.name === "VGLENS" || this.isSupplyChain) {
                this.branch.customer = this.customer;
            } else {
                this.branch.customer = Stores.currentCustomer;
            }
            if (this.branch.customer.id === "") {
                LoadToast(ToastType.WARN, "Customer not selected");
                return;
            }
            this.branch.updatedBy = Stores.currentUser && Stores.currentUser.id;
            this.addCountry(this.countries[0]);
            const formData = new FormData();
            formData.append('branch', JSON.stringify(this.branch));
            this.files.forEach((file) => {
                formData.append('file', file);
            });
            BaseCustomer.post("/branch/update", formData, { headers: { 'content-type': 'multipart/form-data' } }).then(response => {
                this.setNewBranch();
            });
        } else {
            if (Stores.currentUser.userType.name === "VGLENS" || this.isSupplyChain) {
                this.branch.customer = this.customer;
            } else {
                this.branch.customer = Stores.currentCustomer;
            }
            if (this.branch.customer.id === "") {
                LoadToast(ToastType.WARN, "Customer not selected");
                return;
            }
            if (!this.branch.type) {
                this.branch.type = "Warehouse";
            }
            delete this.branch.id;
            this.branch.createdBy = Stores.currentUser && Stores.currentUser.id;
            this.branch.status = "ACTIVE";
            this.addCountry(this.countries[0]);
            const formData = new FormData();
            formData.append('branch', JSON.stringify(this.branch));
            this.files.forEach((file) => {
                formData.append('file', file);
            });
            BaseCustomer.post("/branch", formData, { headers: { 'content-type': 'multipart/form-data' } }).then(response => {
                this.setNewBranch();
            });
        }
    }

    suspendBranch = () => {
        this.suspendDto.id = this.branch.id;
        this.suspendDto.updatedBy = Stores.currentUser.id;
        this.suspendDto.updatedUser = Stores.currentUser.firstName;
        this.suspendDto.comment = this.comment;
        BaseCustomer.put("/branch/suspend", this.suspendDto).then(response => {
            this.suspendAlertModal.close();
            this.suspendNotification(this.branch.customer.id, this.branch.name);
            this.comment = '';
            this.setNewBranch();
            this.suspendDto = new SuspendDto();
        });
    }

    suspendNotification = (customer, branchName) => {
        let persona;
        if (Stores.currentUser.persona.name === "CUSTOMER" || Stores.currentUser.persona.name === "CUSTOMER BRANCH") {
            persona = "CUSTOMER";
        } else if (Stores.currentUser.persona.name === "PARTNER" || Stores.currentUser.persona.name === "PARTNER BRANCH") {
            persona = "PARTNER";
        } else if (Stores.currentUser.persona.name === "SUPPLY CHAIN" || Stores.currentUser.persona.name === "SUPPLY CHAIN BRANCH") {
            persona = "SUPPLY CHAIN";
        }
        BaseUser.post("/email/suspendNotification?customer=" + customer + "&persona=" + persona + "&entity=" + branchName).then(response => {
        });
    }

    activateBranch = () => {
        this.suspendDto.id = this.branch.id;
        this.suspendDto.updatedBy = Stores.currentUser.id;
        this.suspendDto.updatedUser = Stores.currentUser.firstName;
        this.suspendDto.comment = this.comment;
        BaseCustomer.put("/branch/activate", this.suspendDto).then(response => {
            this.activateAlertModal.close();
            this.activateNotification(this.branch.customer.id, this.branch.name);
            this.setNewBranch();
            this.suspendDto = new SuspendDto();
        });
    }

    activateNotification = (customer, branchName) => {
        let persona;
        if (Stores.currentUser.persona.name === "CUSTOMER" || Stores.currentUser.persona.name === "CUSTOMER BRANCH") {
            persona = "CUSTOMER";
        } else if (Stores.currentUser.persona.name === "PARTNER" || Stores.currentUser.persona.name === "PARTNER BRANCH") {
            persona = "PARTNER";
        } else if (Stores.currentUser.persona.name === "SUPPLY CHAIN" || Stores.currentUser.persona.name === "SUPPLY CHAIN BRANCH") {
            persona = "SUPPLY CHAIN";
        }
        BaseUser.post("/email/activateNotification?customer=" + customer + "&persona=" + persona + "&entity=" + branchName).then(response => {
        });
    }

    suspendAlert = () => {
        this.suspendAlertModal.show();
    };

    activateAlert = () => {
        this.activateAlertModal.show();
    };

    selectedBranch = (branch) => {
        this.branch = new Branch();
        this.branch = branch;
        this.setCountry(this.branch.country);
        this.imgUrl = '';
        if (this.branch.image) {
            this.getBranchImage(this.branch.image);
        }
    }

    setNewBranch = () => {
        this.branch = new Branch();
        this.branch.reset();
        this.countries = [];
        this.imgUrl = '';
        this.files = [];
    }

    addCountry = (country) => {
        this.branch.country = new Country();
        this.branch.country.id = country.value;
    }

    setCountry = (country) => {
        this.countries = [];
        country.value = country.id;
        country.label = country.name;
        this.countries.push(country);
    }

    setFilesEmpty = () => {
        this.imgUrl = '';
    }

    deleteImage = () => {
        this.errorAlertModal.show();
    }
    getBranchById = () => {
        BaseCustomer.get(`/branch/${this.branch.id}`).then(response => {
            this.selectedBranch(response.data.payload);
        })
    }

    removeImage = () => {
        BaseCustomer.delete(`/branch/image?id=${this.branch.id}`).then(Response => {
            this.errorAlertModal.close();
        }).catch(error => {
            this.errorAlertModal.close();
        }).finally(() => {
            this.getBranchById();
        });
    }

    render() {
        return (
            <div className="col-12 p-0">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6 h-42'>
                        {
                            this.customer &&
                            <b style={{ fontSize: '18px', fontWeight: 'bold', letterSpacing: '1px' }}>
                                {this.customer.name}
                            </b>
                        }
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <BranchSearch selectedBranch={this.selectedBranch} setNewBranch={this.setNewBranch} />
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0 pt-3">
                    <div className="col-8">
                        <div className="card b-none">
                            <div className="card-body">
                                <div className="pt-2">
                                    <BranchForm branch={this.branch} isSupplyChain={this.isSupplyChain}
                                        handleUserInput={this.handleUserInput} save={this.save}
                                        countries={this.countries} disableInput={this.disableInput}
                                        showHeadQuarterOption={this.showHeadQuarterOption}
                                        files={this.files}
                                        getImage={this.getImage}
                                        setFilesEmpty={this.setFilesEmpty}
                                        countryError={this.countryError}
                                        showBranchType={this.showBranchType} handleChange={this.handleChange}
                                        suspendBranch={this.suspendAlert} activateBranch={this.activateAlert}
                                        hideActivityLog={this.hideActivityLog} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card b-none">
                            <div className="card-body">
                                <div className='w-100' style={{ height: '17px' }}>
                                    <img className="float-right" style={{ width: '13px', cursor: 'pointer' }}
                                        alt="deleteRole" onClick={this.deleteImage}
                                        src={require("../../assets/image/delete.jpg")} />
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ border: '2px solid #CBCBCB', height: '180px' }}>
                                    {this.imgUrl && <img style={{ maxHeight: 176, maxWidth: '100%' }} src={this.imgUrl} alt="branch" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorAlertModal ref={instance => {
                    this.suspendAlertModal = instance
                }}
                    type={"suspend"} comment={this.comment} handleComment={this.handleComment} submit={this.suspendBranch} />
                <ErrorAlertModal ref={instance => { this.activateAlertModal = instance }}
                    type={"activate"} submit={this.activateBranch} />
                <ErrorAlertModal
                    ref={instance => { this.errorAlertModal = instance }}
                    submit={this.removeImage}
                />
            </div>
        )
    }
}

const BranchComponent = withRouter(Branches);
export { BranchComponent as Branch };
