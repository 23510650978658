import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {setMinutes} from "date-fns";
import {DateTimePicker} from '../../constants/formfields/DateTimePicker';
import {convertDatePickerDateTime} from "../../constants/CommonMethods";
import Form from '../../constants/Form';
import {PushMessageSchedule} from '../../store/PushMessageSchedule';

@observer
class SchedulerForm extends React.Component<any, any> {
    @observable minTime:any = setMinutes(new Date(), 90);
    @observable scheduleDate:PushMessageSchedule = new PushMessageSchedule();
    @observable isExistPackage: boolean = false;
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
    }

    addProductPackagingLevel = () => {
        if( this.props.scheduleDates&& this.props.scheduleDates.length < 3 && !this.checkPackageLevelIsExist(this.props.scheduleDates,this.scheduleDate)) {
              this.scheduleDate.scheduleTime = convertDatePickerDateTime(this.scheduleDate.scheduleTime);
             this.props.scheduleDates.push(this.scheduleDate);
            this.scheduleDate = new PushMessageSchedule();
        }
    }

    checkPackageLevelIsExist = (scheduleDates, newScheduleDate) => {
        if (scheduleDates.length >= 1) {
            let found = scheduleDates.some(scheduleDate => scheduleDate === newScheduleDate);
            if (found) {
                this.isExistPackage = true;
                return true;
            } else {
                this.isExistPackage = false;
                return false;
            }
        }
    }

    deleteProductPackagingLevel = (index) => {
        this.props.scheduleDates.splice(index, 1);
    }

    handleUserInput = (targetObject: any, event: any) => {
        targetObject[event.target.id] = event.target.value;
    };

    handleScheduleDate = (date) => {
        this.scheduleDate.scheduleTime = date;
        let selectedDate = new Date(date);
        selectedDate.setHours(0,0,0,0);
        let today =  new Date();
        today.setHours(0,0,0,0);
        if(selectedDate.getTime() === today.getTime()){
           this.minTime = setMinutes(new Date(), 90)
        } else {
           this.minTime = setMinutes(new Date(selectedDate),0);
        }
    }

    render() {
        return (
            <div className="row mx-0">
                <div className="col-12 px-0" style={{height: '300px'}}>
                    <div className="header">
                        <h5>Schedules</h5>
                    </div>
                    <div>
                        <div className="row mx-0">
                            <div className={"col-2 pl-0"}><b>Seq #</b></div>
                            <div className={this.props.disabled ? "col-6 pl-0" : "col-6 pl-0"}>
                                <p className="mb-0"><b>Schedule DateTime</b></p>
                            </div>
                            <div className="col-4 px-0" hidden={this.props.disabled ? true : false}>
                                <p className="mb-0 float-right"><b>Add/ Remove</b></p>
                            </div>
                        </div>
                        {
                            this.props.scheduleDates.map((scheduleDate, key) =>
                                <div className="row mx-0 mt-2" key={key}>
                                    <div className="col-2 text-center pl-0 font-size-13">{ key + 1}</div>
                                    <div className="col-6 text-center px-0 font-size-13">{scheduleDate.scheduleTime}</div>
                                    <div className="col-4 px-0 text-center" hidden={this.props.disabled ? true : false}>
                                        <img className="cursor-pointer p-2" style={{width: '28px'}} alt="remove"
                                             onClick={() => this.deleteProductPackagingLevel(key)}
                                             src={require("../../assets/image/removeIcon.png")}/>
                                    </div>
                                </div>
                            )}
                        <div hidden={this.props.disabled ? true : false}>
                            <Form submit={this.addProductPackagingLevel}>
                                <div className="row mx-0 mt-2">
                                    <div className={"col-2 pl-0"}></div>
                                    <div className="col-6 pl-0">
                                        <DateTimePicker value={this.scheduleDate.scheduleTime} minDate={new Date()}
                                        minTime={this.minTime}
                                        inputChangeHandler={this.handleScheduleDate} isRequired={true} ></DateTimePicker>
                                    </div>
                                    <div className="col-4 px-0 text-center">
                                        <button type="submit" style={{
                                            background: "transparent",
                                            border: 0
                                        }}><img className="cursor-pointer" style={{width: '13px'}} alt="add"
                                                src={require("../../assets/image/addIcon.png")}/></button>
                                    </div>

                                </div>
                            </Form>
                        </div>
                        {
                            this.isExistPackage === true &&
                            <div className="error">
                                Schedule Time is already exist.
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


export {SchedulerForm};
