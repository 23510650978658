import {observable, action} from "mobx";
import {Notification} from './Notification';
import {DispatchReportDto} from './DispatchReportDto';
import {PackagedLabelReportDto} from './PackagedLabelReportDto';
import { Product } from "./Product";
import { Branch } from "./Branch";
import { Customer } from "./Customer";
import {User} from "./User";
import { Credential } from "./Credential";
import {Role} from "./Role";
import { Permission } from "./Permission";
import { Menu } from "./Menu";
import { Loader } from './Loader';

class Stores {

    @observable user: User;
    @observable currentUser: User;
    @observable currentUserRole: Role;
    @observable currentPermission: Permission;
    @observable currentCustomer:Customer;
    @observable currentBranch:Branch;
    @observable notification: Notification;
    @observable dispatchreport: DispatchReportDto;
    @observable packagedlabelreport: PackagedLabelReportDto;
    @observable product: Product;
    @observable branch: Branch;
    @observable customer:Customer;
    @observable partner:Customer;
    @observable credential:Credential;
    @observable loader:Loader;
    @observable notificationPageNo:number = 0;
    @observable notificationLimit:number = 0;
    @observable notificationStatus:string = '';
    @observable notificationCustomer = new Customer();
    @observable notificationProduct = new Product();
    @observable dispatchreportPageNo:number = 0;
    @observable dispatchreportLimit:number = 0;
    @observable dispatchreportStatus:string = '';
    @observable dispatchreportCustomer = new Customer();
    @observable dispatchreportProduct = new Product();
    @observable packagedlabelreportPageNo:number = 0;
    @observable packagedlabelreportLimit:number = 0;
    @observable packagedlabelreportStatus:string = '';
    @observable packagedlabelreportCustomer = new Customer();
    @observable packagedlabelreportProduct = new Product();
    @observable menu = new Menu();
    @observable permissions:Permission[] = [];

    constructor() {
        this.user = new User();
        this.currentUser = new User();
        this.notification = new Notification();
        this.dispatchreport = new DispatchReportDto();
        this.packagedlabelreport = new PackagedLabelReportDto();
        this.product = new Product();
        this.branch = new Branch();
        this.customer = new Customer();
        this.currentCustomer = new Customer();
        this.partner = new Customer();
        this.credential = new Credential();
        this.currentUserRole = new Role();
        this.currentPermission = new Permission();
        this.loader = new Loader();
        this.notificationPageNo = 0;
        this.notificationLimit = 0;
        this.notificationStatus = '';
        this.notificationCustomer = new Customer();
        this.notificationProduct = new Product();
        this.dispatchreportPageNo = 0;
        this.dispatchreportLimit = 0;
        this.dispatchreportStatus = '';
        this.dispatchreportCustomer = new Customer();
        this.dispatchreportProduct = new Product();
        this.packagedlabelreportPageNo = 0;
        this.packagedlabelreportLimit = 0;
        this.packagedlabelreportStatus = '';
        this.packagedlabelreportCustomer = new Customer();
        this.packagedlabelreportProduct = new Product();
        this.currentBranch = new Branch();
        this.menu = new Menu();
        this.permissions = [];
    }

    @action reset() {
        this.user = new User();
        this.currentUser = new User();
        this.notification = new Notification();
        this.dispatchreport = new DispatchReportDto();
        this.packagedlabelreport = new PackagedLabelReportDto();
        this.product = new Product();
        this.branch = new Branch();
        this.customer = new Customer();
        this.currentCustomer = new Customer();
        this.partner = new Customer();
        this.credential = new Credential();
        this.currentUserRole = new Role();
        this.currentPermission = new Permission();
        this.loader = new Loader();
        this.notificationPageNo = 0;
        this.notificationLimit = 0;
        this.notificationStatus = '';
        this.notificationCustomer = new Customer();
        this.notificationProduct = new Product();
        this.dispatchreportPageNo = 0;
        this.dispatchreportLimit = 0;
        this.dispatchreportStatus = '';
        this.dispatchreportCustomer = new Customer();
        this.dispatchreportProduct = new Product();
        this.packagedlabelreportPageNo = 0;
        this.packagedlabelreportLimit = 0;
        this.packagedlabelreportStatus = '';
        this.packagedlabelreportCustomer = new Customer();
        this.packagedlabelreportProduct = new Product();
        this.currentBranch = new Branch();
        this.menu = new Menu();
        this.permissions = [];
    }

     @action setCustomer(customer) {
        this.currentCustomer = customer;
        return this.currentCustomer;
     }

}
const instance = new Stores();
(<any>window).stores = instance;
export  {instance as Stores};
