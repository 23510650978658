import * as React from "react";
import { Modal } from "react-bootstrap";
import { observable } from "mobx";
import { observer } from "mobx-react";
import Form from '../Form';

@observer
class ErrorAlertModal extends React.Component<any, {}> {
    @observable open: boolean = false;
    close = () => {
        this.open = false;
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    show = () => {
        this.open = true;
    };

    handleComment = (comment, event) => {
        comment = event.target.value;
    };

    render() {
        return (
            <Modal
                show={this.open}
                onHide={this.close}
                dialogClassName={"modal-dialog-centered modal-sm"}
            ><Form submit={this.props.submit}>
                    <Modal.Body className="p-2 pt-3">

                        <div className="text-center">
                            {
                                this.props.type === "suspend" &&
                                <h6 className={"custom-alert-text"}>Do you want to suspend?</h6>
                            }
                            {
                                this.props.type === "deactivate" &&
                                <h6 className={"custom-alert-text"}>Do you want to Deactivate?</h6>
                            }
                            {
                                (this.props.type === "suspend" || this.props.type === "deactivate") &&
                                <React.Fragment>
                                    <div className="row mx-0">
                                        <p className="mb-0 header">Comments</p>
                                    </div>
                                    <div className="row mx-0">
                                        <textarea className="form-control" name="justification" style={{ height: '80px', maxHeight: '80px', resize: 'none' }}
                                            required={true}
                                            id="justification" onChange={this.props.handleComment.bind(this)} value={this.props.comment}
                                            rows={5} />
                                        <div className="invalid error" />
                                    </div>
                                </React.Fragment>
                            }
                            {
                                this.props.type === "activate" &&
                                <h6 className={"custom-alert-text"}>Do you want to activate?</h6>
                            }
                            {
                                (this.props.type !== "suspend" && this.props.type !== "activate" && this.props.type !== "deactivate") &&
                                <h6 className={"custom-alert-text"}>Do you want to delete?</h6>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="p-1 pb-3 text-center b-none">
                        <button className="btn btn-sm btn-app-secondary ml-auto" type="button" onClick={this.close}>No</button>
                        <button className="btn btn-sm btn-app-primary mr-auto" type="submit">Yes</button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}

export { ErrorAlertModal };
