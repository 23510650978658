import React from "react";
import { withRouter } from "react-router-dom";

class UnAuthorizedPage extends React.Component<any, any> {
  render() {
    return (
      <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
        <img style={{ width: 150 }} src={require("../../assets/image/access-lock.png")} />
        <h1>You don't have permission to view this page</h1>
        <h4 className="text-primary">Reach administrator for permission</h4>
      </div>
    );
  }
}

const UnAuthorizedPageComponent = withRouter(UnAuthorizedPage);

export { UnAuthorizedPageComponent as UnAuthorizedPage };
