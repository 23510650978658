import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Role } from '../../store/Role';
import { BaseUser } from '../../config/HttpClients';
import { User } from '../../store/User';
import { Stores } from '../../store/Stores';
import {SuspendDto} from '../../store/SuspendDto';
import { Auth } from 'aws-amplify';
import { CreateUserForm } from './CreateUserForm';
import { UserSearch } from '../user/UserSearch';
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";

@observer
class CreateUser extends React.Component<any, any> {

    @observable roles:Role[] = [];
    @observable user:User = new User();
    @observable roleError:string = '';
    @observable disableInput:boolean = false;
    @observable showSuccess = false;
    @observable suspendAlertModal:any;
    @observable activateAlertModal:any;
    @observable comment: string = '';
    @observable suspendDto:SuspendDto = new SuspendDto();
    
    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
    }
    save = () => {
        if((this.roles&&this.roles.length === 0) ||this.roles === null) {
            this.roleError = "This field is required";
            return;
        }
        if(this.user.id){
            this.updateUser();
        } else {
            this.createUser();
       }
    }

    addRoles=(roles)=>{
        this.user.roles = [];
        roles.forEach(role => {
            let newRole = new Role();
            newRole.id = role.value;
            this.user.roles.push(newRole);
        });
    }

    handleUserInput = (targetObject:any, event:any) => {
        this.showSuccess = false;
        targetObject[event.target.id] = event.target.value;
    };

    handleChange = (selectedOptions) => {
        this.showSuccess = false;
        this.roles = selectedOptions;
    };

    handleComment=(event)=>{
        this.comment = event.target.value;
    };

    resetUser=()=>{
        this.user = new User();
        this.user.reset();
        this.roles = [];
    };

    createUser= () => {
        if(Stores.currentUser.userType.name !== "VGLENS") {
            this.user.customer = Stores.currentCustomer.id;
        }
        this.user.createdBy = Stores.currentUser.id;
        this.user.userType = Stores.currentUser.userType;
        this.user.persona = Stores.currentUser.persona;
        this.user.status = "ACTIVE";
        this.addRoles(this.roles);
        BaseUser.post("/user/customer",this.user).then(response=>{
                let user=  {
                      username: this.user.emailId,
                      password: this.user.emailId,
                      attributes: {
                          email: this.user.emailId,
                      }
                  };
                  Auth.signUp(user).then(response=>{
                      this.showSuccess = true;
                  }).catch(error=>{
                  });
            this.resetUser();
        });
    } 

    updateUser= () => {
        this.user.updatedBy = Stores.currentUser.id;
        this.addRoles(this.roles);
        BaseUser.put("/user",this.user).then(response=>{
            this.resetUser();
        });
    };

    suspendUser=()=>{
        this.suspendDto.id = this.user.id;
        this.suspendDto.updatedBy =  Stores.currentUser.id;
        this.suspendDto.updatedUser =  Stores.currentUser.firstName;
        this.suspendDto.comment =  this.comment;
        BaseUser.put("/user/suspend",this.suspendDto).then(response=>{
            this.suspendAlertModal.close();
            this.resetUser();
            this.comment = '';
            this.suspendDto = new SuspendDto();
        });
    };

    activateUser=()=>{
        this.suspendDto.id = this.user.id;
        this.suspendDto.updatedBy =  Stores.currentUser.id;
        this.suspendDto.updatedUser =  Stores.currentUser.firstName;
        this.suspendDto.comment =  this.comment;
        BaseUser.put("/user/activate",this.suspendDto).then(response=>{
            this.activateAlertModal.close();
            this.resetUser();
            this.suspendDto = new SuspendDto();
        });
    };

    suspendAlert = () => {
        this.suspendAlertModal.show();
    }

    activateAlert = () => {
        this.activateAlertModal.show();
    }

    setSelectedUser=(user)=>{
        this.user = user;
        this.disableInput = true;
        this.roles = user.roles;
        this.setRole(this.roles);
    }

    setRole=(roles)=>{
        roles.map((role)=>{
            role.value = role.id;
            role.label = role.name;
            return true;
        });      
    }

    setShowSucces=(status)=>{
        this.showSuccess = status;
    }
    
    render() {
        return(
                <div className="col-12 p-0">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6 h-42'>
                        {/*<b style={{letterSpacing:"1px",fontSize:"28px"}}>Search </b>*/}
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <UserSearch setSelectedUser={this.setSelectedUser} resetUser={this.resetUser}/>
                        </div>
                    </div>
                </div>
                    <div className="row mx-0 pl-0 pt-3">
                        <div className="col-12">
                            <div className="card b-none">
                                <div className="card-body">                                   
                                    <div className="pt-2">
                                        <div className="col-12">
                                            <CreateUserForm roleError={this.roleError} roles={this.roles} submit={this.save} disableInput={this.disableInput}
                                                            user={this.user} handleUserInput={this.handleUserInput} handleChange={this.handleChange}
                                                            showSuccess={this.showSuccess} setShowSucces={this.setShowSucces}
                                                            suspendUser={this.suspendAlert} activateUser={this.activateAlert}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ErrorAlertModal ref={instance => {
                        this.suspendAlertModal = instance}}
                         type={"suspend"} comment={this.comment} handleComment={this.handleComment} submit={this.suspendUser}/>
                    <ErrorAlertModal ref={instance => {this.activateAlertModal = instance}}
                                                     type={"activate"}  submit={this.activateUser}/>
                </div>
        )
    }
}

const CreateUserComponent = withRouter(CreateUser);
export {CreateUserComponent as CreateUser};
