import { observable} from 'mobx';
import {CustomMessageDetail} from './CustomMessageDetail';
import {StatusAudit} from './StatusAudit';

class CustomMessage   {

    @observable id:string = '';
    @observable messageId:string = '';
    @observable messageType:string = '';
    @observable customerId:string = '';
    @observable fromDate:any ;
    @observable toDate:any;
    @observable header:string = '';
    @observable message:string = '';
    @observable customMessageDetails:CustomMessageDetail[] = [];
    @observable createdDate:any;
    @observable createdDateConvert:string = '';
    @observable viewCount:number = 0;
    @observable sentCount:number = 0;
    @observable imageName:string = '';
    @observable imageUrl:string = '';
    @observable status:string = '';
    @observable statusAudits:StatusAudit[] = [];
}

export {CustomMessage};
