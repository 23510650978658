import { observable} from 'mobx';
import {PushMessageSchedule} from './PushMessageSchedule';
import {StatusAudit} from './StatusAudit';

class PushMessage   {

    @observable id:string = '';
    @observable sender:string = '';
    @observable customerId:string = '';
    @observable customerName:string = '';
    @observable customerVertical:string = '';
    @observable messageId:string = '';
    @observable messageType:string = '';
    @observable country:string = '';
    @observable group:string = '';
    @observable message:string = '';
    @observable schedules:PushMessageSchedule[] = [];
    @observable urls:string[] = [];
    @observable comment:string = '';
    @observable createdDate:string = '';
    @observable header:string = '';
    @observable createdDateConvert:string = '';
    @observable viewCount:number = 0;
    @observable imageName:string = '';
    @observable imageUrl:string = '';
    @observable userType:string = '';
    @observable status:string = '';
    @observable statusAudits:StatusAudit[] = [];
}

export {PushMessage};
