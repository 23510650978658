import axios from 'axios';
import Cookies from 'universal-cookie';
import {LoadToast, ToastType} from './Toast';
import {Stores} from '../store/Stores';

const cookies = new Cookies();
const blockLoaderUrls:any = ['/product/subCategory?subCategory=','/product/brandName?brandName=','/branch/searchByName','/customer/searchByName','/user/searchByName','/product/searchByName'];

//Example for testing baseURL: 'https://biz.evdens.com/api',
//http://localhost:5000/api - For Debugging
const BaseCustomer=  axios.create({
    baseURL: 'https://biz.evdens.com/api',
    //baseURL: 'http://localhost:5000/api',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': cookies.get('token')
    }
});
const BaseUser =  axios.create({
    baseURL: 'https://biz.evdens.com/api',
    //baseURL: 'http://localhost:5000/api',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': cookies.get('token')
    }
});
const BaseProduct =  axios.create({
    baseURL: 'https://biz.evdens.com/api',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': cookies.get('token')
    }
});
const BaseLabelWrite =  axios.create({
    baseURL: 'https://biz.evdens.com/api',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': cookies.get('token')
    }
});
const BaseLabelRead =  axios.create({
    baseURL: 'https://biz.evdens.com/api',
    //baseURL: 'http://localhost:5000/api',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': cookies.get('token')
    }
});
const BaseNotification =  axios.create({
    baseURL: 'https://biz.evdens.com/api',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': cookies.get('token')
    }
});

BaseProduct.interceptors.request.use(function (config) {
    return requestSuccessHandler(config);
 }, function (error) {
    return requestErrorHandler(error);
 });

 BaseProduct.interceptors.response.use(function (response) {
    return responseSuccessHandler(response)
}, function (error) {
    return responseErrorHandler(error)
});
BaseCustomer.interceptors.request.use(function (config) {
    return requestSuccessHandler(config);
 }, function (error) {
    return requestErrorHandler(error);
 });

 BaseCustomer.interceptors.response.use(function (response) {
    return responseSuccessHandler(response)
}, function (error) {
    return responseErrorHandler(error)
});
BaseUser.interceptors.request.use(function (config) {
    return requestSuccessHandler(config);
 }, function (error) {
    return requestErrorHandler(error);
 });

 BaseUser.interceptors.response.use(function (response) {
    return responseSuccessHandler(response)
}, function (error) {
    return responseErrorHandler(error)
});

BaseLabelWrite.interceptors.request.use(function (config) {
    return requestSuccessHandler(config);
 }, function (error) {
    return requestErrorHandler(error);
 });

 BaseLabelWrite.interceptors.response.use(function (response) {
    return responseSuccessHandler(response)
}, function (error) {
    return responseErrorHandler(error)
});

BaseLabelRead.interceptors.request.use(function (config) {
    return requestSuccessHandler(config);
}, function (error) {
    return requestErrorHandler(error);
});

BaseLabelRead.interceptors.response.use(function (response) {
    return responseSuccessHandler(response)
}, function (error) {
    return responseErrorHandler(error)
});
BaseNotification.interceptors.request.use(function (config) {
    return requestSuccessHandler(config);
}, function (error) {
    return requestErrorHandler(error);
});

BaseNotification.interceptors.response.use(function (response) {
    return responseSuccessHandler(response)
}, function (error) {
    return responseErrorHandler(error)
});

const requestSuccessHandler = (config:any) => {
    let url = config.url ? config.url:'';
    let value;
    value = blockLoaderUrls.filter((arrayurl:any) =>{
        return url.includes(arrayurl);
    });   
     value.length > 0 ? Stores.loader.setLoader(false): Stores.loader.setLoader(true);
    return config;
};

const requestErrorHandler = (error:any) => {
    Stores.loader.setLoader(false);
    return Promise.reject(error);
};

const responseSuccessHandler = (response:any) => {
    Stores.loader.setLoader(false);
    if(response.config.method !=="get" && response.data.message){
        LoadToast(ToastType.SUCCESS, response.data.message);
    }
    return response;
};

const responseErrorHandler = (error:any) => {
    Stores.loader.setLoader(false);
    switch (error.response&&error.response.status) {
        case 504:
        case 500:
        case 403:
        case 401:
            if (error.response.data.message) {
                LoadToast(ToastType.ERROR, error.response.data.message);
            }
            break;
        case 412:
            return error.response;
    }
    return Promise.reject(error);
}

const updateAuthToken = authToken => {
    BaseUser.defaults.headers['Authorization'] = authToken;
    BaseCustomer.defaults.headers['Authorization'] = authToken;
    BaseProduct.defaults.headers['Authorization'] = authToken;
    BaseLabelWrite.defaults.headers['Authorization'] = authToken;
    BaseLabelRead.defaults.headers['Authorization'] = authToken;
    BaseNotification.defaults.headers['Authorization'] = authToken;
};

const authenticate =() =>{
    return cookies.get('token');
}

const hasPermission = (path) => {
    if (!path || path === '/' || path === '/notfound' || path.includes('/reports') || path.includes('/myProfile') || !Stores.permissions || Stores.permissions.length === 0) {
        return true;
    }
    const featurePath = path.split('/')[1];
    const feature = Stores.permissions.find((permission => {
        const pathUrl = permission.feature && permission.feature.pathUrl || '';
        const permissionPath = pathUrl.split('/')[1];
        return permissionPath === featurePath;
    }));

    return !!feature;
}

const clearCookies =() =>{
    cookies.remove('token', { path: '/' });
    cookies.remove('permission', { path: '/' });
    cookies.remove('customerUrl', { path: '/' });
    delete BaseUser.defaults.headers['Authorization'];
    delete BaseCustomer.defaults.headers['Authorization'] ;
    delete BaseProduct.defaults.headers['Authorization'];
    delete BaseLabelWrite.defaults.headers['Authorization'];
    delete BaseLabelRead.defaults.headers['Authorization'];
    delete BaseNotification.defaults.headers['Authorization'];
}

export { BaseProduct,BaseCustomer,BaseUser,BaseLabelWrite,BaseLabelRead,BaseNotification,cookies,updateAuthToken,authenticate,clearCookies, hasPermission};
