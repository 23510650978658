import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import {Stores} from "../../store/Stores";

@observer
class FeedBackAndComplaintsSideNav extends React.Component<any, any> {
    history:any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
    }
    navigate = (url:any) =>{
        this.history.push(url);
    };

    isActiveMenu=(path)=>{
        return window.location.pathname === path;
    }
    render() {
        return(
            <div className="card b-none" style={{backgroundColor:"#F2F2F2"}}>
                <div className="card-body p-0">
                    <div className="inner-sidenav">
                        <ul className='p-0 mb-0'>
                            <li onClick={()=>this.navigate('/feedbackAndComplaints/complaint')}
                                 className={this.isActiveMenu('/feedbackAndComplaints/complaint') ?'active':''}>
                                <small>Manage</small><br/>
                                    Complaints
                            </li>
                            <li onClick={()=>this.navigate('/feedbackAndComplaints/feedback')}
                                 className={this.isActiveMenu('/feedbackAndComplaints/feedback') ?'active':''}>
                                <small>Manage</small><br/>
                                Feedback
                            </li>
                            {
                             Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS"&&
                             <li onClick={()=>this.navigate('/feedbackAndComplaints/brandReferrals')}
                                 className={this.isActiveMenu('/feedbackAndComplaints/brandReferrals') ?'active':''}>
                                 <small>Manage</small><br/>
                                 Brand Referrals
                             </li>
                             }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const FeedBackAndComplaintsSideNavComponent = withRouter(FeedBackAndComplaintsSideNav);
export {FeedBackAndComplaintsSideNavComponent as FeedBackAndComplaintsSideNav};
