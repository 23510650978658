import { observable} from 'mobx';

class GoodsOutboundReportDto   {

    @observable dispatchFromPlant:number = 0;
    @observable dispatchFromPlantTill:number = 0;
    @observable dispatchFromWareHouse:number = 0;
    @observable dispatchFromWareHouseTill:number = 0;
}

export {GoodsOutboundReportDto};
