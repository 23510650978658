import { observable} from 'mobx';

class CustomMessageDetail   {

    @observable product:string = '';
    @observable productName:string = '';
    @observable batchNos:string[] = [];

}

export {CustomMessageDetail};
