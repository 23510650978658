import { action, observable } from 'mobx';
import { CustomerType } from './CustomerType';
import { CustomerPartnerType } from './CustomerPartnerType';
import { CustomerVertical } from "./CustomerVertical";
import { CustomerPrintingPartner } from "./CustomerPrintingPartner";
import { CustomerSupplychainPartner } from "./CustomerSupplychainPartner";
import { SocailMediaLink } from './SocialMediaLink';

class Customer {
    @observable id: string = '';
    @observable name: string = '';
    @observable displayName: string = '';
    @observable fileName: string = '';
    @observable type: CustomerType = new CustomerType();
    @observable customerType: string = '';
    @observable partnerTypes: CustomerPartnerType[] = [];
    @observable printingPartners: CustomerPrintingPartner[] = [];
    @observable partners: CustomerSupplychainPartner[] = [];
    @observable partner: string = '';
    @observable customerCareNumber: string = '';
    @observable customerCareEmailId: string = '';
    @observable vertical = new CustomerVertical();
    @observable productGtin: boolean = false;
    @observable userTypeId = '';
    @observable createdBy: string = '';
    @observable updatedBy: string = '';
    @observable trackGreyMarket: boolean = false;
    @observable status: string = '';
    @observable solutions: string[] = [];
    @observable evdensUIDType: string = '';
    @observable gstin: string = '';
    @observable companyAddress: string = '';
    @observable companyAbout: string = '';
    @observable pricingPlan: string = '';
    @observable allowVerification: boolean = true;
    @observable companyHeaderImageText: string = '';
    @observable companyHeaderImageUrl: string = '';
    @observable companyHeaderImage: string = '';
    @observable companyHeaderUrl: string = '';
    @observable companyFooter1ImageText: string = '';
    @observable companyFooter1Image: string = '';
    @observable companyFooter1ImageUrl: string = '';
    @observable companyFooter1Url: string = '';
    @observable companyFooter2ImageText: string = '';
    @observable companyFooter2Image: string = '';
    @observable companyFooter2ImageUrl: string = '';
    @observable companyFooter2Url: string = '';
    @observable socialMediaLinks: any;
    @observable socialMedia: string = '';
    @observable solution: string = '';

    @action
    setCustomer = (customer: any) => {
        this.id = customer.id;
        this.name = customer.name;
        this.displayName = customer.displayName;
        this.type = customer.type;
        this.partnerTypes = customer.partnerTypes;
        this.printingPartners = customer.this.printingPartners;
        this.customerType = customer.customerType;
        this.partner = customer.partner;
        this.partners = customer.partners;
        this.customerCareNumber = customer.customerCareNumber;
        this.customerCareEmailId = customer.customerCareEmailId;
        this.productGtin = customer.productGtin;
        this.vertical = customer.vertical;
        this.fileName = customer.fileName;
        this.userTypeId = customer.userTypeId;
        this.createdBy = customer.createdBy;
        this.updatedBy = customer.updatedBy;
        this.trackGreyMarket = customer.trackGreyMarket;
        this.status = customer.status;
        this.solutions = customer.solutions;
        this.evdensUIDType = customer.evdensUIDType;
        this.gstin = customer.gstin;
        this.companyAddress = customer.companyAddress;
        this.companyAbout = customer.companyAbout;
        this.pricingPlan = customer.pricingPlan;
        this.allowVerification = customer.allowVerification;
        this.companyHeaderImageText = customer.companyHeaderImageText;
        this.companyHeaderImageUrl = customer.companyHeaderImageUrl;
        this.companyHeaderImage = customer.companyHeaderImage;
        this.companyHeaderUrl = customer.companyHeaderUrl;
        this.companyFooter1ImageText = customer.companyFooter1ImageText;
        this.companyFooter1Image = customer.companyFooter1Image;
        this.companyFooter1ImageUrl = customer.companyFooter1ImageUrl;
        this.companyFooter1Url = customer.companyFooter1Url;
        this.companyFooter2ImageText = customer.companyFooter2ImageText;
        this.companyFooter2Image = customer.companyFooter2Image;
        this.companyFooter2ImageUrl = customer.companyFooter2ImageUrl;
        this.companyFooter2Url = customer.companyFooter2Url;
        this.socialMedia = customer.socialMedia;
        this.solution = customer.solution;
        this.socialMediaLinks = JSON.parse(customer.socialMedia);
        return this;
    }
    @action reset() {
        this.id = '';
        this.name = '';
        this.displayName = '';
        this.type = new CustomerType();
        this.partnerTypes = [new CustomerPartnerType()];
        this.printingPartners = [];
        this.partner = '';
        this.partners = [];
        this.vertical = new CustomerVertical();
        this.productGtin = false;
        this.fileName = '';
        this.createdBy = '';
        this.updatedBy = '';
        this.customerType = '';
        this.userTypeId = '';
        this.customerCareNumber = '';
        this.customerCareEmailId = '';
        this.trackGreyMarket = false;
        this.status = '';
        this.evdensUIDType = '';
        this.solutions = [];
        this.gstin = '';
        this.companyAddress = '';
        this.companyAbout = '';
        this.pricingPlan = '';
        this.allowVerification = true;
        this.companyHeaderImageText = '';
        this.companyHeaderImageUrl = '';
        this.companyHeaderImage = '';
        this.companyHeaderUrl = '';
        this.companyFooter1ImageText = '';
        this.companyFooter1Image = '';
        this.companyFooter1ImageUrl = '';
        this.companyFooter1Url = '';
        this.companyFooter2ImageText = '';
        this.companyFooter2Image = '';
        this.companyFooter2ImageUrl = '';
        this.companyFooter2Url = '';
        this.socialMedia = '';
        this.solution = '';
        this.socialMediaLinks = [];
    }
}

export { Customer };
