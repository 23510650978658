import React from 'react';
import './App.css';
import './assets/css/StyleSheet.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import {MainLayout}  from './component/mainLayout/MainLayout';
import {observer} from "mobx-react";
import { css } from 'emotion'

import {BrowserRouter  as Router} from "react-router-dom";
import { Switch, Route} from "react-router";
import { Login } from './component/login/Login';
import { ForgotPassword } from './component/forgot-password/ForgotPassword';
import { CreatePassword } from './component/createPassword/CreatePassword';
import { PrivacyPolicy } from './component/privacyPolicy/PrivacyPolicy';
import { ValidationScreen } from './component/validation/ValidationScreen';
import {ToastContainer} from "react-toastify";
import {Stores} from './store/Stores';
import { PropagateLoader } from 'react-spinners';
import { AuthRoute } from './config/AuthRoute';
import { UnAuthorizedPage } from './component/unAuthorizedPermission/UnAuthorizedPage';

const override = css`
    top: 50%;
    bottom: 50%;
    left: 50%;
`;

@observer
class App extends React.Component  {

  
  render(){
  return (
    <div className='h-100 w-100'>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route path="/create-password/:userName" component={CreatePassword}/>
          <Route path="/privacyPolicy&TermsofUse" component={PrivacyPolicy}/>
          <Route path="/validation/**" component={ValidationScreen}/>
          <Route path="/validation/**" component={ValidationScreen}/>
          <Route path="/notfound" component={UnAuthorizedPage} />
          <AuthRoute component={MainLayout}/>
        </Switch>
      </Router>
      { Stores.loader.showLoader &&
        <div className='sweet-loading'>
        <PropagateLoader
        css={override}
          sizeUnit={"px"}
          size={15}
          color={'#0060E7'}
          loading={Stores.loader.showLoader}
        />
      </div>
      }
        
      <ToastContainer toastClassName="toaster" />
    </div>
  );
  }
}

export default App;
