import React from 'react';
import { observer } from 'mobx-react';

@observer
class OrderItemView extends React.Component<any, any> {

    render() {
        return(
            <React.Fragment>
               <div>
                    <div className="row mb-2">
                        <div className="col-12" style={{fontSize:'18px'}}>
                            Generation Status
                        </div>
                    </div>
                    <div>
                    {
                        (this.props.orderItem.orderItemStatus==='OPEN' || this.props.orderItem.orderItemStatus==='INPROGRESS' ||this.props.orderItem.orderItemStatus==='COMPLETED' || this.props.orderItem.orderItemStatus==='CLOSED')?(
                            <div className="row mb-2">
                                <span className='col-6'> Submitted</span>
                                <span className='checkmark'> </span>
                            </div>
                        ):
                        (<React.Fragment></React.Fragment>)
                    }
                    {
                        ( this.props.orderItem.orderItemStatus==='INPROGRESS' ||this.props.orderItem.orderItemStatus==='COMPLETED' || this.props.orderItem.orderItemStatus==='CLOSED')?(
                            <div className="row mb-2">
                                <span className='col-6'>In Progress</span>
                                <span className='checkmark'> </span>
                            </div>
                        ):
                        (<React.Fragment></React.Fragment>)
                    }
                    {
                        ( this.props.orderItem.orderItemStatus==='COMPLETED' || this.props.orderItem.orderItemStatus==='CLOSED')?(
                            <div className="row mb-2">
                                <span className='col-6'> Completed</span>
                                <span className='checkmark'> </span>
                            </div>
                        ):
                        (<React.Fragment></React.Fragment>)
                    }
                    {
                        ( this.props.orderItem.orderItemStatus==='COMPLETED')?(
                            <div className="row mb-2">
                                <div className="col-6 pr-0">Download</div>
                                <div className="col-6 pl-0"><button className="btn btn-sm button-sec-color py-0 px-1"type="button" onClick={()=>this.props.downloadLabel(this.props.order,this.props.orderItem)}> Download</button></div>
                                
                            </div>
                        ):
                        (<React.Fragment></React.Fragment>)
                    }
                    {
                        ( this.props.orderItem.orderItemStatus==='CLOSED'  )?(
                        <React.Fragment>
                         <div className="row mb-2">
                                <span className='col-6 pr-0'> Downloaded</span>
                                <span className='checkmark'> </span>
                            </div>
                            </React.Fragment>
                        ):
                        (<React.Fragment></React.Fragment>)
                    }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export {OrderItemView};
