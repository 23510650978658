import * as React from "react";
import {observer} from "mobx-react";
import { observable } from "mobx";
import Autosuggest from 'react-autosuggest';
import { BaseProduct } from "../../config/HttpClients";
import {getLoggedInUser} from '../../constants/CommonMethods';
import {Customer} from '../../store/Customer';
import {Stores} from '../../store/Stores';


@observer
class ProductSearch extends React.Component<any, any> {
    @observable products : any = [];
    @observable customer : Customer = new Customer();
    @observable disabled : boolean = false;
    @observable status:string = "";
    constructor(props){
        super(props);
        this.state = {
            nameValue: '',
            products: []
        };
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        if(Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS") {
            this.customer = Stores.customer;
            this.status = "All";
        } else {
            this.customer = Stores.currentCustomer;
             this.status = "APPROVED";
        }
        if(!this.customer.id){
           this.disabled = true;
        }
    }

    setCustomer=(customer)=>{
        this.disabled = false;
        this.customer = customer;
        if(!this.customer.id){
            this.disabled = true;
        }
    };
    setProduct=(product)=>{
        console.log(product);
        this.setState({ nameValue: product});
        console.log(this.state.nameValue);
        this.setState({ nameValue: product }, () => {
        console.log(this.state.nameValue, 'dealersOverallTotal1');
        });
        };

    onSuggestionChange = (event, {newValue, method}) => {
        this.setState({
            nameValue: newValue
        });
    };
    onSuggestionsFetchRequested = ({value}) => {
        BaseProduct.get("/product/searchByName?name="+value+'&customerId='+this.customer.id+'&status='+this.status)
            .then(response =>{ 
                this.setState({ products: response.data.payload}
                )});
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({
            // products: [],
            // nameValue: '',
        });
        let paths =  window.location.pathname.split('/');
        if((paths[1] === "consumerConnect") && this.state.nameValue === '') {
            this.props.setNewProduct();
        }
    }

    clearFilter=()=>{
        this.setState({
             products: [],
             nameValue: '',
        });
    }
    getSuggestion = (suggestion) => {
        return suggestion.name;
    }

    renderSuggestion = (suggestion) => {return ( <span>{suggestion.name}</span>);};

    onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
        this.props.setSelectedProduct(suggestion);
    }

    render() {
        const {nameValue, products} = this.state;
        const customerInputProps =
        {
            placeholder: "Search",
            value: nameValue,
            products:products,
            onChange: this.onSuggestionChange,
            disabled: this.disabled,
            style: this.props.inputStyle
        };

        const renderInputComponent = inputProps => (
            <div>
                <input {...inputProps} />
                 <div className={"react-autosuggest__suggestions-container--open text-center p-2 error"} hidden={!(inputProps.products.length === 0 && inputProps.value !== "")}>No Product found...!</div>
            </div>
        );
        return (
            <Autosuggest
            suggestions={products}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestion}
            renderSuggestion={this.renderSuggestion}
            inputProps={customerInputProps} 
            renderInputComponent={renderInputComponent}
        />
        )
    };

}

export {ProductSearch};
