import React from 'react';
import { observer } from 'mobx-react';

@observer
class PartnerTypeTableDetails extends React.Component<any, any> {


    handleStatus = (targetObject:any,status:any, event:any) => {
        targetObject.status = status;
    }

    render() {
        return(
            <React.Fragment>
            {
                this.props.partnerTypes&&this.props.partnerTypes.map((partnerType,key)=>
                    <tr className="row mx-0 table-body-value" key={key}>
                        <td className='col-12' style={{border:'1px solid #E2E2E3', padding: '.25rem'}}>
                            <div className='row text-nowrap align-self-center'>
                                <div className="col-1 text-nowrap text-center">{key+1}</div>
                                <div className='col-4 text-left pl-5'> {partnerType.name}</div>
                                <div className='col-4 text-center'>
                                    <div className="row justify-content-center align-items-center mx-0">  
                                    <div className="ml-1 custom-control custom-radio">
                                        <input type="radio" id={'active'+key} checked={partnerType.status === 'ACTIVE'}  onChange={this.handleStatus.bind(this, partnerType,'ACTIVE')} className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor={"active"+key}> Active</label>
                                    </div>  
                                    <div className="ml-2 custom-control custom-radio">
                                        <input type="radio" id={'inActive'+key} checked={partnerType.status === 'INACTIVE'}  onChange={this.handleStatus.bind(this, partnerType,'INACTIVE')} className="custom-control-input"/>
                                        <label className="custom-control-label" htmlFor={"inActive"+key}> Inactive</label>
                                    </div>
                                </div>
                                </div>
                                <div className='col-3 text-center'> <button className="btn btn-sm button-sec-color" onClick = {()=>this.props.updatePartnerType(partnerType)} >Update</button></div>
                            </div>
                        </td>
                    </tr>
                )
            }
            </React.Fragment>
        )
    }
}


export {PartnerTypeTableDetails};
