import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {Customer} from "../../store/Customer";
import {Order} from "../../store/Order";
import {BaseCustomer} from "../../config/HttpClients";
import {OrderTableDetails} from "./OrderTableDetails";
import {Pagination} from "../../constants/pagination/Pagination";
import {Stores} from '../../store/Stores';
import {Datepicker} from "../../constants/formfields/Datepicker";
import {DateToStringFormat, getLoggedInUser, stringToDateFormat, viewDateFormat} from "../../constants/CommonMethods";

@observer
class SpecificCustomer extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable customers: Customer[] = [];
    @observable customer: Customer = new Customer();
    @observable orders: Order[] = [];
    @observable selectAll: boolean = false;
    @observable paginationFormat: any;
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init();
        }
    }

    init() {
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.getCustomers();
        this.getOrders();
    }

    getCustomers = () => {
        BaseCustomer.get("/customer/partner/" + Stores.currentCustomer.id).then(response => {
            this.customers = response.data.payload;
        });
    }

    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
        this.pageNo = 0;
        this.getOrders();
    }
    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
        this.pageNo = 0;
        this.getOrders();
    }

    getOrders = () => {
        if (!this.customer.id) {
            this.customer.id = 'null';
        }
        BaseCustomer.get("/order?pageNo=" + this.pageNo + "&limit=" + this.limit + "&orderStatus=OPEN&fromDate=" + this.fromDateValue + "&toDate=" + this.toDateValue + "&customerId=" + this.customer.id + "&printingPartnerId=" + Stores.currentUser.customer).then(response => {
            this.orders = response.data.payload;
            this.orders.map((order) => {
                order.orderDate = viewDateFormat(order.orderDate);
                order.orderItems.map((orderItem) => {
                    orderItem.expectedDeliveryDate = viewDateFormat(orderItem.expectedDeliveryDate);
                    return true;
                })
                return true;
            })
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
            this.orders.forEach(order => {
                order.selected = false;
            })
        });
    }

    updateOrder = (orders) => {
        orders.map((order) => {
            order.orderDate = stringToDateFormat(order.orderDate);
            order.orderItems.forEach((orderItem) => {
                orderItem.expectedDeliveryDate = stringToDateFormat(orderItem.expectedDeliveryDate);
            });
            return true;
        })
        BaseCustomer.put("/order", orders).then(response => {
            this.getOrders();
        });
    }

    generateLabel = () => {
        let orders: Order[] = [];
        this.orders.map((order) => {
            if (order.selected) {
                order.orderStatus = "INPROGRESS";
                order.orderItems.forEach((orderItem) => {
                    orderItem.orderItemStatus = "INPROGRESS";
                });
                orders.push(order);
            }
            return true;
        });
        if (orders.length > 0) {
            this.updateOrder(orders);
        }
    }

    handleCustomer = (customer, event) => {
        customer.id = event.target.value;
        this.pageNo = 0;
        this.getOrders();
    };


    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
        this.pageNo = 0;
        this.getOrders();
    };

    handleSelectAll = (event) => {
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
            this.orders.map((order) => {
                order.selected = true;
                return true;
            });
        } else {
            this.orders.map((order) => {
                order.selected = false;
                return true;
            });
        }
    }

    changeSelectAll = (selectOption) => {
        this.selectAll = selectOption;
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getOrders();
    }


    render() {
        return (
            <div className="col-12 py-3 h-100">
                <div className="row mx-0 pl-0 h-100">
                    <div className="col-12 px-0 h-100">
                        <div className="card b-none h-100">
                            <div className="card-body h-100">
                                <div className='row mx-0 mb-1'>
                                    <div className="col-3"> From Date</div>
                                    <div className="col-3"> To Date</div>
                                    <div className='col-3'> Customer</div>
                                    <div className='col-3 pr-0'>
                                        <div className='float-right'> Items per Page</div>
                                    </div>
                                </div>
                                <div className='row mx-0 mb-2'>
                                    <div className="col-3">
                                        <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleFromDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className="col-3">
                                        <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleToDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className='col-3'>
                                        <select className="form-control"
                                                value={this.customer && this.customer.id}
                                                id="customer"
                                                onChange={this.handleCustomer.bind(this, this.customer)}>
                                            <option className="select-option" value={''}>All</option>
                                            {this.customers.map((e, key) => {
                                                return <option className="select-option" key={key}
                                                               value={e.id}>{e.name}</option>;
                                            })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-3 pr-0'>
                                        <div className='col-6 float-right pr-0'>
                                            <select className="form-control"
                                                    value={this.limit} id="customer"
                                                    onChange={this.handlePageLimit.bind(this, this.limit)}>
                                                <option className="select-option" value={10}>10</option>
                                                <option className="select-option" value={15}>15</option>
                                                <option className="select-option" value={20}>20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.totalRecords > 0 &&
                                    <div className="row mx-0">
                                        <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                    </div>
                                }
                                <div className="row mx-0 mt-2" style={{height: 'calc(100% - 102px)'}}>
                                    <div className="col-12 h-100 px-0">
                                        <div className="row mx-0">
                                            <table className="table m-0">
                                                <thead>
                                                <tr className="row text-nowrap table-header">
                                                    <td className="col-1 pl-0 custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input ml-3"
                                                               checked={this.selectAll}
                                                               onChange={this.handleSelectAll.bind(this)}/>
                                                    </td>
                                                    <th className="col-2 text-center ">Order Date</th>
                                                    <th className="col-4 text-center">Customer</th>
                                                    <th className="col-3 text-center">Order Id</th>
                                                    <th className="col-2 text-center">Action</th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="row pr-0 custom-scroll" style={{
                                            marginTop: '-2px',
                                            height: 'calc(100% - 102px)',
                                            overflowY: 'auto',
                                            overflowX: 'hidden'
                                        }}>
                                            <table className="table" style={{marginTop: '-2px'}}>
                                                <OrderTableDetails orders={this.orders}
                                                                   changeSelectAll={this.changeSelectAll}/>
                                            </table>
                                        </div>
                                        <Pagination totalRecords={this.totalRecords}
                                                    displayRecords={this.limit} pageNo={this.pageNo}
                                                    partialPageCount={5} handleChangePage={this.handleChangePage}
                                                    ref={ref => {
                                                        this.paginationFormat = ref
                                                    }}/>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-6 pl-0">
                                    </div>
                                    <div className="col-6 pl-0">
                                        <div className="row mx-0 justify-content-end">
                                            {(Stores.currentPermission.create && (this.orders.length > 0)) ? (
                                                    <button type="button" className="btn btn-app-primary"
                                                            onClick={() => this.generateLabel()}>GENERATE</button>
                                                ) :
                                                (
                                                    <div></div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const SpecificCustomerComponent = withRouter(SpecificCustomer);
export {SpecificCustomerComponent as SpecificCustomer};
