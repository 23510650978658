import * as React from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";

@observer
class NotificationDetailsPagination extends React.Component<any, any> {

    @observable pageLimit: number = 0;
    @observable totalPages: number = 0;

    totalRecordsCount = () => {
        this.totalPages = this.props.totalPage;
    };

    gotoPage = page => {
        const currentPage = Math.max(0, Math.min(page, this.totalPages));
        if(currentPage === this.props.pageNo)
            return;
        this.props.handleChangePage(currentPage);
    };

    handleFirst = evt => {
        evt.preventDefault();
        this.gotoPage(0);
    };

    handleLast = evt => {
        evt.preventDefault();
        this.gotoPage(this.totalPages - 1 );
    };

    handleNext = evt => {
        evt.preventDefault();
        this.gotoPage(this.props.pageNo + 1);
    };

    handlePrevious = evt => {
        evt.preventDefault();
        this.gotoPage(this.props.pageNo - 1);
    };

    render() {
        return (

            (this.totalPages ) && <nav aria-label="Page navigation example" className="table-responsive">
                <div className="pagination mt-2 d-block d-lg-flex justify-content-end">
                    <ul className="pagination pagination-sm mb-0">
                        <li className={'page-item' + (this.props.pageNo === 0 ? ' disabled' : '')}>
                            <p className="mb-0 page-link cursor-pointer b-none" onClick={this.handleFirst.bind(this)}>
                                &laquo;
                            </p>
                        </li>
                        <li className={'page-item' + (this.props.pageNo === 0 ? ' disabled' : '')}>
                            <p className="mb-0 page-link cursor-pointer b-none" onClick={this.handlePrevious.bind(this)}>
                                Prev
                            </p>
                        </li>
                        <li  className="page-item">
                            <p className="mb-0 page-link b-none">
                                { this.props.pageNo+1 } / {this.totalPages}
                            </p>
                        </li>
                        <li className={'page-item' + (this.props.pageNo === this.totalPages-1 ? ' disabled' : '')}>
                            <p className="mb-0 page-link cursor-pointer b-none" onClick={this.handleNext.bind(this)}>
                                Next
                            </p>
                        </li>
                        <li className={'page-item' + (this.props.pageNo === this.totalPages-1 ? ' disabled' : '')}>
                            <p className="mb-0 page-link cursor-pointer b-none" onClick={this.handleLast.bind(this)}>
                                &raquo;
                            </p>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export {NotificationDetailsPagination};
