import React from 'react';
import { observer } from 'mobx-react';
import {DatepickerSelect} from "../../constants/formfields/DatepickerSelect";
import notificationDashIcon from "../../assets/image/notificationdash.png";

@observer
class NotificationRaisedDetail extends React.Component<any, any> {

    render() {
        return(
            <div className='col-6 pl-0'>
                <div className="card border-0 profile-card-with-cover h-100">
                    <div className="card-content">
                        <div className="card-body p-0">
                            <div className="pl-2 pt-2 pr-2">
                                <img src={notificationDashIcon} alt="notification"
                                     width="50"
                                     className="float-left img-fluid card-image-position-right-mid" />
                                <h6 className="border-bottom mb-0 mt-1 font-weight-bold" style={{height:"26px",marginLeft:"56px",color:"#001f3f"}}>Notifications</h6>
                            </div>
                            <div className="card-body p-2">
                                <table className="w-100 text-center" style={{fontSize:"15px",fontWeight:"normal",display:"grid"}}>
                                    <tbody style={{marginTop:"-15px"}}>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}></td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"19%"}}>New</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"28%"}}>Action Initiated</td>
                                        <td className="font-size-15 dashBoard-secondary-color font-weight-500" style={{width:"23%"}}>Resolved</td>
                                    </tr>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}><span className="font-size-15 dashBoard-secondary-color font-weight-500">On</span> <DatepickerSelect className={"border-0 bg-white font-size-13 black-color font-weight-500 pr-0"} maxDate={new Date()}  minDate={this.props.expectedMinDate} value={this.props.notificationTodayDate} inputChangeHandler={this.props.handleNotificationTodayDate}  isRequired={true} disableDate={this.props.disableDate}></DatepickerSelect></td>
                                        <td className="font-size-15 app-logo-primary-color font-weight-600" style={{width:"19%"}}>{this.props.notificationReportDto.statusNew}</td>
                                        <td className="font-size-15 app-logo-secondary-color font-weight-600" style={{width:"28%"}}>{this.props.notificationReportDto.statusActionInitiated}</td>
                                        <td className="font-size-15 app-logo-third-color font-weight-600" style={{width:"23%"}}>{this.props.notificationReportDto.statusResolved}</td>
                                    </tr>
                                    <tr className="row mx-0">
                                        <td style={{width:"30%"}}><span className="font-size-15 dashBoard-secondary-color font-weight-500">Till</span><DatepickerSelect className={"border-0 bg-white font-size-13 black-color font-weight-500 pr-0"} maxDate={new Date()}  minDate={this.props.expectedMinDate} value={this.props.notificationTillDate} inputChangeHandler={this.props.handleNotificationTillDate}  isRequired={true} disableDate={this.props.disableDate}></DatepickerSelect></td>
                                        <td className="font-size-15 app-logo-primary-color font-weight-600" style={{width:"19%"}}>{this.props.notificationReportDto.statusNewTill}</td>
                                        <td className="font-size-15 app-logo-secondary-color font-weight-600" style={{width:"28%"}}>{this.props.notificationReportDto.statusActionInitiatedTill}</td>
                                        <td className="font-size-15 app-logo-third-color font-weight-600" style={{width:"23%"}}>{this.props.notificationReportDto.statusResolvedTill}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {NotificationRaisedDetail };
