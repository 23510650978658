import React from 'react';
import { withRouter } from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from "mobx";
import { Customer } from "../../store/Customer";
import { Branch } from '../../store/Branch';
import { PackagedLabelReportDto } from "../../store/PackagedLabelReportDto";
import { BaseCustomer, BaseLabelRead } from "../../config/HttpClients";
import { Pagination } from "../../constants/pagination/Pagination";
import { Product } from '../../store/Product';
import { PackagedLabelReportTableDetails } from './PackagedLabelReportTableDetails';
import { Stores } from "../../store/Stores";
import { DateToStringFormat, getLoggedInUser, viewDateTimeFormat } from "../../constants/CommonMethods";
import { Datepicker } from "../../constants/formfields/Datepicker";
import { Button } from 'react-bootstrap';

@observer
class PackagedLabelReport extends React.Component<any, any> {

    @observable branches: Branch[] = [];
    @observable branch: Branch = new Branch();
    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable customers: Customer[] = [];
    @observable customer: Customer = new Customer();
    @observable packagedlabelreportType: string = '';
    @observable products: Product[] = [];
    @observable product: Product = new Product();
    @observable packagedlabelreport: PackagedLabelReportDto[] = [];
    @observable paginationFormat: any;
    @observable showCustomer: any;
    @observable productStatus: any;
    @observable productSearchCom: any;
    @observable disabled: boolean = true;
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    @observable alertType: string = '';
    @observable codeType: string = '';
    @observable userType: string = '';
    @observable search: string = '';
    @observable status: string = '';
    @observable secondaryId: string = '';
    @observable evdensCode: string = '';
    @observable disableInput: boolean = false;
    @observable disableInputCode: boolean = true;
    @observable pageOption: string = 'PACKAGEDLABELREPORT';
    @observable count: number = 0;
    @observable currentAlertPageNo: number = 0;
    @observable invalidLabel: boolean = false;
    @observable searchByCode: boolean = false;
    @observable searchByFilter: boolean = true;
    @observable activityType: string = '';
    @observable activityTypes: string[] = [
        'Assigned', 'Aggregated', 'Dispatched', 'Scrapped', 'Unassigned', 'Replaced', 'Returned', 'Shipped'
    ];

    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init();
        }
    }

    init = () => {
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        this.getBranches(Stores.currentCustomer.id);
        if (Stores.currentUser && Stores.currentUser.userType.name === "CUSTOMER") {
            this.showCustomer = false;
            this.customer = Stores.currentCustomer;
            this.getPackagedLabeles();
            this.disabled = false;
        } else {
            this.showCustomer = true;
            this.getCustomers();
            this.getPackagedLabeles();
        }
    }

    getCustomers = () => {
        BaseCustomer.get("/customer/userType?type=CUSTOMER").then(response => {
            this.customers = response.data.payload;
        });
    }

    getBranches = (customer) => {
        if (customer) {
            BaseCustomer.get("/branch/customer?customer=" + customer).then(response => {
                this.branches = response.data.payload;
            });
        }
    }

    getPackagedLabeles = () => {
        let search;
        let fromDateValue;
        let toDateValue;
        if (!this.branch.id) { this.branch.id = 'null'; }
        if (!this.activityType) { this.activityType = 'null'; }
        if (!this.customer.id) { this.customer.id = 'null'; }
        if (this.fromDateValue !== '') {
            fromDateValue = this.fromDateValue;
        } else {
            fromDateValue = 'null';
        }
        if (this.toDateValue !== '') {
            toDateValue = this.toDateValue;
        } else {
            toDateValue = 'null';
        }
        //TODO:add activity type too
        BaseLabelRead.get("/labelRead/packagedlabelreport?pageNo=" + this.pageNo + "&limit=" + this.limit + "&fromDate=" + fromDateValue + "&toDate=" + toDateValue + "&customer=" + this.customer.id + "&branch=" + this.branch.id + "&actionType=" + this.activityType).then(response => {
            this.packagedlabelreport = response.data.payload;
            if (this.packagedlabelreport) {
                this.packagedlabelreport.forEach((packagedlabelreport) => {
                    packagedlabelreport.scannedDateTimeConvert = viewDateTimeFormat(packagedlabelreport.scannedDateTime);
                });
            }
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
        });
    }

    handleCustomer = (customer, event) => {
        customer.id = event.target.value;
    };

    handleBranch = (branch, event) => {
        branch.id = event.target.value;
    };

    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
    };

    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
    }

    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        Stores.packagedlabelreportPageNo = pageNo;
        this.getPackagedLabeles();
    }

    handleAlertType = (event) => {
        this.alertType = event.target.value;
    }

    handleCodeType = (event) => {
        this.codeType = event.target.value;
    }

    handleUserType = (event) => {
        this.userType = event.target.value;
    }

    handleStatus = (event) => {
        this.status = event.target.value;
    }

    handleActivityType = (event) => {
        this.activityType = event.target.value;
    }

    handleEvdensCode = (event) => {
        this.evdensCode = event.target.value;
    }

    handleSearchByFilter = (event) => {
        this.searchByFilter = true;
        this.disableInput = false;
        this.resetFilters();
    }

    handleSubmitFilter = (event) => {
        this.pageNo = 0;
        this.getPackagedLabeles();
    };

    resetFilters() {
        this.limit = 10;
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        this.search = '';
        this.customer.reset();
        this.customer = new Customer();
        this.branch.reset();
        this.branch = new Branch();
    }

    viewPackagedLabelReport = (packagedlabelreport) => {
        this.count = packagedlabelreport.count;
        this.secondaryId = packagedlabelreport.secondaryId;
        this.currentAlertPageNo = this.count - 1;
        this.setOption("PACKAGEDLABELREPORT");
    }

    setOption = (option) => {
        this.pageOption = option;
        if (this.pageOption === "PACKAGEDLABELREPORT") {
            this.getPackagedLabeles();
        }
    }

    navigate = (url: any) => {
        this.history.push(url);
    };

    render() {
        const currentSolution = Stores.currentCustomer.solution;
        const currentUser = Stores.currentUser.userType.name;

        return (
            <React.Fragment>
                {
                    this.pageOption === 'PACKAGEDLABELREPORT' &&
                    <div className="col-12 py-3 h-100">
                        <div className="row mx-0 h-100">
                            <div className="col-12 h-100 px-0">
                                <div className="card b-none h-100">
                                    <div className="card-body h-100">
                                        <div className='row mx-0 mb-2 align-items-center'>
                                            <div className='col-3 pl-0'>
                                                <div>Branch Location</div>
                                                <select className="form-control" value={this.props.user && this.props.user.branch} required={true}
                                                    onChange={this.handleStatus.bind(this)} id="branch" >
                                                    <option value={''}>Select Branch Location</option>
                                                    {this.branches.map((e, key) => {
                                                        return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <div>Activity</div>
                                                <select className="form-control"
                                                    onChange={this.handleActivityType.bind(this)} id="activityTtype" >
                                                    <option value={''}>Select Activity type</option>
                                                    {this.activityTypes.map((activity) => {
                                                        return <option className="select-option" key={activity} value={activity}>{activity}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mx-0 mb-2 align-items-center'>
                                            <div className="col-3 pl-0">
                                                <div>From Date</div>
                                                <Datepicker
                                                    maxDate={new Date()}
                                                    value={this.fromDate}
                                                    minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleFromDate}
                                                    isRequired={true}
                                                    disabled={this.disableInput} />
                                            </div>
                                            <div className="col-3 pl-0">
                                                <div>To Date</div>
                                                <Datepicker
                                                    maxDate={new Date()}
                                                    value={this.toDate}
                                                    minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleToDate}
                                                    isRequired={true}
                                                    disabled={this.disableInput} />
                                            </div>
                                            <div className='col-2 p-0'>
                                                <div >Items per page</div>
                                                <select className="form-control"
                                                    value={this.limit}
                                                    id="customer"
                                                    onChange={this.handlePageLimit.bind(this, this.limit)}
                                                    disabled={this.disableInput}>
                                                    <option className="select-option" value={10}>10</option>
                                                    <option className="select-option" value={20}>20</option>
                                                    <option className="select-option" value={40}>40</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mx-0 mb-2 align-items-center'>
                                            <div className='col-11 p-0' />
                                            <div className='col-1 text-right p-0'>
                                                <Button className="btn btn-app-primary" type="submit" onClick={this.handleSubmitFilter}
                                                    disabled={this.disableInput}>Search</Button>
                                            </div>
                                        </div>

                                        {
                                            this.totalRecords > 0 &&
                                            <div className="row mx-0">
                                                <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                            </div>
                                        }
                                        <div className="row mx-0 mt-2" style={{ height: 'calc(100% - 168px)' }}>
                                            <div className="col-12 h-100 px-0">
                                                <div className="row mx-0">
                                                    <table className="table m-0">
                                                        <thead >
                                                            <tr className="row mx-0 text-nowrap table-header">
                                                                <th className="col-2 px-0 text-center text-wrap">Activity Date</th>
                                                                <th className="col-3 px-0 text-center text-wrap">Product Name</th>
                                                                <th className="col-1 px-0 text-center text-wrap">Packaging Level</th>
                                                                <th className="col-1 px-0 text-center px-0">#Items</th>
                                                                <th className="col-1 px-0 text-center text-wrap">Status</th>
                                                                <th className="col-2 px-0 text-center text-wrap">User</th>
                                                                <th className="col-2 px-0 text-center px-0">Evdens UID</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div className="row mx-0 custom-scroll" style={{ marginTop: '-2px', height: 'calc(100% - 98px)', overflowY: 'auto', overflowX: 'hidden' }}>
                                                    <table className="table">
                                                        <tbody>
                                                            <PackagedLabelReportTableDetails packagedlabelreport={this.packagedlabelreport} isPackagedLabelReport={true} viewPackagedLabelReport={this.viewPackagedLabelReport} />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Pagination totalRecords={this.totalRecords}
                                                    displayRecords={this.limit} pageNo={this.pageNo}
                                                    partialPageCount={5} handleChangePage={this.handleChangePage}
                                                    ref={ref => {
                                                        this.paginationFormat = ref
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const PackagedLabelReportComponent = withRouter(PackagedLabelReport);
export { PackagedLabelReportComponent as PackagedLabelReport };
