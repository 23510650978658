import React from 'react';
import {observer} from 'mobx-react';
import {observable} from "mobx";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Button, ButtonToolbar,FormGroup,ControlLabel} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { BaseCustomer,BaseUser } from "../../config/HttpClients";
import {viewDateTimeFormat,showImage} from "../../constants/CommonMethods";
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";
import Form from "../../constants/Form";
import {Stores} from "../../store/Stores";
import {Customer} from "../../store/Customer";
import {StatusAudit} from "../../store/StatusAudit";
import {SchedulerForm} from "./SchedulerForm";
import {UploadImage} from "./UploadImage";

@observer
class PushMessageForm extends React.Component<any, any> {
    @observable option:any = "SCHEDULE";
    @observable messageOption = ""
    @observable message:string = "";
    @observable comment:string = "";
    @observable ckeditor:any;
    @observable messageReq:boolean = false;
    @observable countryReq:boolean = false;
    @observable scheduleDatesReq:boolean = false;
    @observable fileReq:boolean = false;
    @observable customerReq:boolean = false;
    @observable showAdmin:boolean = false;
    @observable disabled:boolean = true;
    @observable isView:boolean = false;
    @observable customer = new Customer();
    @observable files: any[] = [];
    @observable imageUrl:any;
    @observable customMessageImageCom:any;
    @observable customerSearchCom:any;
    @observable suspendAlertModal:any;
    @observable deleteAlertModal:any;
    messages = '';
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.init();
    }

    init=()=>{
        this.customer = Stores.currentCustomer;
        if(this.props.pushMessage.imageName){
            this.messageOption = "IMAGE";
            this.getCustomImage(this.props.pushMessage.imageName);
        } else {
            this.messageOption = "MESSAGE";
        }
        if(this.props.pushMessage.status === "Submitted" ||this.props.pushMessage.status === "" ){
            this.disabled = false;
        }
        if((this.props.pushMessage.customerId&&Stores.currentUser.roles&&Stores.currentUser.roles.length>0&&Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN")|| (!this.props.pushMessage.customerId&&Stores.currentUser.roles&&Stores.currentUser.roles.length >0&&Stores.currentUser.roles[0].name === "ROLE_VGLENS_ADMIN")) {
             this.showAdmin = true;
        }
        if(this.props.pushMessage.customerId&&Stores.currentUser.userType.name === "VGLENS") {
            this.isView = true;
            this.disabled = true;
        }
    }

    calculateCount=(editor,data,event)=>{
        let maximumLength = 400;
        let messageText = data.replace(/<\/?[^>]+(>|$)/g, "");
        messageText.replace('/&nbsp;/g', ' ');
        if( messageText.length >= maximumLength ) {
            if(data !== this.messages && messageText.length >= maximumLength){
                this.ckeditor.editor.setData(this.messages);
            }
        } else {
            this.messages = data;
            this.message = data;
            this.props.pushMessage.message = this.message;
        }
    }

    saveOrUpdate=()=>{
        if(this.messageOption==="MESSAGE"&&this.props.pushMessage.message.length === 0 ){
            this.messageReq = true;
            return;
        }
        if(this.messageOption==="IMAGE"&&this.files.length === 0&&!this.props.pushMessage.imageName ){
            this.fileReq = true;
            return;
        }
        if(this.props.countries&&this.props.countries.length === 0){
            this.countryReq = true;
             return;
        }
        if(this.props.pushMessage.schedules&&this.props.pushMessage.schedules.length === 0){
            this.scheduleDatesReq = true;
            return;
        }
        this.props.pushMessage.schedules.map((schedule)=>{
            schedule.scheduleTime = moment(schedule.scheduleTime,"YYYY-MMM-DD hh:mm A").utc().format("YYYY-MM-DDTHH:mm").valueOf();
            return true;
        });
        this.addCountry(this.props.countries[0])
        this.props.pushMessage.userType = Stores.currentUser.userType.name;
        if(this.props.pushMessage.hasOwnProperty('createdDate')){
            if(!this.props.pushMessage.createdDate)
                delete this.props.pushMessage.createdDate;
        }
        if (this.props.pushMessage.hasOwnProperty('id')){
            if(!this.props.pushMessage.id){
                delete this.props.pushMessage.id;
                this.savePushMessage();
            } else {
               this.updatePushMessage();
            }
        } else {
            this.savePushMessage();
        }
    }

    savePushMessage=()=>{
        if(Stores.currentUser.userType.name === "CUSTOMER"){
            this.props.pushMessage.customerId = Stores.currentCustomer.id;
            this.props.pushMessage.customerName = Stores.currentCustomer.name;
            this.props.pushMessage.sender = Stores.currentCustomer.name;
            this.props.pushMessage.customerVertical = this.customer.vertical&&this.customer.vertical.id;
        } else {
            this.props.pushMessage.sender = "Evdens";
            this.props.pushMessage.customerId = "";
        }
        this.props.pushMessage.status = "Submitted";
        let formData = new FormData();
        formData.append('pushMessage', JSON.stringify(this.props.pushMessage));
        this.files.map((file) => {
            formData.append('file', file);
            return true;
        });
        BaseCustomer.post("/pushMessage", formData).then(response => {
           this.setNewMessage();
        });
    }

    updatePushMessage=()=>{
        if(Stores.currentUser.userType.name === "CUSTOMER"){
            this.props.pushMessage.customerId = Stores.currentCustomer.id;
            this.props.pushMessage.customerName = Stores.currentCustomer.name;
            this.props.pushMessage.sender = Stores.currentCustomer.name;
            this.props.pushMessage.customerVertical = this.customer.vertical&&this.customer.vertical.id;
        } else {
            this.props.pushMessage.sender = "Evdens";
            this.props.pushMessage.customerId = "";
        }
        let formData = new FormData();
            formData.append('pushMessage', JSON.stringify(this.props.pushMessage));
            this.files.map((file) => {
                formData.append('file', file);
                return true;
        });
        BaseCustomer.post("/pushMessage/update", formData).then(response => {
           this.setNewMessage();
           this.suspendAlertModal.close();
        });
    }

    deletePushMessage=()=>{
        BaseCustomer.delete("/pushMessage?id="+this.props.pushMessage.id).then(response => {
            this.setNewMessage();
            this.deleteAlertModal.close();
        });
    }

    deleteAlert=()=>{
        this.deleteAlertModal.show();
    }

    searchCountry=(event,callback)=>{
        if(!event){
            callback([]);
        }
        if(event) {
            BaseUser.get("/user/country?country="+event).then(response =>{
                let countries:any = [];
                response.data.payload.map(country=>{countries.push({label:country,value:country});
                return true;});
                 callback(countries);
            });
        }
    }

    getCustomImage = (fileName) => {
        BaseCustomer.get("/pushMessage/image?fileName=" + fileName, {
                    headers: {Accept: "*/*"},
                    responseType: "blob"
                }).then(response => {
                    let blob = new Blob([response.data], {type: response.headers["content-type"]});
                    this.imageUrl = URL.createObjectURL(blob);
                });
    }

    approveOrDeActiveMessage=(status)=>{
        this.props.pushMessage.status = status;
        let statusAudit = new StatusAudit();
        statusAudit.status = status;
        statusAudit.comment = this.comment;
        statusAudit.updatedUser = Stores.currentUser.firstName;
        statusAudit.createdAt = new Date().toISOString().replace('Z','');
        if(this.props.pushMessage.statusAudits&&this.props.pushMessage.statusAudits.length > 0) {
            this.props.pushMessage.statusAudits.push(statusAudit);
        } else {
            let statusAudits:StatusAudit[] = [];
            statusAudits.push(statusAudit);
            this.props.pushMessage.statusAudits = statusAudits;
        }
        this.saveOrUpdate();
    }

    addCountry = (country) => {
        this.props.pushMessage.country = country.value;
    }

    handleBack = () => {
        this.props.setOption('VIEW');
    }

    handleComment=(event)=>{
        this.comment = event.target.value;
    };

    handleMessageOption = (type,event)=>{
        this.messageOption = type;
        this.setEmptyFileAndMessage();
    };

    deactivateAlert = () => {
        this.suspendAlertModal.show();
    };

    setEmptyFileAndMessage=()=>{
        if(this.ckeditor) {
            this.ckeditor.editor.setData('');
        }
        if(this.customMessageImageCom) {
            this.customMessageImageCom.fileInput.value = "" ;
        }
        this.messages = '';
        this.message = '';
        this.props.pushMessage.message = '';
        this.files = [];
        this.imageUrl = '';
    }

    setSelectedCustomer=(customer)=>{
        this.customer = customer;
        this.customerReq = false;
    }

    setNewMessage=()=>{
        this.props.newPushMessage();
        this.messageReq = false;
        this.countryReq = false;
        this.customerReq = false;
        this.scheduleDatesReq = false;
        this.fileReq = false;
        this.imageUrl = '';
        this.files = [];
        if(this.customMessageImageCom) {
            this.customMessageImageCom.fileInput.value = "" ;
        }
   }

    setNewCustomer=()=>{
        this.customer = new Customer();
    }

    getImage = () => {
        showImage(this.files[0]).then(response => {
            this.imageUrl = response;
        });
    }

    setFilesEmpty = () => {
        this.imageUrl = '';
    }

    handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleUserInput = (targetObject, event) => {
        targetObject[event.target.name] = event.target.value;
    };

    setOption=(option)=>{
        this.option = option;
    }

    navigate = (url: any) => {
        this.history.push(url);
    };

    render() {
        return (
            <div className="col-12 pt-4 h-100 product-details">
                <div className="pb-2 row mx-0">
                    <div className='col-6  h-42'>
                        <b style={{letterSpacing: "1px", fontSize: "20px"}}>Details </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>

                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0" style={{height:'calc(100% - 50px)'}}>
                    <div className="col-7 pl-0 pb-3 h-100">
                        <div className="card b-none h-100">
                            <div className="card-body h-100 pr-2" >
                                <div className="notification-details-height" style={{height:'calc(100% - 40px)'}}>
                                <Form submit={this.saveOrUpdate}>
                                    <div className="h-100 custom-scroll pr-2" style={{overflowY:'auto',overflowX:'hidden'}}>
                                        <div className='row mx-0'>
                                            <div className='col-6 pl-0'>
                                                <FormGroup controlId="name" bsSize="large">
                                                    <ControlLabel className="font-weight-bold">Message Type</ControlLabel>
                                                    <select className="form-control"value={this.props.pushMessage.messageType}id="messageType" name="messageType"
                                                        onChange={this.handleUserInput.bind(this,this.props.pushMessage)} required={true}  disabled={this.disabled}>
                                                        <option value={''}>Select Message Type</option>
                                                        <option className="select-option" value={'Awareness'}>Awareness</option>
                                                        <option className="select-option" value={'PromotionalCampaign'}>Promotional Campaign</option>
                                                        <option className="select-option" value={'Offer'}>Offers</option>
                                                    </select>
                                                    <div className="invalid error"/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup controlId="name" bsSize="large">
                                                    <ControlLabel className="font-weight-bold">Message Header</ControlLabel>
                                                    <input type="text" id="header" name="header" placeholder="Message Header" className="form-control" onChange={this.handleUserInput.bind(this,this.props.pushMessage)}
                                                     value={this.props.pushMessage.header} required={true} maxLength={50}  disabled={this.disabled}/>
                                                    <div className="invalid error"/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className='row mx-0'>
                                            <div className='col-6 pl-0'>
                                                <FormGroup controlId="name" bsSize="large">
                                                    <ControlLabel className="font-weight-bold">Country</ControlLabel>
                                                    <AsyncSelect
                                                        isDisabled={this.disabled}
                                                        isClearable
                                                        placeholder="Country"
                                                        closeOnSelect={false}
                                                        defaultOptions={this.props.defaultCountries}
                                                        value={this.props.countries.length >0&& this.props.countries[0].label?this.props.countries:null}
                                                        onChange={this.props.handleSelectCountry}
                                                        loadOptions={this.searchCountry.bind(this)}
                                                        onInputChange={this.handleInputChange}
                                                    />
                                                    {
                                                        ((this.countryReq&&this.props.countries.length===0))&&
                                                            <div className="error">
                                                                This field is required
                                                            </div>
                                                    }
                                                </FormGroup>
                                            </div>
                                            <div className='col-6 pl-0'>
                                                <FormGroup controlId="name" bsSize="large">
                                                    <ControlLabel className="font-weight-bold">Target consumers</ControlLabel>
                                                        <select className="form-control"value={this.props.pushMessage.group} id="group" name="group"
                                                            onChange={this.handleUserInput.bind(this,this.props.pushMessage)} required={true}  disabled={this.disabled}>
                                                            <option value={''}>Select Target consumer</option>
                                                            <option className="select-option" value={'All'}>All</option>
                                                            <option className="select-option" value={'MyCustomer'}>My Customers</option>
                                                        </select>
                                                        <div className="invalid error"/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-12 align-self-center pl-0">
                                                <FormGroup controlId="status" bsSize="large">
                                                    <ControlLabel className="font-weight-bold">Message Option</ControlLabel>
                                                    <div className="row mx-0">
                                                        <div className="ml-1 custom-control custom-radio">
                                                            <input type="radio" id={'active'} checked={this.messageOption === 'MESSAGE'}  onChange={this.handleMessageOption.bind(this,'MESSAGE')} className="custom-control-input" name="status" required={true}  disabled={this.disabled}/>
                                                            <label className="custom-control-label" htmlFor={"active"}> Message</label>
                                                        </div>
                                                        <div className="ml-2 custom-control custom-radio">
                                                            <input type="radio" id={'inActive'} checked={this.messageOption === 'IMAGE'}  onChange={this.handleMessageOption.bind(this,'IMAGE')} className="custom-control-input" name="status"  disabled={this.disabled}/>
                                                            <label className="custom-control-label" htmlFor={"inActive"}> Image</label>
                                                            <div className="invalid error"/>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        {
                                            this.messageOption==="MESSAGE"&&
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <ControlLabel className="font-weight-bold">Message</ControlLabel>
                                                    <CKEditor
                                                        disabled={this.disabled?true:false}
                                                        onChange={ ( event, editor ) => {this.calculateCount(editor,editor.getData(),event); } }
                                                        config={{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList' , 'undo' ]}}
                                                        editor={ ClassicEditor }
                                                        data={this.props.pushMessage.message}
                                                        ref={(instance) => { this.ckeditor = instance; }} />
                                                        {
                                                            this.messageReq&&(this.props.pushMessage.message.length===0)&&
                                                            <div className="invalid error">This fiels is required.</div>
                                                        }
                                                </div>
                                            </div>
                                        }
                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <FormGroup controlId="packagingLevels" bsSize="large">
                                                    <ControlLabel className="w-100 font-weight-bold">Schedule</ControlLabel>
                                                     <button type="button" onClick={()=>this.setOption('SCHEDULE')} className="btn btn-sm button-grey-color">View/Modify</button>
                                                     {
                                                        ((this.scheduleDatesReq&&this.props.pushMessage.schedules.length===0))&&
                                                        <div className="error">
                                                            Schedule datetime required
                                                        </div>
                                                     }
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                            {
                                                this.messageOption==="IMAGE"&&
                                                <FormGroup controlId="packagingLevels" bsSize="large">
                                                <ControlLabel className="w-100 font-weight-bold">Image</ControlLabel>
                                                <Button className="btn button-grey-color" type="button"
                                                    onClick={()=>this.setOption('IMAGE')}>VIEW/MODIFY</Button>
                                                {
                                                     this.fileReq&&(this.files.length===0)&&
                                                     <div className="invalid error">Image required.</div>
                                                }
                                                </FormGroup>
                                            }
                                            </div>
                                        </div>
                                        {
                                            this.props.pushMessage&&this.props.pushMessage.statusAudits && this.props.pushMessage.statusAudits.length > 0 &&
                                                <div className="row mx-0 mb-3 mt-2">
                                                    <div className="col-12 px-0">
                                                        <div className="row mx-0">
                                                            <p className="mb-0 font-weight-bold">Activity Log</p>
                                                        </div>
                                                        <div className="row mx-0">
                                                        {
                                                             this.props.pushMessage.statusAudits.map((comment,index) =>
                                                                <p className="mb-0 w-100" key={index}>{comment.status} {comment.comment?<span> - {comment.comment}</span>:<React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                                             )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <ButtonToolbar className="justify-content-center mt-2">
                                        <Button className="btn btn-app-secondary" type="button"
                                            onClick={this.handleBack}> BACK</Button>
                                        <Button className="btn-sm btn-app-primary ml-2" type="submit"
                                            hidden={((this.props.pushMessage.status === '' || this.props.pushMessage.status === 'Submitted') && !this.isView) ? false : true}> SUBMIT</Button>
                                        <Button className="btn-sm btn-app-primary ml-2" type="button" onClick={()=>this.approveOrDeActiveMessage('Approved')}
                                            hidden={(this.props.pushMessage.status === 'Submitted'&&this.showAdmin) ? false : true}> APPROVE</Button>
                                        <Button className="btn-sm btn-app-primary ml-2" type="button" onClick={this.deactivateAlert}
                                            hidden={(this.props.pushMessage.status === 'Approved' && !this.isView) ? false : true}> DEACTIVATE</Button>
                                        <Button className="btn btn-app-primary ml-2" type="button" onClick={this.props.copyAndNewMessage}
                                            hidden={(!this.isView &&this.props.pushMessage.status) ? false : true}> COPY & CREATE</Button>
                                    </ButtonToolbar>
                                </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 px-0">
                        <div className="card">
                            <div className="card-body" style={{minHeight: "200px"}}>
                                <div className="pt-0">
                                {
                                    this.option==="SCHEDULE"&&
                                    <SchedulerForm scheduleDates={this.props.pushMessage.schedules} disabled={this.disabled}/>
                                }
                                {
                                    this.option==="IMAGE"&&
                                    <UploadImage imgUrl={this.imageUrl} files={this.files} getImage={this.getImage} setFilesEmpty={this.setFilesEmpty} ref={(instance) => { this.customMessageImageCom = instance; }} disabled={this.disabled} />
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorAlertModal ref={instance => {this.suspendAlertModal = instance}}
                    type={"deactivate"} comment={this.comment} handleComment={this.handleComment} submit={()=>this.approveOrDeActiveMessage('Deactivated')}/>
            </div>
        )
    }
}

export {PushMessageForm};
