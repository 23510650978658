import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { BaseProduct } from '../../config/HttpClients';
import { showImage,getLoggedInUser } from "../../constants/CommonMethods";
import { ErrorAlertModal } from "../../constants/modal/ErrorAlertModal";
import { Stores } from '../../store/Stores';
import { Product } from '../../store/Product';
import { ProductSearch } from '../product/ProductSearch';
import { ProductForm } from '../product/ProductForm';
import { ProductImage } from './ProductImage';



@observer
class CreateProduct extends React.Component<any, any> {
    
    @observable product = new Product();
    @observable files:any[]=[];
    @observable imgUrl:any;
    @observable showImageDelete:boolean = false;
    @observable disableInput:boolean = false;
    @observable productForm:any;
    @observable errorAlertModal:any
    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        Stores.product = new Product();
        if(Stores.currentCustomer&&Stores.currentCustomer.type&&Stores.currentCustomer.type.id==="31000000-0000-0000-0000-000000000000"){
            this.disableInput = true;
        }
    }
   
    handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
    };

    save = () => {
        if(Stores.currentCustomer.productGtin) {
            if (!this.product.gtin) {
                return;
            }
        }
        if(this.product.hasOwnProperty('id')) {
            if(this.product.id){
                this.updateProduct();
            }else {
                this.createProduct();
            }
        } else {
            this.createProduct();
        }
    }

    createProduct=()=>{
        if(this.product.hasOwnProperty('id')) {
            if(this.product.id === "")
                delete this.product.id;
        }
        this.product.productStatus = "Needs Approval";
        this.product.customer = Stores.currentCustomer.id;
        this.product.createdBy = Stores.currentUser.id;
        this.product.createdAt = new Date().toDateString();
        if( this.product.createdAt){
           delete this.product.createdAt;
        }
        let formData = new FormData();
        formData.append('product', JSON.stringify(this.product));
        this.files.map((file) => {formData.append('file', file); return true;});
        BaseProduct.post("/product",formData).then(response=>{
            this.setNewProduct();
        });
    }

    updateProduct=()=>{
        this.product.updatedBy = Stores.currentUser.id;
        let formData = new FormData();
        formData.append('product', JSON.stringify(this.product));
        this.files.map((file) => {formData.append('file', file);return true;});
        BaseProduct.post("/product/update",formData).then(response=>{
            this.setNewProduct();
        });
    }

    setSelectedProduct=(product)=>{
        this.product = new Product();
        this.product = product;
        this.showImageDelete = false;
        this.imgUrl = '';
        if(this.product.fileName){
            this.getproductImage( this.product.fileName);
            if(this.product.productStatus === null || this.product.productStatus === 'Needs Approval' ){
                this.showImageDelete = true;
            }
        }
    }

    setNewProduct=()=>{
        this.product = new Product();
        this.product.reset();
        this.productForm.reset();
        this.files = [];
        this.imgUrl = '';
        this.showImageDelete = false;
    }

    getproductImage= (fileName) => {
        BaseProduct.get("/product/image?fileName="+fileName,{headers:{Accept:"*/*"},responseType: "blob"}).then(response=>{
            let blob = new Blob([response.data], { type:response.headers["content-type"]});
            this.imgUrl = URL.createObjectURL(blob);
        });
     }

    deleteProductImage= () => {
        BaseProduct.delete("/product/image?id="+this.product.id,).then(reponse=>{
            this.getProductById(this.product.id);
            this.errorAlertModal.close();
        });
    }

    delete=()=>{
        this.errorAlertModal.show();
    }

    getProductById=(id)=>{
        BaseProduct.get("/product/"+this.product.id,).then(reponse=>{
            this.setSelectedProduct(reponse.data.payload);
        });
    }

    showimage=()=>{
        showImage(this.files[0]).then(response=>{
            this.imgUrl = response;
        });
    }

    setDivRef = (element) => {
        this.productForm = element;
    };

    setFilesEmpty=()=>{
        this.imgUrl = "";
    }

    render() {
        return(
                <div className="col-12 p-0">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6 h-42'>

                    </div>
                    <div className='col-6'>
                    <div className='float-right'>
                        <ProductSearch setSelectedProduct={this.setSelectedProduct} />
                        </div>
                    </div>
                </div>
                    <div className="row mx-0 pl-0 pt-3">
                        <div className="col-8 pr-0">
                            <div className="card b-none">
                                <div className="card-body">                                   
                                    <div className="pt-2">
                                        <ProductForm product={this.product} handleUserInput={this.handleUserInput} save={this.save}  showimage={this.showimage}
                                                      files={this.files}  onRef={ref => (this.productForm = ref)}
                                                     setFilesEmpty={this.setFilesEmpty} disableInput={this.disableInput}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <div className="card-body">                                   
                                    <div className="pt-2">
                                        <ProductImage imgUrl={this.imgUrl} showImageDelete={this.showImageDelete}
                                                      deleteImage={this.delete}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ErrorAlertModal  ref={instance=>{this.errorAlertModal=instance}} submit={this.deleteProductImage}  />
                </div>
        )
    }
}

const CreateProductComponent = withRouter(CreateProduct);
export {CreateProductComponent as CreateProduct};
