import React from 'react';
import { withRouter } from "react-router-dom";
import { observer } from 'mobx-react';
import { Button, ButtonToolbar, ControlLabel, FormGroup } from "react-bootstrap";
import Form from '../../constants/Form';
import { Stores } from '../../store/Stores';
import Select from 'react-select';
import { BaseCustomer } from "../../config/HttpClients";
import { observable } from "mobx";
import { Country } from "../../store/Country";
import { viewDateTimeFormat } from '../../constants/CommonMethods';
import { LoadToast, ToastType } from "../../config/Toast";


@observer
class BranchForm extends React.Component<any, any> {

    @observable countries: Country[] = []
    @observable fileInput: any;
    @observable isFileTooLarge: boolean = false;

    constructor(props) {
        super(props);
        this.getCountries();
    }

    handleIsHeadQuarter = (branch, event) => {
        branch.headQuarter = !branch.headQuarter;
    }

    handleStatus = (targetObject: any, status: any, event: any) => {
        targetObject[event.target.name] = status;
    }

    getCountries = () => {
        BaseCustomer.get('/country').then(response => {
            this.countries = response.data.payload;
        });
    }

    handleInputFile = (files, event) => {
        this.props.setFilesEmpty();
        this.handleFileObject(event.target.files, files)
    };

    handleFileObject = (data, files) => {
        let dataObj = data.length !== 0 ? this.handleFile(data) : {};
        Object.keys(dataObj).map(key => {
            files[key] = data[key];
            return true;
        });
        if (!this.isFileTooLarge) {
            this.props.getImage();
        }
    };

    handleFile = (files) => {
        this.isFileTooLarge = false;
        if (this.checkImageType(files)) {
            LoadToast(ToastType.WARN, "File Type is not allowed ,Fotmats jpeg, jpg, png");
        } else {
            if (!this.checkFileSize(files)) {
                this.isFileTooLarge = true;
                return {};
            }
            return files;
        }
        return {};
    };
    checkImageType = (files) => {
        let data;
        let allowedExtension = ['jpeg', 'jpg', 'png'];
        Object.keys(files).map(key => {
            data = allowedExtension.filter((data) => {
                if (Object.keys(files).length !== 0) {
                    return files[key].type.includes(data);
                }
            });
        });
        return data.length === 0;
    };

    checkFileSize = (files) => {
        let fileSize = 0;
        Object.keys(files).map(key => {
            fileSize += files[key].size;
            return true;
        });
        return fileSize < 102400;
    };

    validate = () => {
        if (this.isFileTooLarge) {
            return;
        }
        this.props.submit();
    }

    render() {
        let isUpdate = false;
        if (this.props.branch.hasOwnProperty('id')) {
            if (this.props.branch.id) {
                isUpdate = true;
            }
        }
        let countries = this.countries && this.countries.map(country => { return { label: country['name'], value: country['id'] } });
        return (
            <Form submit={this.props.save} >
                {
                    ((this.props.showBranchType) ? (
                        <div className={'row'}>
                            <div className="col-6 align-self-center">
                                <FormGroup controlId="status" bsSize="large">
                                    <ControlLabel className="font-weight-bold">Branch Type</ControlLabel>
                                    <div className="row mx-0">
                                        <div className="ml-1 custom-control custom-radio">
                                            <input type="radio" id={'active'} disabled={this.props.disableInput}
                                                checked={this.props.branch && this.props.branch.type === 'Plant'}
                                                onChange={this.handleStatus.bind(this, this.props.branch, 'Plant')}
                                                className="custom-control-input form-control" name="type"
                                                required={true} />
                                            <label className="custom-control-label font-weight-500"
                                                htmlFor={"active"}> Plant/Office</label>
                                        </div>
                                        <div className="ml-2 custom-control custom-radio">
                                            <input type="radio" id={'inActive'} disabled={this.props.disableInput}
                                                checked={this.props.branch && this.props.branch.type === 'Warehouse'}
                                                onChange={this.handleStatus.bind(this, this.props.branch, 'Warehouse')}
                                                className="custom-control-input form-control" name="type" />
                                            <label className="custom-control-label font-weight-500"
                                                htmlFor={"inActive"}> Warehouse/Store</label>
                                            <div className="invalid error" />
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    ) :
                        (
                            <React.Fragment></React.Fragment>
                        ))
                }
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="name" bsSize="large">
                            <ControlLabel className="font-weight-bold">Branch Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.branch.name}
                                onChange={this.props.handleUserInput.bind(this, this.props.branch)} maxLength={255}
                                placeholder="Branch Name" id="name" required={true} disabled={this.props.disableInput} />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    <div className="col-6">
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">GSTIN</ControlLabel>
                            <input
                                type='text'
                                onChange={this.props.handleUserInput.bind(this, this.props.branch)}
                                maxLength={255}
                                className="form-control"
                                value={this.props.branch && this.props.branch.gstin}
                                disabled={this.props.disableInput}
                                placeholder={"Branch GSTIN"}
                                id="gstin" />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="city" bsSize="large">
                            <ControlLabel className="font-weight-bold">City/Town</ControlLabel>
                            <input type="text" className="form-control" value={this.props.branch.city}
                                placeholder="City/Town" disabled={this.props.disableInput}
                                onChange={this.props.handleUserInput.bind(this, this.props.branch)} maxLength={255}
                                id="city" required={true} />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="state" bsSize="large">
                            <ControlLabel className="font-weight-bold">State</ControlLabel>
                            <input type="text" className="form-control" value={this.props.branch.state}
                                placeholder="State" disabled={this.props.disableInput}
                                onChange={this.props.handleUserInput.bind(this, this.props.branch)} maxLength={255}
                                id="state" required={true} />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="country" bsSize="large">
                            <ControlLabel className="font-weight-bold">Country</ControlLabel>
                            <Select closeOnSelect={false}
                                isClearable
                                isDisabled={this.props.disableInput}
                                placeholder="Country"
                                classNamePrefix="select"
                                name="name" value={this.props.countries.length > 0 && this.props.countries[0].value ? this.props.countries : null}
                                onChange={this.props.handleChange}
                                options={countries} required
                            />
                            {
                                (this.props.countryError && this.props.countries.length === 0) &&
                                <div className="error">
                                    This field is required
                                </div>
                            }
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="address" bsSize="large">
                            <ControlLabel className="font-weight-bold">Address</ControlLabel>
                            <textarea style={{ resize: 'none' }} className="form-control" value={this.props.branch.address} rows={3}
                                placeholder="Address" disabled={this.props.disableInput}
                                onChange={this.props.handleUserInput.bind(this, this.props.branch)}
                                id="address" required={true}></textarea>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-6" hidden={this.props.disableInput ? true : false}>
                        <FormGroup controlId="logo" bsSize="large" onClick={this.props.showProductImage}>
                            <ControlLabel className="font-weight-bold">Upload Image</ControlLabel>
                            <label htmlFor="branch-file-upload" className="btn button-grey-color ml-2">
                                {this.props.files.length > 0 ? 'Change File' : 'Browse File'}
                            </label>
                            <input id="branch-file-upload" accept=".png, .jpg, .jpeg" type="file" name="files"
                                disabled={this.props.disableInput}
                                ref={instance => {
                                    this.fileInput = instance
                                }}
                                onChange={this.handleInputFile.bind(this, this.props.files)}
                            />
                            <p style={{ color: 'grey' }}>Max file size: 100KB. Recommended dimension 690 X 290</p>
                            <div className="invalid error" />
                        </FormGroup>
                        {
                            this.isFileTooLarge &&
                            <p className="mb-0 error">File is too large</p>
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="imageText" bsSize="large">
                            <ControlLabel className="font-weight-bold">Title</ControlLabel>
                            <textarea className="form-control" value={this.props.branch.imageText}
                                placeholder="Image text" disabled={this.props.disableInput}
                                onChange={this.props.handleUserInput.bind(this, this.props.branch)}
                                id="imageText" />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="imageUrl" bsSize="large">
                            <ControlLabel className="font-weight-bold">Link</ControlLabel>
                            <input type="text" className="form-control" value={this.props.branch.imageUrl}
                                placeholder="Link" disabled={this.props.disableInput}
                                onChange={this.props.handleUserInput.bind(this, this.props.branch)} maxLength={255}
                                id="imageUrl" />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                </div>

                {
                    this.props.showHeadQuarterOption &&
                    <div className='row mx-0'>
                        <div className="col-2 ml-2 custom-checkbox">
                            <input type="checkbox" className="custom-control-input ml-3 mt-2" disabled={this.props.disableInput}
                                checked={this.props.branch.headQuarter} name="isHeadQuarter" id="isHeadQuarter" onChange={this.handleIsHeadQuarter.bind(this, this.props.branch)} />
                            <label className="custom-control-label font-weight-bold" htmlFor="isHeadQuarter">Headquarter</label>
                        </div>
                    </div>
                }
                {
                    this.props.branch && this.props.branch.branchStatusAudits && this.props.branch.branchStatusAudits.length > 0 && !this.props.hideActivityLog &&
                    <div className="row mx-0 mb-3 mt-2">
                        <div className="col-12 px-0">
                            <div className="row mx-0">
                                <p className="mb-0 font-weight-bold">Activity Log</p>
                            </div>
                            <div className="row mx-0">
                                {
                                    this.props.branch.branchStatusAudits.map((comment, index) =>
                                        <p className="mb-0 w-100" key={index}>{comment.status === "ACTIVE" ? <span>Active</span> : <span>Inactive</span>} {comment.comment ? <span> - {comment.comment}</span> : <React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    ((Stores.currentPermission.create) && !this.props.disableInput && !isUpdate) ? (
                        <ButtonToolbar className="justify-content-center">
                            {
                                (this.props.branch.status === "ACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ?
                                    <Button className="btn btn-app-secondary" type="button" onClick={this.props.suspendBranch}
                                    > SUSPEND</Button> : <React.Fragment></React.Fragment>
                            }
                            {
                                (this.props.branch.status === "INACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN") ?
                                    <Button className="btn btn-app-secondary" type="button" onClick={this.props.activateBranch}
                                    > ACTIVATE</Button> : <React.Fragment></React.Fragment>
                            }
                            <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                        </ButtonToolbar>
                    ) :
                        (
                            <React.Fragment></React.Fragment>
                        )

                }
                {
                    ((Stores.currentPermission.modify && this.props.branch && this.props.branch.hasOwnProperty('id')) && !this.props.disableInput && isUpdate) ? (
                        (this.props.branch.id) &&
                        <ButtonToolbar className="justify-content-center">
                            {
                                (this.props.branch.status === "ACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN" && Stores.currentBranch.id !== this.props.branch.id) ?
                                    <Button className="btn btn-app-secondary" type="button" onClick={this.props.suspendBranch}
                                    > SUSPEND</Button> : <React.Fragment></React.Fragment>
                            }
                            {
                                (this.props.branch.status === "INACTIVE" && Stores.currentUser.roles[0].name !== "ROLE_VGLENS_ADMIN" && Stores.currentBranch.id !== this.props.branch.id) ?
                                    <Button className="btn btn-app-secondary" type="button" onClick={this.props.activateBranch}
                                    > ACTIVATE</Button> : <React.Fragment></React.Fragment>
                            }
                            <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                        </ButtonToolbar>
                    ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }

            </Form>
        )
    }
}

const BranchFormComponent = withRouter(BranchForm);
export { BranchFormComponent as BranchForm };
