import * as React from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

@observer
class Pagination extends React.Component<any, any> {

    @observable totalRecords: number = 0;
    @observable pageLimit: number;
    @observable pageNeighbours: number = 1;
    @observable totalPages: number = 0;
    @observable pageRanges: any[] = [];

    constructor(props) {
        super(props);
        this.pageLimit = this.props.displayRecords;
    }

    totalRecordsCount = () => {
        this.pageLimit = this.props.displayRecords;
        this.totalRecords = this.props.totalRecords;
        this.totalPages   = Math.ceil(this.totalRecords / this.pageLimit);
        this.pageRanges   = this.fetchPageNumbers();
    };

    gotoPage = page => {
        const currentPage = Math.max(0, Math.min(page, this.totalPages));
        if(currentPage === this.props.pageNo)
            return;
        this.props.handleChangePage(currentPage);
    };

    handleClick = (page, evt) => {
        evt.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.props.pageNo - this.pageNeighbours * 2 - 1);
    };

    handleNext = evt => {
        evt.preventDefault();
        this.gotoPage(this.props.pageNo + 1);
    };

    handlePrevious = evt => {
        evt.preventDefault();
        this.gotoPage(this.props.pageNo - 1);
    };

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.props.pageNo + this.pageNeighbours * 2 + 1);
    };

    range = (from, to, step = 1) => {

        let i = from;
        const range : any[] = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }
        return range;
    };

    fetchPageNumbers = () => {

        const totalPages = this.totalPages-1;
        const currentPage = this.props.pageNo;
        const pageNeighbours = this.pageNeighbours;

        const totalNumbers = this.pageNeighbours * 2+1;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages: any[] = [];

            const leftBound = currentPage  - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 1;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = this.range(startPage, endPage);
            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = this.range(startPage + 1, startPage);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = this.range(endPage+1 , endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }
            return [0, ...pages, totalPages];
        }
        return this.range(0, totalPages);
    };

    render() {
        return (

            (this.totalRecords >  this.pageLimit) && <nav aria-label="Page navigation example" className="table-responsive">
                <div className="pagination mt-2 justify-content-end d-block d-lg-flex">
                    <ul className="pagination pagination-sm mb-2">
                        <li className={'page-item' + (this.props.pageNo === 0 ? ' disabled' : '')}>
                            <p className="page-link mb-0 cursor-pointer" onClick={this.handlePrevious.bind(this)}>Prev</p>
                        </li>
                        {this.pageRanges.map((page, index) => {
                            if (page === LEFT_PAGE)
                                return (
                                    <li key={index} className="page-item">
                                        <p className="page-link mb-0 cursor-pointer" onClick={this.handleMoveLeft.bind(this)}>
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </p>
                                    </li>
                                );

                            if (page === RIGHT_PAGE)
                                return (
                                    <li key={index} className="page-item">
                                        <p className="page-link mb-0 cursor-pointer" onClick={this.handleMoveRight}>
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </p>
                                    </li>
                                );

                            return (
                                <li key={index} className={`page-item${this.props.pageNo === page ? " active" : ""}`}>
                                    <p className="page-link mb-0 cursor-pointer" onClick={e => this.handleClick(page, e)}>{page+1}</p>
                                </li>
                            );
                        })}
                        <li className={'page-item' + (this.props.pageNo === this.totalPages-1 ? ' disabled' : '')}>
                            <p className="page-link mb-0 cursor-pointer" onClick={this.handleNext.bind(this)}>Next</p>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export {Pagination};
