import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {Button, ControlLabel, FormGroup} from "react-bootstrap";
import Form from '../../constants/Form';
import { BaseUser, BaseCustomer} from '../../config/HttpClients';
import {observable} from "mobx";
import {Stores} from "../../store/Stores";
import { UserType } from '../../store/UserType';
import { Role } from '../../store/Role';
import { Branch } from '../../store/Branch';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import {getLoggedInUser,viewDateTimeFormat} from "../../constants/CommonMethods";

@observer
class CreateUserForm extends React.Component<any, any> {

    @observable roles:Role[] = [];
    @observable userTypes:UserType[] = [];
    @observable branches:Branch[] = [];
    @observable emailMatch:string = "";
    history:any;

    constructor(props) {
        super(props);
        this.history = props.history;
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    componentDidMount() {
        const pasteBox = document.getElementById("confirmEmailId");
        if (pasteBox instanceof HTMLElement) {
            pasteBox.onpaste = e => {
                e.preventDefault();
                return false;
            };
            pasteBox.ondrop = e => {
                e.preventDefault();
                return false;
            };
        }
    }

    init=()=>{
        this.getUserTypes();
        this.getRoles();
        if( Stores.currentUser.userType.name !== "VGLENS") {
            this.getBranches(Stores.currentCustomer.id);
        }
    }

    getUserTypes= () => {
        BaseUser.get("/userType/name/"+Stores.currentUser.userType.name).then(response=>{
            this.userTypes = response.data.payload;
        });
    }
    getRoles=()=>{
        if(Stores.currentUser&&Stores.currentUser.userType&&Stores.currentUser.userType.name === "VGLENS") {
            this.getRolesByVglens(Stores.currentUser.id);
        } else {
            this.getRolesByUser(Stores.currentUser.customer);
        }
    }
    getRolesByVglens=(createdBy)=>{
        BaseUser.get("/role/vglens/"+createdBy).then(response=>{
            this.roles = response.data.payload;
        });
    }
    getRolesByUser=(createdBy)=>{
        BaseUser.get("/role/customer/"+createdBy).then(response=>{
            this.roles = response.data.payload;
        });
    }
    
    getBranches=(customer)=>{
        BaseCustomer.get("/branch/customer?customer="+customer).then(response=>{
            this.branches = response.data.payload;
        });
    }
    handleUserType= (user, event) => {
        user.userType = {id: event.target.value};
    };
    handleUserBranch= (user, event) => {
        this.props.setShowSucces(false);
        user.branch = {id: event.target.value};
    };

    handleUserInput = (targetObject:any, event:any) => {
        this.props.setShowSucces(false);
        targetObject[event.target.id] = event.target.value;
    };

    checkConfirmEmailId=()=>{
        this.emailMatch = '';
        if(this.props.user.emailId !== this.props.user.confirmEmailId) {
            this.emailMatch = "Email Id do not match";
        }
    };

    render() {
        let roles = this.roles && this.roles.map(role => { return { label: role['name'], value: role['id'] } });
        return(
            <Form submit={this.props.submit} >
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="firstName" bsSize="large">
                            <ControlLabel className="font-weight-bold">First Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.user&&this.props.user.firstName}
                                   placeholder="First Name"
                                   onChange={this.props.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                                   id="firstName" required={true}/>
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="lastName" bsSize="large">
                            <ControlLabel className="font-weight-bold">Last Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.user&&this.props.user.lastName}
                                   placeholder="Last Name"
                                   onChange={this.props.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                                    id="lastName" required={true}/>
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="emailId" bsSize="large">
                            <ControlLabel className="font-weight-bold">Email Id</ControlLabel>
                            <input type="email" className="form-control" value={this.props.user&&this.props.user.emailId}
                                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                   disabled={this.props.disableInput} placeholder="Email Id"
                                   data-x='{"typeMismatch":"Please enter valid email","patternMismatch":"Please enter valid email"}'
                                   onChange={this.props.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                                   id="emailId" required={true} />
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="emailId" bsSize="large">
                            <ControlLabel className="font-weight-bold">Confirm Email Id</ControlLabel>
                            <input type="email" className="form-control" value={this.props.user&&this.props.user.confirmEmailId}
                                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                   disabled={this.props.disableInput} placeholder="Confirm Email Id"
                                   data-x='{"typeMismatch":"Please enter valid email","patternMismatch":"Please enter valid email"}'
                                   onChange={this.handleUserInput.bind(this, this.props.user)}  maxLength={255}
                                   id="confirmEmailId" required={true} />
                            <div className="invalid error"/>
                            <div hidden={!this.emailMatch} className="error">{this.emailMatch}</div>
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="mobileNo" bsSize="large">
                            <ControlLabel className="font-weight-bold">Mobile Number</ControlLabel>
                            <InputMask type="text" maskChar={null} mask="9999999999"
                                data-x='{"patternMismatch":"Invalid Mobile Number"}'
                                required={true} pattern="[0-9]{10}"
                                className="form-control" name="mobileNumber"
                                 id="mobileNumber" placeholder="Mobile Number"
                                value={this.props.user&&this.props.user.mobileNumber}
                                onChange={this.props.handleUserInput.bind(this, this.props.user)}
                           />
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="role" bsSize="large">
                            <ControlLabel className="font-weight-bold">Role</ControlLabel>
                            <Select closeOnSelect={false} isMulti
                                    name="name" value={this.props.roles&&this.props.roles}
                                    onChange={this.props.handleChange}
                                    options={roles} required
                            />
                            <div className="error">
                                {this.props.roleError}</div>
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                {
                    Stores.currentUser.userType.name !== "VGLENS" &&
                    <div className='col-6'>
                        <FormGroup controlId="branch" bsSize="large">
                            <ControlLabel className="font-weight-bold">Branch</ControlLabel>
                            <select className="form-control" value={this.props.user&&this.props.user.branch} required={true}
                                    disabled={this.props.disableInput}
                                    onChange={this.props.handleUserInput.bind(this, this.props.user)} id="branch">
                                <option value={''}>Select Branch</option>
                                { this.branches.map((e, key) => {
                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>; }) }
                            </select>
                            <div className="invalid error"/>
                        </FormGroup>
                     </div>
                }
                </div>
                {
                    this.props.user&&this.props.user.userStatusAudits &&  this.props.user.userStatusAudits.length > 0 &&
                        <div className="row mx-0 mb-3">
                            <div className="col-12 px-0">
                                <div className="row mx-0">
                                    <p className="mb-0 font-weight-bold">Activity Log</p>
                                </div>
                                <div className="row mx-0">
                                {
                                     this.props.user.userStatusAudits.map((comment,index) =>
                                        <p className="mb-0 w-100" key={index}>{comment.status==="ACTIVE"?<span>Active</span>:<span>Inactive</span>} {comment.comment?<span> - {comment.comment}</span>:<React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                     )
                                }
                                </div>
                            </div>
                        </div>
                }
                {
                ((Stores.currentPermission.create) ||(Stores.currentPermission.modify && (this.props.user&&this.props.user.id)))?(
                    <div className='row mx-0'>
                            <div className='col-3 px-0'>
                            </div>
                            <div className='col-4 text-right'>
                                {
                                    this.props.user.status==="ACTIVE"&&
                                    <Button className="btn btn-app-secondary" type="button" onClick={this.props.suspendUser}
                                    > SUSPEND</Button>
                                }
                                {
                                    this.props.user.status==="INACTIVE"&&
                                    <Button className="btn btn-app-secondary" type="button"  onClick={this.props.activateUser}
                                    > ACTIVATE</Button>
                                }
                                <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                            </div>
                            <div className='col-4 px-0'>
                                {   this.props.showSuccess&&
                                    <label className="mb-0 text-success"  style={{fontSize:"13px"}}>Email with instructions to complete the registration has been sent to this user</label>
                                }
                            </div>
                        </div>
                ):
                (<React.Fragment></React.Fragment>)
                }
            </Form>
        )
    }
}

const CreateUserFormComponent = withRouter(CreateUserForm);
export {CreateUserFormComponent as CreateUserForm};
