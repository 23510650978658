import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import moment from 'moment';

@observer
class PrivacyPolicy extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    navigateLogin = () => {
      window.open("https://evdens.com", '_self');
    }

    render() {
        return (
            <div className="privacy-policy">
                <div className="padding-5">
                    <img className="privacy-policy-image" src={require("../../assets/image/ic_evdens_t_new.png")} style={{width:"200px"}} alt="evdens" onClick={() => this.navigateLogin()}/>
                </div>
                <div className="container privacy-policy-content">
                    <h3 className="mb-3">Vglens Solutions Pvt Ltd</h3>
                    <h1 className="mb-4">Privacy Policy</h1>
                    <h6 className="color-default my-3">LAST UPDATE: 13th March 2021</h6>
                    <p className="text-justify">Evdens is a product of Vglens Solutions Pvt Ltd, a private limited company registered in India that has created anti-counterfeiting solution. A User can access and use Evdens by downloading it on their mobile device as an application (“App”). Evdens app is a product authenticity verification tool that can work on your mobile device by communicating with the Evdens server. Anything scanned using Evdens app stays on your device and a part of that is stored in the Evdens server. Vglens Solutions is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using our services, then you can be assured that it will only be used in accordance with this privacy statement. If you have any questions or concerns about our policy, or our practices regarding your personal information, please contact us at info@vglens.net.</p>

                    <h2>WHAT DO WE DO WITH YOUR INFORMATION?</h2>

                    <p>When you verify something using our Application, as part of the process, we collect the personal information you give us such as your name, mobile number. When you use site or mobile application, we may get your location for locating you and the bitmap of your verification/scan for further analytics and improving the services and for additional intelligence to thwart counterfeiting. Email marketing (if applicable): With your permission, we may send you emails about our application, new stores, new products and other updates.</p>
                    <h2>CONSENT</h2>
                    <p>How do you get my consent?</p>
                    <p>When you provide us with personal information to complete a transaction, verifying product authenticity, redeeming a voucher, and while using evdens Site and Mobile Applications we imply that you consent to our collecting it and using it as deemed necessary. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.</p>
                    <p>How do I withdraw my consent?</p>
                    <p>If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at <a href="info@vglens.net"><strong>info@vglens.net</strong></a> or mailing us at: #F8, F Block Door-2, 2nd Main Rd, Anna Nagar, Chennai, Tamil Nadu 600102
                     </p>
                    <h2>DISCLOSURE</h2>
                    <p>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</p>

                    <h2>Links</h2>

                    <p>When you click on links on our mobile application, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>

                    <h2>SECURITY</h2>

                    <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>

                    <h2>COOKIES</h2>

                    <p>We use cookies to maintain session of your user. It is not used to personally identify you on other websites.</p>

                    <h2>AGE OF CONSENT</h2>

                    <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>

                    <h2>CHANGES TO THIS PRIVACY POLICY</h2>

                    <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website/app. If we make material changes to this policy, we may or may not notify you explicitly.</p>

                    <p>If our mobile application is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to allow you to shop at stores.</p>

                    <h2>QUESTIONS AND CONTACT INFORMATION</h2>

                    <p>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at <strong>info@vglens.net </strong>or by mail at: #F8, F Block Door-2, 2nd Main Rd, Anna Nagar
                    Chennai, Tamil Nadu 600102 </p>

                    <p className="mb-5">PLEASE NOTE: IF YOU USE OUR APP, PLATFORM AND/OR OUR SERVICES, YOU HAVE AGREED TO AND ACCEPTED THE PRACTICES DESCRIBED IN THIS PRIVACY POLICY AND THE TERMS AND CONDITIONS SET FORTH IN OUR TERMS OF USE.</p>

                    <p className="text-center">Copyright ©{moment().format('YYYY')} Vglens Solutions Pvt Ltd</p>
                </div>
            </div>
           
        )
     }
}

const PrivacyPolicyComponent = withRouter(PrivacyPolicy);

export {PrivacyPolicyComponent as PrivacyPolicy};
