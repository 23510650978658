import * as React from "react";
import {Modal} from "react-bootstrap";
import {observable} from "mobx";
import {observer} from "mobx-react";

@observer
class ValidationModal extends React.Component<any, {}> {
    @observable open:boolean = false;
    close=()=>{
        this.open = false;
    };

    show=()=>{
        this.open = true;
    };

    render() {
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

        return (
            <Modal show={this.open} onHide={this.close} dialogClassName={"modal-dialog-centered modal-sm top-20"}>
                <Modal.Header className="b-none p-0">
                    {
                        (this.props.showProductImage) ? 
                            <label className="p-3">Product Image</label>
                        : <React.Fragment></React.Fragment>
                    }
                    {
                        (this.props.showEvdensCodes) ? 
                            <label className="p-3">Caution: Do not buy if the code on the product/package is different</label>
                        : <React.Fragment></React.Fragment>
                    }
                    {
                        (this.props.showCustomMessage) ? 
                            <label className="p-3">{this.props.customHeader}</label>
                        : <React.Fragment></React.Fragment>
                    }
                    {
                        (this.props.showProductImage || this.props.showCustomMessage) ? 
                            <button type="button" className="btn ml-auto pb-0" onClick={this.close}>
                                <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                            </button>
                        : <React.Fragment></React.Fragment>
                    }
                    
                </Modal.Header>
                <Modal.Body className="pb-3 pt-0">
                    <div className="text-center">
                        {
                            (this.props.showProductImage) ?
                                <img src={this.props.value} style={{width:"200px"}} alt="Product Image"/>
                            : <React.Fragment></React.Fragment>
                        }
                        {
                            (this.props.showCustomMessage) ?
                                (this.props.value) ? 
                                    <img src={this.props.value} style={{width:"200px"}} alt="Message"/>
                                : 
                                <div>
                                    {renderHTML(this.props.customBody)}
                                </div>    
                            : <React.Fragment></React.Fragment>
                        }
                        {
                            (this.props.showWarranty) ?
                                <div className="pt-3">
                                    <p>You will be redirected to brand's website for warranty registrations</p>
                                    <button className="btn btn-sm btn-app-secondary mr-1" type="button" onClick={this.close}>Cancel</button>
                                    <button className="btn btn-sm btn-app-primary ml-1" type="button" onClick={this.props.submit}>Proceed</button>
                                </div>
                            : <React.Fragment></React.Fragment>
                        }
                        { (this.props.showEvdensCodes) ?
                            this.props.value.map((secondary,index) =>
                                <React.Fragment key={index}>
                                    {
                                        (index == 0) ?
                                             <tr className="row table-body-value">
                                                <td className="col-2 text-center font-weight-bold pr-0 py-1">No</td>
                                                <td className="col-10 text-left font-weight-bold pr-0 py-1"><span>Evdens UID</span></td>
                                            </tr>
                                        : <React.Fragment></React.Fragment>    
                                    }
                                    <tr className="row table-body-value">
                                        <td className="col-2 text-center pr-0 py-1">{index+1}</td>
                                        <td className="col-10 text-left pr-0 py-1"><span>{secondary}</span></td>                                                    
                                    </tr>
                                    {
                                        (index+1 == this.props.value.length) ?
                                            <button className="btn btn-sm btn-app-secondary ml-auto mt-2" type="button" onClick={this.close}>Close</button>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </React.Fragment>
                            )
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                </Modal.Body>        
            </Modal>
        )
    }
}

export {ValidationModal};
