import React from 'react';
import {withRouter} from "react-router-dom";
import {Auth} from 'aws-amplify';
import {observer} from 'mobx-react';
import {observable} from "mobx";
import Form from '../../constants/Form';
import {Credential} from '../../store/Credential';
import {LoadToast, ToastType} from '../../config/Toast';
import eyeVision from "../../assets/image/eye-vision.png";
import eyeVisionHide from "../../assets/image/eye-vision-hide.png";

@observer
class ForgotPassword extends React.Component<any, any> {
    @observable option: string = "sendOTP";
    @observable creantials = new Credential();
    @observable errorMsg: string = '';
    @observable passwordMatch: string = "";
    @observable newPasswordType:boolean = false;
    @observable confirmPasswordType:boolean = false;

    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
    }

    navigateRoute = (url: any) => {
        this.history.push(url)
    };
    changeNewPasswordType =() => {
        this.newPasswordType = ! this.newPasswordType;
    }
    changeConfirmPasswordType =() => {
        this.confirmPasswordType = ! this.confirmPasswordType;
    }
    handleUserInput = (targetObject: any, event: any) => {
        targetObject[event.target.id] = event.target.value;
        if(this.errorMsg){
            this.errorMsg = '';
        }
    };

    sendOTP = async () => {
        this.errorMsg = '';
        await Auth.forgotPassword(this.creantials.email).then(response => {
            this.option = 'resetPassword';
            LoadToast(ToastType.SUCCESS, "OTP has been sent to your email Id");
        }).catch(error => {
            if (error.__type === 'UserNotFoundException' || error.name === 'UserNotFoundException') {
                this.errorMsg = "Username does not exist";
            } else {
                this.errorMsg = error.message;
            }
        });
    };
    resetPassword = async () => {
        this.errorMsg = '';
        if (this.creantials.password !== this.creantials.confirmPassword) {
            this.passwordMatch = "Passwords do not match";
        } else {
            await Auth.forgotPasswordSubmit(this.creantials.email, this.creantials.otp, this.creantials.password).then(response => {
                LoadToast(ToastType.SUCCESS, "Password has been reset successfully");
                this.option = 'login';
            }).catch(error => {
            if (error.__type === 'CodeMismatchException' || error.name === 'CodeMismatchException') {
                this.errorMsg = "Incorrect OTP, please try again";
            } else {
                this.errorMsg = error.message;
            }
            });
        }
    };

    render() {
        return (
            <div className="authentication-wrapper authentication-3">
                <div className="authentication-inner">
                    <div className="d-none d-lg-flex col-lg-8 align-items-center background-bg p-5">
                        <div className="ui-bg-overlay bg-dark opacity-50"></div>
                        <div className="w-100 text-white px-5">
                            <div className="text-large font-weight-light">
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-lg-4 bg-white p-5 align-items-center">
                        <div className="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                            <div className="w-100">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="ui w-100">
                                        <div className="w-100 text-center position-relative">
                                            <img src={require("../../assets/image/ic_evdens_t_new.png")} style={{width: "200px"}}
                                                 alt="logo"/>
                                        </div>
                                        <div className="mt-3">
                                            <h6>Forgot your Password?</h6>
                                        </div>
                                    </div>
                                </div>
                                {
                                (this.option === "resetPassword" || this.option === "sendOTP")?(
                                <Form submit={this.option === "resetPassword" ? this.resetPassword : this.sendOTP}>
                                    <div className="form-group">
                                        <label className="form-label w-100 text-left">Username</label>
                                        <input type="email" className="form-control mb-2" autoComplete={"off"}
                                               name="email" id="email"
                                               value={this.creantials.email} pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                               data-x='{"patternMismatch":"please enter a valid email Id","typeMismatch":"Please enter a valid email Id","valueMissing":"Please fill out this field"}'
                                               onChange={this.handleUserInput.bind(this, this.creantials)}
                                               disabled={this.option === "resetPassword"} required={true}/>
                                        <div className="invalid error pl-3"/>
                                    </div>
                                    {
                                        this.option === "resetPassword" &&
                                        <React.Fragment>
                                            <div className="form-group">
                                                <label className="form-label w-100 text-left">Password</label>
                                                <input type={this.newPasswordType?'text':'password'} value={this.creantials.password}
                                                       autoComplete={"off"} name="password"
                                                       data-x='{"tooShort":"Password length must be between 8 to 15 characters"}' minLength={8} maxLength={15}
                                                        id="password"
                                                       onChange={this.handleUserInput.bind(this, this.creantials)}
                                                       className="form-control" required/>
                                                <img className='eye' src={this.newPasswordType?eyeVisionHide:eyeVision} alt='forgotPassword' height='20px' width='20px' style={{marginRight:'5px'}} onClick={this.changeNewPasswordType} />
                                                <div className="invalid error pl-3"/>
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label w-100 text-left">Confirm Password</label>
                                                <input type={this.confirmPasswordType?'text':'password'}  autoComplete={"off"} name="confirmPassword"
                                                       data-x='{"tooShort":"Password length must be between 8 to 15 characters"}' minLength={8} maxLength={15}
                                                        id="confirmPassword"
                                                       value={this.creantials.confirmPassword}
                                                       onChange={this.handleUserInput.bind(this, this.creantials)}
                                                       className="form-control" required/>
                                                <img className='eye' src={this.confirmPasswordType?eyeVisionHide:eyeVision} alt='forgotPassword' height='20px' width='20px' style={{marginRight:'5px'}} onClick={this.changeConfirmPasswordType} />
                                                <div className="invalid error pl-3">
                                                    <div className="error pl-3">{this.passwordMatch}</div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label w-100 text-left">OTP</label>
                                                <input type="text" autoComplete={"off"} name="otp" 
                                                       id="otp" value={this.creantials.otp}
                                                       onChange={this.handleUserInput.bind(this, this.creantials)}
                                                       className="form-control" required/>
                                                <div className="invalid error pl-3"/>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className="d-flex float-right m-0">
                                        <button type="submit" className="btn btn-primary"> Submit</button>
                                        <button type="button" className="ml-3 btn btn-primary"
                                                onClick={() => this.navigateRoute('/login')}> Back
                                        </button>
                                    </div>
                                </Form>)
                                :(
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <button type="button" className="ml-3 btn btn-primary"
                                                onClick={() => this.navigateRoute('/login')}> Login
                                        </button>
                                    </div>
                                )
                                }
                                <div className="card-footer p-2" style={{borderTop: "none"}} hidden={!this.errorMsg}>
                                    <div className="text-center badge-outline-danger error" style={{boxShadow: 'none'}}>
                                        {this.errorMsg}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const ForgotPasswordComponent = withRouter(ForgotPassword);

export {ForgotPasswordComponent as ForgotPassword};
