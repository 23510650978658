import {Component} from 'react';
import PropTypes from 'prop-types';
import * as React from "react";

class Form extends Component {
    state = {
        isValidated: false,
        checkValidate:false
    }
   
    props:any;
    formEl:any;
    count:number = 0;
    validate = () => {
        this.count = 0;
        const formLength = this.formEl.length;
        if (this.formEl.checkValidity() === false && this.state.checkValidate) {
            for(let i=0; i<formLength; i++) {
                const elem = this.formEl[i];
                const errorLabel = elem.parentNode.querySelector('.invalid');
                if (errorLabel && elem.nodeName.toLowerCase() !== 'button') { 
                    var data = JSON.parse(elem.getAttribute("data-x"));
                    if (!elem.validity.valid) {
                        ++this.count;
                        errorLabel.textContent = elem.validationMessage;
                       // this.count==1?elem.focus():'';
                        if(elem.validity.valueMissing)
                            errorLabel.textContent = (data && data.valueMissing) ? data.valueMissing : "This field is required";
                        else if(elem.validity.typeMismatch)
                            errorLabel.textContent = this.commonErrorMessage(data,"typeMismatch",elem);
                        else if(elem.validity.patternMismatch)
                            errorLabel.textContent = this.commonErrorMessage(data,"patternMismatch",elem);
                        else if(elem.validity.tooShort)
                            errorLabel.textContent = this.commonErrorMessage(data,"tooShort",elem);
                        else if(elem.validity.badInput)
                            errorLabel.textContent = this.commonErrorMessage(data,"badInput",elem);
                        else if(elem.validity.rangeOverflow)
                            errorLabel.textContent = this.commonErrorMessage(data,"rangeOverflow",elem);
                        else if(elem.validity.rangeUnderflow)
                            errorLabel.textContent = this.commonErrorMessage(data,"rangeUnderflow",elem);
                        else if(elem.validity.stepMismatch)
                            errorLabel.tooLong = this.commonErrorMessage(data,"stepMismatch",elem);
                        else if(elem.validity.customError)
                            errorLabel.textContent = this.commonErrorMessage(data,"customError",elem);
                        else if(elem.validity.valid)
                            errorLabel.textContent = this.commonErrorMessage(data,"valid",elem);
                    } else {
                        errorLabel.textContent = '';
                    }
                }
            }
            return false;
        } else {
            const formLength = this.formEl.length;
            for(let i=0; i<formLength; i++) {
                const elem = this.formEl[i];
                const errorLabel = elem.parentNode.querySelector('.invalid');
                if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
                    errorLabel.textContent = '';
                }
            };
            return true;
        }
    }


    reset=()=> {
       
        document.getElementById("form-reset")!.click();
        const formLength = this.formEl.length;
        for(let i=0; i<formLength; i++) {
            const elem = this.formEl[i];
            const errorLabel = elem.parentNode.querySelector('.invalid');
            if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
                errorLabel.textContent = '';
            }
        };
                
    }

    commonErrorMessage (data:any,name:any,elem:any){
        return (data && data[name]) ? data[name] : elem.validationMessage;
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.state.checkValidate= true;
         
        //this.state.checkValidate = true;
        if (this.validate()) {
            this.props.submit();
        }
        this.state.isValidated = true;
    }

    render() {
        const props = this.props;
        let classNames : any;
        if (this.state.isValidated) {
            classNames = classNames+" validated";
        }
        if (props.className) {
            classNames = [...props.className];
            delete props.className;
        }

        return (
            <form autoComplete="off" ref={form => this.formEl = form}  className={classNames}  onSubmit={this.submitHandler} noValidate>
                {this.props.children}
                <button id="form-reset" hidden type="reset"></button>
            </form>
        );
    }

    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string, 
        submit: PropTypes.func.isRequired
    };
}

export default Form;
