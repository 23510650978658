import React from 'react';
import { observer } from 'mobx-react';

@observer
class BrandReferralTableDetails extends React.Component<any, any> {

    render() {
        return(
            <React.Fragment>
                {
                    this.props.brandReferrals && this.props.brandReferrals.map((brandReferral,index)=>
                        <tr key={index} className="row mx-0 text-nowrap table-body-value cursor-pointer" onClick={()=>this.props.viewBrandReferral(brandReferral)} >
                            <td className='col-3 text-center px-0 text-wrap'>{brandReferral.referralId} </td>
                            <td className="col-3 text-center px-0 text-wrap">{brandReferral.mobileNumber}</td>
                            <td className="col-3 px-0 text-center text-wrap">{brandReferral.brandOrCompany}</td>
                            <td className="col-3 px-0 text-left text-wrap">{brandReferral.createdDateConvert}</td>
                        </tr>
                    )
                }
                {
                    this.props.brandReferrals && this.props.brandReferrals.length===0&&
                    <tr >
                        <td className="text-center error" colSpan={7}>No records found ...</td>
                    </tr>
                }
            </React.Fragment>
        )
    }
}


export {BrandReferralTableDetails};
