import {action, observable} from 'mobx';

class CustomerType {
    @observable id:string = '';
    @observable name:string = '';
    @observable status:string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';

    @action
    setCustomerType = (customerType: any) => {
        this.id = customerType.id;
        this.name = customerType.name;
        this.status = customerType.status;
        this.createdBy = customerType.createdBy;
        this.updatedBy = customerType.updatedBy;
        return this;
    }
    @action reset() {
        this.id = '';
        this.name = '';
        this.status = '';
        this.createdBy = '';
        this.updatedBy = '';
    }
}

export {CustomerType};
