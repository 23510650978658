import * as React from "react";
import { observer } from "mobx-react";
import { observable } from 'mobx';
import Form from "../../constants/Form";
import {
  FormGroup,
  Button
} from "react-bootstrap";
import { Product } from '../../store/Product';
import { NumberField } from "../../constants/formfields/NumberField";
import { Datepicker } from "../../constants/formfields/Datepicker";
import { ProductSearch } from './ProductSearch';
import { Stores } from "../../store/Stores";

@observer
class CreateOrderForm extends React.Component<any, any> {

  @observable expectedMinDate: any;
  @observable product: Product = new Product();
  @observable productSearchCom: any;

  constructor(props: any) {
    super(props);
    this.expectedMinDate = new Date();
    this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
  }
  handleUserInput = (targetObject: any, event: any) => {
    targetObject[event.target.id] = event.target.value;
  };

  handleDate = (date) => {
    this.props.orderItem.expectedDeliveryDate = date;
  }

  setSelectedProduct = (product) => {
    this.product = product;
    this.props.orderItem.product = product.id;
    this.props.orderItem.productName = product.name;
  }

  setNewProduct = () => {
    this.product = new Product();
  };

  setproduct = (product) => {
    this.productSearchCom.setProduct && this.productSearchCom.setProduct(product);
  };

  setNewDetail = () => {
    this.setNewProduct();
    this.productSearchCom.clearFilter && this.productSearchCom.clearFilter();
  };


  render() {
    const currentSolution = Stores.currentCustomer.solution;

    return (
      <Form submit={this.props.save}>
        <div className="row">
          <div className="col-3 px-0">
            <div className='col-10 text-center pr-0'>
              <FormGroup controlId="labelSize " bsSize="large">
                <ProductSearch setSelectedProduct={this.setSelectedProduct} setNewProduct={this.setNewProduct} ref={(instance) => { this.productSearchCom = instance; }} />
                {/*  <select className="form-control" value={this.props.orderItem.labelSize}
                    onChange={this.handleUserInput.bind(this, this.props.orderItem)} required={true} id="labelSize">
                    <option value={''}>Select Label Size</option>
                    <option className="select-option" value={'Small'}>Small</option>
                    <option className="select-option" value={'Mini'}>Mini</option>
                </select>
                <div className="invalid error"/> */}
              </FormGroup>
            </div>
          </div>
          <div className="col-2 pr-0">
            <FormGroup controlId="ovd" bsSize="large">
              <select className="form-control" value={this.props.orderItem.ovd}
                onChange={this.handleUserInput.bind(this, this.props.orderItem)} required={true} id="ovd">
                <option value={''}>Select Type</option>
                {currentSolution && (currentSolution == 'evdensDigiSign' || currentSolution == 'Both') && <option className="select-option" value={'WithOVD'}> evdensDigiSign</option>}
                {currentSolution && (currentSolution == 'evdensCodes' || currentSolution == 'Both') && <option className="select-option" value={'WithoutOVD'}> evdensCodes</option>}
              </select>
              <div className="invalid error" />
            </FormGroup>
          </div>

          <div className="col-2 pr-0">
            <FormGroup controlId="quantity" bsSize="large">
              <NumberField maxlength={20} placeholder="Quantity" maxValue={200000}
                required={true} valueName="quantity" stateValue={this.props.orderItem} />
              <span style={{ color: 'grey' }}>Maximum 200K</span>
            </FormGroup>
          </div>
          <div className="col-3">
            <FormGroup controlId="expectedDeliveryDate" bsSize="large">
              <Datepicker value={this.props.orderItem.expectedDeliveryDate} minDate={this.expectedMinDate} inputChangeHandler={this.handleDate} isRequired={true}></Datepicker>
            </FormGroup>
          </div>
          <div className="col-2 px-0 text-center">
            <Button className="btn btn-sm" type="submit">
              <img className="cursor-pointer" alt="add" style={{ width: '13px' }}
                src={require("../../assets/image/addIcon.png")} />
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export { CreateOrderForm };
