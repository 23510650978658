import { observable} from 'mobx';

class ProductNotification   {
    @observable name:string = '';
    @observable customer:string = '';
    @observable customerId:string = '';
    @observable registeredBy:string = '';
    @observable emailId:string = '';
    @observable status:string = '';
    @observable date:Date = new Date();
}

export {ProductNotification};
