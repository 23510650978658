import { observable} from 'mobx';

class EvdensCodeConsumedReportDto   {

    @observable assignedOrAggregated:number = 0;
    @observable scraped:number = 0;
    @observable replaced:number = 0;
    @observable assignedOrAggregatedTill:number = 0;
    @observable scrapedTill:number = 0;
    @observable replacedTill:number = 0;
}

export {EvdensCodeConsumedReportDto};
