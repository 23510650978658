import {action, observable} from 'mobx';
import { PackagingLevel } from './PackagingLevel';


class ValidationRequest {
    @observable qrCode:string = '';
    @observable userId:string = '';
    @observable mobileNumber:string = '';
    @observable country:string = '';
    @observable location:string = '';
    @observable withOVD:boolean = false;
    @observable verified:boolean = false;
    @observable channel:string = '';
    @observable channelName:string = '';
    @observable channelId:string = '';
    @observable userType:string = '';

    @action
    setValidationRequest = (validationRequest: any) => {
        this.qrCode = validationRequest.qrCode;
        this.userId = validationRequest.userId;
        this.mobileNumber = validationRequest.mobileNumber;
        this.country=validationRequest.country;
        this.location = validationRequest.location;
        this.withOVD = validationRequest.withOVD;
        this.verified = validationRequest.verified;
        this.channel = validationRequest.channel;  
        this.channelName = validationRequest.channelName;
        this.channelId = validationRequest.channelId;
        this.userType = validationRequest.userType;
        return this;
    }
    @action reset() {
        this.qrCode             = '';
        this.userId                = '';
        this.mobileNumber          = '';
        this.country               = '';
        this.withOVD               = false;
        this.verified              = false;
        this.channel               = '';
        this.channelName           = '';
        this.channelId             = '';
        this.userType             = '';
    }
}

export {ValidationRequest};
