import {action, observable} from 'mobx';
import { Customer } from './Customer';
import { Branch } from './Branch';
import { OrderItem } from './OrderItem';

class Order   {
    @observable id:string = '';
    @observable orderItems:OrderItem[] =[];
    @observable customer = new Customer();
    @observable status:string = '';
    @observable orderId:string = '';
    @observable orderDate:string = '';
    @observable selected:boolean = false;
    @observable orderStatus:string = '';
    @observable partner:string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';
    @observable branch = new Branch();

    @action
    setOrder= (labelOrder: any) => {
        this.id = labelOrder.id;
        this.orderItems = labelOrder.orderItems;
        this.status = labelOrder.status;
        this.orderId = labelOrder.orderId;
        this.orderDate = labelOrder.orderDate;
        this.orderStatus = labelOrder.orderStatus;
        this.partner = labelOrder.partner;
        this.createdBy = labelOrder.createdBy;
        this.updatedBy = labelOrder.updatedBy;
        this.branch = labelOrder.branch;
        return this;
    }
    @action reset() {
        this.id       = '';
        this.orderItems  = [];
        this.customer = new Customer();
        this.status = '';
        this.orderId = '';
        this.orderDate = '';
        this.orderStatus = '';
        this.partner = '';
        this.createdBy = '';
        this.updatedBy = '';
        this.branch = new Branch();
    }
}

export {Order};
