import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Stores } from '../../store/Stores';
import { CustomerVertical } from '../../store/CustomerVertical';
import { CustomerVerticalForm } from './CustomerVerticalForm';
import { BaseCustomer } from '../../config/HttpClients';
import { CustomerVerticalSearch } from './CustomerVerticalSearch';
import { PartnerTypeTableDetails } from '../partnerType/PartnerTypeTableDetails';

@observer
class CustomerVerticals extends React.Component<any, any> {

    @observable customerVertical= new CustomerVertical();
    @observable customerverticals : CustomerVertical[] =[];

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
    }
    save = () => {
        if(this.customerVertical.id){
            this.updateCustomerVertical(this.customerVertical);
        } else {
            this.createCustomerVertical();
        }
    }

    createCustomerVertical=()=>{
        this.customerVertical.createdBy = Stores.currentUser.id;
        BaseCustomer.post("/customerVertical", this.customerVertical).then(response=>{
           this.setNewCustomerVertical();
        });
    }

    updateCustomerVertical=(customerVertical)=>{
        customerVertical.updatedBy = Stores.currentUser.id;
        BaseCustomer.put("/customerVertical", customerVertical).then(response=>{
           this.setNewCustomerVertical();
        });
    }

    getCustomerVertical=(customerVertical)=>{
        if(customerVertical.customerType&&customerVertical.customerType.id){
            BaseCustomer.get("/customerVertical/customerType?customerType="+customerVertical.customerType.id).then(response=>{
                this.customerverticals = response.data.payload;
            }); 
        }
    }

    setNewCustomerVertical=()=>{
        this.customerVertical = new CustomerVertical();
    }

    setSelectedCustomerVertical=(customerVertical)=>{
        this.customerVertical = customerVertical;
    }

    render() {
        return(
                <div className="col-12 p-0 h-100">
                <div className="pb-2 pt-4 row ml-0 search-bg">
                    <div className='col-6 h-42'>
                        <b style={{letterSpacing:"1px",fontSize:"28px"}}> </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <CustomerVerticalSearch setNewCustomerVertical={this.setNewCustomerVertical} setSelectedCustomerVertical={this.setSelectedCustomerVertical}/>
                        </div>
                    </div>
                </div>
                    <div className="row mx-0 py-3" style={{height:'calc(100% - 75px)'}}>
                        <div className="col-12 h-100">
                            <div className="card b-none h-100">
                                <div className="card-body h-100">
                                    <div className="pt-2 h-100">
                                        <div className="col-12 px-0 h-100">
                                            <CustomerVerticalForm  save={this.save} customerVertical={this.customerVertical} getCustomerVertical={this.getCustomerVertical} />
                                            <div className="row mx-0 mt-2" style={{height:'calc(100% - 75px)'}}>
                                                <div className="col-12 h-100">
                                                    <div>
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr className="row table-header">
                                                                    <th className="col-1 text-nowrap text-center">S.No</th>
                                                                    <th className="col-4 text-nowrap text-center">Industry Vertical</th>
                                                                    <th className="col-4 text-nowrap text-center">Current State</th>
                                                                    <th className="col-3 text-nowrap text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                         </table>
                                                     </div>
                                                     <div className="row custom-scroll" style={{height:'calc(100% - 50px)',overflowY:'auto', overflowX:'hidden'}}>
                                                         <table className="table">
                                                            <tbody>
                                                                <PartnerTypeTableDetails partnerTypes = {this.customerverticals} updatePartnerType = {this.updateCustomerVertical}/>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const CustomerVerticalsComponent = withRouter(CustomerVerticals);
export {CustomerVerticalsComponent as CustomerVerticals};
