import { observable} from 'mobx';

class TradePartnerScannedReportDto   {

    @observable count:number = 0;
    @observable customer:string = '';
    @observable countTill:number = 0;
    @observable customerTill:string = '';

}

export {TradePartnerScannedReportDto};
