import {action, observable} from "mobx";

class Subset {

    @observable id: string = '';
    @observable name: string = '';
    @observable create:boolean = false;
    @observable view:boolean = false;
    @observable modify:boolean = false;
    @observable suspend:boolean = false;

    @action
    setProduct = (type: any) => {
        this.id = type.id;
        this.name = type.name;
        this.create = type.create;
        this.view = type.view;
        this.modify = type.modify;
        this.suspend = type.suspend;
        return this;
    }

    @action reset() {
        this.id = '';
        this.name = '';
        this.create = false;
        this.view = false;
        this.modify = false;
        this.suspend = false;
    }
}
export {Subset};
