import {action, observable} from 'mobx';
import { ProductCategory } from './ProductCategory';
import { ProductAttribute } from './ProductAttribute';
import { ProductPackagingLevel } from './ProductPackagingLevel';
import {StatusAudit} from "./StatusAudit";

class Product {
    @observable id:string = '';
    @observable name:string = '';
    @observable productCode:string = '';
    @observable category:ProductCategory = new ProductCategory();
    @observable subCategory:string = '';
    @observable gtin:string = '';
    @observable imageUrl:string = '';
    @observable fileName:string = '';
    @observable customer:string = '';
    @observable customerName:string = '';
    @observable customerDisplayName:string = '';
    @observable labelType:string = '';
    @observable allowPrimaryDispatch:boolean = false;
    @observable label:string = '';
    @observable value:string = '';
    @observable warranty:boolean = false;
    @observable batchNo:boolean = false;
    @observable manufactureDate:boolean = false;
    @observable expiryDate:boolean = false;
    @observable warrantyDuration:string = '';
    @observable warrantyRegistration:boolean = false;
    @observable warrantyRegistrationUrl:string = '';
    @observable attributes:ProductAttribute[]=[];
    @observable packagingLevels:ProductPackagingLevel[]=[];
    @observable createdBy:string = '';
    @observable updatedBy:string = '';
    @observable brandName:string = '';
    @observable productStatus:string = '';
    @observable createdAt:string = '';
    @observable createdDate:string = '';
    @observable notifyGMD:boolean = false;
    @observable dispatchMust:boolean = false;
    @observable productStatusAudits:StatusAudit[] = [];

    @action
    setProduct = (product: any) => {
        this.id = product.id;
        this.name = product.name;
        this.productCode = product.productCode;
        this.category = product.category;
        this.subCategory = product.subCategory;
        this.brandName = product.brandName;
        this.gtin = product.gtin;
        this.imageUrl = product.imageUrl;
        this.fileName = product.fileName;
        this.customer = product.customer;
        this.customerName = product.customerName;
        this.customerDisplayName = product.customerDisplayName;
        this.labelType = product.labelType;
        this.label = product.label;
        this.batchNo = product.batchNo;
        this.manufactureDate = product.manufactureDate;
        this.expiryDate = product.expiryDate;
        this.warranty = product.warranty;
        this.warrantyDuration = product.warrantyDuration;
        this.warrantyRegistration = product.imageUrl;
        this.warrantyRegistrationUrl = product.warrantyRegistrationUrl;
        this.attributes = product.attributes;
        this.packagingLevels = product.packagingLevels;
        this.createdBy = product.createdBy;
        this.updatedBy = product.updatedBy;
        this.productStatus = product.productStatus;
        this.allowPrimaryDispatch   = product.allowPrimaryDispatch;
        this.notifyGMD = product.notifyGMD;
        this.dispatchMust = product.dispatchMust;
        this.productStatusAudits = product.productStatusAudits;
        return this;
    }
    @action reset() {
        this.id                     = '';
        this.name                   = '';
        this.productCode            = '';
        this.category               = new ProductCategory();
        this.subCategory            = '';
        this.brandName            = '';
        this.gtin                   = '';
        this.imageUrl               = '';
        this.fileName               = '';
        this.customer               = '';
        this.customerName           = '';
        this.customerDisplayName    = '';
        this.labelType              = '';
        this.allowPrimaryDispatch   = false;
        this.label                  = '';
        this.batchNo                = false;
        this.manufactureDate        = false;
        this.expiryDate             = false;
        this.warranty               = false;
        this.warrantyDuration       = '';
        this.warrantyRegistration   = false;
        this.warrantyRegistrationUrl= '';
        this.attributes             = [];
        this.packagingLevels        = [];
        this.createdBy              = '';
        this.updatedBy              = '';
        this.productStatus          = '';
        this.notifyGMD              = false;
        this.dispatchMust           = false;
        this.productStatusAudits    = [];
    }
}

export {Product};
