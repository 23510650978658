import React from 'react';
import {withRouter} from "react-router-dom";
import  { Auth } from 'aws-amplify';
import { observer } from 'mobx-react';
import {observable} from "mobx";
import Form from "../../constants/Form";
import {Credential} from "../../store/Credential";
import eyeVision from "../../assets/image/eye-vision.png";
import eyeVisionHide from "../../assets/image/eye-vision-hide.png";

@observer
class ChangePassword extends React.Component<any, any> {

    history:any;
    @observable userName: string = "";
    @observable credentials = new Credential();
    @observable errorMsg:string= '';
    @observable passwordMatch:string = "";
    @observable oldPasswordType:boolean = false;
    @observable newPasswordType:boolean = false;
    @observable confirmPasswordType:boolean = false;

    constructor(props:any) {
        super(props);
        this.history = props.history;
    }

    navigateRoute = (url:any) =>{
        this.history.push(url)
    };

   handleUserInput = (targetObject:any, event:any) => {
       this.errorMsg = '';
        targetObject[event.target.id] = event.target.value;
        if(event.target.id === "confirmPassword") {
            this.checkConfirmPassword();
        }
    };

    changeOldPasswordType =() => {
        this.oldPasswordType = ! this.oldPasswordType;
    }
    changeNewPasswordType =() => {
        this.newPasswordType = ! this.newPasswordType;
    }
    changeConfirmPasswordType =() => {
        this.confirmPasswordType = ! this.confirmPasswordType;
    }

    checkConfirmPassword = ()=>{
        this.passwordMatch = '';
        if(this.credentials.password !== this.credentials.confirmPassword) {
            this.passwordMatch = "Passwords do not match";
        }
    };

    changePassword(credentials) {
        this.credentials = credentials;
        if(this.credentials.confirmPassword) {
            if(this.credentials.password !== this.credentials.confirmPassword){
                this.passwordMatch = "Passwords do not match";
            }
            Auth.currentAuthenticatedUser().then(user => {
                Auth.changePassword(user, this.credentials.oldPassword, this.credentials.confirmPassword).then(response=>{
                    Auth.signOut();
                    this.navigateRoute('/login');
                }).catch(error=>{
                    if(error.code === 'NotAuthorizedException') {
                        this.errorMsg = "Incorrect old password";
                    } else {
                        this.errorMsg = error.message;
                    }
                });
            });
        }
    }

    render() {
        return(
            <div className="pt-3">
                <div className="col-12 px-0">
                    <div className="card b-none">
                        <div className="card-body">
                            <div className='col-12'>
                                 <Form submit={()=>this.changePassword(this.credentials)}>
                                     <div className="row form-group">
                                         <label className="col-3 text-left font-weight-bold">Old Password</label>
                                         <div className='col-5'>
                                             <input type={this.oldPasswordType?'text':'password'} autoComplete={"off"} name="oldPassword" minLength={8}
                                                    data-x='{"tooShort":"Minimum of 8 characters"}' id="oldPassword" value={this.credentials.oldPassword}
                                                    onChange={this.handleUserInput.bind(this,this.credentials)}
                                                    className="col-10 form-control" required={true} placeholder="Old Password"/>
                                             <img className='eye' src={this.oldPasswordType?eyeVisionHide:eyeVision} alt='Profile' height='20px' width='20px' style={{marginRight:'55px'}} onClick={this.changeOldPasswordType} />
                                             <div className="invalid error "/>
                                         </div>
                                     </div>
                                      <div className="row form-group">
                                          <label className="col-3 text-left font-weight-bold">New Password</label>
                                          <div className='col-5'>
                                              <input type={this.newPasswordType?'text':'password'} autoComplete={"off"} name="password" minLength={8} maxLength={15}
                                                     data-x='{"tooShort":"Password length must be between 8 to 15 characters"}' id="password" value={this.credentials.password}
                                                     onChange={this.handleUserInput.bind(this,this.credentials)}
                                                     className="col-10 form-control" required={true} placeholder="New Password"/>
                                               <img className='eye' src={this.newPasswordType?eyeVisionHide:eyeVision} alt='Profile' height='20px' width='20px' style={{marginRight:'55px'}} onClick={this.changeNewPasswordType} />
                                              <div className="invalid error "/>
                                          </div>
                                      </div>
                                      <div className="form-group row">
                                          <label className="col-3 text-left font-weight-bold">Confirm New Password</label>
                                          <div className='col-5'>
                                              <input type={this.confirmPasswordType?'text':'password'} autoComplete={"off"} name="confirmPassword" minLength={8} maxLength={15}
                                                     data-x='{"tooShort":"Password length must be between 8 to 15 characters"}' value={this.credentials.confirmPassword}
                                                     id="confirmPassword" onChange={this.handleUserInput.bind(this,this.credentials)}
                                                     className="col-10 form-control" required={true}  placeholder="Confirm New Password"/>
                                              <img className='eye' src={this.confirmPasswordType?eyeVisionHide:eyeVision} alt='Profile' height='20px' width='20px' style={{marginRight:'55px'}} onClick={this.changeConfirmPasswordType} />
                                              <div className="invalid error "/>
                                              <div hidden={!this.passwordMatch} className="error">{this.passwordMatch}</div>
                                          </div>
                                      </div>
                                     <div className="row">
                                         <div className="col-3 "></div>
                                         <div className='col-8'>
                                              <button type="submit" className="btn btn-app-primary"> SUBMIT</button>
                                         </div>
                                     </div>
                                     <div className="card-footer p-2" style={{borderTop: "none"}} hidden={!this.errorMsg}>
                                         <div className="text-center badge-outline-danger error" style={{boxShadow: 'none'}}>
                                             {this.errorMsg}
                                         </div>
                                     </div>
                                  </Form>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        );
    }


}

const ChangePasswordComponent = withRouter(ChangePassword);
export {ChangePasswordComponent as ChangePassword}