import {action, observable} from 'mobx';
import {Feature} from "./Feature";

class Persona {

    @observable id:string = '';
    @observable name:string = '';
    @observable features:Feature[] = [];

    @action
    setProduct = (type: any) => {
        this.id = type.id;
        this.name = type.name;
        this.features = type.features;
        return this;
    }

    @action reset() {
        this.id = '';
        this.name = '';
        this.features    = [new Feature()];
    }
}

export {Persona};
