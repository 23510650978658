import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Stores } from '../../store/Stores';
import { PartnerType } from '../../store/PartnerType';
import { BaseCustomer } from '../../config/HttpClients';
import { PartnerTypeForm } from './PartnerTypeForm';
import { PartnerTypeSearch } from './PartnerTypeSearch';
import { PartnerTypeTableDetails } from './PartnerTypeTableDetails';

@observer
class PartnerTypes extends React.Component<any, any> {

    @observable partnerType = new PartnerType();
    @observable partnerTypes: PartnerType[] = [];

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.getPartnerTypes();
    }
    save = () => {
        if(this.partnerType.id){
            this.updatePartnerType(this.partnerType);
        } else {
            this.createPartnerType();
        }
    }

    createPartnerType=()=>{
        if(this.partnerTypes.length > 0){
            this.partnerType.partnerTypeOrder = this.partnerTypes.length+5;
        } else {
            this.partnerType.partnerTypeOrder = 5;
        }
        this.partnerType.createdBy = Stores.currentUser.id;
        this.partnerType.vglensPartner = 'CUSTOMER';
        BaseCustomer.post("/partnerType", this.partnerType).then(response=>{
           this.setNewPartnerType();
        });
    }

    updatePartnerType=(partnerType)=>{
        partnerType.updatedBy = Stores.currentUser.id;
        BaseCustomer.put("/partnerType", partnerType).then(response=>{
           this.setNewPartnerType();
        });
    }

    getPartnerTypes = () => {
        let type = 'null';
        let status = 'all'
        BaseCustomer.get("/partnerType?userType=CUSTOMER&type="+type+"&status="+status).then(response => {
            this.partnerTypes = response.data.payload;
        });
    }

    setNewPartnerType=()=>{
        this.partnerType = new PartnerType();
        this.getPartnerTypes();
    }

    setSelectedPartnerType=(partnerType)=>{
        this.partnerType = partnerType;
    }

    render() {
        return(
                <div className="col-12 p-0 h-100">
                <div className="pb-2 pt-4 row ml-0 search-bg">
                    <div className='col-6 h-42'>
                        <b style={{letterSpacing:"1px",fontSize:"28px"}}> </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <PartnerTypeSearch setSelectedPartnerType={this.setSelectedPartnerType} setNewPartnerType={this.setNewPartnerType}/>
                        </div>
                    </div>
                </div>
                    <div className="row mx-0 py-3" style={{height:'calc(100% - 75px)'}}>
                        <div className="col-12 h-100">
                            <div className="card b-none h-100">
                                <div className="card-body h-100">
                                    <div className="pt-2 h-100">
                                        <div className="col-12 px-0 h-100">
                                            <PartnerTypeForm  save={this.save} partnerType={this.partnerType} />
                                            <div className="row mx-0 mt-2" style={{height:'calc(100% - 75px)'}}>
                                                <div className="col-12 h-100">
                                                    <div>
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr className="row table-header">
                                                                    <th className="col-1 text-nowrap text-center">S.No</th>
                                                                    <th className="col-4 text-nowrap text-center">Partner Type</th>
                                                                    <th className="col-4 text-nowrap text-center">Current State</th>
                                                                    <th className="col-3 text-nowrap text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                         </table>
                                                     </div>
                                                     <div className="row custom-scroll" style={{height:'calc(100% - 50px)',overflowY:'auto', overflowX:'hidden'}}>
                                                         <table className="table">
                                                            <tbody>
                                                                <PartnerTypeTableDetails partnerTypes = {this.partnerTypes} updatePartnerType = {this.updatePartnerType}/>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const PartnerTypesComponent = withRouter(PartnerTypes);
export {PartnerTypesComponent as PartnerTypes};
