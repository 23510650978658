import {action, observable} from 'mobx';

class StatusAudit {
    @observable status:string = '';
    @observable comment:string = '';
    @observable updatedUser:string = '';
    @observable updatedBy:string = '';
    @observable createdAt:any;

    @action
    setStatusAudit = (statusAudit: any) => {
        this.status = statusAudit.status;
        this.comment = statusAudit.comment;
        this.updatedUser = statusAudit.updatedUser;
        this.updatedBy = statusAudit.updatedBy;
        return this;
    }

}

export {StatusAudit};
