import * as React from "react";
import { observer } from "mobx-react";
import { strictAlphabets } from "../CommonMethods";

@observer
class NumberField extends React.Component<any, any> {

    onlyNumber = event => {
        strictAlphabets(event);
    };

    handleTypeNumber = (stateValue, length, event) => {
        stateValue[event.target.id] = event.target.value.length <= length ? event.target.value : stateValue[event.target.id];
    };

    render() {
        return (
        <React.Fragment>
                <input type="number" className="form-control" id={this.props.valueName}
                       name={this.props.valueName} placeholder={this.props.placeholder}
                       required={this.props.required ? this.props.required : false}
                       disabled={this.props.disabled ? this.props.disabled : false}
                       onKeyPress={this.onlyNumber.bind(this)}
                       onChange={this.handleTypeNumber.bind(this, this.props.stateValue, this.props.maxlength)}
                       pattern="^[1-9]\d*$"
                       min="1"
                       max={this.props.maxValue}
                       data-x='{"patternMismatch":"Please enter valid"}'
                       value={this.props.stateValue && this.props.stateValue[this.props.valueName]}/>
                <div className="invalid error"></div>
            </React.Fragment>
        )
    }
}
export {NumberField};