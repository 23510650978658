import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Role } from '../../store/Role';
import { BaseUser } from '../../config/HttpClients';
import { CreateProfileForm } from './CreateProfileForm';
import { Permission } from '../../store/Permission';
import { Feature } from '../../store/Feature';
import { PermissionsForm } from './PermissionsForm';
import { Stores } from '../../store/Stores';
import {getLoggedInUser} from "../../constants/CommonMethods";
import {User} from "../../store/User";
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";

@observer
class CreateProfile extends React.Component<any, any> {
    
    @observable role:Role = new Role();
    @observable deleteRoleObj:Role = new Role();
    @observable roles:Role[] = [];
    @observable existingPermissions:Permission[] = [];
    currentUserPermissions:Permission[] = [];
    @observable features:Feature[] = [];
    @observable permissions:Permission[] = [];
    @observable referencePermissions:Permission[] = [];
    @observable selectedRole:boolean = false ;
    @observable user:User = new User();
    @observable errorAlertModal:any;

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        if(Stores.currentUser.userName === "") {
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        this.getRoles();
        this.getRoleFeatures();
    }

    getRoles=()=>{
        if(Stores.currentUser.id) {
            if(Stores.currentUser&&Stores.currentUser.userType&&Stores.currentUser.userType.name === "VGLENS") {
                BaseUser.get("/role/vglens/"+Stores.currentUser.id).then(response=>{
                    this.roles = response.data.payload;
                });
            } else {
                BaseUser.get("/role/customer/"+Stores.currentUser.customer).then(response=>{
                    this.roles = response.data.payload;
                });
            }
        }
    }

    getRoleFeatures=()=>{
        let roles:string[] = [];
        Stores.currentUser.roles.map(role=>{
            roles.push(role.id);
            return true;
        })
        this.getUserRolePermissions(roles)
    }

    getMobileFeatureByPersona=()=>{
        // this.permissions = [];
        // BaseUser.get("/feature/persona?name="+Stores.currentUser.persona.name).then(response=>{
        //     this.features = response.data.payload;
        //     this.features.forEach(feature=>{
        //         let permission = new Permission();
        //         permission.feature = feature;
        //         this.permissions.push(permission);
        //     });
        // });
    }

    handleWebPermissions=()=>{
        //this.permissions = Object.assign([], this.referencePermissions);
    }

    getUserRolePermissions=(roles)=>{
        BaseUser.get("/permission/roles?roles="+roles).then(response=>{
            this.permissions = response.data.payload;
            this.referencePermissions = response.data.payload;
            this.currentUserPermissions = Object.assign([], response.data.payload);
            this.setPermissionStatus(this.currentUserPermissions);
            this.setPermissionStatus(this.referencePermissions);
            this.setPermissionStatus(this.permissions);
        });
    }

    setSelectedRole=(role,event)=>{
        this.role = role;
        this.getRolePermissions(this.role.id);
    }

    setPermissionStatus=(permissions)=>{
        permissions.map(permission=>{
            if(!permission.create){
                delete permission.create;
            } else {
                permission.create = false;
            }
            if(!permission.view){
                delete permission.view;
            } else {
                permission.view = false;
            }
            if(!permission.modify){
                delete permission.modify;
            } else {
                permission.modify = false;
            }
            if(!permission.suspend){
                delete permission.suspend;
            } else {
                permission.suspend = false;
            }
            return true;
        });
    }

    getRolePermissions=(roleId)=>{
        this.referencePermissions = [];
        this.referencePermissions = [...this.currentUserPermissions];
        BaseUser.get("/permission/role?id="+roleId).then(response=>{
            this.existingPermissions = response.data.payload;
            if(this.existingPermissions.length>0) {
                for(var i=0;i<this.referencePermissions.length; i++) {
                    for(var j=0;j<this.existingPermissions.length; j++) {
                        if(this.existingPermissions[j].feature.id === this.referencePermissions[i].feature.id) {
                            if(this.existingPermissions[j].feature.mobile){
                                if (this.existingPermissions[j].create) {
                                    this.referencePermissions[i].create = this.existingPermissions[j].create;
                                }
                                if (this.existingPermissions[j].view) {
                                    this.referencePermissions[i].view = this.existingPermissions[j].view;
                                }
                                if (this.existingPermissions[j].modify) {
                                    this.referencePermissions[i].modify = this.existingPermissions[j].modify;
                                }
                                if (this.existingPermissions[j].suspend) {
                                    this.referencePermissions[i].suspend = this.existingPermissions[j].suspend;
                                }
                            } else {
                                for (var k = 0; k < this.referencePermissions[i].feature.subset.length; k++) {
                                    if (this.existingPermissions[j].subset.id === this.referencePermissions[i].feature.subset[k].id) {
                                        if (this.existingPermissions[j].create) {
                                            this.referencePermissions[i].feature.subset[k].create = this.existingPermissions[j].create;
                                        }
                                        if (this.existingPermissions[j].view) {
                                            this.referencePermissions[i].feature.subset[k].view = this.existingPermissions[j].view;
                                        }
                                        if (this.existingPermissions[j].modify) {
                                            this.referencePermissions[i].feature.subset[k].modify = this.existingPermissions[j].modify;
                                        }
                                        if (this.existingPermissions[j].suspend) {
                                            this.referencePermissions[i].feature.subset[k].suspend = this.existingPermissions[j].suspend;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.permissions = Object.assign([], this.referencePermissions);
            } else {
                this.getRoleFeatures();
            }
        });
    }

    updatePermissions=()=> {
        let permissions:Permission[] = [];
        this.permissions.map((selectedPermission)=> {
            if(selectedPermission.feature.mobile){
                if(selectedPermission.create || selectedPermission.modify || selectedPermission.view || selectedPermission.suspend) {
                    let permission = new Permission();
                    permission.feature = selectedPermission.feature;
                    permission.role = this.role;
                    delete permission.subset;
                    permission.create = selectedPermission.create;
                    permission.view = selectedPermission.view;
                    permission.modify = selectedPermission.modify;
                    permission.suspend = selectedPermission.suspend;
                    permissions.push(permission);
                }
            } else {
                selectedPermission.feature.subset.map((subset)=> {
                    if(subset.create || subset.modify || subset.view || subset.suspend) {
                        let permission = new Permission();
                        delete permission.id;
                        permission.feature = selectedPermission.feature;
                        permission.role = this.role;
                        permission.subset = subset;
                        permission.create = subset.create;
                        permission.view = subset.view;
                        permission.modify = subset.modify;
                        permission.suspend = subset.suspend;
                        permissions.push(permission);
                    }
                    return true;
                });
            }
           return true;
        });
        if(permissions.length > 0){
            this.savePermission(permissions);
        }
    }
    setSelectedUser=(user)=>{
        this.user = user;
        this.roles = user.roles;
        this.setRole(this.roles);
    }

    setRole=(roles)=>{
        roles.map((role)=>{
            role.value = role.id;
            role.label = role.name;
            return true;
        });
    }
    savePermission= (permissions) => {
        BaseUser.post("/permission",permissions).then(response=>{
            this.role= new Role();
        });
    }

    deleteRole=()=> {
        BaseUser.delete("/role?id="+this.deleteRoleObj.id).then(response=>{
            this.errorAlertModal.close();
            this.role = new Role();
            this.deleteRoleObj =  new Role();
            this.getRoles();
        });
    }

    deleteRoleAlert=(role)=> {
        this.deleteRoleObj = role;
        this.errorAlertModal.show();
    }

    render() {
        return(
                <div className="col-12 p-0">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='h-42'></div>
                    {/*<div className='col-6  h-42'>*/}
                    {/*    <b style={{letterSpacing:"1px",fontSize:"28px"}}>Search </b>*/}
                    {/*</div>*/}
                    {/*<div className='col-6'>*/}
                    {/*    <div className='float-right'>*/}
                    {/*        <UserSearch setSelectedUser={this.setSelectedUser}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                    <div className="row mx-0 pl-0 pt-3">
                        <div className="col-6 pr-0">
                            <div className="card b-none">
                                <div className="card-body">                                   
                                    <div className="pt-2">
                                        <div className="row mx-0">
                                            <CreateProfileForm getRoles={this.getRoles}/>
                                        </div>
                                        <div className="row mx-0 mt-3 pt-2" style={{borderTop:"1px solid rgba(112, 112, 112, 0.38)"}}>
                                        {
                                            this.roles&&this.roles.map((role,key)=>
                                            <div className="col-12 custom-control custom-radio" key={role.id}>
                                                <div className='row mx-2'>
                                                    <div className='col-9'>
                                                        <input type="radio" checked={this.role&&this.role.id===role.id}  onChange={this.setSelectedRole.bind(this, role)}
                                                               className="custom-control-input " name="example1" id={'customRadio'+key} />
                                                        <label className="custom-control-label cursor-pointer details-value" htmlFor={"customRadio"+key}> {role.name}</label>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='float-right'>
                                                        <img  style={{width: '13px',cursor:'pointer'}}
                                                              alt="deleteRole" onClick={() => this.deleteRoleAlert(role)}
                                                              src={require("../../assets/image/delete.jpg")}/></div>
                                                    </div>
                                                </div>

                                          </div>
                                            )
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body px-2">                              
                                    <div className="pt-2">
                                    {
                                        this.role.id&&
                                        <PermissionsForm permissions={this.permissions} updatePermissions={this.updatePermissions} getMobileFeatureByPersona={this.getMobileFeatureByPersona}
                                                         handleWebPermissions={this.handleWebPermissions}/>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ErrorAlertModal  ref={instance=>{this.errorAlertModal=instance}} submit={this.deleteRole}  />
                </div>
        )
    }
}

const CreateProfileComponent = withRouter(CreateProfile);
export {CreateProfileComponent as CreateProfile};
