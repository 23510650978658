import React from 'react';
import { observer } from 'mobx-react';
import {observable} from "mobx";
import {FormGroup,ControlLabel} from "react-bootstrap";
import {LoadToast, ToastType} from "../../config/Toast";

@observer
class UploadImage extends React.Component<any, any> {

    @observable isFileTooLarge:boolean = false;
    @observable fileInput:any;

    handleInputFile = (files, event) => {
        this.props.setFilesEmpty();
        this.handleFileObject(event.target.files, files)
    };

     handleFileObject = (data, files) => {
        let dataObj = data.length !== 0 ? this.handleFile(data) : {};
           Object.keys(dataObj).map(key => {
               files[key] = data[key];
               return true;
           });
           if(!this.isFileTooLarge){
               this.props.getImage();
           }
     };

     handleFile = (files) => {
           this.isFileTooLarge = false;
           if (this.checkImageType(files)) {
               LoadToast(ToastType.WARN, "File Type is not allowed ,Fotmats jpeg, jpg, png");
           } else {
               if (!this.checkFileSize(files)) {
                   this.isFileTooLarge = true;
                   return {};
               }
               return files;
           }
           return {};
     };

     checkImageType = (files) => {
           let data;
           let allowedExtension = ['jpeg', 'jpg', 'png'];
           Object.keys(files).map(key => {
               data = allowedExtension.filter((data) => {
                   if (Object.keys(files).length !== 0) {
                       return files[key].type.includes(data);
                   }
                   return false;
               });
               return true;
           });
           return data.length === 0;
     };

     checkFileSize = (files) => {
           let fileSize = 0;
           Object.keys(files).map(key => {
               fileSize += files[key].size;
               return true;
           });
           return fileSize < 102400;
     };

    render() {
        return(
            <div className="row mx-0">
                <div className="col-12">
                    <div className="text-center w-100" style={{border:'2px solid #CBCBCB',height:'180px'}}>
                    {
                        this.props.imgUrl&&
                            <img  src={this.props.imgUrl} style={{maxHeight:'176px',maxWidth:'100%',}} alt="product"/>
                    }
                    </div>
                    <div className="col-6 mt-2" hidden={this.props.disabled ? true : false}>
                    <FormGroup controlId="logo" bsSize="large">
                        <ControlLabel className="font-weight-bold">Upload Image</ControlLabel>
                        <label htmlFor="file-upload" className="btn button-grey-color mb-0">
                            {this.props.files.length > 0 ? 'Change File' : 'Browse File'}
                        </label>
                        <input id="file-upload" accept=".png, .jpg, .jpeg" type="file" name="files"
                            ref={instance => {this.fileInput = instance}}
                            onChange={this.handleInputFile.bind(this, this.props.files)}  />
                        <div className="invalid error"/>
                    </FormGroup>
                    {
                        this.isFileTooLarge&&
                        <p className="mb-0 error"> File is too large</p>
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export {UploadImage};
