import {action, observable} from 'mobx';
import { Feature } from './Feature';
import { Role } from './Role';
import {Subset} from "./Subset";

class Permission {

    @observable id:string = '';
    @observable feature:Feature = new Feature();
    @observable subset:Subset = new Subset();
    @observable create:boolean = false;
    @observable view:boolean = false;
    @observable modify:boolean = false;
    @observable suspend:boolean = false;
    @observable enabled:boolean = false;
    @observable role:Role = new Role();
    @observable expanded:boolean = false;

    @action.bound
    setPermission = (permission: any) => {
        this.id = permission.id;
        this.feature = permission.feature;
        this.subset = permission.subset;
        this.create = permission.create;
        this.view = permission.view;
        this.modify = permission.modify;
        this.suspend = permission.suspend;
        this.role   = permission.role;
        this.enabled= permission.enabled;
	    this.expanded= permission.expanded;
        return this;
    }
    @action reset() {
        this.id = '';
        this.feature = new Feature();
        this.subset = new Subset();
        this.create = false;
        this.view = false;
        this.modify = false;
        this.suspend = false;
        this.role = new Role();
        this.expanded = false;
    }
}

export {Permission};
