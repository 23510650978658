import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {BaseLabelRead, BaseProduct} from "../../config/HttpClients";
import {Pagination} from "../../constants/pagination/Pagination";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {DateToStringFormat, getLoggedInUser, viewDateTimeFormat} from "../../constants/CommonMethods";
import {Stores} from "../../store/Stores";
import {Customer} from "../../store/Customer";
import {Product} from "../../store/Product";
import {Feedback} from "../../store/Feedback";
import {CustomerSearch} from "../customer/CustomerSearch";
import {FeedbackTableDetails} from "./FeedbackTableDetails";


@observer
class FeedBacks extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable paginationFormat: any;
    @observable customer: Customer = new Customer();
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    @observable feedBacks: Feedback[] = [];
    @observable products: Product[]= [];
    @observable product:Product = new Product();
    @observable selectedRatings:any = [];
    @observable showCustomer:boolean = true;
    history: any;
    ratings= [{label:'1 star',value:1},{label:'2 star',value:2},{label:'3 star',value:3},{label:'4 star',value:4},{label:'5 star',value:5}];
    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init()
        }
    }

    init = () => {
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        if(Stores.currentUser&&Stores.currentUser.userType.name === "CUSTOMER") {
            this.showCustomer = false;
            this.customer = Stores.currentCustomer;
        } else {
            this.showCustomer = true;
        }
        this.getFeedbacks();
    }

    getFeedbacks = () => {
        if (!this.customer.id) {
            this.customer.id = 'null';
        }
        if (!this.product.id) {
            this.product.id = 'null';
        }
        let ratings:any=[];
        if(this.selectedRatings&&this.selectedRatings.length>0){
            this.selectedRatings.map(rating=>{
                ratings.push(rating.value);
                return true;
            })
        }
        let products:any=[];
        if(this.products&&this.products.length>0){
            this.products.map(rating=>{
                products.push(rating.value);
                return true;
            })
        }
        BaseLabelRead.get("/feedback?pageNo=" + this.pageNo + "&limit=" + this.limit + "&fromDate=" + this.fromDateValue + "&toDate=" + this.toDateValue + "&customer=" + this.customer.id +"&products="+products+"&ratings="+ratings).then(response => {
            this.feedBacks = response.data.payload;
            this.feedBacks.forEach((notification) => {
                notification.createdDateConvert = viewDateTimeFormat(notification.createdDate);
            });
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
        });
    }

    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
        this.pageNo = 0;
        this.getFeedbacks();
    };
    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
        this.pageNo = 0;
        this.getFeedbacks();
    }

    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
        this.pageNo = 0;
        this.getFeedbacks();
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getFeedbacks();
    }

    setSelectedCustomer=(customer)=>{
        this.customer = new Customer();
        this.customer.reset();
        this.customer = customer;
        this.getFeedbacks();
    }

    setNewCustomer=()=>{
        this.customer = new Customer();
        this.getFeedbacks();
    }

    handleRatings=(ratings)=>{
        this.selectedRatings = ratings;
        this.getFeedbacks();
    }

    navigate = (url: any) => {
        this.history.push(url);
    };

    searchProduct=(event,callback)=>{
        if(!event){
            callback([]);
        }
        if(event) {
            if(!this.customer.id){
                this.customer.id = 'null';
            }
            BaseProduct.get("/product/searchByName?name="+event+'&customerId='+this.customer.id+'&status=All').then(response =>{
                let products:any = [];
                response.data.payload.map(product=>{products.push({label:product.name,value:product.id});return true;});
                callback(products);
            });
        }
    }

    handleSelectProduct = (event) => {
        this.products = event;
        this.getFeedbacks();
    }

    handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    render() {
        return (
            <div className="col-12 py-3 h-100">
                <div className="row mx-0 h-100">
                    <div className="col-12 h-100 px-0">
                        <div className="card b-none h-100">
                            <div className="card-body h-100 px-2">
                                <div className='row mx-0 mb-1'>
                                    <div className='col-3 pl-0'>{this.showCustomer&&<span>Customer Name</span>}</div>
                                    <div className='col-6 pl-0'></div>
                                    <div className='col-3 pl-0'>
                                        <div className='float-right'> Items per page</div>
                                    </div>
                                </div>
                                <div className='row mx-0 mb-2'>
                                    <div className="col-3 pl-0">
                                        {this.showCustomer &&
                                        <CustomerSearch setSelectedCustomer={this.setSelectedCustomer}
                                                        setNewCustomer={this.setNewCustomer}/>
                                        }
                                    </div>
                                    <div className='col-6'></div>
                                    <div className='col-3 pr-0'>
                                        <div className='col-6 px-0 float-right'>
                                            <select className="form-control"
                                                    value={this.limit}
                                                    id="customer"
                                                    onChange={this.handlePageLimit.bind(this, this.limit)}
                                                    required={true}>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mx-0 mb-1'>
                                    <div className='col-3 pl-0'>From Date</div>
                                    <div className='col-3 pl-0'>To Date</div>
                                    <div className='col-3 px-0'>Rating</div>
                                    <div className='col-3 pr-0'>Product Name</div>
                                </div>
                                <div className='row mx-0 mb-2'>
                                    <div className='col-3 pl-0'>
                                        <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleFromDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className='col-3 pl-0'>
                                        <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleToDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className='col-3 px-0'>
                                        <Select closeOnSelect={false}
                                                isClearable
                                                isMulti
                                                name="name"
                                                value={this.selectedRatings}
                                                onChange={this.handleRatings}
                                                options={this.ratings} required
                                        />
                                    </div>
                                    <div className='col-3 pr-0'>
                                        <AsyncSelect
                                            isClearable
                                            isMulti
                                            closeOnSelect={false}
                                            value={this.products}
                                            onChange={this.handleSelectProduct}
                                            loadOptions={this.searchProduct.bind(this)}
                                            onInputChange={this.handleInputChange}
                                        />
                                    </div>
                                </div>
                                {
                                    this.totalRecords > 0 &&
                                    <div className="row mx-0">
                                        <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                    </div>
                                }
                                <div className="row mx-0 mt-2" style={{height: 'calc(100% - 126px)'}}>
                                    <div className="col-12 h-100 px-0">
                                        <div className="row mx-0 h-100 custom-scroll" style={{overflowY:'auto'}}>
                                            <table className="table m-0 w-100" style={{tableLayout:'fixed'}}>
                                                <thead>
                                                <tr className="table-header" >
                                                    <th className="text-center px-0 text-wrap" style={{width:'12%'}}>Feedback Id</th>
                                                    <th className="text-center px-0 text-wrap" style={{width:'13%'}}>Consumer Identity</th>
                                                    <th className="text-center px-0 text-wrap" style={{width:'12%'}}>Scan Location</th>
                                                    <th className="text-center" style={{width:'13%'}}>Date</th>
                                                    <th className="pl-0 text-center" style={{width:'12%'}}>Rating</th>
                                                    <th className="px-0 text-center" style={{width:'12%'}}>Evdens UID</th>
                                                    <th className="text-center px-0 text-wrap" style={{width:'13%'}}>Product Name</th>
                                                    <th className="text-center px-0 text-wrap" style={{width:'13%'}}>Feedback</th>
                                                </tr>
                                                </thead>
                                                <tbody className="custom-scroll" style={{ height: 'calc(100% - 98px)',overflowY:'auto'}}>
                                                    <FeedbackTableDetails feedbacks={this.feedBacks} />
                                                </tbody>
                                            </table>
                                        </div>
                                        {/*<div className="row mx-0 custom-scroll" style={{*/}
                                        {/*    marginTop: '-2px',*/}
                                        {/*    height: 'calc(100% - 98px)',*/}
                                        {/*    overflowY: 'auto',*/}
                                        {/*    overflowX: 'hidden',*/}
                                        {/*    tableLayout:'fixed'*/}
                                        {/*}}>*/}
                                        {/*    <table className="table">*/}
                                        {/*        <tbody>*/}
                                        {/*            <FeedbackTableDetails feedbacks={this.feedBacks} />*/}
                                        {/*        </tbody>*/}
                                        {/*    </table>*/}
                                        {/*</div>*/}
                                        <Pagination totalRecords={this.totalRecords}
                                                    displayRecords={this.limit} pageNo={this.pageNo}
                                                    partialPageCount={5}
                                                    handleChangePage={this.handleChangePage}
                                                    ref={ref => {
                                                        this.paginationFormat = ref
                                                    }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const FeedBacksComponent = withRouter(FeedBacks);
export {FeedBacksComponent as FeedBacks};
