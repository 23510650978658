import {action, observable} from 'mobx';
import {PartnerType} from './PartnerType';
import {Customer} from './Customer';

class CustomerPartnerType {
    @observable partnerType = new PartnerType();
    @observable partnerOfCustomer = new Customer();

    @action
    setCustomerPartnerType = (customerPartnerType: any) => {
        this.partnerType = customerPartnerType.partnerType;
        this.partnerOfCustomer = customerPartnerType.partnerOfCustomer;
        return this;
    }
    @action reset() {
        this.partnerType = new PartnerType();
        this.partnerOfCustomer = new Customer() ;
    }
}

export {CustomerPartnerType};
