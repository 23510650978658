import React from 'react';
import {withRouter} from "react-router-dom";
import { FormGroup, ControlLabel,Button, ButtonToolbar } from 'react-bootstrap';
import { observer } from 'mobx-react';
import Form from '../../constants/Form';
import { CustomSwitch } from '../../constants/formfields/CustomSwitch';
import { NumberField } from '../../constants/formfields/NumberField';
import { Stores } from '../../store/Stores';
import {viewDateTimeFormat} from "../../constants/CommonMethods";

@observer
class ProductDetailForm extends React.Component<any, any> {

    history:any;  

    constructor(props: any) {
         super(props);
         this.history = props.history;
    }
    
    handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
    };

    handleNotifyGMD = event => {
        this.props.productDetail.notifyGMD = !this.props.productDetail.notifyGMD;
    };

    handleWarrenty = event => {
        this.props.productDetail.warranty = !this.props.productDetail.warranty;
    };

    handleInprintLabel = (productDetail,event) => {
        productDetail.labelType = event.target.value;
    };

    handleLabel = (productDetail,event) => {
        productDetail.label = event.target.value;
    };

    handleWarrantyRegistration= event => {
        this.props.productDetail.warrantyRegistration = !this.props.productDetail.warrantyRegistration;
    };

    setPrimaryDispatch=()=>{
        this.props.productDetail.allowPrimaryDispatch = false;
    }

    setEnableWarranty=()=>{
        this.props.productDetail.warrantyDuration = "";
        this.props.productDetail.warrantyRegistration = false;
        this.props.productDetail.warrantyRegistrationUrl = "";
    }

    setEnableWarrantyRegistration=()=>{
        this.props.productDetail.warrantyRegistrationUrl = "";
    }

    render() {
        return (
                <Form submit={this.props.save} >
                   
                    <div className='row'>
                        <div className='col-6'>
                            <FormGroup controlId="attributes" bsSize="large">
                                <ControlLabel className="w-100 font-weight-bold">Product Attributes</ControlLabel>
                                <button type="button" onClick={()=>this.props.setOption('attribute')} className="btn btn-sm button-grey-color">View/Modify</button>
                                <div className="invalid error"/>
                            </FormGroup>
                        </div>
                        <div className='col-6'>
                            <FormGroup controlId="packagingLevels" bsSize="large">
                                <ControlLabel className="w-100 font-weight-bold">Packaging Levels</ControlLabel>
                                <button type="button" onClick={()=>this.props.setOption('pacageLevel')} className="btn btn-sm button-grey-color">View/Modify</button>
                                <div className="invalid error"/>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-9 my-auto'>
                            <ControlLabel className="w-100 mb-0 font-weight-bold">Is Dispatch Must?</ControlLabel>
                        </div>
                        <div className='col-3 my-auto pl-0'>
                            <CustomSwitch inputId={'dispatchMust'} change={this.setPrimaryDispatch}
                                          inputName={'dispatchMust'} inputOnName={'Yes'} inputOffName={'No'}
                                          inputValue={this.props.productDetail} disabled={this.props.disableInput}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-9 my-auto'>
                            <ControlLabel className="w-100 mb-0 font-weight-bold">Allow Dispatch of Primary Products?</ControlLabel>
                        </div>
                        <div className='col-3 my-auto pl-0'>
                            <CustomSwitch inputId={'allowPrimaryDispatch'} disabled={!this.props.productDetail.dispatchMust || this.props.disableInput}
                                          inputName={'allowPrimaryDispatch'} inputOnName={'Yes'} inputOffName={'No'}
                                          inputValue={this.props.productDetail} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-9 my-auto'>
                            <ControlLabel className="w-100 mb-0 font-weight-bold">Notify Consumer on Grey Market Product?</ControlLabel>
                        </div>
                        <div className='col-3 my-auto pl-0'>
                            <CustomSwitch inputId={'notifyGMD'} disabled={!Stores.currentCustomer.trackGreyMarket || this.props.disableInput}
                                          inputName={'notifyGMD'} inputOnName={'Yes'} inputOffName={'No'}
                                          inputValue={this.props.productDetail}/>
                        </div>
                    </div>
                    <div className='row mx-0 mt-3'>
                        <ControlLabel className="mb-0 inner-header">Show to Consumer?</ControlLabel>
                        <div className='col-12 p-2' style={{border:'1px solid #e8e6e6'}}>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="row">
                                    <div className='col-9 my-auto'>
                                        <ControlLabel className="mb-0 font-weight-bold">Batch/Lot Number</ControlLabel>
                                    </div>
                                    <div className='col-3 my-auto px-0'>
                                        <CustomSwitch inputId={'batchNo'} inputName={'batchNo'} inputOnName={'Yes'}
                                         inputOffName={'No'} inputValue={this.props.productDetail} disabled={this.props.disableInput}/>
                                    </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="row">
                                    <div className='col-9 my-auto'>
                                        <ControlLabel className="mb-0 font-weight-bold">Manufacturing Date</ControlLabel>
                                    </div>
                                    <div className='col-3 px-0 my-auto'>
                                            <CustomSwitch inputId={'manufactureDate'} inputName="manufactureDate" disabled={this.props.disableInput}
                                             inputOnName={'Yes'} inputOffName={'No'} inputValue={this.props.productDetail}/>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                </div>
                                <div className='col-6'>
                                    <div className="row">
                                    <div className='col-9 my-auto'>
                                        <ControlLabel className="mb-0 font-weight-bold">Expiry Date</ControlLabel>
                                    </div>
                                    <div className='col-3 my-auto px-0'>
                                        <CustomSwitch inputId={'expiryDate'} inputName={'expiryDate'} inputOnName={'Yes'} inputOffName={'No'}
                                            inputValue={this.props.productDetail} disabled={this.props.disableInput}/>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mx-0 my-3'>
                        <ControlLabel className="mb-0 inner-header">Warranty Details</ControlLabel>
                        <div className='col-12 p-2' style={{border:'1px solid #e8e6e6'}}>
                            <div className='row'>
                                <div className='col-6 my-auto'>
                                    <ControlLabel className="mb-0 font-weight-bold">Warranty Applicable?</ControlLabel>
                                </div>
                                <div className='col-6 px-0 my-auto'>
                                    <CustomSwitch inputId={'warranty'} change={this.setEnableWarranty}
                                                  inputName="warranty" inputOnName={'Yes'} inputOffName={'No'}
                                                  inputValue={this.props.productDetail} disabled={this.props.disableInput}/>
                                </div>
                            </div>
                            {this.props.productDetail.warranty===true&&
                                <React.Fragment>
                            <div className='row'>
                                <div className='col-6 my-auto'>
                                    <ControlLabel className="w-100 mb-0 font-weight-bold">Warranty Duration in Months</ControlLabel>
                                </div>
                                <div className='col-2 my-auto pl-0'>
                                    <NumberField maxlength={2} disabled={this.props.disableInput}
                                                     required={true}  valueName="warrantyDuration" stateValue={this.props.productDetail}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 my-auto'>
                                    <ControlLabel className="mb-0 font-weight-bold">Enable Warranty Registration?</ControlLabel>
                                </div>
                                <div className='col-6 my-auto px-0'>
                                    <CustomSwitch inputId={'warrantyRegistration'} change={this.setEnableWarrantyRegistration}
                                                  inputName={'warrantyRegistration'} inputOnName={'Yes'} inputOffName={'No'}
                                                  inputValue={this.props.productDetail} disabled={this.props.disableInput}/>
                                </div>
                            </div>
                                    { this.props.productDetail.warrantyRegistration===true&&
                                    <div className='row'>
                                            <div className='col-6'>
                                                <ControlLabel className="w-100 font-weight-bold">Warranty Registration URL</ControlLabel>
                                            </div>
                                            <div className='col-6 pl-0'>
                                                <input type="text" className="form-control"
                                                       value={this.props.productDetail.warrantyRegistrationUrl}
                                                       onChange={this.handleUserInput.bind(this, this.props.productDetail)}
                                                       maxLength={255} disabled={this.props.disableInput}
                                                       id="warrantyRegistrationUrl" required={true}/>
                                                <div className="invalid error"/>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    {
                        this.props.productDetail&&this.props.productDetail.productStatusAudits.length>0&&
                        <div className="row mx-0">
                            <div className='col-12 px-0'>
                                <FormGroup controlId="attributes" bsSize="large">
                                    <ControlLabel className="w-100 font-weight-bold">Activity Log</ControlLabel>
                                    {
                                        this.props.productDetail.productStatusAudits.map((comment,index) =>
                                            <p className="mb-0 w-100" key={index}>{comment.status}  {comment.comment?<span> - {comment.comment}</span>:<React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                        )
                                    }
                                </FormGroup>
                            </div>
                        </div>
                    }
                   {
                       (Stores.currentPermission.modify && this.props.productDetail&&this.props.productDetail.id&& !this.props.disableInput)?(
                        <ButtonToolbar className="justify-content-center">
                            {
                                (this.props.productDetail.productStatus==="Approved" || this.props.productDetail.productStatus==="Activated" )&&
                                <Button className="btn btn-app-secondary" type="button" onClick={this.props.suspendProduct}
                                > SUSPEND</Button>
                            }
                            {
                                this.props.productDetail.productStatus==="Suspended"&&
                                <Button className="btn btn-app-secondary" type="button"  onClick={this.props.activateProduct}
                                > ACTIVATE</Button>
                            }
                            <Button className="btn btn-app-primary ml-2"  type="submit">SUBMIT</Button>
                        </ButtonToolbar>
                         ):(
                         <React.Fragment></React.Fragment>
                         )
                    }
                </Form>
        )
     }
}

const ProductDetailFormComponent = withRouter(ProductDetailForm);
export {ProductDetailFormComponent as ProductDetailForm};
