import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Form from '../../constants/Form';
import { SocailMediaLink } from '../../store/SocialMediaLink';


@observer
class SocialMediaForm extends React.Component<any, any> {

    @observable link: SocailMediaLink = new SocailMediaLink();
    @observable links: any;
    @observable selectedSocialMedia: any = [];
    constructor(props: any) {
        super(props);
        this.links = props.details || [];
        this.selectedSocialMedia = this.links.map(link => link.value);
    }

    addSocialMedia = () => {
        this.links.push(this.link);
        this.selectedSocialMedia = this.links.map(link => link.value);
        this.link = new SocailMediaLink();
    }

    onSocialMediaTyeChangeHandler = (event) => {
        this.link.value = event.target.value;
    }

    onLinkChangeHandler = (link, event) => {
        link.link = event.target.value;
    }
    onDeleteLinkClickHandler = (link) => {
        this.links.splice(this.links.indexOf(link), 1);
        this.selectedSocialMedia = this.links.map(item => item.value);
    }

    render() {
        return (
            <div className="row mx-0">
                <div className="col-12 px-0">
                    <div className="header">
                        <h5>Social Media Links</h5>
                    </div>
                    <div>
                        <div className="row mx-0">
                            <div className="col-5 pl-4">
                                <p><b>Social Media Name</b></p>
                            </div>
                            <div className="col-5 pl-1">
                                <p><b>Link</b></p>
                            </div>
                            <div className="col-2 pl-0">
                                <p><b>Add/Remove</b></p>
                            </div>
                        </div>
                        {
                            (this.links || []).map((link, key) =>
                                <div className="row mx-0 mt-2" key={key}>
                                    <div className="col-5 pl-0">
                                        <input
                                            type="text"
                                            style={{ width: 150, height: 32 }}
                                            value={link.value}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-5 pl-1">
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            maxLength={255}
                                            value={link.link}
                                        />
                                    </div>
                                    <div className="col-2 pl-0">
                                        <img className="cursor-pointer p-2"
                                            style={{ width: '28px' }}
                                            onClick={() => this.onDeleteLinkClickHandler(link)}
                                            src={require("../../assets/image/removeIcon.png")}
                                            alt="remove"
                                        />
                                    </div>
                                </div>
                            )}
                        <div>
                            <Form submit={this.addSocialMedia}>
                                <div className="row mx-0 mt-2">
                                    <div className="col-5 pl-0">
                                        <select required={true} style={{ width: 150, height: 32 }} value={this.link.value} onChange={this.onSocialMediaTyeChangeHandler}>
                                            <option value={''}>select</option>
                                            <option disabled={this.selectedSocialMedia.includes('Website')} value={'Website'}>Website</option>
                                            <option disabled={this.selectedSocialMedia.includes('Facebook')} value={'Facebook'}>Facebook</option>
                                            <option disabled={this.selectedSocialMedia.includes('LinkedIn')} value={'LinkedIn'}>LinkedIn</option>
                                            <option disabled={this.selectedSocialMedia.includes('Twitter')} value={'Twitter'}>Twitter</option>
                                            <option disabled={this.selectedSocialMedia.includes('Instagram')} value={'Instagram'}>Instagram</option>
                                            <option disabled={this.selectedSocialMedia.includes('Youtube')} value={'Youtube'}>Youtube</option>
                                        </select>
                                        <div className="invalid error" />
                                    </div>
                                    <div className="col-5 pl-0">
                                        <input type="text" className="form-control"
                                            maxLength={255} value={this.link.link}
                                            onChange={this.onLinkChangeHandler.bind(this, this.link)}
                                            id="value" required={true} />
                                        <div className="invalid error" />
                                    </div>
                                    <div className="col-2 pl-0">
                                        <button type="submit" style={{
                                            background: "transparent",
                                            border: 0
                                        }}>
                                            <img className="cursor-pointer"
                                                style={{ width: '13px' }}
                                                src={require("../../assets/image/addIcon.png")}
                                                alt="add" />
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export { SocialMediaForm };
