import {action, observable} from 'mobx';

class SocailMediaLink {
    @observable value:string = '';
    @observable link:string = '';

    @action
    setProductAttribute = (productAttribute: any) => {
        this.value = productAttribute.id;
        this.link = productAttribute.label;
        return this;
    }
    
    @action reset() {
        this.value = '';
        this.link = '';
    }
}

export {SocailMediaLink};
