export default {
	cognito: {
		REGION: 'AP_SOUTH_1',
        USER_POOL_ID: 'ap-south-1_NfcTLLUJd',
		APP_CLIENT_ID: '6h0k5mehrffaott3386que58r6',
	},
	aws:{
		ACCESSKEYID: 'AKIATGQZB4BKJZUSGRGM',
		SECRETACCESSKEY: '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',
		REGION: 'AP_SOUTH_1'
	},
	bucket:{
		bucketName: 'generated-label'
	}
};
