import * as React from "react";
import { Modal } from "react-bootstrap";
import { observable } from "mobx";
import { observer } from "mobx-react";

const ErrorMessage = {
    '0': "UID does not exist",
    '1': "Invalid or Not your UID",
    '7': "UID not in assigning state",
    '3': "Already Dispatched",
    '10': "Already Assigned"
};

@observer
class ScrapConfirmModal extends React.Component<any, {}> {
    @observable open: boolean = false;
    @observable hasSuccessMessage: boolean = false;
    @observable message: string = '';

    close = () => {
        this.open = false;
    };

    show = () => {
        this.hasSuccessMessage = false;
        this.open = true;
    };
    showSuccess = (message) => {
        this.hasSuccessMessage = true;
        this.message = message || 'UID Assigned successfully';
        this.open = true;
    }

    render() {
        const { scrapDetails } = this.props;

        if (!scrapDetails) {
            return null;
        }

        const { payload, labels, message } = scrapDetails;
        const hasError = !!message; // "Invalid label"

        if (this.hasSuccessMessage) {
            return (
                <Modal
                    show={this.open}
                    onHide={this.close}
                    dialogClassName={"modal-dialog-centered modal-md"}
                >
                    <Modal.Body className="p-2 pt-3 text-center">
                        <h6>{this.message}</h6>
                    </Modal.Body>
                    <Modal.Footer className="p-1 pb-3 text-center b-none d-flex justify-content-center">
                        <button className="btn btn-sm btn-app-secondary" type="button" onClick={this.close}>OK</button>
                    </Modal.Footer>
                </Modal>
            )
        }

        return (
            <Modal
                show={this.open}
                onHide={this.close}
                dialogClassName={"modal-dialog-centered modal-md"}>
                {
                    hasError ?
                        <Modal.Body className="p-2 pt-3">
                            <div className="p-2 d-flex flex-column align-items-center">
                                <h5>{`Label  ${labels[Object.keys(labels)[0]][0]}`}</h5>
                                <h6>{ErrorMessage[Object.keys(labels)[0]]}</h6>
                            </div>
                        </Modal.Body> :

                        <Modal.Body className="p-2 pt-3">
                            <div className="p-2 d-flex flex-column align-items-center">
                                <li className="d-flex w-25 justify-content-between"><h6 className="m-0 w-80">{`Total    :`}</h6> {payload.totalCount}</li>
                                <li className="d-flex w-25 justify-content-between"><h6 className="m-0 w-80">{'Assigned :'}</h6> {payload.assignedCount}</li>
                                <li className="d-flex w-25 justify-content-between"><h6 className="m-0 w-80">{'Scrapped :'}</h6> {payload.scrapCount}</li>
                            </div>
                            <div className="text-center">
                                <h5 className={"custom-alert-text"}>Do you want to continue?</h5>
                            </div>
                        </Modal.Body>
                }
                <Modal.Footer className="p-1 pb-3 text-center b-none d-flex justify-content-center">
                    {!hasError && <button className="btn btn-sm btn-app-primary" type="button" onClick={this.props.submit}>Yes</button>}
                    <button className="btn btn-sm btn-app-secondary" type="button" onClick={this.close}>Cancel</button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export { ScrapConfirmModal };
