import { observable} from 'mobx';

class LabelGenerateDto  {
    @observable itemId:string = '';
    @observable customerId:string = '';
    @observable customerName:string = '';
    @observable partnerId:string = '';
    @observable partnerName:string = '';
    @observable partnerUserId:string = '';
    @observable partnerUserFirstName:string = '';
    @observable partnerUserLastName:string = '';
    @observable productId:string = '';
    @observable productName:string = '';
    @observable orderId:string = '';
    @observable currentBranchCountry:string = '';
    @observable currentBranchId:string = '';
    @observable currentBranchName:string = '';
    @observable userId:string = '';
    @observable firstName:string = '';
    @observable lastName:string = '';
    @observable assignMode:string = '';
}

export {LabelGenerateDto};
