import React from 'react';
import { observer } from 'mobx-react';
import { Tabs, Tab} from 'react-bootstrap';
import { MobilePermissionForm } from './MobilePermissionForm';
import {WebPermissionForm} from "./WebPermissionForm";

@observer
class PermissionsForm extends React.Component<any, any> {

    render() {

        return(
            <Tabs defaultActiveKey="web" id="uncontrolled-tab-example" >
                <Tab eventKey="web" title="Web">
                   <WebPermissionForm permissions={this.props.permissions} updatePermissions={this.props.updatePermissions} />
                </Tab>
                <Tab eventKey="mobile" title="Mobile">
                    <MobilePermissionForm permissions={this.props.permissions} updatePermissions={this.props.updatePermissions} getMobileFeatureByPersona={this.props.getMobileFeatureByPersona}
                                          />
                </Tab>

            </Tabs>

        )
    }
}


export {PermissionsForm};
