import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {PackagingLevel} from '../../store/PackagingLevel';
import {ProductPackagingLevel} from '../../store/ProductPackagingLevel';
import {BaseProduct} from '../../config/HttpClients';
import {NumberField} from '../../constants/formfields/NumberField';
import Form from '../../constants/Form';


@observer
class PackagingLevelForm extends React.Component<any, any> {
    @observable packagingLevel: ProductPackagingLevel = new ProductPackagingLevel();
    @observable activePackagingLevels: PackagingLevel[] = [];
    @observable packagingLevels: PackagingLevel[] = [];
    @observable isExistPackage: boolean = false;
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.getPackagingLevels();
        this.getActivePackagingLevels();
    }

    getPackagingLevels = () => {
        BaseProduct.get("/packagingLevel").then(response => {
            this.packagingLevels = response.data.payload;
        });
    }

    getActivePackagingLevels = () => {
        BaseProduct.get("/packagingLevel/active").then(response => {
            this.activePackagingLevels = response.data.payload;
        });
    }

    deleteProductPackagingLevelById = (id, index) => {
        BaseProduct.delete("/product/packagingLevel/" + id).then(response => {
            this.props.packagingLevels.splice(index, 1);
        });
    }
    addProductPackagingLevel = () => {
        this.props.packagingLevels.push(this.packagingLevel);
        this.packagingLevel = new ProductPackagingLevel();
        this.packagingLevel.reset();

    }

    checkPackageLevelIsExist = (packagingLevels, newPackageLevel) => {
        if (packagingLevels.length >= 1) {
            let found = packagingLevels.some(packagingLevel => packagingLevel.packagingLevel && packagingLevel.packagingLevel.id === newPackageLevel.packagingLevel.id);
            if (found) {
                this.isExistPackage = true;
                return true;
            } else {
                this.isExistPackage = false;
                return false;
            }
        }
    }

    deleteProductPackagingLevel = (index) => {
        if (this.props.packagingLevels[index].id) {
            this.deleteProductPackagingLevelById(this.props.packagingLevels[index].id, index);
        } else {
            this.props.packagingLevels.splice(index, 1);
        }
    }

    handleUserInput = (targetObject: any, event: any) => {
        targetObject[event.target.id] = event.target.value;
    };

    handlePackagingLevelSizeFixed = (event: any) => {
        this.packagingLevel.levelFixed = !this.packagingLevel.levelFixed;
    }

    handleUserPackagingLevelSizeFixed = (index: any, event: any) => {
        this.props.packagingLevels[index][event.target.id] = !this.props.packagingLevels[index][event.target.id];
    }

    handleAddedPackagingLevel = (productPackagingLevel, event) => {
        let productPackageLevel = {packagingLevel: {id: ''}}
        productPackageLevel.packagingLevel = {id: event.target.value};
        if (!this.checkPackageLevelIsExist(this.props.packagingLevels, productPackageLevel)) {
            productPackagingLevel.packagingLevel = {id: event.target.value};
        }

    };

    render() {
        return (
            <div className="row mx-0">
                <div className="col-12 px-0" style={{height: '300px', overflow: 'auto'}}>
                    <div className="header">
                        <h5>Packaging Levels</h5>
                    </div>
                    <div>
                        <div className="row mx-0">
                            <div className={"col-2 pl-0"}><b>Seq #</b></div>
                            <div className={this.props.disabled ? "col-4 pl-0" : "col-4 pl-0"}>
                                <p className="mb-0"><b>Packaging Level</b></p>
                            </div>
                            <div className={this.props.disabled ? "col-3 pl-2" : "col-2 pl-0"}>
                                <p className="mb-0"><b># Units</b></p>
                            </div>
                            <div className={this.props.disabled ? "col-3 pl-0" : "col-2 pl-0"}>
                                <p className="mb-0"><b>Units Fixed?</b></p>
                            </div>
                            <div className="col-2 px-0" hidden={this.props.disabled ? true : false}>
                                <p className="mb-0"><b>Add/ Remove</b></p>
                            </div>
                        </div>
                        {
                            this.props.packagingLevels.map((packagingLevel, key) =>
                                <div className="row mx-0 mt-2" key={key}>
                                    <div className="col-1 text-center pl-0 font-size-13"
                                    >{packagingLevel.order ? packagingLevel.order : key + 1}</div>
                                    <div className={this.props.disabled ? "col-5 pl-0" : "col-5 pl-0"}>
                                        <select className="form-control" disabled={this.props.disabled ? true : false}
                                                value={packagingLevel.packagingLevel && packagingLevel.packagingLevel.id}
                                                onChange={this.handleAddedPackagingLevel.bind(this, this.props.packagingLevels[key])}
                                                required={true} id="partner">
                                            <option value={''}>Select</option>
                                            {this.packagingLevels.map((e, key) => {
                                                return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                            })
                                            }
                                        </select>
                                    </div>
                                    <div className={this.props.disabled ? "col-3 pl-2" : "col-2 pl-0 pr-1"}>
                                        <NumberField maxlength={4} disabled={this.props.disabled}
                                                     placeholder="Unit" required={true} valueName="unit"
                                                     stateValue={this.props.packagingLevels[key]}/>
                                    </div>
                                    <div
                                        className={this.props.disabled ? "col-3 pl-0 custom-control custom-checkbox" : "col-2 pl-0 custom-control custom-checkbox"}>
                                        <input type="checkbox" id="levelFixed"
                                               className="custom-control-input ml-3 mt-2"
                                               checked={packagingLevel.levelFixed} disabled={this.props.disabled}
                                               onChange={this.handleUserPackagingLevelSizeFixed.bind(this, key)}/>
                                    </div>
                                    <div className="col-2 px-0" hidden={this.props.disabled ? true : false}>
                                        <img className="cursor-pointer p-2" style={{width: '28px'}} alt="remove"
                                             onClick={() => this.deleteProductPackagingLevel(key)}
                                             src={require("../../assets/image/removeIcon.png")}/>

                                    </div>
                                </div>
                            )}
                        <div hidden={this.props.disabled ? true : false}>
                            <Form submit={this.addProductPackagingLevel}>
                                <div className="row mx-0 mt-2">
                                    <div className={"col-1 pl-0"}
                                    ></div>
                                    <div className="col-5 pl-0">
                                        <select className="form-control"
                                                value={this.packagingLevel.packagingLevel && this.packagingLevel.packagingLevel.id}
                                                onChange={this.handleAddedPackagingLevel.bind(this, this.packagingLevel)}
                                                required={true} id="partner">
                                            <option value={''}>Select Level</option>
                                            {this.activePackagingLevels.map((e, key) => {
                                                return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                            })}
                                        </select>
                                        <div className="invalid error"/>
                                    </div>
                                    <div className="col-2 pl-0 pr-1">
                                        <NumberField maxlength={3}
                                                     placeholder="Unit" required={true} valueName="unit"
                                                     stateValue={this.packagingLevel}/>
                                    </div>
                                    <div className="col-2 pl-0 custom-control custom-checkbox">
                                        <input type="checkbox" checked={this.packagingLevel.levelFixed}
                                               className="custom-control-input ml-3 mt-2"
                                               onChange={this.handlePackagingLevelSizeFixed.bind(this)}
                                        />
                                    </div>
                                    <div className="col-2 px-0">
                                        <button type="submit" style={{
                                            background: "transparent",
                                            border: 0
                                        }}><img className="cursor-pointer" style={{width: '13px'}} alt="add"
                                                src={require("../../assets/image/addIcon.png")}/></button>
                                    </div>

                                </div>
                            </Form>
                        </div>
                        {
                            this.isExistPackage === true &&
                            <div className="error">
                                Packaging level already exist.
                            </div>
                        }
                    </div>

                    {/* <ButtonToolbar className="justify-content-end">
                        <Button className="btn button-sec-color btn-sm" type="button"> Update</Button>
                    </ButtonToolbar> */}
                </div>
            </div>
        )
    }
}


export {PackagingLevelForm};
