import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {Order} from "../../store/Order";
import {OrderItemTableDetails} from "./OrderItemTableDetails";

@observer
class OrderTableDetails extends React.Component<any, any> {
    @observable order:Order = new Order();

    showOrderDetails(order) {
        this.order = order;
    }
    closeOrderDetails() {
        this.order = new Order();
    }

    handleSelection=(labelOrder,event)=>{
        labelOrder.selected = !labelOrder.selected;
        let isAllSelected = this.props.orders.some(order=>order.selected === false);
        if(isAllSelected){
            this.props.changeSelectAll(false);
        } else {
            this.props.changeSelectAll(true);
        }
    }

    render() {
        return(
            <tbody>
            {
                this.props.orders && this.props.orders.map((order,index)=>
                    <React.Fragment key={index}>
                        <tr key={index} className="row mx-0 text-nowrap table-body-value"
                            style={this.order&&this.order.id !== order.id ? {paddingTop:'3px'}:{}}>
                            <td className="col-1 pl-0 custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input ml-3 mt-2" value={order.selected}
                                       checked={order.selected} onChange={this.handleSelection.bind(this,order)}/>
                            </td>
                            <td className="col-2 text-center">{order.orderDate}</td>
                            <td className="col-4 text-center">{order.customer.name}</td>
                            <td className="col-3 text-center">{order.orderId}</td>
                            <td className={'col-2 text-center'}>
                                {
                                    this.order && this.order.id !== order.id &&
                                    <img className="cursor-pointer" style={{width:'13px'}} onClick={() => this.showOrderDetails(order)} alt="add"
                                         src={require("../../assets/image/addIcon.png")}/>
                                }
                                {
                                    this.order&&this.order.id  === order.id&&
                                    <img className="cursor-pointer p-2" alt="remove" style={{width:'28px'}} onClick={()=>this.closeOrderDetails()} src={require("../../assets/image/removeIcon.png")}/>

                                }
                            </td>
                        </tr>

                        {
                            this.order && this.order.id === order.id  &&
                            <tr className="row mx-2 table-header" >
                                <td className='col-12'
                                    style={{padding: '.65rem'}}>
                                    <div className='row mx-0 text-nowrap mb-1'>
                                        {/* <div className='col-3 font-weight-bold text-center'> Size</div> */}
                                        {/* <div className='col-3 font-weight-bold text-center'> Product</div> */}
                                        <div className='col-3 font-weight-bold text-center'>Evdens UID Type</div>
                                        <div className='col-3 font-weight-bold text-center'>Quantity</div>
                                        <div className='col-3 font-weight-bold text-center'>Expected Delivery Date</div>
                                    </div>
                                </td>
                            </tr>

                        }
                        {
                            this.order && this.order.id === order.id  &&
                            <OrderItemTableDetails orderItems={order.orderItems}/>
                        }
                    </React.Fragment>
                )
            }
            {
                this.props.orders && this.props.orders.length===0&&
                <tr >
                <td className="text-center error" colSpan={7}>No orders found</td>
                </tr>
            }

            </tbody>
        )
    }
}


export {OrderTableDetails};
