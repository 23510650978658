import {action, observable} from 'mobx';

class ProductCategory {
    @observable id:string = '';
    @observable name:string = '';
    @observable vertical:string = '';
    @observable customerType:string = '';
    @observable status:string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';

    @action
    setProductCategory = (productCategory: any) => {
        this.id = productCategory.id;
        this.name = productCategory.name;
        this.status = productCategory.status;
        this.createdBy = productCategory.createdBy;
        this.updatedBy = productCategory.updatedBy;
        this.vertical = productCategory.vertical;
        this.customerType = productCategory.customerType;
        return this;
    }
    @action reset() {
        this.id = '';
        this.name = '';
        this.status = '';
        this.createdBy = '';
        this.updatedBy = '';
        this.vertical = '';
        this.customerType = '';
    }
}

export {ProductCategory};
