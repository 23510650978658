import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Form from '../../constants/Form';
import {Product} from '../../store/Product';
import {CustomMessageDetail} from '../../store/CustomMessageDetail';
import { ProductSearch } from '../product/ProductSearch';

@observer
class CustomMessageDetailForm extends React.Component<any, any> {
    @observable isExistProduct: boolean = false;
    @observable isProductReq:boolean = false;
    @observable product:Product = new Product();
    @observable customMessageDetail: CustomMessageDetail = new CustomMessageDetail();
    @observable productSearchCom:any;
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
    }

    addCustomMessageDetail = () => {
        if(!this.product.id) {
            this.isProductReq = true;
            return;
        }
        if((this.props.customMessageDetails&& this.props.customMessageDetails.length < 5) || this.props.customMessageDetails === null ) {
            if(this.props.customMessageDetails === null){
                this.props.setNewCustomMessageDetail();
                setTimeout(() => {
                    this.props.customMessageDetails.push(this.customMessageDetail);
                    this.setNewDetail();
                }, 1);
            } else {
                if(!this.checkProductIsExist(this.props.customMessageDetails,this.customMessageDetail)) {
                    this.props.customMessageDetails.push(this.customMessageDetail);
                    this.setNewDetail();
                }
            }
        }
    }

    deleteCustomMessageDetail = (detailIndex) => {
        this.props.customMessageDetails.splice(detailIndex,1);
    }

    setSelectedProduct=(product)=>{
        this.product = product;
        this.customMessageDetail.product = product.id;
        this.customMessageDetail.productName = product.name;
        this.isProductReq = false;
    }

    setNewProduct=()=>{
        this.product = new Product();
    }

    setNewDetail=()=>{
        this.customMessageDetail = new CustomMessageDetail();
        this.setNewProduct();
        this.productSearchCom.clearFilter&&this.productSearchCom.clearFilter();
    }

    handleUserInput = (targetObject: any, event: any) => {
        targetObject[event.target.id] = event.target.value;
    };

    checkProductIsExist = (customMessageDetails, newCustomMessageDetail) => {
        if (customMessageDetails.length >= 1) {
            let found = customMessageDetails.some(customMessageDetail => customMessageDetail.product === newCustomMessageDetail.product);
            if (found) {
                this.isExistProduct = true;
                return true;
            } else {
                this.isExistProduct = false;
                return false;
            }
        }
    }

    render() {
        return (
            <div className="row mx-0">
                <div className="col-12 px-0 custom-scroll" style={{height: '300px',overflow:'auto'}}>
                    <div className="header">

                    </div>
                    <div>
                        <div className="row mx-0 pt-2">
                            <div className={"col-2 pl-0"}><b>Seq #</b></div>
                            <div className={ "col-7 pl-0 text-center"}>
                                <p className="mb-0"><b>Product</b></p>
                            </div>
                            <div className="col-3 px-0" hidden={this.props.disabled ? true : false}>
                                <p className="mb-0 float-right"><b>Add/Remove</b></p>
                            </div>
                        </div>
                        {
                            this.props.customMessageDetails&&this.props.customMessageDetails.map((customMessageDetail, key) =>
                                <div className="row mx-0 mt-2" key={key}>
                                    <div className="col-2 text-center px-0 font-size-13">{ (key + 1)}</div>
                                    <div className="col-7 text-center px-0 font-size-13">{customMessageDetail.productName}</div>
                                    <div className="col-3 px-0 text-center" hidden={this.props.disabled ? true : false}>
                                        <img className="cursor-pointer p-2" style={{width: '28px'}} alt="remove"
                                             onClick={() => this.deleteCustomMessageDetail(key)}
                                             src={require("../../assets/image/removeIcon.png")}/>
                                    </div>
                                </div>
                           )}
                        <div hidden={this.props.disabled ? true : false}>
                            <Form submit={this.addCustomMessageDetail}>
                                <div className="row mx-0 mt-2">
                                    <div className={"col-2 pl-0"}></div>
                                    <div className="col-7 pl-0">
                                        <ProductSearch setSelectedProduct={this.setSelectedProduct} setNewProduct={this.setNewProduct} ref={(instance) => { this.productSearchCom = instance; }} />
                                        {
                                            this.isProductReq&&
                                            <div className="invalid error">This field required.</div>
                                        }
                                    </div>
                                    <div className="col-3 px-0 text-center">
                                        <button type="submit" style={{
                                            background: "transparent",
                                            border: 0
                                        }}><img className="cursor-pointer" style={{width: '13px'}} alt="add"
                                                src={require("../../assets/image/addIcon.png")}/></button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        {
                            this.isExistProduct === true &&
                            <div className="error">
                               Product is already exist.
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export {CustomMessageDetailForm };
