import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Stores } from '../../store/Stores';
import { CustomerType } from '../../store/CustomerType';
import { CustomerTypeForm } from './CustomerTypeForm';
import { BaseCustomer } from '../../config/HttpClients';
import {CustomerTypeSearch} from './CustomerTypeSearch';
import { PartnerTypeTableDetails } from '../partnerType/PartnerTypeTableDetails';

@observer
class CustomerTypes extends React.Component<any, any> {

    @observable customerType = new CustomerType();
    @observable customerTypes: CustomerType[] = [];

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.getCustomerTypes();
    }

    save = () => {
        if(this.customerType.id){
            this.updateCustomerType(this.customerType);
        } else {
            this.createCustomerType();
        }
    }

    createCustomerType=()=>{
        this.customerType.createdBy = Stores.currentUser.id;
        BaseCustomer.post("/customerType", this.customerType).then(response=>{
           this.setNewCustomerType();
        });
    }

    updateCustomerType=(customerType)=>{
        customerType.updatedBy = Stores.currentUser.id;
        BaseCustomer.put("/customerType", customerType).then(response=>{
           this.setNewCustomerType();
        });
    }

    getCustomerTypes = () => {
        BaseCustomer.get("/customerType").then(response => {
            this.customerTypes = response.data.payload;
        });
    }

    setNewCustomerType=()=>{
        this.customerType = new CustomerType();
        this.getCustomerTypes();
    }

    setSelectedCustomerType=(customerType)=>{
        this.customerType = customerType;
    }


    render() {
        return(
            <div className="col-12 p-0 h-100">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6 h-42'>
                        <b style={{letterSpacing:"1px",fontSize:"28px"}}> </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <CustomerTypeSearch setNewCustomerType={this.setNewCustomerType} setSelectedCustomerType={this.setSelectedCustomerType}/>
                        </div>
                    </div>
                </div>
                    <div className="row mx-0 py-3" style={{height:'calc(100% - 75px)'}}>
                        <div className="col-12 h-100">
                            <div className="card b-none h-100">
                                <div className="card-body h-100">
                                    <div className="pt-2 h-100">
                                        <div className="col-12 px-0 h-100">
                                            <CustomerTypeForm  save={this.save} customerType={this.customerType} />
                                            <div className="row mx-0 mt-2" style={{height:'calc(100% - 75px)'}}>
                                                <div className="col-12 h-100">
                                                    <div>
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr className="row table-header">
                                                                    <th className="col-1 text-nowrap text-center">S.No</th>
                                                                    <th className="col-4 text-nowrap text-center">Customer Type</th>
                                                                    <th className="col-4 text-nowrap text-center">Current State</th>
                                                                    <th className="col-3 text-nowrap text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                         </table>
                                                     </div>
                                                     <div className="row custom-scroll" style={{height:'calc(100% - 50px)',overflowY:'auto', overflowX:'hidden'}}>
                                                         <table className="table">
                                                            <tbody>
                                                                <PartnerTypeTableDetails partnerTypes = {this.customerTypes} updatePartnerType = {this.updateCustomerType}/>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const CustomerTypesComponent = withRouter(CustomerTypes);
export {CustomerTypesComponent as CustomerTypes};
