import * as React from "react";
import {observer} from "mobx-react";
import Autosuggest from 'react-autosuggest';
import { BaseCustomer } from "../../config/HttpClients";

@observer
class CustomerVerticalSearch extends React.Component<any, any> {

    constructor(props){
        super(props);
        this.state = {
            nameValue: '',
            customerverticals: []
        };
    }

    onSuggestionChange = (event, {newValue, method}) => {
        this.setState({
            nameValue: newValue
        });
    };
    onSuggestionsFetchRequested = ({value}) => {
         BaseCustomer.get("/customerVertical/searchByName?name="+value)
           .then(response => {
            if(response.data.payload.length===0){
                    this.props.setNewCustomerVertical();
                }
            this.setState({ customerverticals: response.data.payload});});
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            nameValue: '',
            partnerTypes: []
        });
    }
    getSuggestion = (suggestion) => {
        return suggestion.name;
    }

    renderSuggestion = (suggestion) => {return ( <span>{suggestion.name}</span>);};

    onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
        this.props.setSelectedCustomerVertical(suggestion);
       // this.onSuggestionsClearRequested();
    }

    render() {
        const {nameValue, customerverticals} = this.state;

        const branchInputProps =
            {
                placeholder: "Search",
                value: nameValue,
                customerverticals: customerverticals,
                onChange: this.onSuggestionChange
            };

        const renderInputComponent = inputProps => (
            <div>
                <input {...inputProps} />
                <div className={"react-autosuggest__suggestions-container--open text-center p-2 error"}
                     hidden={!(inputProps.customerverticals.length === 0 && inputProps.value !== "")}>No customer vertical found...!
                </div>
            </div>
        );
        return (
            <Autosuggest
                suggestions={customerverticals}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestion}
                renderSuggestion={this.renderSuggestion}
                inputProps={branchInputProps}
                renderInputComponent={renderInputComponent}
            />
        )
    };
}

export {CustomerVerticalSearch};
