import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { ProductSearch } from '../product/ProductSearch';
import { observable } from 'mobx';
import { BaseProduct, BaseUser} from '../../config/HttpClients';
import {getLoggedInUser} from "../../constants/CommonMethods";
import { ProductDetailForm } from './ProductDetailForm';
import { ProductAttributeForm } from './ProductAttributeForm';
import { PackagingLevelForm } from './PackagingLevelForm';
import { Stores } from '../../store/Stores';
import { Product } from '../../store/Product';
import {ProductNotification} from "../../store/ProductNotification";
import {SuspendDto} from '../../store/SuspendDto';
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";

@observer
class ProductDetails extends React.Component<any, any> {  

    @observable option:string = 'packageLevel';
    @observable productSearchCom:any;
    @observable productNotification = new ProductNotification();
    @observable suspendAlertModal:any;
    @observable activateAlertModal:any;
    @observable suspendDto:SuspendDto = new SuspendDto();
    @observable comment:string ="";
    @observable disableInput:boolean = false;

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
   }

   init=()=>{
        if(Stores.currentCustomer&&Stores.currentCustomer.type&&Stores.currentCustomer.type.id==="31000000-0000-0000-0000-000000000000"){
            this.disableInput = true;
        }
   }

    setSelectedProduct=(product)=>{
        Stores.product = new Product();
        Stores.product = product;
    }

    save = () => {
        Stores.product.packagingLevels.map((packagingLevel,key)=>{
            packagingLevel.order = (key+1).toString();
            return true;
        });
        if(Stores.product.id){
            this.updateProductDetail();
        }
    }

    updateProductDetail=()=>{
        let sendNotification;
        Stores.product.updatedBy = Stores.currentUser.id;
        if(Stores.product.productStatus === null) {
            sendNotification = true;
            Stores.product.productStatus = "Needs Approval";
        }
        BaseProduct.put("/product",Stores.product).then(response=>{
            if(sendNotification) {
                this.productNotification.customer = Stores.currentCustomer.name;
                this.productNotification.customerId = Stores.currentCustomer.id;
                this.productNotification.name = Stores.product.name;
                this.productNotification.registeredBy = Stores.currentUser.firstName+' '+Stores.currentUser.lastName;
                this.productNotification.emailId = Stores.currentUser.userName;
                this.productNotification.date = new Date();
                this.sendProductNotification(this.productNotification);
            }
           this.setNewProduct();
        });
    }

    sendProductNotification=(productNotification)=>{
        BaseUser.post("/email/productRegister",productNotification).then(response=>{
        });
    }

    suspendProduct=()=>{
        this.suspendDto.id = Stores.product.id;
        this.suspendDto.updatedBy =  Stores.currentUser.id;
        this.suspendDto.updatedUser =  Stores.currentUser.firstName;
        this.suspendDto.comment =  this.comment;
        BaseProduct.put("/product/suspend",this.suspendDto).then(response=>{
            this.suspendAlertModal.close();
            this.productNotification.status = "Suspended";
            this.getUserById(Stores.product.createdBy);
            this.setNewProduct();
            this.comment = "";
            this.suspendDto = new SuspendDto();
        });
    }

    activateProduct=()=>{
        this.suspendDto.id = Stores.product.id;
        this.suspendDto.updatedBy =  Stores.currentUser.id;
        this.suspendDto.updatedUser =  Stores.currentUser.firstName;
        console.log("=========df=======",this.suspendDto);
        BaseProduct.put("/product/activate",this.suspendDto).then(response=>{
            this.activateAlertModal.close();
            this.productNotification.status = "Activated";
            this.getUserById(Stores.product.createdBy);
            this.setNewProduct();
            this.suspendDto = new SuspendDto();
        });
    }

    getUserById = (id) => {
         BaseUser.get("/user/id/" + id).then(response => {
            this.productNotification.registeredBy = response.data.payload.userName;
            this.sendProductStatusNotification(this.productNotification);
        });
    };

    sendProductStatusNotification = (productNotification) => {
        BaseUser.post("/email/productApproval", productNotification).then(response => {
            this.productNotification = new ProductNotification();
        });
    };

    suspendAlert = () => {
        this.suspendAlertModal.show();
    }

    activateAlert = () => {
        this.activateAlertModal.show();
    }

    handleComment=(event)=>{
        this.comment = event.target.value;
    };

    setNewProduct=()=>{
        Stores.product = new Product();
        Stores.product.reset();
        this.productSearchCom.clearFilter();
    }

    handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
    };

    setOption=(option)=>{
        this.option = option;
    }

    render() {
        return(
                <div className="col-12 p-0">
                <div className="pb-2 pt-4 row mx-0 search-bg">
                    <div className='col-6 h-42'>
                        <b style={{letterSpacing:"1px",fontSize:"28px"}}> </b>
                    </div>
                    <div className='col-6'>
                    <div className='float-right'>
                        <ProductSearch setSelectedProduct={this.setSelectedProduct} ref={ref => {this.productSearchCom = ref}}/>
                        </div>
                    </div>
                </div>
                    <div className="row mx-0 pl-0 pt-3">
                        <div className="col-7 pr-0">
                            <div className="card b-none">
                                <div className="card-body">                                   
                                        <ProductDetailForm productDetail={Stores.product}  save={this.save} setOption={this.setOption}
                                           disableInput={this.disableInput} suspendProduct={this.suspendAlert} activateProduct={this.activateAlert}/>
                                </div>
                            </div>
                        </div>
                         <div className="col-5"> 
                            <div className="card">
                                <div className="card-body">                                   
                                    <React.Fragment>
                                    {
                                        this.option==='pacageLevel'&&
                                        <PackagingLevelForm packagingLevels={Stores.product.packagingLevels} />
                                    }
                                    {
                                        this.option==='attribute'&&
                                        <ProductAttributeForm attributes={Stores.product.attributes}  handleUserInput={this.handleUserInput}/>
                                    }
                                    </React.Fragment>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <ErrorAlertModal ref={instance => {
                        this.suspendAlertModal = instance
                    }} type={"suspend"} comment={this.comment} handleComment={this.handleComment} submit={this.suspendProduct}/>
                    <ErrorAlertModal ref={instance => {this.activateAlertModal = instance}}
                     type={"activate"}  submit={this.activateProduct}/>
                </div>
        )
    }
}

const ProductDetailsComponent = withRouter(ProductDetails);
export {ProductDetailsComponent as ProductDetails};
