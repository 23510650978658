import {action, observable} from 'mobx';
import {Subset} from "./Subset";

class Feature {
    @observable id:string = '';
    @observable name:string = '';
    @observable subset:Subset[] = [];
    @observable pathUrl:string = '';
    @observable imageUrl:string = '';
    @observable displayOrder:number = 0;
    @observable selected:boolean =false;
    @observable mobile:boolean = false;
    @observable hasSubMenu:boolean = false;

    @action
    setProduct = (feature: any) => {
        this.id = feature.id;
        this.name = feature.name;
        this.subset = feature.subset;
        this.pathUrl = feature.pathUrl;
        this.imageUrl = feature.imageUrl;
        this.displayOrder = feature.displayOrder;
        this.mobile = feature.mobile;
        this.hasSubMenu = feature.hasSubMenu;
        return this;
    }
    @action reset() {
        this.id = '';
        this.name = '';
        this.subset = [new Subset()];
        this.pathUrl = '';
    }
}

export {Feature};
