import {action, observable} from 'mobx';
import {UserScanData} from "./UserScanData";

class Label {
    @observable secondaryId:string = '';
    
    @observable lastScannedActionType:string = '';
    @observable lastScannedBranchId:string = '';
    @observable lastScannedDate:string = '';
    @observable lastScannedLocation:string = '';
    @observable lastScannedUserId:string = '';

    @observable userScanData:UserScanData[] = [];


    @action
    setProduct = (label: any) => {
        this.secondaryId = label.secondaryId;
        this.lastScannedActionType = label.lastScannedActionType;
        this.lastScannedBranchId = label.lastScannedBranchId;
        this.lastScannedDate = label.lastScannedDate;
        this.lastScannedLocation = label.lastScannedLocation;
        this.lastScannedUserId = label.lastScannedUserId;
        this.userScanData = label.userScanData;
        return this;
    }
    @action reset() {
        this.secondaryId = '';
        this.userScanData = [new UserScanData()];

    }
}

export {Label};
