import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {Customer} from "../../store/Customer";
import { BaseNotification} from "../../config/HttpClients";
import {Pagination} from "../../constants/pagination/Pagination";
import {Stores} from "../../store/Stores";
import {DateToStringFormat, getLoggedInUser, viewDateTimeFormat} from "../../constants/CommonMethods";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {AlertDetails} from "./AlertDetails";
import {Notification} from "../../store/Notification";
import {NotificationTableDetails} from "./NotificationTableDetails";
import {Button} from 'react-bootstrap';

@observer
class Alerts extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable paginationFormat: any;
    @observable showCustomer: any;
    @observable customer:Customer = new Customer();
    @observable productStatus: any;
    @observable productSearchCom: any;
    @observable disabled: boolean = true;
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    @observable pageOption: string = 'ALERT';
    @observable count: number = 0;
    @observable currentAlertPageNo: number = 0;
    @observable notifications:Notification[] = [];
    @observable notification:Notification = new Notification();
    @observable alertType:string = '';
    @observable codeType:string = '';
    @observable userType:string = '';
    @observable search:string = '';
    @observable secondaryId:string = '';
    @observable status:string = '';
    @observable evdensCode:string = '';
    @observable disableInput:boolean = false;
    @observable disableInputCode:boolean = true;
    @observable invalidLabel: boolean = false;
    @observable searchByCode: boolean = false;
    @observable searchByFilter: boolean = true;
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init()
        }
    }

    init = () => {
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        this.getNotifications();
    }

    getNotifications= () => {
        let search;
        let status;
        let userType;
        let alertType;
        let codeType;
        let evdensCode;
        if(!this.customer.id){this.customer.id = 'null';}
        if(this.search !== ''){
            search = this.search;
        } else {
            search = 'null';
        }
        if(this.status !== ''){
            status = this.status;
        } else {
            status = 'null';
        }
        if(this.userType !== ''){
            userType = this.userType;
        } else {
            userType = 'null';
        }
        if(this.alertType !== ''){
            alertType = this.alertType;
        } else {
            alertType = 'null';
        }
        if(this.codeType !== ''){
            codeType = this.codeType;
        } else {
            codeType = 'null';
        }
        if(this.evdensCode !== ''){
            evdensCode = this.evdensCode;
        } else {
            evdensCode = 'null';
        }
        BaseNotification.get("/notification?pageNo="+this.pageNo+"&limit="+this.limit+"&fromDate="+this.fromDateValue+"&toDate="+this.toDateValue+"&type=Alert&alertType="+alertType+"&from="+userType+"&customer="+this.customer.id+"&status="+status+"&search="+search+"&codeType="+codeType+"&secondaryId="+evdensCode).then(response=>{
            this.notifications = response.data.payload;
            this.notifications.forEach((notification)=>{
                notification.createdDateConvert = viewDateTimeFormat(notification.createdDate);
            });
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
        });
    }

    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
    };
    
    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
    }

    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getNotifications();
    }

    handleAlertType = (event) => {
        this.alertType = event.target.value;
    }

    handleCodeType = (event) => {
        this.codeType = event.target.value;
    }

    handleSearch= (event) => {
        this.search = event.target.value;
    }

    handleUserType= (event) => {
        this.userType = event.target.value;
    }

    handleStatus= (event) => {
        this.status = event.target.value;
    }

     handleEvdensCode = (event) => {
        this.evdensCode = event.target.value;
    }

    resetFilters() {
        this.limit = 10;
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.alertType = '';
        this.codeType = '';
        this.userType = '';
        this.search = '';
        this.status = '';
        this.customer.reset();
        this.customer = new Customer();
        this.evdensCode = '';
    }

     handleSearchByCode = (event) => {
        this.searchByCode = true;
        this.disableInputCode = false;
        this.searchByFilter = false;
        this.disableInput = true;
        this.resetFilters();
    }

    handleSearchByFilter = (event) => {
        this.searchByFilter = true;
        this.disableInput = false;
        this.searchByCode = false;
        this.disableInputCode = true;
        this.resetFilters();
    }

    handleSubmitCode = (event) => {
        this.invalidLabel = false;
        if(this.evdensCode.length > 0 && this.evdensCode.length < 14) {
            this.invalidLabel = true;
            return;
        }
        if(/^[A-Za-z]+$/.test(this.evdensCode.substring(0,3))) {
            this.pageNo = 0;
            this.getNotifications();
        } else {
            this.invalidLabel = true;
        }
    };

    handleSubmitFilter = (event) => {
        this.pageNo = 0;
        this.getNotifications();
    };

    viewNotification = (notification) => {
       this.count = notification.count;
       this.secondaryId = notification.secondaryId;
       this.currentAlertPageNo = this.count -1;
        this.setOption("ALERTDETAIL");
    }

    navigate = (url: any) => {
        this.history.push(url);
    };

    setOption=(option)=>{
        this.pageOption = option;
        if(this.pageOption ==="ALERT"){
            this.getNotifications();
        }
    }

    render() {
        const currentSolution = Stores.currentCustomer.solution;
        const currentUser = Stores.currentUser.userType.name;
        return (
            <React.Fragment>
                {
                    this.pageOption === 'ALERT' &&
                    <div className="col-12 py-3 h-100">
                        <div className="row mx-0 h-100">
                            <div className="col-12 h-100 px-0">
                                <div className="card b-none h-100">
                                    <div className="card-body h-100">
                                        <div className='row mx-0 mb-2 align-items-center'>
                                            <div className='col-2 pl-0 font-weight-bold'>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id={'searchByCode'} checked={this.searchByCode}
                                                        onChange={this.handleSearchByCode.bind(this)} className="custom-control-input"/>
                                                    <label className="custom-control-label" htmlFor={"searchByCode"}> Search By</label>
                                                </div>
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <input type="text" className="form-control"
                                                   value={this.evdensCode}
                                                   onChange={this.handleEvdensCode} id="evdensCode" required={true} 
                                                   autoComplete="off" maxLength={14} placeholder="Evdens UID" disabled={this.disableInputCode}/>
                                               {this.invalidLabel ? <div className="invalid error">Invaild Evdens UID</div> : null}
                                            </div>
                                            <div className='col-3 px-0'>
                                                <Button className="btn btn-app-primary mr-2" type="submit" onClick={this.handleSubmitCode}
                                                disabled={this.disableInputCode}>Search</Button>
                                            </div>
                                        </div>
                                        <div className='row mx-0 mb-2'>
                                            <div className='col-2 pl-0 font-weight-bold'>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id={'searchByFilter'} checked={this.searchByFilter}
                                                        onChange={this.handleSearchByFilter.bind(this)} 
                                                        className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor={"searchByFilter"}> Search By</label>
                                                </div>
                                            </div>
                                            <div className="col-3 pl-0">
                                                <div>From Date</div>
                                                <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                            inputChangeHandler={this.handleFromDate}
                                                            isRequired={true} disabled={this.disableInput}></Datepicker>
                                            </div>
                                            <div className="col-3 pl-0">
                                                <div>To Date</div>
                                                <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                            inputChangeHandler={this.handleToDate}
                                                            isRequired={true} disabled={this.disableInput}></Datepicker>
                                            </div>
                                            <div className='col-2 pl-0'>
                                                <div>Status</div>
                                                <select className="form-control"
                                                            value={this.status}
                                                            id="customer"
                                                            onChange={this.handleStatus.bind(this)}
                                                            disabled={this.disableInput}>
                                                        <option className="select-option" value={''}>All</option>
                                                        <option className="select-option" value={'New'}>New</option>
                                                        <option className="select-option" value={'ActionInitiated'}>Action Initiated</option>
                                                        <option className="select-option" value={'Resolved'}>Resolved</option>
                                                        <option className="select-option" value={'Released'}>Released</option>
                                                </select>
                                            </div>
                                            <div className='col-2 px-0'>
                                                 <div>Items per page</div>
                                                <select className="form-control"
                                                        value={this.limit}
                                                        id="customer"
                                                        onChange={this.handlePageLimit.bind(this, this.limit)}
                                                        disabled={this.disableInput}>
                                                    <option className="select-option" value={10}>10</option>
                                                    <option className="select-option" value={15}>15</option>
                                                    <option className="select-option" value={20}>20</option>
                                                </select>
                                        </div>
                                        </div>
                                        <div className='row mx-0'>
                                            <div className='col-2 pl-0'></div>
                                            <div className='col-2 pl-0'>Alert Type</div>
                                            <div className='col-2 pl-0'>Alerts From</div>
                                            <div className='col-2 pl-0'>Evdens UID Type</div>
                                            <div className='col-3 px-0'>Customer/Partner name</div>
                                            <div className='col-1 pr-0'></div>
                                        </div>
                                        <div className='row mx-0 mb-2'>
                                            <div className='col-2 pl-0'></div>
                                            <div className='col-2 pl-0'>
                                                <select className="form-control"
                                                        value={this.alertType}
                                                        id="customer"
                                                        onChange={this.handleAlertType.bind(this)}
                                                        disabled={this.disableInput}>
                                                    <option className="select-option" value={''}>All</option>
                                                    <option className="select-option" value={'Misshipment'}>Misshipment</option>
                                                    <option className="select-option" value={'Cloned'}>Cloned</option>
                                                    <option className="select-option" value={'Invalid'}>Invalid</option>
                                                    <option className="select-option" value={'Denied'}>Denied</option>
                                                </select>
                                            </div>
                                            <div className='col-2 pl-0'>
                                                <select className="form-control"
                                                        value={this.userType}
                                                        id="customer"
                                                        onChange={this.handleUserType.bind(this)}
                                                        disabled={this.disableInput}>
                                                    <option className="select-option" value={''}>All</option>
                                                    <option className="select-option" value={'CUSTOMER'}>CUSTOMER</option>
                                                    <option className="select-option" value={'PARTNER'}>PARTNER</option>
                                                    <option className="select-option" value={'CONSUMER'}>CONSUMER</option>
                                                </select>
                                            </div>
                                            <div className='col-2 pl-0'>
                                                <select className="form-control" value={this.codeType} id="customer"
                                                        onChange={this.handleCodeType.bind(this)}
                                                        disabled={this.disableInput}>
                                                    <option className="select-option" value={''}>All</option>
                                                    {((currentUser && currentUser=='VGLENS') || (currentSolution && (currentSolution == 'evdensDigiSign' || currentSolution == 'Both'))) && <option className="select-option" value={'DigiSign'}>evdensDigiSign</option>}
                                                    {((currentUser && currentUser=='VGLENS') || currentSolution && (currentSolution == 'evdensCodes' || currentSolution == 'Both')) && <option className="select-option" value={'PlainCode'}>evdensCodes</option>}
                                                </select>
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <input type="text" className="form-control" value={this.search}
                                                       onChange={this.handleSearch.bind(this)}  maxLength={255}
                                                       id="search" />
                                            </div>
                                            <div className='col-1 p-0 text-right'>
                                               <Button className="btn btn-app-primary" type="submit" onClick={this.handleSubmitFilter} disabled={this.disableInput}>Search</Button>
                                            </div>
                                        </div>
                                        {
                                            this.totalRecords > 0 &&
                                            <div className="row mx-0">
                                                <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                            </div>
                                        }
                                        <div className="row mx-0 mt-2" style={{height: 'calc(100% - 168px)'}}>
                                            <div className="col-12 h-100 px-0">
                                                <div className="row mx-0">
                                                    <table className="table m-0">
                                                        <thead>
                                                        <tr className="row mx-0 text-nowrap table-header" >
                                                            <th className="col-2 px-0 text-center px-0">Alert Id</th>
                                                            <th className="col-2 px-0 text-center px-0">Evdens UID</th>
                                                            <th className="col-2 px-0 text-center px-0">Evdens UID Type</th>
                                                            <th className="col-1 px-0 text-center">Count</th>
                                                            <th className="col-1 px-0 text-center px-0">Alert Type</th>
                                                            <th className="col-1 px-0 text-center">Source</th>
                                                            <th className="col-2 px-0 text-center">Alert Date</th>
                                                            <th className="col-1 px-0 text-center">Status</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div className="row mx-0 custom-scroll" style={{
                                                    marginTop: '-2px',
                                                    height: 'calc(100% - 98px)',
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden'
                                                }}>
                                                    <table className="table">
                                                        <tbody>
                                                            <NotificationTableDetails notifications={this.notifications} isNotification={false} viewNotification={this.viewNotification}/>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Pagination totalRecords={this.totalRecords}
                                                            displayRecords={this.limit} pageNo={this.pageNo}
                                                            partialPageCount={5}
                                                            handleChangePage={this.handleChangePage}
                                                            ref={ref => {
                                                                this.paginationFormat = ref
                                                            }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.pageOption === 'ALERTDETAIL' &&
                    <AlertDetails totalPage={this.count} fromDateValue={this.fromDateValue} toDateValue={this.toDateValue}
                                  alertType={this.alertType} secondaryId={this.secondaryId}
                                  search={this.search} userType={this.userType} status={this.status}
                                  currentPageNo={this.currentAlertPageNo} setOption={this.setOption}/>
                }
            </React.Fragment>
        )
    }
}

const AlertsComponent = withRouter(Alerts);
export {AlertsComponent as Alerts};
