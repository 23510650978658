import * as React from 'react'
import * as ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Auth } from 'aws-amplify';
import HeaderLogoIcon from '../../assets/image/ic_evdesns_new_shield.png';
import HeaderLogoWords from '../../assets/image/ic_evdens_new.png';
import Profile from '../../assets/image/profile.png';
import SignoutIcon from '../../assets/image/signOutIcon.png';
import DrawerMenu from '../../assets/image/drawer_menu.png';
import { clearCookies, BaseCustomer,BaseUser } from '../../config/HttpClients';
import {Stores} from "../../store/Stores";
import {User} from "../../store/User";
import {Customer} from "../../store/Customer";

@observer
class Header extends React.Component<any,any> {
    history;
    @observable isOpen:boolean = false;
    @observable imgUrl:string = '';
    @observable user:User = new User();
    @observable customer:Customer = new Customer();
    @observable dropdown;any;
    constructor(props) {
        super(props);
        this.history = props.history;
        this.getLoggedInUser();
        if((Stores.currentUser && Stores.currentUser.userType.name === "CUSTOMER") && (Stores.currentCustomer && Stores.currentCustomer.fileName)){
            this.getCustomerImage(Stores.currentCustomer.fileName);
        }
    }
    componentWillUnmount() {
    // make sure you remove the listener when the component is destroyed
        document.removeEventListener('click', this.handleClick, false);
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClick, false);
    }
    handleClick =(e) => {
        const node = ReactDOM.findDOMNode(this.dropdown);
        if (node instanceof HTMLElement) {
            if(!node.contains(e.target)) {
                this.isOpen = false;
           }
        }
    }
    navigateRoute = (url:any) =>{
        this.history.push(url);
    };
    logout=async event=>{
        await Auth.signOut();
        clearCookies();
        this.navigateRoute('/login')
    };
    toggleOpen = () => {
        this.isOpen = !this.isOpen;
    }

    menuCollapse = () => {
        Stores.menu.expanded = !Stores.menu.expanded;
        console.log("====sd=s=====", Stores.menu.expanded);
    }

    imageOnError =(event)=>{
        event.target.src = Profile;
    };

    getLoggedInUser=async()=> {
        await Auth.currentAuthenticatedUser().then(response=>{
            BaseUser.get("/user/"+response.username).then(
                response=> {
                    this.user = response.data.payload;
                    if(this.user.userType.name !== "VGLENS") {
                        this.getCurrentCustomer();
                    }
                }
            )
        })
    }

    getCurrentCustomer=()=>{
        BaseCustomer.get("/customer/"+this.user.customer)
        .then(response => {
            this.customer = response.data.payload;
            if(this.customer.fileName) {
                this.getCustomerImage(this.customer.fileName);
            }
        });
    }

    getCustomerImage= (fileName) => {
        BaseCustomer.get("/customer/image?fileName="+fileName,{headers:{Accept:"*/*"},responseType: "blob"}).then(response=>{
            let blob = new Blob([response.data], { type:response.headers["content-type"]});
            this.imgUrl = URL.createObjectURL(blob);
        });
     }

    render() {
        return (
            <div className="fixed-top row mx-0 app-header">
                <div id="headerLogo" className={Stores.menu.expanded ? 'active':''} style={{height:"50px"}}>
                        <div className='row mx-0' style={{width:'235px',height:"50px"}}>
                            {(Stores.menu.expanded) ? <div className='p-3' style={{width:"28px"}} >
                                <img src={HeaderLogoIcon} alt="HeaderLogoIcon" style={{width:"18px", height:"20px"}}/>
                            </div> : null}
                            <span className='col-12 text-center' style={{width:"140px", paddingTop:"5px"}}>
                               <img src={HeaderLogoWords} alt="HeaderLogoWords" style={{height:"40px"}}/>
                            </span>
                    </div>
                </div>
            <div className={Stores.menu.expanded?'app-header-width row mx-0':'app-header-sidebar-width row mx-0'}>
              
               <div className={(this.user.userType.name !== "VGLENS") ? 'col-9 py-2' : 'col-1 py-2'}>
                   {
                       (this.user.userType.name !== "VGLENS") ? 
                       <React.Fragment>
                           <div className="row">
                           <div className="px-3 py-2" onClick={this.menuCollapse}><img src={DrawerMenu} alt="DrawerMenu" style={{width:"20px"}} /></div>
                           <div hidden={(Stores.currentUser && Stores.currentUser.userType.name === "VGLENS")}>
                               <div className="float-right">
                                   {
                                       this.imgUrl&&
                                       <img src={this.imgUrl} alt='Profile' height='30px' onError={this.imageOnError.bind(this)} width='30px' className="profile" />
                                   }
                                   {
                                       !this.imgUrl&&
                                       <img src={Profile} alt='Profile' height='30px' width='30px' className="profile"/>
                                   }
                               </div>
                           </div>
                           <div className="ml-2 pl-0 col-10 py-0 pt-1 text-ellipsis" style={{ fontSize:'18px',fontWeight:'bold', letterSpacing: '1px'}}>
                               {Stores.currentCustomer.name}
                           </div>
                           </div>
                        </React.Fragment> :
                        <React.Fragment> 
                          <div  onClick={this.menuCollapse}>
                            <div className="px-2 py-2">
                              <img src={DrawerMenu} alt="DrawerMenu" style={{width:"20px"}} />
                            </div>
                          </div>
                        </React.Fragment>

                   }

               </div>
               {/*<div className='col-3 py-3 px-0'>*/}
               {/*    <div className='float-right'>*/}
               {/*        <img src={NotificationIcon} alt='NotificationIcon' height='20px' width='20px' /> &nbsp;&nbsp;*/}
               {/*        <img src={ReportIcon} alt='ReportIcon' height='20px' width='20px' /> &nbsp;&nbsp;*/}
               {/*    <img src={Notification} alt='Notification' height='25px' width='23px' />*/}
               {/*    </div>*/}
               {/*</div>*/}
                <div className={(this.user.userType.name !== "VGLENS") ? 'col-3 py-3 pl-0 dropdown show' : 'col-11 py-3 pl-0 dropdown show'}>
                    <div className='float-right' style={{cursor:'pointer', fontSize:'15px'}} >
                        <div className="dropdown-toggle font-weight-500" onClick={()=>this.toggleOpen()} ref={(dropdown) => this.dropdown = dropdown}>
                            {Stores.currentUser.firstName ? Stores.currentUser.firstName+' '+ Stores.currentUser.lastName : 'Username'} &nbsp;&nbsp;
                        </div>
                        <div className={this.isOpen?'dropdown-menu show':'dropdown-menu'} >
                            <div className='dropdown-list headerDropdown' onClick={()=>this.navigateRoute('/myProfile/profile')}>My Profile</div>
                            <div className='dropdown-list headerDropdown' onClick={()=>this.navigateRoute('/myProfile/changePassword')}>Change Password</div>
                            <div onClick={this.logout} className='dropdown-list headerDropdown'>
                               Logout
                                <div className='float-right pr-2'>
                                    <img src={SignoutIcon} alt='SignoutIcon' height='18px' width='18px' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
};


const HeaderComponent = withRouter(Header);

export {HeaderComponent as Header};
