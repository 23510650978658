import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {Customer} from '../../store/Customer';
import {observable} from 'mobx';
import {Order} from '../../store/Order';
import {BaseCustomer, BaseLabelWrite, BaseUser} from "../../config/HttpClients";
import {OrderItem} from "../../store/OrderItem";
import {OrderItemView} from "./OrderItemView";
import {Pagination} from "../../constants/pagination/Pagination";
import AWS from 'aws-sdk';
import config from "../../config/config";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {DateToStringFormat, getLoggedInUser, stringToDateFormat, viewDateFormat} from "../../constants/CommonMethods";
import {Stores} from "../../store/Stores";
import {LabelGenerateDto} from "../../store/LabelGenerateDto";

@observer
class OrderStatusView extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable orderStatus = "INPROGRESS";
    @observable customers: Customer[] = [];
    @observable customer: Customer = new Customer();
    @observable orders: Order[] = [];
    @observable order: Order = new Order();
    @observable orderItem: OrderItem = new OrderItem();
    @observable paginationFormat: any;
    @observable keyImages: string[] = [];
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init();
        }
    }

    init = () => {
        this.fromDate = DateToStringFormat(new Date());
        this.toDate = DateToStringFormat(new Date());
        this.fromDateValue = DateToStringFormat(new Date());
        this.toDateValue = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.getCustomers();
        this.getOrders();
    }

    getCustomers = () => {
        BaseCustomer.get("/customer/partner/" + Stores.currentCustomer.id).then(response => {
            this.customers = response.data.payload;
        });
    }

    getOrders = () => {
        if (!this.customer.id) {
            this.customer.id = 'null';
        }
        if (!this.orderStatus) {
            this.orderStatus = 'null';
        }
        BaseCustomer.get("/order?pageNo=" + this.pageNo + "&limit=" + this.limit + "&orderStatus=" + this.orderStatus + "&fromDate=" + this.fromDateValue + "&toDate=" + this.toDateValue + "&customerId=" + this.customer.id + "&printingPartnerId=" + Stores.currentUser.customer).then(response => {
            this.orders = response.data.payload;
            this.orders.map((order) => {
                order.orderDate = viewDateFormat(order.orderDate);
                order.orderItems.map((orderItem) => {
                    orderItem.expectedDeliveryDate = viewDateFormat(orderItem.expectedDeliveryDate);
                    return true;
                });
                return true;
            })
            this.totalRecords = response.data.count;
            this.paginationFormat.totalRecordsCount();
        });
    }

    showOrderDetails(order) {
        this.order = order;
        this.orderItem = new OrderItem();
    }

    closeOrderDetails = () => {
        this.order = new Order();
        this.orderItem = new OrderItem();
    }

    showOrderItem(labelItem) {
        this.getOrderItem(labelItem);
    }

    handleCustomer = (customer, event) => {
        customer.id = event.target.value;
        this.pageNo = 0;
        this.getOrders();
    };
    handleOrderStatus = (event) => {
        this.orderStatus = event.target.value;
        this.pageNo = 0;
        this.getOrders();
    }
    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
        this.pageNo = 0;
        this.getOrders();
    }
    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
        this.pageNo = 0;
        this.getOrders();
    }

    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
        this.pageNo = 0;
        this.getOrders();
    };
    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getOrders();
    }

    downloadLabel = (order, orderItem) => {
        this.keyImages = [];
        let bucket = config.bucket.bucketName;
        AWS.config.update({
            'accessKeyId': config.aws.ACCESSKEYID,
            'secretAccessKey': config.aws.SECRETACCESSKEY,
            "region": config.aws.REGION
        });
        let _this = this;
        let s3 = new AWS.S3();
        let params = {
            Bucket: bucket
        };
        s3.headBucket(params, function (err, data) {
            let imageFolder = orderItem.id + '/image';
            if (err) {
                console.log("errr", err, err.stack);
            } else {
                let params = {
                    Bucket: bucket,
                    Prefix: imageFolder
                };
                _this.initiateLabelWrite(order, orderItem, order.customer);
                _this.getFolderImageList(params, order, orderItem);
            }
        });
    }

    getFolderImageList = (params, order, orderItem) => {
        let s3 = new AWS.S3();
        let _this = this;
        s3.listObjectsV2(params, function (err, data) {
            if (err) console.log(err);
            if (data !== undefined && data.Contents !== undefined) {
                data.Contents.forEach(function (content) {
                    let key = content.Key;
                    if (key && content.Size !== undefined) {
                        if (content.Size > 0)
                            _this.keyImages.push(key);
                    }
                });
                if (data.IsTruncated) {
                    params.ContinuationToken = data.NextContinuationToken;
                    _this.getFolderImageList(params, order, orderItem);
                } else {
                    _this.getImageByKey(params.Bucket, order, orderItem);
                }
            }
        });
    }

    getImageByKey = (bucket, order, orderItem) => {
        let s3 = new AWS.S3();

        let _this = this;
        let fileName = order.customer && order.customer.displayName + '-' + order.orderId + '-' + orderItem.labelSize + '.zip';
        this.keyImages.forEach((key, index) => {
            let fileParams = {
                Bucket: bucket,
                Key: key
            };
            s3.getObject(fileParams, function (err, fileContents) {
                if (err) {
                    console.log('Downloading err: ' + err);
                } else if (fileContents !== undefined) {
                    // Read the file
                    _this.downloadFile(fileContents, fileName);
                    if (_this.keyImages.length - 1 === index) {
                        _this.updateOrderItem(order, orderItem);
                    }
                }
            });
        });
    }

    downloadFile = (fileContents, fileName) => {
        let blob = new Blob([fileContents.Body], {type: fileContents.ContentType});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }
    initiateLabelWrite = (order, orderItem, customer) => {
        BaseUser.get("/user/id/" + order.createdBy).then(response => {
            let labelGenerateDto: LabelGenerateDto = new LabelGenerateDto();
            labelGenerateDto.itemId = orderItem.id;
            labelGenerateDto.customerId = customer.id;
            labelGenerateDto.customerName = customer.name;
            labelGenerateDto.productId = orderItem.product;
            labelGenerateDto.productName = orderItem.productName;
            labelGenerateDto.partnerId = Stores.currentCustomer.id;
            labelGenerateDto.partnerName = Stores.currentCustomer.name;
            labelGenerateDto.partnerUserId = Stores.currentUser.id;
            labelGenerateDto.partnerUserFirstName = Stores.currentUser.firstName;
            labelGenerateDto.partnerUserLastName = Stores.currentUser.lastName;
            labelGenerateDto.orderId = order.orderId;
            labelGenerateDto.currentBranchCountry = order.branch.country.name;
            labelGenerateDto.currentBranchId = order.branch.id;
            labelGenerateDto.currentBranchName = order.branch.name;
            labelGenerateDto.userId = response.data.payload.id;
            labelGenerateDto.firstName = response.data.payload.firstName;
            labelGenerateDto.lastName = response.data.payload.lastName;
            labelGenerateDto.assignMode = "GENERATION";
            BaseLabelWrite.post("/labelWrite/orderedItem", labelGenerateDto).then(response => {
            });    
        });
        
    }
    getOrderItem = (orderItem) => {
        BaseCustomer.get("/orderItem/" + orderItem.id).then(response => {
            this.orderItem = response.data.payload;
        });
    }
    updateOrderItem = (order, orderItem) => {
        order.orderDate = stringToDateFormat(order.orderDate);
        order.orderItems.forEach((item) => {
            item.expectedDeliveryDate = stringToDateFormat(item.expectedDeliveryDate);
            if (item.id === orderItem.id) {
                item.orderItemStatus = "CLOSED";
            }
        });
        BaseCustomer.put("/order/update", order).then(response => {
            this.pageNo = 0;
            this.getOrders();
            this.closeOrderDetails();
            // this.deleteBucket(orderItem); For time being
        });
    }

    deleteBucket = (orderItem) => {
        BaseCustomer.delete("/orderItem/" + orderItem.id).then(response => {
        });
    }


    render() {
        return (
            <div className="col-12 py-3 h-100">
                <div className="row h-100">
                    <div className="col-8 h-100">
                        <div className="card b-none h-100">
                            <div className="card-body h-100">
                                <div className='row mb-1'>
                                    <div className="col-2 pl-0 pr-1"> From Date</div>
                                    <div className="col-2 pl-0 pr-1"> To Date</div>
                                    <div className='col-3 pl-0 pr-1'> Customer</div>
                                    <div className='col-3 pl-0 pr-1'> Status</div>
                                    <div className='col-2 pl-0'>
                                        <div className='float-right text-nowrap'> Items per Page</div>
                                    </div>

                                </div>
                                <div className='row mb-2'>
                                    <div className="col-2 pl-0 pr-1">
                                        <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleFromDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className="col-2 pl-0 pr-1">
                                        <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                    inputChangeHandler={this.handleToDate}
                                                    isRequired={true}></Datepicker>
                                    </div>
                                    <div className='col-3 pl-0 pr-1'>
                                        <select className="form-control"
                                                value={this.customer && this.customer.id}
                                                id="customer"
                                                onChange={this.handleCustomer.bind(this, this.customer)}>
                                            <option className="select-option" value=''>All</option>
                                            {this.customers.map((e, key) => {
                                                return <option className="select-option" key={key}
                                                               value={e.id}>{e.name}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-3 pl-0 pr-1'>
                                        <select className="form-control"
                                                id="orderStatus" onChange={this.handleOrderStatus.bind(this)}
                                                value={this.orderStatus}>
                                            <option className="select-option" value=''>All</option>
                                            <option className="select-option" value='OPEN'>Open</option>
                                            <option className="select-option" value='INPROGRESS'>In progress</option>
                                            <option className="select-option" value='CLOSED'>Completed</option>
                                        </select>
                                    </div>
                                    <div className='col-2 pl-0 pr-1'>
                                        <div className='col-12 float-right pr-0'>
                                            <select className="form-control"
                                                    value={this.limit}
                                                    id="customer"
                                                    onChange={this.handlePageLimit.bind(this, this.limit)}
                                                    required={true}>
                                                <option className="select-option" value={10}>10</option>
                                                <option className="select-option" value={15}>15</option>
                                                <option className="select-option" value={20}>20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.totalRecords > 0 &&
                                    <div className="row mx-0">
                                        <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                    </div>
                                }
                                <div className="row mx-0 mt-2" style={{height: 'calc(100% - 68px)'}}>
                                    <div className="col-12 h-100 px-0">
                                        <div className="row mx-0">
                                            <table className="table m-0">
                                                <thead>
                                                <tr className="row text-nowrap table-header">
                                                    <th className="col-2 text-center ">Order Date</th>
                                                    <th className="col-3 text-center">Customer</th>
                                                    <th className="col-3 text-center"> Status</th>
                                                    <th className="col-2 px-0 text-center">Order Id</th>
                                                    <th className="col-2 px-0 text-center">Action</th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="row pr-0 custom-scroll" style={{
                                            marginTop: '-2px',
                                            height: 'calc(100% - 102px)',
                                            overflowY: 'auto',
                                            overflowX: 'hidden'
                                        }}>
                                            <table className="table">
                                                <tbody>
                                                {
                                                    this.orders && this.orders.map((order, index) =>
                                                        <React.Fragment key={index}>
                                                            <tr key={index} className="row mx-0 text-nowrap table-body-value"
                                                                style={this.order.id !== order.id ? {paddingTop: '3px'} : {}}>
                                                                <td className="col-2 text-left">{order.orderDate}</td>
                                                                <td className="col-3 text-center">{order.customer.displayName}</td>
                                                                <td className="col-3 text-center">{(order.orderStatus === 'INPROGRESS') ? (
                                                                    <span>In Progress</span>) : (
                                                                    <React.Fragment></React.Fragment>)}{(order.orderStatus === 'OPEN') ? (
                                                                    <span>Submitted</span>) : (
                                                                    <React.Fragment></React.Fragment>)}{(order.orderStatus === 'COMPLETED') ? (
                                                                    <span>Completed</span>) : (
                                                                    <React.Fragment></React.Fragment>)}{(order.orderStatus === 'CLOSED') ? (
                                                                    <span>Completed</span>) : (
                                                                    <React.Fragment></React.Fragment>)}</td>
                                                                <td className="col-2 text-center">{order.orderId}</td>
                                                                <td className={'col-2 text-center'}
                                                                >
                                                                    {
                                                                        this.order && this.order.id !== order.id &&
                                                                        <img className="cursor-pointer" alt="add"
                                                                             style={{width: '13px'}}
                                                                             onClick={() => this.showOrderDetails(order)}
                                                                             src={require("../../assets/image/addIcon.png")}/>
                                                                    }
                                                                    {
                                                                        this.order && this.order.id === order.id &&
                                                                        <img className="cursor-pointer p-2"
                                                                             style={{width: '28px'}}
                                                                             alt="close"
                                                                             onClick={() => this.closeOrderDetails()}
                                                                             src={require("../../assets/image/removeIcon.png")}/>

                                                                    }
                                                                </td>
                                                            </tr>
                                                            {
                                                                this.order && this.order.id === order.id &&
                                                                <tr className="row mx-2 table-header">
                                                                    <td className='col-12'
                                                                        style={{
                                                                            padding: '.65rem'
                                                                        }}>
                                                                        <div className='row text-nowrap mb-1'>
                                                                            {/* <div
                                                                                className='col-2 font-weight-bold text-center'> Size
                                                                            </div> */}
                                                                            {/* <div
                                                                                className='col-2 font-weight-bold text-center'> Product
                                                                            </div> */}
                                                                            <div
                                                                                className='col-2 font-weight-bold text-center'>Evdens UID Type
                                                                            </div>
                                                                            <div
                                                                                className='col-3 font-weight-bold text-center'>
                                                                                Quantity
                                                                            </div>
                                                                            <div
                                                                                className='col-3 font-weight-bold text-center'>
                                                                                Delivery Date
                                                                            </div>
                                                                            <div
                                                                                className='col-2 font-weight-bold text-center'> Action
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            }
                                                            {
                                                                this.order && this.order.id === order.id &&
                                                                order.orderItems.map((orderItem, key) =>
                                                                    <tr className="row mx-2 table-body-value" key={key}>
                                                                        <td className='col-12' style={{
                                                                            border: '1px solid #E2E2E3',
                                                                            padding: '.65rem'
                                                                        }}>
                                                                            <div className='row text-nowrap'>
                                                                                {/* <div
                                                                                    className='col-2 text-center'> {orderItem.labelSize}</div> */}
                                                                                {/* <div className='col-2 text-center'> {orderItem.productName?(<span >{orderItem.productName}</span>):(<span>-</span>)}</div> */}                                                                         
                                                                                <div
                                                                                    className='col-2 text-center'> {(orderItem.ovd==='WithOVD')?(<span >evdensDigiSign</span>):(<React.Fragment></React.Fragment>)}{(orderItem.ovd==='WithoutOVD')?(<span >evdensCodes</span>):(<React.Fragment></React.Fragment>)}</div>
                                                                                <div
                                                                                    className='col-3 text-center'> {orderItem.quantity}</div>
                                                                                <div
                                                                                    className='col-3 text-center'> {orderItem.expectedDeliveryDate}</div>
                                                                                <div
                                                                                    className='col-2 text-left cursor-pointer '>
                                                                                    <button
                                                                                        className={this.orderItem.id !== orderItem.id ? 'btn btn-app-secondary' : 'btn btn-app-primary'}
                                                                                        onClick={() => this.showOrderItem(orderItem)}>
                                                                                        VIEW
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </React.Fragment>
                                                    )
                                                }
                                                {
                                                    this.orders && this.orders.length === 0 &&
                                                    <tr>
                                                        <td className="text-center error" colSpan={7}>No orders found
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination totalRecords={this.totalRecords}
                                                    displayRecords={this.limit} pageNo={this.pageNo}
                                                    partialPageCount={5} handleChangePage={this.handleChangePage}
                                                    ref={ref => {
                                                        this.paginationFormat = ref
                                                    }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 pl-0">
                        <div className="card">
                            <div className="card-body">
                                {
                                    this.orderItem && this.orderItem.id &&
                                    <OrderItemView order={this.order} orderItem={this.orderItem}
                                                   downloadLabel={this.downloadLabel}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const OrderStatusViewComponent = withRouter(OrderStatusView);
export {OrderStatusViewComponent as OrderStatusView};
