import * as React from "react";
import {observer} from "mobx-react";
import { observable } from "mobx";
import Autosuggest from 'react-autosuggest';
import { BaseCustomer } from "../../config/HttpClients";
import {Stores} from "../../store/Stores";

@observer
class CustomerSearch extends React.Component<any, any> {
    @observable customers : any = [];
    constructor(props){
        super(props);
        this.state = {
            nameValue: '',
            customers: []
        };
    }

    onSuggestionChange = (event, {newValue, method}) => {
        this.setState({
            nameValue: newValue
        });
    };
    onSuggestionsFetchRequested = ({value}) => {
        var userTypeId;
        if(this.props.userTypeId) {
            userTypeId = this.props.userTypeId;
        } else {
            userTypeId = Stores.currentUser.userType.id;
        }
        BaseCustomer.get("/customer/searchByName?name="+value+"&userType=CUSTOMER&customer=null&userTypeId="+userTypeId)
            .then(response => {
                if(response.data.payload.length===0 ){
                    this.props.setNewCustomer();
                }
                let customersObj = response.data.payload;
                if(Stores.currentCustomer && Stores.currentCustomer.id) {
                    customersObj = customersObj.filter(function (item) {
                        return item.id !== Stores.currentCustomer.id
                    });
                }
                
                this.setState({ customers: customersObj});
            });
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({
           // nameValue: '',
           // customers: []
       });
        let paths =  window.location.pathname.split('/');
        if((paths[1] === "feedbackAndComplaints" || paths[1] === "dashboard" ) && this.state.nameValue === '') {
            this.props.setNewCustomer();
        }
    }
    clearFilter=()=>{
        if(this.state.customers.length > 0) {
            this.setState({
                customers: [],
                nameValue: '',
            });
        }
    }
    getSuggestion = (suggestion) => {
        return suggestion.name;
    }

    renderSuggestion = (suggestion) => {return ( <span>{suggestion.name}</span>);};

    onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
        this.props.setSelectedCustomer(suggestion);
    }

    render() {
        const {nameValue, customers} = this.state;
        const customerInputProps =
        {
            placeholder: "Search",
            value: nameValue,
            customers:customers,
            onChange: this.onSuggestionChange
        };

        const renderInputComponent = inputProps => (
            <div>
                <input {...inputProps} />
                 <div className={"react-autosuggest__suggestions-container--open text-center p-2 error"} hidden={!(inputProps.customers.length === 0 && inputProps.value !== "")}>No Customer found...!</div>
            </div>
        );
        return (
            <Autosuggest
            suggestions={customers}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestion}
            renderSuggestion={this.renderSuggestion}
            inputProps={customerInputProps}
            renderInputComponent={renderInputComponent}
        />
        )
    };

}

export {CustomerSearch};
