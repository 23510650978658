import * as React from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import Form from "../../constants/Form";
import {FormGroup, ControlLabel, ButtonToolbar, Button} from "react-bootstrap";
import {BaseCustomer} from "../../config/HttpClients";
import {Stores} from "../../store/Stores";
import {CustomerType} from "../../store/CustomerType";
import {CustomerVertical} from "../../store/CustomerVertical";
import Select from 'react-select';
import { LoadToast,ToastType } from "../../config/Toast";
import {CustomerSearch} from '../customer/CustomerSearch';

@observer
class PartnerForm extends React.Component<any, any> {

    @observable customerTypes: CustomerType[] = [];
    @observable customerVerticals: CustomerVertical[] = [];
    @observable isFileTooLarge: boolean = false;
    @observable fileInput: any;
    @observable customerSearch: any;
    

    constructor(props) {
        super(props);
        this.getCustomerTypes();
    }

    getCustomerTypes = () => {
        BaseCustomer.get("/customerType").then(response => {
            this.customerTypes = response.data.payload;
        });
    }

    getCustomerVertical=(customer)=>{
        if(customer.type&&customer.type.id) {
            BaseCustomer.get("/customerVertical/customerType?customerType="+customer.type.id).then(response=>{
                this.customerVerticals = response.data.payload;
            });
        }
    }

    handleCustomerType = (partner, event) => {
        partner.type = {id: event.target.value};
        if (event.target.value) {
            let type = this.customerTypes.find(customerType => customerType.id === event.target.value);
            partner.type = type;
        }
    };

    handleCustomerVertical = (partner, event) => {
        partner.vertical = {id: event.target.value};
    };

    handleInputFile = (files, event) => {
        this.props.setFilesEmpty();
        this.handleFileObject(event.target.files, files)
    };

    handleFileObject = (data, files) => {
        let dataObj = data.length !== 0 ? this.handleFile(data) : {};
        Object.keys(dataObj).map(key => {
            files[key] = data[key];
            return true;
        });
        if(!this.isFileTooLarge){
            this.props.showImage();
        }
    };

    handleFile = (files) => {
        this.isFileTooLarge = false;
        if (this.checkImageType(files)) {
            LoadToast(ToastType.WARN, "File Type is not allowed ,Fotmats jpeg, jpg, png");
        } else {
            if (!this.checkFileSize(files)) {
                this.isFileTooLarge = true;
            }
            return files;
        }
        return {};
    };
    checkImageType = (files) => {
        let data;
        let allowedExtension = ['jpeg', 'jpg', 'png'];
        Object.keys(files).map(key => {
            data = allowedExtension.filter((data) => {
                if (Object.keys(files).length !== 0) {
                    return files[key].type.includes(data);
                }
            })
        });
        return data.length === 0;
    };

    checkFileSize = (files) => {
        let fileSize = 0;
        Object.keys(files).map(key => {
            fileSize += files[key].size;
            return true;
        });
        return fileSize < 10485760;
    };

    validate=()=>{
        if(this.isFileTooLarge){
            return;
        }
        this.props.submit();
    }

    //setNewCustomer = () => {
    //    this.props.customer = new Customer();
    //    this.props.customer.reset();
    //    this.props.partner.reset();
    //}

    //setSelectedCustomer = (customer) => {
    //    this.props.customer = new Customer();
    //    this.props.customer.reset();
    //    this.props.customer = customer;
    //    this.props.partner.name = this.customer.name;
    //    this.props.partner.displayName = this.customer.displayName;
    //}

    render() {
        let partnerTypes = this.props.partnerTypes && this.props.partnerTypes.map(partnerType => {
            return {label: partnerType['name'], value: partnerType['id']}
        });
        let isUpdate = false;
        if(this.props.partner.hasOwnProperty('id')) {
            if(this.props.partner.id) {
                isUpdate = true;
            }
        }
        let userPartnerTypes;
        if(this.props.isSupplyChain) {
            if(this.props.userPartnerTypes) {
                userPartnerTypes = this.props.userPartnerTypes.filter(function (item) {
                    return item.userType !== "VGLENS"
                });
            }
            if(isUpdate && this.props.partner.customerType !== "CUSTOMER" && (Stores.currentUser&&Stores.currentUser.userType.name === "SUPPLYCHAIN")) {
                partnerTypes = partnerTypes.filter(function (item) {
                    return item.label !== "Business Customer"
                });
            }
        } else {
            userPartnerTypes = this.props.userPartnerTypes;
        }

        return (
            <Form submit={this.validate}>
                <div className="row">
                    <div className="col-6">
                        <FormGroup controlId="partnerType" bsSize="large">
                            <ControlLabel className="font-weight-bold">Partner Type</ControlLabel>
                            <Select closeOnSelect={false}
                                    isDisabled={this.props.disableInput || this.props.disablePartner}
                                    inputProps={{required: true}}
                                    name="name" value={this.props.userPartnerTypes && userPartnerTypes}
                                    onChange={this.props.handlePartner}
                                    options={partnerTypes}
                            >
                            </Select>

                            {
                                ((this.props.partnerTypeError) && !(this.props.userPartnerTypes && this.props.userPartnerTypes.length > 0)) ? (
                                    <div className="error">
                                        This field is required.
                                    </div>) : (<React.Fragment>
                                    <div className="error"></div>
                                </React.Fragment>)
                            }
                            
                        </FormGroup>
                    </div>
                    <div className="col-6" hidden={this.props.hideCustomer ? true : false}>
                        <FormGroup controlId="searchCustomer" bsSize="large">
                            <ControlLabel className="font-weight-bold">Search Customer</ControlLabel>
                            <CustomerSearch setSelectedCustomer={this.props.setSelectedCustomer}
                                            setNewCustomer={this.props.setNewCustomer} ref={this.props.setCustomerSearch} userTypeId="31000000-0000-0000-0000-000000000000"/>
                            {
                                (this.props.customerError) ? (
                                    <div className="error">
                                        This field is required.
                                    </div>) : (<React.Fragment>
                                    <div className="error"></div>
                                </React.Fragment>)
                            }
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FormGroup controlId="name" bsSize="large">
                            <ControlLabel className="font-weight-bold">Partner Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.partner.name}
                                   disabled={(this.props.disableInput || !this.props.hideCustomer) ? true : false}
                                   onChange={this.props.handleUserInput.bind(this, this.props.partner)} maxLength={255}
                                   placeholder="Partner Name " id="name" required={true}/>
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                    <div className="col-6" hidden={this.props.disableInput ? true : false}>
                        <FormGroup controlId="type" bsSize="large">
                            <ControlLabel className="font-weight-bold">Display Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.partner.displayName}
                                   disabled={(this.props.disableInput || !this.props.hideCustomer) ? true : false}
                                   onChange={this.props.handleUserInput.bind(this, this.props.partner)} maxLength={255}
                                   placeholder="Display Name " id="displayName" required={true}/>
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-6 my-auto" hidden={this.props.disableInput ? true : false}>
                        <FormGroup controlId="logo" className={'mb-0'} bsSize="large">
                            <ControlLabel className={'mb-0 font-weight-bold'}>Upload Logo</ControlLabel>
                            <label htmlFor="file-upload" className="btn button-grey-color ml-2 mb-0">
                                {this.props.files.length > 0 ? 'Change File' : 'Browse File'}
                            </label>
                            <input id="file-upload" accept=".png, .jpg, .jpeg" type="file" name="files"
                                   disabled={(this.props.disableInput || !this.props.hideCustomer) ? true : false}
                                   ref={instance => {
                                       this.fileInput = instance
                                   }}
                                   onChange={this.handleInputFile.bind(this, this.props.files)}
                            />
                            <div className="invalid error"/>
                        </FormGroup>
                    </div>
                </div>
                {
                    ((Stores.currentPermission.create)  && !this.props.disableInput && !isUpdate) ? (
                            <ButtonToolbar className="justify-content-center">
                                <Button className="btn btn-app-secondary" type="reset" onClick={this.props.setNewPartner}> CLEAR</Button>
                                {
                                    this.props.partner.status==="ACTIVE"&&this.props.isSupplyChain&&
                                        <Button className="btn btn-app-secondary ml-2" type="button"
                                         onClick={this.props.suspendPartner}> SUSPEND</Button>
                                }
                                <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                            </ButtonToolbar>
                        ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }
                {
                    ((Stores.currentPermission.modify && this.props.partner&&this.props.partner.hasOwnProperty('id')) && !this.props.disableInput && isUpdate) ? (
                            (this.props.partner.id)&&
                            <ButtonToolbar className="justify-content-center">
                                <Button className="btn btn-app-secondary" type="reset" onClick={this.props.setNewPartner}> CLEAR</Button>
                                {
                                    this.props.partner.status==="ACTIVE"&&this.props.isSupplyChain&&
                                        <Button className="btn btn-app-secondary ml-2" type="button" hidden={(this.props.hideCustomer && this.props.partner.customerType !== "CUSTOMER")  ? false : true}
                                            onClick={this.props.suspendPartner}> SUSPEND</Button>
                                }
                                <Button className="btn btn-app-primary ml-2" type="submit"> SUBMIT</Button>
                            </ButtonToolbar>
                        ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }

            </Form>
        )
    };

}

export {PartnerForm};
