import * as React from 'react'
//import {observable} from "mobx";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {Stores} from '../../store/Stores';

@observer
class SubMenu extends React.Component<any, any> {
    history:any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
    }

    navigate = (url:any) =>{
        this.history.push(url);
    };

    isActiveMenu=(path)=>{
        return window.location.pathname === path;
    }
    
    render() {
        let feature = this.props.feature;
        return (
            <div className={this.props.expanded ? "panel-collapse": "panel-collapse panel-close"}>
                {
                    (feature && feature.name === "Manage Partners") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/partners/partner')}
                             className={this.isActiveMenu('/partners/partner') ? 'sub-menu-item active':'sub-menu-item'}>Partner Profile</div>
                        <div onClick={()=>this.navigate('/partners/branch')}
                             className={this.isActiveMenu('/partners/branch') ?'sub-menu-item active':'sub-menu-item'}>Branch Locations</div>
                        <div  onClick={()=>this.navigate('/partners/user')}
                             className={this.isActiveMenu('/partners/user') ?'sub-menu-item active':'sub-menu-item'}>Branch Managers</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Manage Customers") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/customers/customer')}
                             className={this.isActiveMenu('/customers/customer') ?'sub-menu-item active':'sub-menu-item'}>Customer Profile</div>
                        <div onClick={()=>this.navigate('/customers/branch')}
                             className={this.isActiveMenu('/customers/branch') ? 'sub-menu-item active':'sub-menu-item'}>Branch Locations</div>
                        <div onClick={()=>this.navigate('/customers/user')}
                             className={this.isActiveMenu('/customers/user') ?'sub-menu-item active':'sub-menu-item'}>Branch Managers</div>
                        <div onClick={()=>this.navigate('/customers/product')}
                             className={this.isActiveMenu('/customers/product') ?'sub-menu-item active':'sub-menu-item'}>Products</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "User Management") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/users/profile')}
                             className={this.isActiveMenu('/users/profile') ? 'sub-menu-item active':'sub-menu-item'}>User Roles</div>
                        <div onClick={()=>this.navigate('/users/permissions')}
                             className={this.isActiveMenu('/users/permissions') ?'sub-menu-item active':'sub-menu-item'}>Users</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Manage Masters") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/master/customerType')}
                             className={this.isActiveMenu('/master/customerType') ? 'sub-menu-item active':'sub-menu-item'}>Customer Types</div>
                        <div onClick={()=>this.navigate('/master/customerVertical')}
                             className={this.isActiveMenu('/master/customerVertical') ?'sub-menu-item active':'sub-menu-item'}>Industry Verticals</div>
                        <div onClick={()=>this.navigate('/master/partnerType')}
                             className={this.isActiveMenu('/master/partnerType') ?'sub-menu-item active':'sub-menu-item'}>Partner Types</div>
                        <div onClick={()=>this.navigate('/master/productCategory')}
                             className={this.isActiveMenu('/master/productCategory') ?'sub-menu-item active':'sub-menu-item'}>Product Categories</div>
                        <div onClick={()=>this.navigate('/master/packagingLevel')}
                             className={this.isActiveMenu('/master/packagingLevel') ?'sub-menu-item active':'sub-menu-item'}>Product Packaging Levels</div>
                        <div onClick={()=>this.navigate('/master/evdensContacts')}
                             className={this.isActiveMenu('/master/evdensContacts') ?'sub-menu-item active':'sub-menu-item'}>Evdens Contacts</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Notifications & Alerts") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/notifications/notification')}
                             className={this.isActiveMenu('/notifications/notification') ? 'sub-menu-item active':'sub-menu-item'}>Notifications</div>
                        <div onClick={()=>this.navigate('/notifications/alert')}
                             className={this.isActiveMenu('/notifications/alert') ?'sub-menu-item active':'sub-menu-item'}>Alerts</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Reports") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/reports/dispatch')}
                             className={this.isActiveMenu('/reports/dispatch') ? 'sub-menu-item active':'sub-menu-item'}>Dispatch Report</div>
                        <div onClick={()=>this.navigate('/reports/packagedlabel')}
                             className={this.isActiveMenu('/reports/packagedlabel') ? 'sub-menu-item active':'sub-menu-item'}>Activity Report</div>

                    </div> : null
                }
                {
                    (feature && feature.name === "Consumer Engagement") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/consumerEngage/pushMessages')}
                             className={this.isActiveMenu('/consumerEngage/pushMessages') ? 'sub-menu-item active':'sub-menu-item'}>Push Messages</div>
                        <div onClick={()=>this.navigate('/consumerEngage/customMessages')}
                             className={this.isActiveMenu('/consumerEngage/customMessages') ?'sub-menu-item active':'sub-menu-item'}>Custom Messages</div>
                        <div onClick={()=>this.navigate('/consumerEngage/complaint')}
                             className={this.isActiveMenu('/consumerEngage/complaint') ?'sub-menu-item active':'sub-menu-item'}>Complaints</div>
                        <div onClick={()=>this.navigate('/consumerEngage/feedback')}
                             className={this.isActiveMenu('/consumerEngage/feedback') ?'sub-menu-item active':'sub-menu-item'}>Feedback</div>
                        {
                            Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS"&&
                                <div onClick={()=>this.navigate('/consumerEngage/brandReferrals')}
                                    className={this.isActiveMenu('/consumerEngage/brandReferrals') ?'sub-menu-item active':'sub-menu-item'}>Brand Referrals</div>
                        }
                    </div> : null
                }
                {
                    (feature && feature.name === "Company Profile") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/company/customer')}
                             className={this.isActiveMenu('/company/customer') ? 'sub-menu-item active':'sub-menu-item'}>My Company Details</div>
                        <div onClick={()=>this.navigate('/company/branch')}
                             className={this.isActiveMenu('/company/branch') ?'sub-menu-item active':'sub-menu-item'}>Branch Locations</div>
                        <div onClick={()=>this.navigate('/company/user')}
                             className={this.isActiveMenu('/company/user') ?'sub-menu-item active':'sub-menu-item'}>Branch Managers</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Manage Products") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/products/product')}
                             className={this.isActiveMenu('/products/product') ? 'sub-menu-item active':'sub-menu-item'}>Products</div>
                        <div onClick={()=>this.navigate('/products/productDetail')}
                             className={this.isActiveMenu('/products/productDetail') ?'sub-menu-item active':'sub-menu-item'}>Product Details</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Manage Supply Chain") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/supplyChain/partner')}
                             className={this.isActiveMenu('/supplyChain/partner') ? 'sub-menu-item active':'sub-menu-item'}>Partners</div>
                        <div onClick={()=>this.navigate('/supplyChain/branch')}
                             className={this.isActiveMenu('/supplyChain/branch') ?'sub-menu-item active':'sub-menu-item'}>Branch Locations</div>
                        <div onClick={()=>this.navigate('/supplyChain/user')}
                             className={this.isActiveMenu('/supplyChain/user') ?'sub-menu-item active':'sub-menu-item'}>Branch Managers</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Label Orders") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/labelOrder/createOrder')}
                             className={this.isActiveMenu('/labelOrder/createOrder') ? 'sub-menu-item active':'sub-menu-item'}>Place Orders</div>
                        <div onClick={()=>this.navigate('/labelOrder/viewOrder')}
                             className={this.isActiveMenu('/labelOrder/viewOrder') ?'sub-menu-item active':'sub-menu-item'}>View Orders</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Fulfill Label Orders") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/generateLabel/specificCustomer')}
                             className={this.isActiveMenu('/generateLabel/specificCustomer') ? 'sub-menu-item active':'sub-menu-item'}>Orders</div>
                        <div onClick={()=>this.navigate('/generateLabel/status')}
                             className={this.isActiveMenu('/generateLabel/status') ?'sub-menu-item active':'sub-menu-item'}>Order Status</div>
                    </div> : null
                }
                {
                    (feature && feature.name === "Ops Management") ? <div className="sub-menu">
                        <div onClick={()=>this.navigate('/OpsManagement/manageLabel')}
                             className={this.isActiveMenu('/OpsManagement/manageLabel') ? 'sub-menu-item active':'sub-menu-item'}>Manage Labels</div>
                    </div> : null
                }
            </div>
        );
    }
};

const SubMenuComponent = withRouter(SubMenu);
export {SubMenuComponent as SubMenu};

