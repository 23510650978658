import {action, observable} from 'mobx';

class Menu {
    @observable expanded:boolean = false;

    @action
    setBranch = (branch: any) => {
        this.expanded = branch.expanded;
        return this;
    }
    @action reset() {
        this.expanded = false;
    }
}

export {Menu};
