import React from 'react';
import {withRouter} from "react-router-dom";
import  { Auth } from 'aws-amplify';
import { observer } from 'mobx-react';
import {observable} from "mobx";
import Form from '../../constants/Form';
import { Credential } from '../../store/Credential';
import eyeVision from "../../assets/image/eye-vision.png";
import eyeVisionHide from "../../assets/image/eye-vision-hide.png";
import {LoadToast, ToastType} from "../../config/Toast";

@observer
class CreatePassword extends React.Component<any, any> {
    @observable userName: string = "";
    @observable creantials = new Credential();
    @observable errorMsg:string= '';
    @observable passwordMatch:string = "";
    @observable newPasswordType:boolean = false;
    @observable confirmPasswordType:boolean = false;
    @observable showLoginButton:boolean = false;

    history:any;
    constructor(props: any) {
         super(props);
         this.history = props.history;    
         this.userName = this.props.match.params.userName;       
    }
    navigateRoute = (url:any) =>{
        this.history.push(url)
    };
    changeNewPasswordType =() => {
        this.newPasswordType = ! this.newPasswordType;
    }
    changeConfirmPasswordType =() => {
        this.confirmPasswordType = ! this.confirmPasswordType;
    }
	handleUserInput = (targetObject:any, event:any) => {
        targetObject[event.target.id] = event.target.value;
        if(event.target.id === "confirmPassword") {
            this.checkConfirmPassword();
        }
    };

    checkConfirmPassword = ()=>{
        this.passwordMatch = '';
        if(this.creantials.password !== this.creantials.confirmPassword) {
            this.passwordMatch = "Passwords do not match";
        }
    }

    resetPassword = async() => {
        this.errorMsg = '';
        if(this.creantials.password !== this.creantials.confirmPassword){  
            this.passwordMatch = "Passwords do not match";
        } else {
            let attribute ={email: this.userName};
            await Auth.signIn(this.userName, this.userName).then(response=>{
                if (response.challengeName === 'NEW_PASSWORD_REQUIRED'){
                    Auth.completeNewPassword(response,this.creantials.password,attribute
                    ).then(user => {
                        LoadToast(ToastType.SUCCESS, "Password has been generated successfully");
                        this.showLoginButton = true;
                    }).catch( error => {
                        this.showLoginButton = false;
                    })
                }   
                else {
                    Auth.currentAuthenticatedUser().then(user => {
                        Auth.changePassword(user, this.userName, this.creantials.password).then(response=>{
                            LoadToast(ToastType.SUCCESS, "Password has been generated successfully");
                            Auth.signOut();
                            this.navigateRoute('/login');
                        }).catch(error=>{
                            this.errorMsg = error.message;
                        });
                    });
                }

            }).catch(error=>{
                if(error.name === "NotAuthorizedException"){
                    this.errorMsg = "Password has already been generated."
                }
                if(error.name === "UserNotConfirmedException"){
                    this.errorMsg = "User email not confirmed."
                }
                if(error.name === "UserNotFoundException") {
                    this.errorMsg = "Your email Id has not been verified yet"
                }
            });     
        }
    };
    render() {
        return (
            <div className="authentication-wrapper authentication-3">
                <div className="authentication-inner">
                    <div className="d-none d-lg-flex col-lg-8 align-items-center background-bg p-5" >
                        <div className="ui-bg-overlay bg-dark opacity-50"></div>
                            <div className="w-100 text-white px-5">
                                <div className="text-large font-weight-light">
                                </div>
                            </div>
                        </div>
                        <div className="d-flex col-lg-4 bg-white p-5 align-items-center">
                        <div className="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                                <div className="w-100">
                                    <div className="d-flex justify-content-center align-items-center mb-4">
                                        <div className="ui w-100">
                                            <div className="w-100 text-center position-relative" >
                                                <img src={require("../../assets/image/ic_evdens_t_new.png")} style={{width:"200px"}} alt="logo" />
                                            </div>
                                        </div>
                                    </div>
                                    <Form submit={this.resetPassword}>
                                        <h5 className='font-weight-bold'>Generate Password</h5>
                                       <div className="form-group">
                                            <label className="form-label w-100 text-left">Password</label>
                                            <input type={this.newPasswordType?'text':'password'} value={this.creantials.password} autoComplete={"off"} name="password" minLength={8} maxLength={15}
                                                   data-x='{"tooShort":"Password length must be between 8 to 15 characters"}' id="password"onChange={this.handleUserInput.bind(this,this.creantials)}className="form-control" required={true} />
                                           <img className='eye' src={this.newPasswordType?eyeVisionHide:eyeVision} alt='createPassword' height='20px' width='20px' style={{marginRight:'5px'}} onClick={this.changeNewPasswordType} />
                                            <div className="invalid error pl-3"/>
                                         </div>
                                        <div className="form-group">
                                            <label className="form-label w-100 text-left">Confirm Password</label>
                                            <input type={this.confirmPasswordType?'text':'password'} autoComplete={"off"} name="confirmPassword" minLength={8} maxLength={15}
                                                   data-x='{"tooShort":"Password length must be between 8 to 15 characters"}'  id="confirmPassword" value={this.creantials.confirmPassword} onChange={this.handleUserInput.bind(this,this.creantials)}className="form-control" required={true} />
                                            <img className='eye' src={this.confirmPasswordType?eyeVisionHide:eyeVision} alt='createPassword' height='20px' width='20px' style={{marginRight:'5px'}} onClick={this.changeConfirmPasswordType} />
                                            <div className="invalid error pl-3">
                                            </div>
                                            <div hidden={!this.passwordMatch} className="error pl-3">{this.passwordMatch}</div>
                                        </div>
                                        {
                                            !this.showLoginButton &&
                                            <div className="d-flex justify-content-between align-items-center m-0">
                                                <button type="submit" className="btn btn-primary"> Submit</button>
                                            </div>
                                        }

                                        {
                                            this.showLoginButton &&
                                            <div className="d-flex justify-content-center align-items-center mt-5">
                                                <button type="button" className="ml-3 btn btn-primary"
                                                        onClick={() => this.navigateRoute('/login')}> Login
                                                </button>
                                            </div>
                                        }
                                    </Form>
                               
                                    <div className="card-footer p-2" style={{borderTop:"none",fontSize:"14px"}} hidden={!this.errorMsg}>
                                        <div  className="text-center badge-outline-danger error" style={{boxShadow:'none'}}>
                                            {this.errorMsg}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        )
     }
}

const CreatePasswordComponent = withRouter(CreatePassword);

export {CreatePasswordComponent as CreatePassword};
