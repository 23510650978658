import * as React from "react";
import {observer} from "mobx-react";
import { observable } from "mobx";
import Autosuggest from 'react-autosuggest';
import { BaseCustomer } from "../../config/HttpClients";
import {Stores} from "../../store/Stores";
import {Customer} from "../../store/Customer";
import {getLoggedInUser} from '../../constants/CommonMethods';

@observer
class PartnerSearch extends React.Component<any, any> {
    @observable customer : Customer = new Customer();

    constructor(props){
        super(props);
        this.state = {
            nameValue: '',
            partners: []
        };
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        if(Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS") {
            this.customer = new Customer();
        } else {
            this.customer = Stores.currentCustomer;
        }
    }

    onSuggestionChange = (event, {newValue, method}) => {
        this.setState({
            nameValue: newValue
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        if(!this.customer.id) { this.customer.id = 'null';}
        BaseCustomer.get("/customer/searchByName?name="+value+"&userType=PARTNER&customer="+this.customer.id+"&userTypeId="+Stores.currentUser.userType.id)
            .then(response => {
                if(response.data.payload.length===0 ){
                    this.props.setNewPartner();
                }
                this.setState({ partners: response.data.payload});
            });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            partners: []
        });
    }
    getSuggestion = (suggestion) => {
        return suggestion.name;
    }

    renderSuggestion = (suggestion) => {return ( <span>{suggestion.name}</span>);};

    onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
        this.props.setSelectedPartner(suggestion);
        this.setState({
           nameValue: '',
            partners: []
        });
    }

    render() {
        const {nameValue, partners} = this.state;
        const partnerInputProps =
            {
                placeholder: "Search",
                value: nameValue,
                partners:partners,
                onChange: this.onSuggestionChange
            };

        const renderInputComponent = inputProps => (
            <div>
                <input {...inputProps} />
                <div className={"react-autosuggest__suggestions-container--open text-center p-2 error"} hidden={!(inputProps.partners.length === 0 && inputProps.value !== "")}>No Partner found...!</div>
            </div>
        );
        return (
            <Autosuggest
                suggestions={partners}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestion}
                renderSuggestion={this.renderSuggestion}
                inputProps={partnerInputProps}
                renderInputComponent={renderInputComponent}
            />
        )
    };

}

export {PartnerSearch};
