import React from 'react';
import { withRouter } from "react-router-dom";
import { FormGroup, ControlLabel, Button, ButtonToolbar } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { observable } from "mobx";
import Form from '../../constants/Form';
import { BaseProduct } from '../../config/HttpClients';
import { ProductCategory } from '../../store/ProductCategory';
import { Stores } from '../../store/Stores';
import { LoadToast, ToastType } from "../../config/Toast";
import * as ReactDOM from 'react-dom';
import { getLoggedInUser } from "../../constants/CommonMethods";
import { NumberField } from "../../constants/formfields/NumberField";

@observer
class ProductForm extends React.Component<any, any> {

    @observable categories: ProductCategory[] = [];
    @observable fileInput: any;
    @observable form: any;
    @observable subcategories: string[] = [];
    @observable brandNames: string[] = [];
    @observable gtinError: boolean = false;
    @observable isFileTooLarge: boolean = false;

    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init();
        }
    }

    componentWillUnmount() {
        // make sure you remove the listener when the component is destroyed
        document.removeEventListener('click', this.handleClick, false);
    }
    componentDidMount() {
        this.props.onRef(this);
        document.addEventListener('click', this.handleClick, false);
    }
    handleClick = e => {
        const node = ReactDOM.findDOMNode(this.refs.searchInput);
        if (node instanceof HTMLElement) {
            if (!node.contains(e.target)) {
                this.subcategories = [];
                this.brandNames = [];
            }
        }
    }

    init = () => {
        this.getProductCategory();
    }

    getProductCategory = () => {
        if (Stores.currentCustomer && Stores.currentCustomer.vertical && Stores.currentCustomer.vertical.id) {
            BaseProduct.get("/category/vertical/active?vertical=" + Stores.currentCustomer.vertical.id).then(response => {
                this.categories = response.data.payload;
            });
        }
    }

    getProductSubCategory = (product) => {
        if (product.subCategory) {
            BaseProduct.get("/product/subCategory?subCategory=" + product.subCategory).then(response => {
                this.subcategories = response.data.payload;
            });
        }
    }

    getBrandNames = (product) => {
        if (product.brandName) {
            BaseProduct.get("/product/brandName?brandName=" + product.brandName + "&customerId=" + Stores.currentCustomer.id).then(response => {
                this.brandNames = response.data.payload;
            });
        }
    }

    handleCategory = (product, event) => {
        product.category = { id: event.target.value };
    };

    handleType = (product, event) => {
        product.productType =  event.target.value;
    };

    handleSubCategory = (product, event) => {
        product.subCategory = event.target.value;
        this.getProductSubCategory(product);
    };

    handleBrandName = (product, event) => {
        product.brandName = event.target.value;
        this.getBrandNames(product);
    }

    setSubCategory = (subcategory) => {
        this.props.product.subCategory = subcategory;
        this.subcategories = [];
    };
    setBrandNames = (brandName) => {
        this.props.product.brandName = brandName;
        this.brandNames = [];
    };


    handleInputFile = (files, event) => {
        this.props.setFilesEmpty();
        this.handleFileObject(event.target.files, files)
    };
    handleFileObject = (data, files) => {
        let dataObj = data.length !== 0 ? this.handleFile(data) : {};
        Object.keys(dataObj).map(key => {
            files[key] = data[key];
            return true;
        });
        if (!this.isFileTooLarge) {
            this.props.showimage();
        }
    };

    handleFile = (files) => {
        this.isFileTooLarge = false;
        if (this.checkImageType(files)) {
            LoadToast(ToastType.WARN, "File Type is not allowed ,Fotmats jpeg, jpg, png");
        } else {
            if (!this.checkFileSize(files)) {
                this.isFileTooLarge = true;
                return {};
            }
            return files;
        }
        return {};
    };
    checkImageType = (files) => {
        let data;
        let allowedExtension = ['jpeg', 'jpg', 'png'];
        Object.keys(files).map(key => {
            data = allowedExtension.filter((data) => {
                if (Object.keys(files).length !== 0) {
                    return files[key].type.includes(data);
                }
            })
        });
        return data.length === 0;
    };

    checkFileSize = (files) => {
        let fileSize = 0;
        Object.keys(files).map(key => { fileSize += files[key].size; return true; });
        return fileSize < 102400;
    };

    reset = () => {
        this.form.reset();
        this.gtinError = false;
    };
    validate = () => {
        if (Stores.currentCustomer.productGtin) {
            if (!this.props.product.gtin) {
                this.gtinError = true;
                return;
            }
        }
        if (this.isFileTooLarge) {
            return;
        }
        this.props.save();
    }

    render() {
        let isUpdate = false;
        let isShowSave = false;
        let isImageRequired = true;
        if (this.props.product.hasOwnProperty('id')) {
            if (this.props.product.imageUrl !== null && this.props.product.imageUrl !== "") {
                isImageRequired = false;
            }
            if (this.props.product.productStatus !== null && this.props.product.productStatus !== "" && this.props.product.productStatus !== 'Needs Approval') {
                isUpdate = true;
                isShowSave = true;
            }
        }
        return (
            <Form submit={this.validate} ref={reference => {
                this.form = reference;
            }} >
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="category" bsSize="large">
                            <ControlLabel className="font-weight-bold">Product Category</ControlLabel>
                            <select className="form-control" value={this.props.product.category && this.props.product.category.id}
                                onChange={this.handleCategory.bind(this, this.props.product)} required={true} id="category" disabled={isUpdate || this.props.disableInput} >
                                <option value={''}>Select Product Category</option>
                                {this.categories.map((e, key) => {
                                    return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    <div className='col-6' ref="searchInput">
                        <FormGroup controlId="subcategory" className="react-autosuggest__container" bsSize="large">
                            <ControlLabel className="font-weight-bold">Sub-category</ControlLabel>
                            <input type="text" className="form-control react-autosuggest__input rounded input-height" value={this.props.product.subCategory}
                                onChange={this.handleSubCategory.bind(this, this.props.product)} maxLength={255}
                                id="subcategory" required={true} disabled={isUpdate || this.props.disableInput} placeholder="Sub-category" />
                            {
                                this.subcategories && this.subcategories.length > 0 &&

                                <div className="react-autosuggest__suggestions-container--open">
                                    {
                                        this.subcategories && this.subcategories.map((e, key) => {
                                            return <p key={key} className="cursor-pointer w-100 py-1 pl-1 mb-0" onClick={() => this.setSubCategory(e)}>{e}</p>
                                        })
                                    }
                                </div>
                            }
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6' ref="searchInput">
                        <FormGroup controlId="brandName" className="react-autosuggest__container" bsSize="large">
                            <ControlLabel className="font-weight-bold">Brand Name</ControlLabel>
                            <input type="text" className="form-control react-autosuggest__input rounded input-height" value={this.props.product.brandName}
                                onChange={this.handleBrandName.bind(this, this.props.product)} maxLength={255}
                                id="brandName" required={true} disabled={isUpdate || this.props.disableInput} placeholder="Brand Name" />
                            {
                                this.brandNames && this.brandNames.length > 0 &&
                                <div className="react-autosuggest__suggestions-container--open">
                                    {
                                        this.brandNames && this.brandNames.map((e, key) => {
                                            return <p key={key} className="cursor-pointer w-100 py-1 pl-1 mb-0" onClick={() => this.setBrandNames(e)}>{e}</p>
                                        })
                                    }
                                </div>
                            }
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="name" bsSize="large">
                            <ControlLabel className="font-weight-bold">Product Name</ControlLabel>
                            <input type="text" className="form-control" value={this.props.product.name}
                                onChange={this.props.handleUserInput.bind(this, this.props.product)} maxLength={255}
                                id="name" required={true} disabled={isUpdate || this.props.disableInput} placeholder="Product Name" />
                            <div className="invalid error" />
                        </FormGroup>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="productType" bsSize="large">
                            <ControlLabel className="font-weight-bold">Product Type</ControlLabel>
                            <select className="form-control" value={this.props.product.productType && this.props.product.productType}
                                onChange={this.handleType.bind(this, this.props.product)} required={true} id="productType" disabled={isUpdate || this.props.disableInput} >
                                <option value={''}>Select Product Type</option>
                                <option className="select-option" value={'rawMaterial'}>{'Raw Material'}</option>
                                <option className="select-option" value={'inProcessProduct'}>{'In-process Product'}</option>
                                <option className="select-option" value={'finishedProduct'}>{'Finished Product'}</option>
                            </select>
                            <div className="invalid error" />
                        </FormGroup>
                    </div>
                    <div className='col-6'>
                        <FormGroup controlId="imageUrl" bsSize="large">
                            <ControlLabel className="font-weight-bold">Upload Product Image</ControlLabel>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" value={this.props.files && this.props.files.length > 0 && this.props.files[0].name ? this.props.files[0].name : ''} disabled={true}
                                    onChange={this.props.handleUserInput.bind(this, this.props.product)} maxLength={255}
                                    placeholder="imageUrl " id="imageUrl" />
                                <div className="input-group-append">
                                    <label htmlFor="file-upload" className="btn button-grey-color mb-0">
                                        {this.props.files.length > 0 ? 'Change File' : 'Browse File'}
                                    </label>
                                    <input id="file-upload" accept=".png, .jpg, .jpeg" type="file" name="files"
                                        required={((isImageRequired) && (this.props.files.length === 0)) ? true : false}
                                        ref={instance => { this.fileInput = instance }} disabled={isUpdate || this.props.disableInput}
                                        onChange={this.handleInputFile.bind(this, this.props.files)}
                                    /><br />
                                    <div className="invalid error" />
                                </div>
                                <span style={{ color: 'grey' }}>Max upload file size: 100KB</span>
                            </div>
                            {
                                this.isFileTooLarge &&
                                <p className="mb-0 error"> File is too large</p>
                            }
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <FormGroup controlId="gtin" bsSize="large">
                            <ControlLabel className="font-weight-bold">GTIN</ControlLabel>
                            <NumberField maxlength={14} disabled={isUpdate || this.props.disableInput} placeholder="GTIN"
                                required={Stores.currentCustomer.productGtin} valueName="gtin" stateValue={this.props.product} />
                            <div className="invalid error" />
                            {this.gtinError && !this.props.product.gtin &&
                                <div className="error">This field is required</div>
                            }
                        </FormGroup>
                    </div>
                </div>
                {
                    this.props.product && this.props.product.productId &&
                    <div className='row'>
                        <div className='col-6'>
                            <FormGroup controlId="productId" bsSize="large">
                                <ControlLabel className="font-weight-bold">Product Id</ControlLabel>
                                <p className="mb-0">{this.props.product.productId}</p>
                            </FormGroup>
                        </div>
                    </div>
                }
                {
                    ((Stores.currentPermission.create) && !this.props.disableInput && !isUpdate && !isShowSave) ? (
                        <ButtonToolbar className="justify-content-center">
                            <Button className="btn btn-app-primary" type="submit" > SUBMIT</Button>
                        </ButtonToolbar>
                    ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }
                {
                    ((Stores.currentPermission.modify && this.props.product && this.props.product.hasOwnProperty('id')) && !this.props.disableInput && !isUpdate && isShowSave) ? (
                        (this.props.product.id) &&
                        <ButtonToolbar className="justify-content-center">
                            <Button className="btn btn-app-primary" type="submit"> SUBMIT</Button>
                        </ButtonToolbar>
                    ) :
                        (
                            <React.Fragment></React.Fragment>
                        )
                }
            </Form>
        )
    }
}

const ProductFormComponent = withRouter(ProductForm);
export { ProductFormComponent as ProductForm };
