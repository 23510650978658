import {action, observable} from 'mobx';
import {Label} from "./Label";

class LabelDto {
    @observable labelList:Label[] = [];

    @action
    setProduct = (labelDto: any) => {
        this.labelList = labelDto.labelList;
        return this;
    }
    @action reset() {
        this.labelList = [new Label()];
    }
}

export {LabelDto};
