import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {ControlLabel, FormGroup} from "react-bootstrap";
import {observable} from "mobx";
import {Stores} from "../../store/Stores";
import { Role } from '../../store/Role';
import InputMask from 'react-input-mask';
import {getLoggedInUser} from "../../constants/CommonMethods";
import {User} from "../../store/User";

@observer
class MyProfileForm extends React.Component<any, any> {

    @observable roles:Role[] = [];
    @observable user = new User();

    constructor(props) {
        super(props);
        getLoggedInUser().then(response=>{
            this.user = Stores.currentUser;
        });
    }

    render() {
        return(
            <div className="pt-3">
                <div className="col-12 px-0">
                    <div className="card b-none">
                        <div className="card-body">
                                <div className='row'>
                                    <div className='col-6'>
                                        <FormGroup controlId="firstName" bsSize="large">
                                            <ControlLabel className="font-weight-bold">First Name</ControlLabel>
                                            <input type="text" className="form-control" readOnly value={this.user&&this.user.firstName}/>
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup controlId="lastName" bsSize="large">
                                            <ControlLabel className="font-weight-bold">Last Name</ControlLabel>
                                            <input type="text" className="form-control" readOnly value={this.user&&this.user.lastName}/>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <FormGroup controlId="Mobile Number" bsSize="large">
                                            <ControlLabel className="font-weight-bold">Mobile Number</ControlLabel>
                                            <InputMask type="text"  className="form-control" readOnly value={this.user&&this.user.mobileNumber} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup controlId="emailId" bsSize="large">
                                            <ControlLabel className="font-weight-bold">Email-Id</ControlLabel>
                                            <input type="email" className="form-control" readOnly value={this.user&&this.user.emailId} />
                                        </FormGroup>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const MyProfileFormComponent = withRouter(MyProfileForm);
export {MyProfileFormComponent as MyProfileForm};
