import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';

@observer
class ProductImage extends React.Component<any, any> {
    
    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
   }  
    
    render() {
        return(
            <div className="row mx-0">
                <div className="col-12 px-0" style={{height:'220px'}}>
                    {
                        this.props.showImageDelete&&
                        <div className='w-100' style={{height:'17px'}}>
                            <img className="float-right" style={{width: '13px',cursor:'pointer'}}
                                 alt="deleteRole" onClick={()=>this.props.deleteImage()}
                                 src={require("../../assets/image/delete.jpg")}/>
                        </div>
                    }
                    <div className="text-center" style={{border:'2px solid #CBCBCB',height:'180px'}}>
                    {
                        this.props.imgUrl&&
                            <img  src={this.props.imgUrl} style={{maxHeight:'176px',maxWidth:'100%',}} alt="product"/>
                       
                    }
                    </div>
                    <p className="pt-2 text-center font-size-14" hidden={this.props.disabled?true:false}>Uploaded Image</p>
                </div>
            </div>
        )
    }
}

const ProductImageComponent = withRouter(ProductImage);
export {ProductImageComponent as ProductImage};
