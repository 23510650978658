import {action, observable} from 'mobx';
import { UserType } from './UserType';
import { Role } from './Role';
import { Persona } from './Persona';


class User   {
    @observable id:string = '';
    @observable userName:string = '';
    @observable firstName:string = '';
    @observable lastName:string = '';
    @observable branch:string = '';
    @observable userType:UserType = new UserType();
    @observable roles:Role[] = [];
    @observable emailId:string = '';
    @observable confirmEmailId:string='';
    @observable mobileNumber:string = '';
    @observable customer: string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';
    @observable persona:Persona = new Persona();
    @observable status:string = '';

    @action
    setUser = (user: any) => {
        this.id = user.id;
        this.userName = user.userName;
        this.firstName = user.firstName;
        this.lastName= user.lastName
        this.branch = user.branch
        this.userType = user.userType
        this.emailId = user.emailId
        this.confirmEmailId = user.confirmEmailId
        this.mobileNumber = user.mobileNumber
        this.roles = user.roles;
        this.customer = user.customer;
        this.createdBy = user.createdBy;
        this.updatedBy = user.updatedBy;
        this.persona = user.persona;
        this.status = user.status;
        return this;
    }
    @action reset() {
        this.id       = '';
        this.userName = '';
        this.firstName= '';
        this.lastName = '';
        this.branch   = '';
        this.userType = new UserType();
        this.roles    = [new Role()];
        this.mobileNumber = '';
        this.emailId ='';
        this.confirmEmailId = '';
        this.customer = '';
        this.createdBy= '';
        this.updatedBy= '';
        this.persona  = new Persona();
        this.status = '';
    }
}

export {User};
