import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';

@observer
class MyProfileSideNav extends React.Component<any, any> {

    history:any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
    }
    navigate = (url:any) =>{
        this.history.push(url);
    };

    isActiveMenu=(path)=>{
        return window.location.pathname === path ? true:false;
    }

    render() {
        return(
            <div className="card b-none" style={{backgroundColor:"#F2F2F2"}}>
                <div className="card-body p-0">
                    <div className="inner-sidenav">
                        <ul className='p-0 mb-0'>
                            <li  onClick={()=>this.navigate('/myProfile/profile')}
                                 className={this.isActiveMenu('/myProfile/profile') ?'active':''}>
                                <small>My</small><br/>
                                Profile
                            </li>
                            <li  onClick={()=>this.navigate('/myProfile/changePassword')}
                                 className={this.isActiveMenu('/myProfile/changePassword') ?'active':''}>
                                <small>Change</small><br/>
                                Password
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const MyProfileSideNavComponent = withRouter(MyProfileSideNav);
export {MyProfileSideNavComponent as MyProfileSideNav};
