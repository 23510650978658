import {Auth} from "aws-amplify";
import {BaseCustomer, BaseUser} from "../config/HttpClients";
import {Stores} from "../store/Stores";
import moment from 'moment';

const strictAlphabets = (event) => {

    if (event.which < 48 || event.which > 57)
    {
        event.preventDefault();
    }
};

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const viewDateFormat = (date) => {
    if(date) {
         date = new Date(date);
          date = date.getFullYear() + '-' + monthNames[date.getMonth()] + '-'+ ("0" + date.getDate()).slice(-2);
        return date;
    }   	
};

const viewDateTimeFormat = (date) => {
    if(date) {
        let momentDate = moment.utc(date).local().toString();
        date = new Date(momentDate);
        let minutes = date.getMinutes().toString().length === 1 ? '0'+date.getMinutes() :date.getMinutes();
        let hours = date.getHours().toString().length === 1 ? '0'+date.getHours() : date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        let ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        date = date.getFullYear() + '-' + monthNames[date.getMonth()] + '-'+ ("0" + date.getDate()).slice(-2)+' '+hours+':'+minutes+ampm;;
        return date;
    }
};

const convertDatePickerDateTime = (date) => {
    if(date) {
        date = new Date(date);
        let minutes = date.getMinutes().toString().length === 1 ? '0'+date.getMinutes() :date.getMinutes();
        let hours = date.getHours().toString().length === 1 ? '0'+date.getHours() : date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        let ampm = date.getHours() >= 12 ? ' PM' : ' AM';
        date = date.getFullYear() + '-' + monthNames[date.getMonth()] + '-'+ ("0" + date.getDate()).slice(-2)+' '+hours+':'+minutes+ampm;;
        return date;
    }
};

const convertDatePickerDateTimeFromUtc = (date) => {
    if(date) {
        date = moment.utc(date,"YYYY-MMM-DD hh:mm A").local().format("YYYY-MMM-DD hh:mm A");
        return date;
    }
};

const DateToStringFormat = (date) => {
    if(date) {
        date = new Date(date);
          return date.getFullYear() + '-' +("0"+(date.getMonth()+1)).slice(-2) + '-'+ ("0" + date.getDate()).slice(-2);
    }
};

const stringToDateFormat = (date) => {
    if(date) {
        let paths =  date.split('-');
        return paths[0] + '-' +("0"+(monthNames.indexOf(paths[1])+1)).slice(-2)+ '-'+ ("0" + paths[2]).slice(-2);
    }
};

const getLoggedInUser=()=> {
 return new Promise(resolve => {  
     Auth.currentAuthenticatedUser().then(response=>{
        BaseUser.get("/user/"+response.username).then(
            response=> {
                Stores.currentUser = response.data.payload;
                if(Stores.currentUser.userType.name !== "VGLENS") {
                   getCurrentCustomer(Stores.currentUser.customer).then(response=>{
                    resolve("success");
                   });
                } else {
                    resolve("success");
                }
            }
        )
    });
    });
}

const getCurrentCustomer=(customer)=>{
    return new Promise(resolve => { 
        BaseCustomer.get("/customer/"+customer)
            .then(response => {
                Stores.currentCustomer = Stores.setCustomer(response.data.payload);
                resolve("success");
        });
    });
}

const showImage=(file)=>{
    return new Promise(resolve => {
        let reader = new FileReader();
        if(file) {
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
                resolve( reader.result);
            }
        }
    });
}

const checkStringIsJson=(str)=>{
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

export{strictAlphabets,DateToStringFormat,viewDateFormat,stringToDateFormat,viewDateTimeFormat,convertDatePickerDateTime,convertDatePickerDateTimeFromUtc,getLoggedInUser,showImage, checkStringIsJson}
