import {action, observable} from 'mobx';
import {StatusAudit} from "./StatusAudit";

class Notification   {

    @observable id:string = '';
    @observable secondaryId:string = '';
    @observable customerId:string = '';
    @observable labelCustomer:string = '';
    @observable customerName:string = '';
    @observable productId:string = '';
    @observable productName:string = '';
    @observable productCategory:string = '';
    @observable productSubCategory:string = '';
    @observable status:string = '';
    @observable scanResult:string = '';
    @observable noOfTimeScanned:string = '';
    @observable firstName:string = '';
    @observable lastName:string = '';
    @observable userName:string = '';
    @observable location:string = '';
    @observable justification:string = '';
    @observable userType:string = '';
    @observable type:string = '';
    @observable notificationType:string = '';
    @observable alertType:string = '';
    @observable branchLocationName:string = '';
    @observable packagedLevel:string = '';
    @observable productionLocation:string = '';
    @observable notificationId:string = '';
    @observable lastAggregatedUser:string = '';
    @observable notificationDate:string = '';
    @observable count:number = 0;
    @observable scannedImageKey1:string = '';
    @observable scannedImageKey2:string = '';
    @observable scannedImageKey3:string = '';
    @observable scannedImageKey4:string = '';
    @observable referenceImageKey:string = '';
    @observable labelGeneratedPartnerName:string = '';
    @observable labelGeneratedDate:string = '';
    @observable labelGeneratedDateConvert:string = '';
    @observable createdDate:string = '';
    @observable createdDateConvert:string = '';
    @observable primaryId:string = '';
    @observable dispatchCountry:string ='';
    @observable userCountry:string ='';
    @observable scanType:string = '';
    @observable reason:string = '';
    @observable reasonCode:string = '';
    @observable notificationStatusAudits:StatusAudit[] = [];
    @observable successCount:number = 0;
    @observable reasonJson:string = '';
    @observable codeType:string = '';

    @action
    setNotification = (notification: any) => {
        this.id = notification.id;
        this.customerId = notification.customerId;
        this.labelCustomer = notification.labelCustomer;
        this.customerName = notification.customerName;
        this.productId = notification.productId;
        this.productName = notification.productName;
        this.productCategory = notification.productCategory;
        this.productSubCategory = notification.productSubCategory;
        this.scanResult = notification.scanResult;
        this.noOfTimeScanned = notification.noOfTimeScanned;
        this.status = notification.status;
        this.secondaryId = notification.secondaryId;
        this.firstName = notification.firstName;
        this.lastName = notification.lastName;
        this.location = notification.location;
        this.justification = notification.justification;
        this.userType  = notification.userType;
        this.type  = notification.type;
        this.branchLocationName = notification.branchLocationName;
        this.packagedLevel =   notification.packagedLevel;
        this.productionLocation = notification.productionLocation;
        this.notificationId = notification.notificationId;
        this.lastAggregatedUser = notification.lastAggregatedUser;
        this.labelGeneratedPartnerName = notification.labelGeneratedPartnerName;
        this.labelGeneratedDate = notification.labelGeneratedDate;
        this.primaryId = notification.primaryId;
        this.dispatchCountry = notification.dispatchCountry;
        this.userCountry = notification.userCountry;
        this.scanType = notification.scanType;
        this.notificationStatusAudits = notification.notificationStatusAudits;
        this.reasonJson = notification.reasonJson;
        this.codeType = notification.codeType;
        return this;
    }
}

export {Notification};
