import {observer} from "mobx-react";
import React from "react";
import {withRouter} from "react-router";
import {observable} from "mobx";
import {DateToStringFormat, getLoggedInUser} from "../../constants/CommonMethods";
import {BaseLabelRead, BaseNotification} from "../../config/HttpClients";
import {GoodsOutboundReportDto} from "../../store/GoodsOutboundReportDto";
import {EvdensCodeConsumedReportDto} from "../../store/EvdensCodeConsumedReportDto";
import {EvdensCodeSourcedConsumedReportDto} from "../../store/EvdensCodeSourcedConsumedReportDto";
import {ConsumerScannedReportDto} from "../../store/ConsumerScannedReportDto";
import {NotificationReportDto} from "../../store/NotificationReportDto";
import {TradePartnerScannedReportDto} from "../../store/TradePartnerScannedReportDto";
import {Customer} from "../../store/Customer";
import {Branch} from "../../store/Branch";
import {Stores} from "../../store/Stores";
import {GoodsOutboundDetail} from "./GoodsOutboundDetail";
import {EvdensCodeConsumedDetail} from "./EvdensCodeConsumedDetail";
import {EvdensCodeSourcedDetail} from "./EvdensCodeSourcedDetail";
import {ConsumerScannedDetail} from "./ConsumerScannedDetail";
import {NotificationRaisedDetail} from "./NotificationRaisedDetail";
import {TradePartnerScannedDetail} from "./TradePartnerScannedDetail";
import {CustomerSearch} from "../customer/CustomerSearch";
import {BranchSearch} from "../branch/BranchSearch";

@observer
class Dashboard extends React.Component<any, any> {
    @observable goodsOutboundTodayDate: any;
    @observable goodsOutboundTodayDateValue: any;
    @observable goodsOutboundTillDate: any;
    @observable goodsOutboundTillDateValue:any;
    @observable evdensCodeConsumedTodayDate: any;
    @observable evdensCodeConsumedTodayDateValue:any;
    @observable evdensCodeConsumedTillDate: any;
    @observable evdensCodeConsumedTillDateValue:any;
    @observable evdensCodeSourcedTillDate: any;
    @observable evdensCodeSourcedTillDateValue:any;
    @observable consumerScannedTodayDate: any;
    @observable consumerScannedTodayDateValue:any;
    @observable consumerScannedTillDate: any;
    @observable consumerScannedTillDateValue:any;
    @observable notificationTodayDate: any;
    @observable notificationTodayDateValue:any;
    @observable notificationTillDate: any;
    @observable notificationTillDateValue:any;
    @observable tradePartnerTodayDate: any;
    @observable tradePartnerTodayDateValue:any;
    @observable tradePartnerTillDate: any;
    @observable tradePartnerTillDateValue:any;
    @observable expectedMinDate: any;
    @observable yesterdayDate: any;
    @observable customer = new Customer();
    @observable branch = new Branch();
    @observable goodsOutboundReportDto:GoodsOutboundReportDto = new GoodsOutboundReportDto();
    @observable evdensCodeConsumedReportDto:EvdensCodeConsumedReportDto = new EvdensCodeConsumedReportDto();
    @observable evdensCodeSourcedConsumedReportDto:EvdensCodeSourcedConsumedReportDto = new EvdensCodeSourcedConsumedReportDto();
    @observable consumerScannedReportDto:ConsumerScannedReportDto = new ConsumerScannedReportDto();
    @observable notificationReportDto:NotificationReportDto = new NotificationReportDto()
    @observable tradePartnerScannedReportDto:TradePartnerScannedReportDto = new TradePartnerScannedReportDto();
    @observable customerSearch: any;
    @observable branchSearch:any;
    @observable customerShow:boolean = false;
    @observable branchShow:boolean = false;
    @observable disableDate:boolean = false;

    constructor(props) {
        super(props);
        this.expectedMinDate = new Date('1950-01-01');
        this.yesterdayDate = new Date().setDate(new Date().getDate() - 1);
        this.goodsOutboundTodayDate = new Date();
        this.goodsOutboundTodayDateValue = DateToStringFormat(this.goodsOutboundTodayDate);
        this.goodsOutboundTillDate = new Date();
        this.goodsOutboundTillDateValue = DateToStringFormat(this.goodsOutboundTillDate);
        this.evdensCodeSourcedTillDate = new Date();
        this.evdensCodeSourcedTillDateValue = DateToStringFormat(this.evdensCodeSourcedTillDate);
        this.tradePartnerTodayDate = new Date();
        this.tradePartnerTodayDateValue = DateToStringFormat(this.tradePartnerTodayDate);
        this.tradePartnerTillDate = new Date();
        this.tradePartnerTillDateValue = DateToStringFormat(this.tradePartnerTillDate);
        this.evdensCodeConsumedTodayDate = new Date();
        this.evdensCodeConsumedTodayDateValue = DateToStringFormat(this.evdensCodeConsumedTodayDate);
        this.evdensCodeConsumedTillDate = new Date();
        this.evdensCodeConsumedTillDateValue = DateToStringFormat(this.evdensCodeConsumedTillDate);
        this.consumerScannedTodayDate = new Date();
        this.consumerScannedTodayDateValue = DateToStringFormat(this.consumerScannedTodayDate);
        this.consumerScannedTillDate = new Date();
        this.consumerScannedTillDateValue = DateToStringFormat(this.consumerScannedTillDate);
        this.notificationTodayDate = new Date();
        this.notificationTodayDateValue = DateToStringFormat(this.notificationTodayDate);
        this.notificationTillDate = new Date();
        this.notificationTillDateValue = DateToStringFormat(this.notificationTillDate);
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init()
        }
    }

    componentWillUnmount() {
        Stores.customer = new Customer();
    }

    init = () => {
        if(Stores.currentUser&&Stores.currentUser.userType.name === "CUSTOMER") {
            this.customer = Stores.currentCustomer;
            if(Stores.currentUser&&Stores.currentUser.roles.length > 0 &&Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN"){
                this.branchShow = true;
            } else  {
                this.branch.id = Stores.currentUser.branch;
                this.branchShow = false;
            }
            // if(Stores.currentUser&&Stores.currentUser.roles.length > 0 &&Stores.currentUser.roles[0].name !== "ROLE_CUSTOMER_ADMIN"||Stores.currentUser&&Stores.currentUser.roles.length > 0 &&Stores.currentUser.roles[0].name !== "ROLE_CUSTOMER_BRANCH_ADMIN" ){
            //     this.disableDate = true;
            // }
            this.initMethods();
            this.customerShow = false;
        } else if(Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS"){
            this.customerShow = true;
            this.branchShow = true;
            this.initMethods();
        }
    }

    initMethods=()=>{
        this.getGoodsOutboundReport(this.customer.id,this.branch.id,this.goodsOutboundTodayDateValue,this.goodsOutboundTillDateValue);
        this.getEvdensCodeConsumedReport(this.customer.id,this.branch.id,this.evdensCodeConsumedTodayDateValue,this.evdensCodeConsumedTillDateValue);
        this.getEvdensCodeSourcedReport(this.customer.id,this.branch.id,this.evdensCodeSourcedTillDateValue);
        this.getConsumerScannedReport(this.customer.id,this.branch.id,this.consumerScannedTodayDateValue,this.consumerScannedTillDateValue);
        let partners:any = [];
        this.customer.partners.forEach(partner=>{
            partners.push(partner.partner);
        });
        this.getTradePartnerScannedReport(this.customer.id,this.branch.id,partners,this.tradePartnerTodayDateValue,this.tradePartnerTillDateValue);
        this.getNotificationsReport(this.customer.id,this.branch.id,this.notificationTodayDateValue,this.notificationTillDateValue);
    }

    getGoodsOutboundReport = (customer, branch, date, tillDate) => {
        let customerId;
        if (customer) {
            customerId = customer;
        } else {
            customerId = "null";
        }
        let branchId;
        if (branch) {
            branchId = branch;
        } else {
            branchId = "null";
        }
        BaseLabelRead.get("/labelRead/goodsOutBoundReport?customer="+customerId+"&branch="+branchId+"&date="+date+"&toDate="+tillDate).then(response=>{
            if(response.data.payload === null){
                this.goodsOutboundReportDto = new GoodsOutboundReportDto();
            } else {
                this.goodsOutboundReportDto = response.data.payload;
            }
        });
    }

    getEvdensCodeConsumedReport=(customer,branch,date,tillDate)=>{
        let customerId;
        if (customer) {
            customerId = customer;
        } else {
            customerId = "null";
        }
        let branchId;
        if (branch) {
            branchId = branch;
        } else {
            branchId = "null";
        }
        BaseLabelRead.get("/labelRead/evdensCodeConsumedReport?customer="+customerId+"&branch="+branchId+"&date="+date+"&toDate="+tillDate).then(response=>{

            if(response.data.payload === null){
                this.evdensCodeConsumedReportDto = new EvdensCodeConsumedReportDto();
            } else {
                this.evdensCodeConsumedReportDto = response.data.payload;
            }
        });
    }

    getEvdensCodeSourcedReport=(customer,branch,date)=>{
        let customerId;
        if (customer) {
            customerId = customer;
        } else {
            customerId = "null";
        }
        let branchId;
        if (branch) {
            branchId = branch;
        } else {
            branchId = "null";
        }
        BaseLabelRead.get("/labelRead/evdensCodeSourcedAndConsumedReport?customer="+customerId+"&branch="+branchId+"&toDate="+date).then(response=>{
            this.evdensCodeSourcedConsumedReportDto = response.data.payload;
        });
    }

    getConsumerScannedReport=(customer,branch,date,tillDate)=>{
        let customerId;
        if (customer) {
            customerId = customer;
        } else {
            customerId = "null";
        }
        let branchId;
        if (branch) {
            branchId = branch;
        } else {
            branchId = "null";
        }
        BaseLabelRead.get("/labelRead/consumerScannedReport?customer="+customerId+"&branch="+branchId+"&date="+date+"&toDate="+tillDate).then(response=>{
            this.consumerScannedReportDto = response.data.payload;
        });
    }

    getNotificationsReport=(customer,branch,date,tillDate)=>{
        let customerId;
        if (customer) {
            customerId = customer;
        } else {
            customerId = "null";
        }
        let branchId;
        if (branch) {
            branchId = branch;
        } else {
            branchId = "null";
        }
        BaseNotification.get("/notification/notificationReport?customer="+customerId+"&branch="+branchId+"&date="+date+"&toDate="+tillDate).then(response=>{
            this.notificationReportDto = response.data.payload;
        });
    }

    getTradePartnerScannedReport=(customer,branch,partners,date,tillDate)=>{
        let customerId;
        if (customer) {
            customerId = customer;
        } else {
            customerId = "null";
        }
        let branchId;
        if (branch) {
            branchId = branch;
        } else {
            branchId = "null";
        }
        BaseLabelRead.get("/labelRead/partnerScannedReport?customer="+customerId+"&branch="+branchId+"&partners="+partners+"&date="+date+"&toDate="+tillDate).then(response=>{
            this.tradePartnerScannedReportDto = response.data.payload;
        });
    }

    handleGoodsOutboundTodayDate=(date)=> {
        this.goodsOutboundTodayDate = date;
        if(this.goodsOutboundTodayDate) {
            this.goodsOutboundTodayDateValue = DateToStringFormat(date);
        }
        this.getGoodsOutboundReport(this.customer.id,this.branch.id,this.goodsOutboundTodayDateValue,this.goodsOutboundTillDateValue);
    }

    handleGoodsOutboundTillDate=(date)=> {
        this.goodsOutboundTillDate = date;
        if(this.goodsOutboundTillDate) {
            this.goodsOutboundTillDateValue = DateToStringFormat(date);
        }
        this.getGoodsOutboundReport(this.customer.id,this.branch.id,this.goodsOutboundTodayDateValue,this.goodsOutboundTillDateValue);
    }

    handleEvdensCodeSourcedYesterdayDate=(date)=> {
        this.evdensCodeSourcedTillDate = date;
        if(this.evdensCodeSourcedTillDate) {
            this.evdensCodeSourcedTillDateValue = DateToStringFormat(date);
        }
        this.getEvdensCodeSourcedReport(this.customer.id,this.branch.id,this.evdensCodeSourcedTillDateValue);
    }

    handleTradePartnerTodayDate=(date)=> {
        this.tradePartnerTodayDate = date;
        if(this.tradePartnerTodayDate) {
            this.tradePartnerTodayDateValue = DateToStringFormat(date);
        }
        let partners:any = [];
        this.customer.partners.forEach(partner=>{
            partners.push(partner.partner);
        });
        this.getTradePartnerScannedReport(this.customer.id,this.branch.id,partners,this.tradePartnerTodayDateValue,this.tradePartnerTillDateValue);
    }

    handleTradePartnerTillDate=(date)=> {
        this.tradePartnerTillDate = date;
        if(this.tradePartnerTillDate) {
            this.tradePartnerTillDateValue = DateToStringFormat(date);
        }
        let partners:any = [];
        this.customer.partners.forEach(partner=>{
            partners.push(partner.partner);
        });
        this.getTradePartnerScannedReport(this.customer.id,this.branch.id,partners,this.tradePartnerTodayDateValue,this.tradePartnerTillDateValue);

    }

    handleEvdensCodeConsumedTodayDate=(date)=> {
        this.evdensCodeConsumedTodayDate = date;
        if(this.evdensCodeConsumedTodayDate) {
            this.evdensCodeConsumedTodayDateValue = DateToStringFormat(date);
        }
        this.getEvdensCodeConsumedReport(this.customer.id,this.branch.id,this.evdensCodeConsumedTodayDateValue,this.evdensCodeConsumedTillDateValue);
    }

    handleEvdensCodeConsumedTillDate=(date)=> {
        this.evdensCodeConsumedTillDate = date;
        if(this.evdensCodeConsumedTillDate) {
            this.evdensCodeConsumedTillDateValue = DateToStringFormat(date);
        }
        this.getEvdensCodeConsumedReport(this.customer.id,this.branch.id,this.evdensCodeConsumedTodayDateValue,this.evdensCodeConsumedTillDateValue);
    }

    handleConsumerScannedTodayDate=(date)=> {
        this.consumerScannedTodayDate = date;
        if(this.consumerScannedTodayDate) {
            this.consumerScannedTodayDateValue = DateToStringFormat(date);
        }
        this.getConsumerScannedReport(this.customer.id,this.branch.id,this.consumerScannedTodayDateValue,this.consumerScannedTillDateValue);
    }

    handleConsumerScannedTillDate=(date)=> {
        this.consumerScannedTillDate = date;
        if(this.consumerScannedTillDate) {
            this.consumerScannedTillDateValue = DateToStringFormat(date);
        }
        this.getConsumerScannedReport(this.customer.id,this.branch.id,this.consumerScannedTodayDateValue,this.consumerScannedTillDateValue);
    }

    handleNotificationTodayDate=(date)=> {
        this.notificationTodayDate = date;
        if(this.notificationTodayDate) {
            this.notificationTodayDateValue = DateToStringFormat(date);
        }
        this.getNotificationsReport(this.customer.id,this.branch.id,this.notificationTodayDateValue,this.notificationTillDateValue);
    }

    handleNotificationTillDate=(date)=> {
        this.notificationTillDate = date;
        if(this.notificationTillDate) {
            this.notificationTillDateValue = DateToStringFormat(date);
        }
        this.getNotificationsReport(this.customer.id,this.branch.id,this.notificationTodayDateValue,this.notificationTillDateValue);
    };

    setSelectedCustomer = (customer) => {
        this.customer = new Customer();
        this.customer.reset();
        this.customer = customer;
        Stores.customer = customer;
        this.initMethods();
        this.branchSearch.setCustomer();
    }

    setNewCustomer = () => {
        this.customer = new Customer();
        this.customer.reset();
        Stores.customer =  new Customer();
        if(this.customer.id){
            this.customerSearch.clearFilter();
        }
        this.branchSearch.setCustomer();
        this.initMethods();
    }
    selectedBranch = (branch) => {
        this.branch = new Branch();
        this.branch = branch;
        this.initMethods();
    }

    setNewBranch = () => {
        this.branch = new Branch();
        this.branch.reset();
        this.initMethods();
    }

    render() {
        return (
            <div className='col-12 p-0 dashBoard'>
                <div className="row mx-0 py-3 align-items-center">
                    <div className='col-6'>
                        <h4 className="mb-0">Dashboard</h4>
                    </div>
                    <div className='col-6'>
                        <div className="row mx-0 justify-content-end">
                            {
                                this.customerShow&&
                                <div className="col-6 px-0">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-4 pl-0 font-size-14 font-weight-500">
                                            <p className="mb-0"> Customer</p>
                                        </div>
                                        <div className="col-8 pl-0">
                                            <CustomerSearch setSelectedCustomer={this.setSelectedCustomer}
                                                            setNewCustomer={this.setNewCustomer} ref={instance => {
                                                this.customerSearch = instance
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.branchShow&&
                                <div className="col-6 px-0">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-4 pl-0 font-size-14 font-weight-500">
                                            <p className="mb-0"> Branch</p>
                                        </div>
                                        <div className="col-8 p-0">
                                            <BranchSearch selectedBranch={this.selectedBranch} setNewBranch={this.setNewBranch}
                                                          branchType={'Plant'}
                                                          ref={instance => {
                                                              this.branchSearch = instance
                                                          }}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='row mx-0 px-3'>
                    <GoodsOutboundDetail goodsOutboundReportDto={this.goodsOutboundReportDto} yesterdayDate={this.yesterdayDate} expectedMinDate={this.expectedMinDate}
                                         goodsOutboundTodayDate={this.goodsOutboundTodayDate} handleGoodsOutboundTodayDate={this.handleGoodsOutboundTodayDate}
                                         goodsOutboundTillDate={this.goodsOutboundTillDate} handleGoodsOutboundTillDate={this.handleGoodsOutboundTillDate} disableDate={this.disableDate}/>
                    <EvdensCodeConsumedDetail evdensCodeConsumedReportDto={this.evdensCodeConsumedReportDto} yesterdayDate={this.yesterdayDate} expectedMinDate={this.expectedMinDate}
                                              evdensCodeConsumedTodayDate={this.evdensCodeConsumedTodayDate} handleEvdensCodeConsumedTodayDate={this.handleEvdensCodeConsumedTodayDate}
                                              evdensCodeConsumedTillDate={this.evdensCodeConsumedTillDate} handleEvdensCodeConsumedTillDate={this.handleEvdensCodeConsumedTillDate} disableDate={this.disableDate}/>
                </div>
                <div className='row mx-0 px-3 mt-3'>
                    <EvdensCodeSourcedDetail evdensCodeSourcedConsumedReportDto={this.evdensCodeSourcedConsumedReportDto} yesterdayDate={this.yesterdayDate} expectedMinDate={this.expectedMinDate}
                                             evdensCodeSourcedTillDate={this.evdensCodeSourcedTillDate} handleEvdensCodeSourcedYesterdayDate={this.handleEvdensCodeSourcedYesterdayDate}
                                             disableDate={this.disableDate}/>

                    <ConsumerScannedDetail consumerScannedReportDto={this.consumerScannedReportDto} yesterdayDate={this.yesterdayDate} expectedMinDate={this.expectedMinDate}
                                           consumerScannedTodayDate={this.consumerScannedTodayDate} handleConsumerScannedTodayDate={this.handleConsumerScannedTodayDate}
                                           consumerScannedTillDate={this.consumerScannedTillDate} handleConsumerScannedTillDate={this.handleConsumerScannedTillDate}
                                           disableDate={this.disableDate}/>

                </div>
                <div className='row mx-0 px-3 my-3'>
                    <NotificationRaisedDetail notificationReportDto={this.notificationReportDto} yesterdayDate={this.yesterdayDate} expectedMinDate={this.expectedMinDate}
                                              notificationTodayDate={this.notificationTodayDate} handleNotificationTodayDate={this.handleNotificationTodayDate}
                                              notificationTillDate={this.notificationTillDate} handleNotificationTillDate={this.handleNotificationTillDate}
                                              disableDate={this.disableDate}/>

                    <TradePartnerScannedDetail tradePartnerScannedReportDto={this.tradePartnerScannedReportDto} yesterdayDate={this.yesterdayDate} expectedMinDate={this.expectedMinDate}
                                               tradePartnerTodayDate={this.tradePartnerTodayDate} handleTradePartnerTodayDate={this.handleTradePartnerTodayDate}
                                               tradePartnerTillDate={this.tradePartnerTillDate} handleTradePartnerTillDate={this.handleTradePartnerTillDate}
                                               disableDate={this.disableDate}/>
                </div>
            </div>
        )
    };
}

const DashboardComponent = withRouter(Dashboard);
export {DashboardComponent as Dashboard};
