import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {DispatchReportDto} from "../../store/DispatchReportDto";
import {BaseLabelRead} from "../../config/HttpClients";
import {Button, ButtonToolbar} from "react-bootstrap";
import Form from "../../constants/Form";
import {Customer} from "../../store/Customer";
import {DispatchReportDetailsPagination} from "../../constants/pagination/DispatchReportDetailsPagination";
import {ScannedImage} from "./ScannedImage";
import {stringToDateFormat, viewDateFormat, viewDateTimeFormat, checkStringIsJson} from "../../constants/CommonMethods";
import {StatusAudit} from "../../store/StatusAudit";
import {Stores} from "../../store/Stores";

@observer
class DispatchReportDetails extends React.Component<any, any> {
    @observable dispatchreportdto = new DispatchReportDto();
    @observable comment: string = '';
    @observable justificationError: boolean = false;
    @observable totalPage = 0;
    @observable pageNo = 0;
    @observable customer: Customer = new Customer();
    @observable paginationFormat: any;
    @observable alertId: string = '';
    @observable alertType: string = '';
    @observable userType: string = '';
    @observable search: string = '';
    @observable status: string = '';
    @observable secondaryId: string = '';
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable scannedImgUrl1: any;
    @observable scannedImgUrl2: any;
    @observable scannedImgUrl3: any;
    @observable scannedImgUrl4: any;
    @observable referenceImgUrl: any;
    @observable showScannedImg: boolean = false;
    @observable showReferenceImg: boolean = false;
    history: any;
    @observable isConsumer: boolean = false;
    @observable isVerifiedOrAuth: boolean = false;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.pageNo = this.props.currentPageNo;
        this.totalPage = this.props.totalPage;
        this.fromDateValue = this.props.fromDateValue;
        this.toDateValue = this.props.toDateValue;
        this.search = this.props.search;
        this.alertType = this.props.alertType;
        this.userType = this.props.userType;
        this.secondaryId = this.props.secondaryId;
        this.status = this.props.status;
        //this.getDispatchReportsBySecondaryId();
    }

//     getDispatchReportsBySecondaryId = () => {
//         let search;
//         let status;
//         let userType;
//         let alertType;
//         let fromDateValue;
//         let toDateValue;
//         if (!this.customer.id) {
//             this.customer.id = 'null';
//         }
//         if (this.search !== '') {
//             search = this.search;
//         } else {
//             search = 'null';
//         }
//         if (this.status !== '') {
//             status = this.status;
//         } else {
//             status = 'null';
//         }
//         if (this.userType !== '') {
//             userType = this.userType;
//         } else {
//             userType = 'null';
//         }
//         if (this.alertType !== '') {
//             alertType = this.alertType;
//         } else {
//             alertType = 'null';
//         }
//         if(this.fromDateValue !== ''){
//             fromDateValue = this.fromDateValue;
//         } else {
//             fromDateValue = 'null';
//         }
//         if(this.toDateValue !== ''){
//             toDateValue = this.toDateValue;
//         } else {
//             toDateValue = 'null';
//         }
//         BaseLabelRead.get("/dispatchreport/secondaryId?pageNo=" + this.pageNo + "&limit=1&fromDate=" + fromDateValue + "&toDate=" + toDateValue + "&secondaryId=" + this.secondaryId + "&type=DispatchReport&alertType=" + alertType + "&from=" + userType + "&customer=" + this.customer.id + "&status=" + status + "&search=" + search).then(response => {
//             this.dispatchreportdto = response.data.payload[0];
//             if (this.dispatchreportdto.id) {
//                 this.dispatchreportdto.labelGeneratedDateConvert = viewDateFormat(this.dispatchreportdto.labelGeneratedDate);
//                 this.dispatchreportdto.createdDateConvert = viewDateTimeFormat(this.dispatchreportdto.createdDate);
//             }
//             this.paginationFormat.totalRecordsCount();
//             this.showScannedImg = false;
//             this.scannedImgUrl1 = '';
//             this.scannedImgUrl2 = '';
//             this.scannedImgUrl3 = '';
//             this.scannedImgUrl4 = '';
//             if(this.dispatchreportdto.reasonJson && checkStringIsJson(this.dispatchreportdto.reasonJson)) {
//                 var reason = JSON.parse(this.dispatchreportdto.reasonJson);
//                 var distHref = "";
//                 if(reason["lastScannedLocation"]) {
//                     distHref = "<a href=http://maps.google.com/?q=" + reason["lastScannedLocation"] + " target=_blank " +
//                                                 "rel=noopener noreferrer>" + reason["lastScannedLocation"] + ", </a>";
//                 }
//                 var distHtml = "";
//                 if(reason["distance"]) {
//                     var dist = Number(reason["distance"]).toFixed(2);
//                     distHtml = "<span>" + dist + ", </span>";
//                 }
//                 var dateHtml = "";
//                 if(reason["lastScannedDate"]) {
//                     var date = viewDateTimeFormat(reason["lastScannedDate"]);
//                     dateHtml = "<span>" + date + ", </span>";
//                 }
//
//                 var detectedHtml = "";
//                 if(reason["EdgesDetected"]) {
//                     detectedHtml = "<span>" + reason["EdgesDetected"] + "</span>";
//                 }
//
//                 var edgesHtml = "";
//                 if(reason["Assigned Edge"]) {
//                     var edges = reason["Assigned Edge"];
//                     edgesHtml = "<span>" + edges["bLeftLeft"] + "," + edges["bLeftRight"] + "," + edges["bRightLeft"] + "," + edges["bRightRight"] + "</span>";
//                 }
//
//                 this.dispatchreportdto.reason = "<div>" + distHref + distHtml + dateHtml + detectedHtml + edgesHtml + "</div>";
//             }
//             if (this.dispatchreportdto.scannedImageKey1) {
//                 this.getScannedImage(this.dispatchreportdto.scannedImageKey1,  "scannedImgUrl1");
//             }
//             if (this.dispatchreportdto.scannedImageKey2) {
//                 this.getScannedImage(this.dispatchreportdto.scannedImageKey2,  "scannedImgUrl2");
//             }
//             if (this.dispatchreportdto.scannedImageKey3) {
//                 this.getScannedImage(this.dispatchreportdto.scannedImageKey3,  "scannedImgUrl3");
//             }
//             if (this.dispatchreportdto.scannedImageKey4) {
//                 this.getScannedImage(this.dispatchreportdto.scannedImageKey4,  "scannedImgUrl4");
//             }
//             if (this.dispatchreportdto.referenceImageKey) {
//                 this.getScannedImage(this.dispatchreportdto.referenceImageKey, "referenceImgUrl");
//             }
//             if (this.dispatchreportdto.userType === 'CONSUMER') {
//                 this.isConsumer = true;
//             }
//
//             if (this.dispatchreportdto.scanType && (this.dispatchreportdto.scanType === 'Verified' ||
//                     this.dispatchreportdto.scanType === 'Authenticated')) {
//                 this.isVerifiedOrAuth = true;
//             }
//         });
//     }
//
//     getScannedImage = (fileName, ImageUrl) => {
//         BaseLabelRead.get("/dispatchreport/image?fileName=" + fileName, {
//             headers: {Accept: "*/*"},
//             responseType: "blob"
//         }).then(response => {
//             let blob = new Blob([response.data], {type: response.headers["content-type"]});
//             if (ImageUrl == "scannedImgUrl1")
//                 this.scannedImgUrl1 = URL.createObjectURL(blob);
//             if (ImageUrl == "scannedImgUrl2")
//                 this.scannedImgUrl2 = URL.createObjectURL(blob);
//             if (ImageUrl == "scannedImgUrl3")
//                 this.scannedImgUrl3 = URL.createObjectURL(blob);
//             if (ImageUrl == "scannedImgUrl4")
//                 this.scannedImgUrl4 = URL.createObjectURL(blob);
//             if (ImageUrl == "referenceImgUrl")
//                 this.referenceImgUrl = URL.createObjectURL(blob);
//         });
//     }
//
//     updateDispatchReport = () => {
//         this.setDateFormet(this.dispatchreportdto);
//         let statusAudit = new StatusAudit();
//         statusAudit.status = this.dispatchreportdto.status;
//         statusAudit.comment = this.comment;
//         statusAudit.updatedUser = Stores.currentUser.firstName;
//         statusAudit.createdAt = new Date().toUTCString();
//         if(this.dispatchreportdto.dispatchreportStatusAudits&&this.dispatchreportdto.dispatchreportStatusAudits.length > 0) {
//             this.dispatchreportdto.dispatchreportStatusAudits.push(statusAudit);
//         } else {
//             let statusAudits:StatusAudit[] = [];
//             statusAudits.push(statusAudit);
//             this.dispatchreportdto.dispatchreportStatusAudits = statusAudits;
//         }
//         BaseLabelRead.put("/dispatchreport", this.dispatchreportdto).then(response => {
//             this.comment = '';
//             this.getDispatchReportsBySecondaryId();
//         });
//     }
//
//     handleComment = (product, event) => {
//         this.comment = event.target.value;
//     };
//
//     setDateFormet = (dispatchreport) => {
//         dispatchreport.labelGeneratedDateConvert = stringToDateFormat(dispatchreport.labelGeneratedDateConvert);
//     }
//
//     handleChangePage = (pageNo) => {
//         this.pageNo = pageNo;
//         this.getDispatchReportsBySecondaryId();
//     }
//
//     handleBack = () => {
//         this.props.setOption('NOTIFICATION');
//     }
//
//     handleScannedImageShow = () => {
//         this.showScannedImg = !this.showScannedImg;
//     }
//
//     handleReferenceImageShow = () => {
//         this.showReferenceImg = !this.showReferenceImg;
//     }
//
//     handleUserInput = (targetObject, event) => {
//         targetObject[event.target.name] = event.target.value;
//     };
//
//     navigate = (url: any) => {
//         this.history.push(url);
//     };

    render() {
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

        return (
            <div className="col-12 pt-3 h-100 product-details">
            </div>
        )
    }
}

const DispatchReportDetailsComponent = withRouter(DispatchReportDetails);
export {DispatchReportDetailsComponent as DispatchReportDetails};
