import * as React from "react";
import { observer } from "mobx-react";
import { observable } from 'mobx';
import Form from "../../constants/Form";
import {
  FormGroup,
  ButtonToolbar, 
  Button,
  ControlLabel
} from "react-bootstrap";
import { CustomerType } from '../../store/CustomerType';
import { BaseCustomer } from '../../config/HttpClients';


@observer
class CustomerVerticalForm extends React.Component<any, any> {
  @observable customertypes : CustomerType[] =[];

  constructor(props: any) {
    super(props);
    this.getCustomerType();
  }

  getCustomerType=()=>{
    BaseCustomer.get("/customerType").then(response=>{
      this.customertypes = response.data.payload;
    });
  }

  handleUserInput = (targetObject:any, event:any) => {
		targetObject[event.target.id] = event.target.value;
	};
  
  handleStatus = (targetObject:any,status:any, event:any) => {
    targetObject[event.target.name] = status;
  }

  handleCustomerType = (customerVertical, event) => {
    customerVertical.customerType = {id:event.target.value};
  };

  	render() {
    return (
      <Form submit={this.props.save}>
        <div className="row">
          <div className="col-6">
          <div className="row mx-0">
            <div className="col-6 pl-0">
              <FormGroup controlId="name" bsSize="large">
                <ControlLabel className="font-weight-bold">Customer Type</ControlLabel>
                <select className="form-control"
                value={this.props.customerVertical&&this.props.customerVertical.customerType&&this.props.customerVertical.customerType.id} 
                onChange={this.handleCustomerType.bind(this, this.props.customerVertical)}
                required={true} id="vertical"  onClick={()=>this.props.getCustomerVertical(this.props.customerVertical)}>
                    <option value={''}>Select Customer Type</option>
                    {this.customertypes&&this.customertypes.map((e, key) => {
                        return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
              <div className="invalid error"/>
            </FormGroup>
            </div>
            <div className="col-6 pl-0">
            <FormGroup controlId="name" bsSize="large">
              <ControlLabel className="font-weight-bold">Industry Vertical</ControlLabel>
              <input type="text" className="form-control" value={this.props.customerVertical&&this.props.customerVertical.name}
                onChange={this.handleUserInput.bind(this, this.props.customerVertical)}  maxLength={255}
                 id="name" required={true} placeholder="Industry Vertical"/>
              <div className="invalid error"/>
            </FormGroup>
            </div>
            </div>
          </div>
          <div className="col-4 align-self-center pl-0">
            <FormGroup controlId="status" bsSize="large">
              <ControlLabel className="font-weight-bold">State</ControlLabel>
              <div className="row mx-0">
                <div className="ml-1 custom-control custom-radio">
                  <input type="radio" id={'active'} checked={this.props.customerVertical&&this.props.customerVertical.status === 'ACTIVE'}  onChange={this.handleStatus.bind(this, this.props.customerVertical,'ACTIVE')} className="custom-control-input" name="status" required={true}/>
                  <label className="custom-control-label" htmlFor={"active"}> Active</label>
                </div>  
                <div className="ml-2 custom-control custom-radio">
                  <input type="radio" id={'inActive'} checked={this.props.customerVertical&&this.props.customerVertical.status === 'INACTIVE'}  onChange={this.handleStatus.bind(this, this.props.customerVertical,'INACTIVE')} className="custom-control-input" name="status"/>
                  <label className="custom-control-label" htmlFor={"inActive"}> Inactive</label>
                  <div className="invalid error"/>  
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="col-2 pl-0 align-self-center">
            <ButtonToolbar >
              <Button className="btn button-sec-color" type="submit"> Save</Button>
             </ButtonToolbar>
          </div>
        </div>
      </Form>
    );
  }
}

export { CustomerVerticalForm };
