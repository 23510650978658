import {action, observable} from 'mobx';

class CustomerVertical {
    @observable id:string = '';
    @observable name:string = '';
    @observable status:string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';

    @action
    setCustomerVertical = (customerVertical: any) => {
        this.id = customerVertical.id;
        this.name = customerVertical.name;
        this.status = customerVertical.status;
        this.createdBy = customerVertical.createdBy;
        this.updatedBy = customerVertical.updatedBy;
        return this;
    }
    @action reset() {
        this.id = '';
        this.name = '';
        this.status = '';
        this.createdBy = '';
        this.updatedBy = '';
    }
}

export {CustomerVertical};
