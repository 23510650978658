import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Stores } from '../../store/Stores';
import { PackagingLevel } from '../../store/PackagingLevel';
import { BaseProduct } from '../../config/HttpClients';
import { PackagingLevelForm } from './PackagingLevelForm';
import { PackagingLevelSearch } from './PackagingLevelSearch';
import { PartnerTypeTableDetails } from '../partnerType/PartnerTypeTableDetails';

@observer
class PackagingLevels extends React.Component<any, any> {

    @observable packagingLevel = new PackagingLevel();
    @observable packaginglevels: PackagingLevel[] = [];

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.getPackagingLevel();
    }
    
    save = () => {
        if(this.packagingLevel.id){
            this.updatePackagingLevel(this.packagingLevel);
        } else {
            this.createPackagingLevel();
        }
    }

    createPackagingLevel=()=>{
        this.packagingLevel.createdBy = Stores.currentUser.id;
        BaseProduct.post("/packagingLevel", this.packagingLevel).then(response=>{
           this.setNewPackagingLevel();
        });
    }

    updatePackagingLevel=(packagingLevel)=>{
        packagingLevel.updatedBy = Stores.currentUser.id;
        BaseProduct.put("/packagingLevel", packagingLevel).then(response=>{
           this.setNewPackagingLevel();
        });
    }

    getPackagingLevel=()=>{
        BaseProduct.get("/packagingLevel").then(response=>{
            this.packaginglevels = response.data.payload; 
        });
    }

    setNewPackagingLevel=()=>{
        this.packagingLevel = new PackagingLevel();
        this.getPackagingLevel();
    }

    setSelectedPackagingLevel=(packagingLevel)=>{
        this.packagingLevel = packagingLevel;
    }


    render() {
        return(
                <div className="col-12 p-0 h-100">
                <div className="pb-2 pt-4 row ml-0 search-bg">
                    <div className='col-6 h-42'>
                        <b style={{letterSpacing:"1px",fontSize:"28px"}}> </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                            <PackagingLevelSearch setNewPackagingLevel={this.setNewPackagingLevel} setSelectedPackagingLevel={this.setSelectedPackagingLevel}/>
                        </div>
                    </div>
                </div>
                    <div className="row mx-0 py-3" style={{height:'calc(100% - 75px)'}}>
                        <div className="col-12 h-100">
                            <div className="card b-none h-100">
                                <div className="card-body h-100">                                   
                                    <div className="pt-2 h-100">
                                        <div className="col-12 px-0 h-100">
                                            <PackagingLevelForm  save={this.save} packagingLevel={this.packagingLevel} />
                                            <div className="row mx-0 mt-2" style={{height:'calc(100% - 75px)'}}>
                                                <div className="col-12 h-100">
                                                    <div>
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr className="row table-header">
                                                                    <th className="col-1 text-nowrap text-center">S.No</th>
                                                                    <th className="col-4 text-nowrap text-center">Packaging Level</th>
                                                                    <th className="col-4 text-nowrap text-center">Current State</th>
                                                                    <th className="col-3 text-nowrap text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                         </table>
                                                     </div>
                                                     <div className="row custom-scroll" style={{height:'calc(100% - 50px)',overflowY:'auto', overflowX:'hidden'}}>
                                                         <table className="table">
                                                            <tbody>
                                                                <PartnerTypeTableDetails partnerTypes = {this.packaginglevels} updatePartnerType = {this.updatePackagingLevel}/>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const PackagingLevelsComponent = withRouter(PackagingLevels);
export {PackagingLevelsComponent as PackagingLevels};
