import * as React from "react";
import { observer } from "mobx-react";
import { observable } from 'mobx';
import Form from "../../constants/Form";
import {
  FormGroup,
  ButtonToolbar, 
  Button,
  ControlLabel
} from "react-bootstrap";
import { CustomerVertical } from '../../store/CustomerVertical'; 
import { CustomerType } from '../../store/CustomerType';
import { BaseCustomer } from '../../config/HttpClients';

@observer
class ProductCategoryForm extends React.Component<any, any> {
  @observable customerVerticals : CustomerVertical[] =[];
  @observable customertypes : CustomerType[] =[];

  constructor(props: any) {
    super(props);
    this.getCustomerType();
  }

  getCustomerVertical=(productCategory)=>{
    if(productCategory.customerType) {
      BaseCustomer.get("/customerVertical/customerType?customerType="+productCategory.customerType).then(response=>{
        this.customerVerticals = response.data.payload;
      });
    }
  }

  getCustomerType=()=>{
    BaseCustomer.get("/customerType").then(response=>{
      this.customertypes = response.data.payload;
    });
  }

  handleUserInput = (targetObject:any, event:any) => {
		targetObject[event.target.id] = event.target.value;
	};

  handleStatus = (targetObject:any,status:any, event:any) => {
    targetObject[event.target.name] = status;
  }

  handleProductVertical = (productCategory, event) => {
    productCategory.vertical = event.target.value;
  };
  handleProductCustomerType = (productCategory, event) => {
    productCategory.customerType = event.target.value;
     productCategory.vertical = '';
  };

  render() {
    return (
      <Form submit={this.props.save}>
        <div className="row">
          <div className="col-6">
           <FormGroup controlId="name" bsSize="large">
              <ControlLabel className="font-weight-bold">Customer Type</ControlLabel>
               <select className="form-control"
                value={this.props.productCategory&&this.props.productCategory.customerType} 
                onChange={this.handleProductCustomerType.bind(this, this.props.productCategory)}
                required={true} id="vertical"  onClick={()=>this.getCustomerVertical(this.props.productCategory)}>
                    <option value={''}>Select Customer Type</option>
                    {this.customertypes&&this.customertypes.map((e, key) => {
                        return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
              <div className="invalid error"/>
            </FormGroup>
          </div>
          <div className="col-6">
           <FormGroup controlId="name" bsSize="large">
              <ControlLabel className="font-weight-bold">Industry Vertical</ControlLabel>
               <select className="form-control"
                value={this.props.productCategory&&this.props.productCategory.vertical} 
                onChange={this.handleProductVertical.bind(this, this.props.productCategory)}
                required={true} id="vertical" onClick={()=>this.props.getProductCategory(this.props.productCategory)}>
                    <option value={''}>Select Industry Vertical</option>
                    {this.customerVerticals&&this.customerVerticals.map((e, key) => {
                        return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
              <div className="invalid error"/>
            </FormGroup>
          </div>
        </div>
        <div className="row">
           <div className="col-6">
           <FormGroup controlId="name" bsSize="large">
              <ControlLabel className="font-weight-bold">Product Category</ControlLabel>
              <input type="text" className="form-control" value={this.props.productCategory&&this.props.productCategory.name}
                onChange={this.handleUserInput.bind(this, this.props.productCategory)}  maxLength={255}
                 id="name" required={true} placeholder="Product Category"/>
              <div className="invalid error"/>
            </FormGroup>
          </div>
          <div className="col-4 align-self-center">
            <FormGroup controlId="status" bsSize="large">
              <ControlLabel className="font-weight-bold">State</ControlLabel>
              <div className="row mx-0">
                <div className="ml-1 custom-control custom-radio">
                  <input type="radio" id={'active'} checked={this.props.productCategory&&this.props.productCategory.status === 'ACTIVE'}  onChange={this.handleStatus.bind(this, this.props.productCategory,'ACTIVE')} className="custom-control-input" name="status" required={true}/>
                  <label className="custom-control-label" htmlFor={"active"}> Active</label>
                </div>  
                <div className="ml-2 custom-control custom-radio">
                  <input type="radio" id={'inActive'} checked={this.props.productCategory&&this.props.productCategory.status === 'INACTIVE'}  onChange={this.handleStatus.bind(this, this.props.productCategory,'INACTIVE')} className="custom-control-input" name="status"/>
                  <label className="custom-control-label" htmlFor={"inActive"}> Inactive</label>
                  <div className="invalid error"/>  
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="col-2 align-self-center pl-0">
            <ButtonToolbar >
              <Button className="btn button-sec-color" type="submit"> Save</Button>
             </ButtonToolbar>
          </div>
        </div>      
      </Form>
    );
  }
}

export { ProductCategoryForm };
