import {action, observable} from 'mobx';
import { Customer } from './Customer';
import {Country} from "./Country";

class Branch   {
    @observable id:string = '';
    @observable name:string = '';
    @observable status:string = '';
    @observable country:Country = new Country();
    @observable state:string = '';
    @observable city:string = '';
    @observable address:string = '';
    @observable pincode:string = '';
    @observable customer:Customer = new Customer();
    @observable createdBy:string = '';
    @observable updatedBy:string = '';
    @observable headQuarter:boolean = false;
    @observable type:string = '';
    @observable gstin:string = '';
    @observable imageText:string = '';
    @observable image:string = '';
    @observable imageUrl:string = '';
    @observable branchImageUrl:string = '';

    @action
    setBranch = (branch: any) => {
        this.id = branch.id;
        this.name = branch.name;
        this.country = branch.country;
        this.city = branch.city;
        this.address = branch.address;
        this.pincode = branch.pincode;
        this.state = branch.state;
        this.status = branch.status;
        this.customer= branch.customer;
        this.createdBy = branch.createdBy;
        this.updatedBy = branch.updatedBy;
        this.headQuarter = branch.headQuarter;
        this.type = branch.type;
        this.gstin = branch.gstin;
        this.imageText = branch.imageText;
        this.image = branch.image;
        this.imageUrl = branch.imageUrl;
        this.branchImageUrl = branch.branchImageUrl;
        return this;
    }
    @action reset() {
        this.id             = '';
        this.name           = '';
        this.country        = new Country();
        this.city           = '';
        this.address        = '';
        this.state          = '';
        this.status         = '';
        this.customer       = new Customer();
        this.createdBy      = '';
        this.updatedBy      = '';
        this.headQuarter    = false;
        this.type           = '';
        this.gstin          = '';
        this.imageText      = '';
        this.image          = '';
        this.imageUrl       = '';
        this.branchImageUrl = '';
    }
}

export {Branch};
