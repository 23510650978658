import React from 'react';
import { withRouter } from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, ControlLabel } from 'react-bootstrap';
import { ScrapConfirmModal } from './ScrapConfirmModal';
import { FormGroup } from "react-bootstrap";
import { Datepicker } from '../../constants/formfields/Datepicker';
import { ProductSearch } from '../labelOrder/ProductSearch';
import { Product } from '../../store/Product';
import { User } from '../../store/User';
import { BranchSearch } from '../branch/BranchSearch';
import { Branch } from '../../store/Branch';
import Form from '../../constants/Form';
import { Stores } from "../../store/Stores";
import { getLoggedInUser } from "../../constants/CommonMethods";
import { BaseLabelWrite, BaseUser } from "../../config/HttpClients";
import { Customer } from '../../store/Customer';

const dateToStringFormat = (date) => {
  if (date) {
    date = new Date(date);
    return ("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  }
};

@observer
class ManageLabel extends React.Component<any, any> {

  @observable productSearchCom: any;
  @observable product: Product = new Product();
  @observable expectedMinExpiryDate: Date = new Date();
  @observable expectedMinMfgDate: Date = new Date();
  @observable confirmModal: any;
  @observable form: any;
  @observable user = new User();
  @observable role: any;
  @observable branch: Branch = new Branch();
  @observable expiryDate: any;
  @observable mfgDate: any;
  @observable scrapDetails;
  @observable secondaryIDStart: string = '';
  @observable secondaryIDEnd: string = '';
  @observable batchNo: string = '';
  @observable comment: string = '';
  @observable customers: Customer[] = [];
  @observable customer: Customer = new Customer();
  @observable branchSearch: any;
  @observable isProductReq: boolean = false;
  @observable isBranchReq: boolean = false;

  constructor(props: any) {
    super(props);
    if (Stores.currentUser.userName === "") {
      getLoggedInUser().then(response => {
        this.init();
      });
    } else {
      this.init();
    }

  }

  init = () => {
    //this.branchSearch.setCustomer();
  }

  setSelectedProduct = (product) => {
    this.product = product;
    this.isProductReq = false;
    this.isBranchReq = false;
  }

  setNewProduct = () => {
    this.product = new Product();
  };

  onSubmit = () => {
    if (!this.product.id) {
      this.isProductReq = true;
      return;
    }
    if (!this.branch.id && (Stores.currentUserRole.name == 'ROLE_VGLENS_ADMIN' || Stores.currentUserRole.name == 'ROLE_CUSTOMER_ADMIN')) {
      this.isBranchReq = true;
      return;
    }

    if (!this.branch.id){
        this.branch = Stores.currentBranch;
    }

    BaseLabelWrite.get(`/labelRead/countLabelBySecondaryId?secondaryIDStart=${this.secondaryIDStart}&secondaryIDEnd=${this.secondaryIDEnd}`).then((response) => {
      this.scrapDetails = response.data || {};
      this.confirmModal.show();
    }).catch(error => { });
  }

  onClearClickHandler = () => {
    this.form.reset();
    this.mfgDate = null;
    this.expiryDate = null;
    this.setNewBranch();
    this.branchSearch.reset();
    this.product = new Product();
    this.product.reset();
    this.productSearchCom.clearFilter();
  }


  onConfirmClickHandler = () => {
    const user = Stores.currentUser;
    const customer = Stores.currentCustomer;

    if (!this.branch.id){
        this.branch = Stores.currentBranch;
    }

    BaseLabelWrite.post("/labelWrite/bulkassign", {
      assignMode: "BULKASSIGN",
      comment: this.comment,
      currentBranchCountry: this.branch.country.name,
      currentBranchId: this.branch.id,
      currentBranchName: this.branch.name,
      customerId: customer.id,
      customerName: customer.name,
      partnerId: customer.id, // same as customer id
      productId: this.product.id,
      productName: this.product.name,
      secondaryIDStart: this.secondaryIDStart,
      secondaryIDEnd: this.secondaryIDEnd,
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      productMfgDetails: [{
        batchNo: this.batchNo,
        mfgDate: dateToStringFormat(this.mfgDate),
        expiryDate: dateToStringFormat(this.expiryDate),
      }]
    },
    ).then(response => {
        if (!response.data){
            this.confirmModal.showSuccess(response.data.message);
        }
        else{
            this.confirmModal.showSuccess('Invalid or Not your UID');
        }

    }).catch(error => console.log(error));
    this.confirmModal.close();
  }

  selectedBranch = (branch) => {
    this.branch = new Branch();
    this.branch = branch;
  }

  setNewBranch = () => {
    this.branch = new Branch();
    this.branch.reset();
  }

  onExpiryDateChangeHandler = (date) => {
    this.expiryDate = date;
  }

  onMfgDateChangeHandler = (date) => {
    this.expiryDate = null;
    this.expectedMinExpiryDate = new Date(date);
    if (this.expectedMinExpiryDate.getTime() < new Date().getTime()) {
      this.expectedMinExpiryDate = new Date();
    }
    this.expectedMinExpiryDate.setDate(this.expectedMinExpiryDate.getDate());
    this.mfgDate = date;
  }

  onInputChangeHandler(value, property) {
    this[property] = value;
  }

  render() {
    return (
      <div className="col-12 pt-3">
        <div className="row mx-0 pl-0">
          <div className="col-8 px-0">
            <div className="card b-none">
              <div className="card-body pt-0">
                <Form ref={instance => { this.form = instance }} submit={this.onSubmit}>
                  <div className="pt-2">
                    <div className="row">
                      <div className="col-3">
                        <ControlLabel className="font-weight-bold">Evdens UID Range</ControlLabel>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-6 pr-0">
                        <div className="col-8 pr-0">
                          <FormGroup controlId="manageLabelFrom" bsSize="large">
                            <ControlLabel className="font-weight-bold">From</ControlLabel>
                            <input type="text" className="form-control" maxLength={16}
                              pattern="[A-Za-z0-9]{14}\s\d"
                              onChange={(e) => this.onInputChangeHandler(e.target.value, 'secondaryIDStart')}
                              onInvalid={(event:any) => event.target.setCustomValidity('Please enter valid UID')}
                              placeholder="from " id="from" required={true} />
                            <div className="invalid error" />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="col-6 pr-0">
                        <div className="col-8 pr-0">
                          <FormGroup controlId="manageLabelTo" bsSize="large">
                            <ControlLabel className="font-weight-bold">To</ControlLabel>
                            <input type="text" className="form-control" maxLength={16}
                              pattern="[A-Za-z0-9]{14}\s\d"
                              onInvalid={(event:any) => event.target.setCustomValidity('Please enter valid UID')}
                              onChange={(e) => this.onInputChangeHandler(e.target.value, 'secondaryIDEnd')}
                              placeholder="to " id="to" required={true} />
                            <div className="invalid error" />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-6 pr-0">
                        <div className="col-8 pr-0">
                          <FormGroup controlId="manageLabelProduct" bsSize="large">
                            <ControlLabel className="font-weight-bold">Product</ControlLabel>
                            <ProductSearch
                              required
                              inputStyle={{ borderRadius: 0 }}
                              setSelectedProduct={this.setSelectedProduct}
                              setNewProduct={this.setNewProduct}
                              ref={(instance) => { this.productSearchCom = instance; }}
                            />
                            {
                              this.isProductReq &&
                              <div className="invalid error">Please select a product</div>
                            }
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-6 pr-0" hidden={Stores.currentUserRole.name !== 'ROLE_VGLENS_ADMIN' && Stores.currentUserRole.name !== 'ROLE_CUSTOMER_ADMIN'}>
                        <div className="col-8 pr-0">
                          <FormGroup controlId="manageLabelBranch" bsSize="large">
                            <ControlLabel className="font-weight-bold">Branch Location</ControlLabel>
                            <BranchSearch
                              required
                              inputStyle={{ borderRadius: 0 }}
                              ref={instance => {
                                this.branchSearch = instance;
                              }}
                              branchType={'Plant'}
                              selectedBranch={this.selectedBranch}
                              setNewBranch={this.setNewBranch}
                              statusType="ACTIVE"
                            />
                            {
                              this.isBranchReq &&
                              <div className="invalid error">Please select a branch</div>
                            }
                          </FormGroup>
                        </div>
                      </div>
                      <div className="col-6 pr-0">
                        <div className="col-8 pr-0">
                          <FormGroup controlId="manageLabelBatch" bsSize="large">
                            <ControlLabel className="font-weight-bold">Batch/Lot No</ControlLabel>
                            <input type="text" className="form-control" maxLength={255}
                              onChange={(e) => this.onInputChangeHandler(e.target.value, 'batchNo')}
                              placeholder="Batch/Lot No " id="batchNo"/>
                            <div className="invalid error" />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-6 pr-0">
                        <div className="col-8 pr-0">
                          <FormGroup controlId="manageLabelMfgDate" bsSize="large">
                            <ControlLabel className="font-weight-bold">Mfg Date</ControlLabel>
                            <Datepicker
                              value={this.mfgDate}
                              inputChangeHandler={this.onMfgDateChangeHandler}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="col-6 pr-0">
                        <div className="col-8 pr-0">
                          <FormGroup controlId="manageLabelExpDate" bsSize="large">
                            <ControlLabel className="font-weight-bold">Expiry Date</ControlLabel>
                            <Datepicker
                              disabled={!this.mfgDate}
                              value={this.expiryDate}
                              inputChangeHandler={this.onExpiryDateChangeHandler}
                              minDate={this.expectedMinExpiryDate} />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-12 pr-0">
                        <FormGroup controlId="comments" bsSize="large">
                          <ControlLabel className="font-weight-bold">Comment</ControlLabel>
                          <textarea className="form-control"
                            onChange={(e) => this.onInputChangeHandler(e.target.value, 'comment')}
                            placeholder="Add your comments here..." id="comment" />
                        </FormGroup>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <ControlLabel className="font-weight-bold">Note: </ControlLabel>
                        <p>This action will not alter already Assigned and Scrapped Evdens UID's</p>
                      </div>
                    </div>
                  </div>
                  <div className='btn-toolbar justify-content-center '>
                    <Button className="btn btn-app-secondary mr-2" onClick={this.onClearClickHandler}>CLEAR</Button>
                    <Button className="btn btn-app-primary mr-2" type="submit">SUBMIT</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className='col-4'>
            <div className="card b-none">
              <div className="card-body pt-0">
              </div>
            </div>
          </div>
        </div>
        <ScrapConfirmModal ref={instance => { this.confirmModal = instance }} scrapDetails={this.scrapDetails} submit={this.onConfirmClickHandler} />
      </div>
    )
  }
}

const ManageLabelComponent = withRouter(ManageLabel);
export { ManageLabelComponent as ManageLabel };
