import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from 'mobx-react';
import {observable} from "mobx";
import {Button} from "react-bootstrap";
import { BaseCustomer} from "../../config/HttpClients";
import {Pagination} from "../../constants/pagination/Pagination";
import {Datepicker} from "../../constants/formfields/Datepicker";
import {DateToStringFormat,convertDatePickerDateTimeFromUtc,getLoggedInUser} from "../../constants/CommonMethods";
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";
import {Stores} from "../../store/Stores";
import {PushMessage} from "../../store/PushMessage";
import {Country} from '../../store/Country';
import {Customer} from '../../store/Customer';
import {PushMessageForm} from "./PushMessageForm";
import {PushMessageTableDetails} from './PushMessageTableDetails';

@observer
class PushMessages extends React.Component<any, any> {

    @observable pageNo: number = 0;
    @observable limit: number = 10;
    @observable totalRecords: number = 0;
    @observable paginationFormat: any;
    @observable deleteAlertModal:any;
    @observable pushMessageFormCom:any;
    @observable disabled: boolean = true;
    @observable showCustomer:boolean = false;
    @observable fromDate: any;
    @observable fromDateValue: any;
    @observable toDateValue: any;
    @observable toDate: any;
    @observable expectedMinDate: any;
    @observable pageOption: string = 'VIEW';
    @observable messageType:string = 'all';
    @observable status:string = 'all';
    @observable pushMessages:PushMessage[] = [];
    @observable pushMessage:PushMessage = new PushMessage();
    @observable customer = new Customer();
    @observable customers:Customer[] = [];
    @observable countries:Country[] = [];
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        if (Stores.currentUser.userName === "") {
            getLoggedInUser().then(response => {
                this.init();
            });
        } else {
            this.init()
        }
    }

    init = () => {
        this.fromDate = DateToStringFormat((new Date(new Date().setDate(new Date().getDate() - 30))));
        this.toDate = DateToStringFormat(new Date());
        this.expectedMinDate = new Date('1950-01-01');
        this.expectedMinDate.setDate(this.expectedMinDate.getDate() + 4);
        this.fromDateValue = DateToStringFormat((new Date(new Date().setDate(new Date().getDate() - 30))));
        this.toDateValue = DateToStringFormat(new Date());
        this.customer = Stores.currentCustomer;
        if(Stores.currentUser&&Stores.currentUser.userType.name === "CUSTOMER"){
            this.showCustomer = false;
        } else {
            this.showCustomer = true;
            this.getCustomers();
        }
        this.getPushMessages();
    }

    getPushMessages= () => {
     if(!this.customer.id){
        this.customer.id = 'null';
     }
        BaseCustomer.get("/pushMessage?customer="+this.customer.id+"&messageType="+this.messageType+"&status="+this.status+"&fromDate="+this.fromDateValue+"&toDate="+this.toDateValue+"&pageNo="+this.pageNo+"&limit="+this.limit).then(response => {
            this.pushMessages = response.data.payload;
            this.totalRecords = response.data.count;
            this.pushMessages.map((pushMessage)=>{
                pushMessage.schedules.map((schedule)=>{
                    schedule.scheduleTime = convertDatePickerDateTimeFromUtc(schedule.scheduleTime);
                    return true;
                });
                return true;
            });
            this.paginationFormat&&this.paginationFormat.totalRecordsCount&&this.paginationFormat.totalRecordsCount();
         });
    }

    getCustomers=() => {
        BaseCustomer.get("/customer/userType?type=CUSTOMER").then(response=>{
            this.customers = response.data.payload;
        });
    }

    deletePushMessage=()=>{
        BaseCustomer.delete("/pushMessage?id="+this.pushMessage.id).then(response => {
            this.pushMessage = new PushMessage();
            this.deleteAlertModal.close();
            this.getPushMessages();
        });
    }

    deleteAlert=(event,pushMessage)=>{
        event.stopPropagation();
        this.pushMessage = pushMessage;
        this.deleteAlertModal.show();
    }

    handleCustomer= (customer, event) => {
        customer.id =  event.target.value;
        this.pageNo = 0;
        this.getPushMessages();
    };

    handlePageLimit = (limit, event) => {
        this.limit = event.target.value;
        this.pageNo = 0;
        this.getPushMessages();
    };

    handleFromDate = (date) => {
        this.fromDate = DateToStringFormat(date);
        if (this.fromDate) {
            this.fromDateValue = this.fromDate
        }
        this.pageNo = 0;
        this.getPushMessages();
    }

    handleToDate = (date) => {
        this.toDate = DateToStringFormat(date);
        if (this.toDate) {
            this.toDateValue = this.toDate
        }
        this.pageNo = 0;
        this.getPushMessages();
    }

    handleStatus = (event) => {
        this.status = event.target.value;
        this.pageNo = 0;
        this.getPushMessages();
    }

    handleChangePage = (pageNo) => {
        this.pageNo = pageNo;
        this.getPushMessages();
    }

    handleMessageType = (event) => {
        this.messageType = event.target.value;
        this.pageNo = 0;
        this.getPushMessages();
    }

    handleSelectCountry = (selectedOptions) => {
        this.countries = [];
        if (selectedOptions && selectedOptions.value) {
            this.countries.splice(0, 0, selectedOptions);
        }
    }

    viewPushMessage = (event,pushMessage) => {
        event.stopPropagation();
        this.pushMessage = pushMessage;
        this.setCountry(this.pushMessage.country);
        this.setOption("NEW");
    }

    copyAndNewMessage=()=>{
        let message:PushMessage = this.pushMessage;
        this.pushMessage = new PushMessage();
        this.pushMessage = message;
        this.pushMessage.id = "";
        this.pushMessage.status = "";
        this.pushMessage.customerId = "";
        this.pushMessage.statusAudits = [];
        if(this.pushMessage.schedules&&this.pushMessage.schedules.length > 0){
            this.removeScheduleId(this.pushMessage.schedules)
        }
        this.pushMessageFormCom&&this.pushMessageFormCom.init&&this.pushMessageFormCom.init();
    }

    removeScheduleId=(schedules)=>{
        schedules.map((schedule)=>{
            if(schedule.id) {
                delete schedule.id;
            }
            return true;
        });
    }

    setCountry = (country) => {
        this.countries = [];
        let countryObj = new Country();
        countryObj.value = country;
        countryObj.label = country;
        this.countries.push(countryObj);
    }

    newPushMessage = () => {
        this.pushMessage = new PushMessage();
        this.countries = [];
        this.setOption("NEW");
     }

    navigate = (url: any) => {
        this.history.push(url);
    };

    setOption=(option)=>{
        this.pageOption = option;
        if(this.pageOption ==="VIEW"){
            this.getPushMessages();
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.pageOption === 'VIEW'&&
                    <div className="col-12 py-3 h-100">
                        <div className="row mx-0 h-100">
                            <div className="col-12 h-100 px-0">
                                <div className="card b-none h-100">
                                    <div className="card-body h-100 px-3">
                                        <div className='row mx-0 mb-1'>
                                            <div className='col-3 pl-0'>From Date</div>
                                            <div className='col-3 pl-0'>To Date</div>
                                            <div className='col-3 pl-0'></div>
                                            <div className='col-3 pl-0'><div className='col-10 px-0 text-right float-right'>Items per page</div></div>
                                        </div>
                                        <div className='row mx-0 mb-2'>
                                            <div className="col-3 pl-0">
                                                <Datepicker value={this.fromDate} minDate={this.expectedMinDate}
                                                            inputChangeHandler={this.handleFromDate}
                                                            isRequired={true}></Datepicker>
                                            </div>
                                            <div className="col-3 pl-0">
                                                <Datepicker value={this.toDate} minDate={this.expectedMinDate}
                                                            inputChangeHandler={this.handleToDate}
                                                            isRequired={true}></Datepicker>
                                            </div>
                                            <div className='col-3 pl-0'>
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <div className='col-4 px-0 float-right'>
                                                    <select className="form-control"
                                                        value={this.limit}
                                                        id="customer"
                                                        onChange={this.handlePageLimit.bind(this, this.limit)}>
                                                        <option className="select-option" value={10}>10</option>
                                                        <option className="select-option" value={15}>15</option>
                                                        <option className="select-option" value={20}>20</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mx-0 mb-1'>
                                            <div className='col-3 pl-0'>Message type</div>
                                            <div className='col-3 pl-0'>Status</div>
                                            <div className='col-3 pl-0'>{this.showCustomer&&<span>Customer</span>}</div>
                                            <div className='col-3 pl-0'></div>
                                        </div>
                                        <div className='row mx-0 mb-1'>
                                            <div className='col-3 pl-0'>
                                                <select className="form-control"value={this.messageType}id="messageType"
                                                    onChange={this.handleMessageType.bind(this)}>
                                                    <option className="select-option" value={'all'}>All</option>
                                                    <option className="select-option" value={'Awareness'}>Awareness</option>
                                                    <option className="select-option" value={'PromotionalCampaign'}>Promotional Campaign</option>
                                                    <option className="select-option" value={'Offer'}>Offers</option>
                                                </select>
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <select className="form-control"value={this.status}id="status"
                                                    onChange={this.handleStatus.bind(this)}>
                                                    <option className="select-option" value={'all'}>All</option>
                                                    <option className="select-option" value={'Submitted'}>Submitted</option>
                                                    <option className="select-option" value={'Approved'}>Approved</option>
                                                    <option className="select-option" value={'Deactivated'}>Deactivated</option>
                                                </select>
                                            </div>
                                            <div className='col-3 pl-0'>
                                            {
                                                this.showCustomer&&
                                                <select className="form-control" value={this.customer.id}
                                                    id="customer" onChange={this.handleCustomer.bind(this,this.customer)}>
                                                    <option className="select-option" value={''}>All</option>
                                                    {this.customers.map((e, key) => {
                                                        return <option className="select-option" key={key} value={e.id}>{e.name}</option>;
                                                    })}
                                                </select>
                                            }
                                            </div>
                                            <div className='col-3 pl-0'>
                                                <Button className="btn btn-app-primary float-right" type="button" onClick={this.newPushMessage}>CREATE NEW</Button>
                                            </div>
                                        </div>
                                        {
                                            this.totalRecords > 0 &&
                                            <div className="row mx-0">
                                                <p className="mb-0 font-size-13 black-color">{this.totalRecords} record(s) found</p>
                                            </div>
                                        }
                                        <div className="row mx-0 mt-2" style={{height: 'calc(100% - 124px)'}}>
                                            <div className="col-12 h-100 px-0">
                                                <div className="row mx-0">
                                                    <table className="table m-0">
                                                        <thead>
                                                        <tr className="row mx-0 text-nowrap table-header" >
                                                            <th className="col-2 text-center px-0">Message Id</th>
                                                            <th className="col-2 text-center px-0">Message Type</th>
                                                            <th className="col-2 pl-0 text-center">Country</th>
                                                            <th className="col-2 text-center px-0">Target Consumers</th>
                                                            <th className="col-2 px-0 text-center">Date Created</th>
                                                            <th className="col-2 text-center px-0">Status</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div className="row mx-0 custom-scroll" style={{
                                                    marginTop: '-2px',
                                                    height: 'calc(100% - 98px)',
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden'
                                                }}>
                                                    <table className="table">
                                                        <tbody>
                                                            <PushMessageTableDetails pushMessages={this.pushMessages} viewPushMessage={this.viewPushMessage}
                                                             deleteAlert={this.deleteAlert} />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Pagination totalRecords={this.totalRecords}
                                                            displayRecords={this.limit} pageNo={this.pageNo}
                                                            partialPageCount={5}
                                                            handleChangePage={this.handleChangePage}
                                                            ref={ref => {
                                                                this.paginationFormat = ref
                                                            }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.pageOption === 'NEW' &&
                    <PushMessageForm pushMessage={this.pushMessage} newPushMessage={this.newPushMessage}
                     countries={this.countries} defaultCountries={this.countries} copyAndNewMessage={this.copyAndNewMessage}
                     handleSelectCountry={this.handleSelectCountry} setOption={this.setOption}
                     ref={(instance) => { this.pushMessageFormCom = instance; }}/>
                }
                <ErrorAlertModal ref={instance => {this.deleteAlertModal = instance}}
                                     submit={this.deletePushMessage}/>
            </React.Fragment>
        )
    }
}

const PushMessagesComponent = withRouter(PushMessages);
export {PushMessagesComponent as PushMessages};
