import React from 'react';
import { observer } from 'mobx-react';

@observer
class NotificationTableDetails extends React.Component<any, any> {
    
    render() {
        return(
            <React.Fragment>
            {
                this.props.notifications && this.props.notifications.map((notification,index)=>
                        <tr key={index} className="row mx-0 text-nowrap  cursor-pointer table-body-value" onClick={()=>this.props.viewNotification(notification)}>
                            <td className='col-2 px-0 text-center text-wrap'>{notification.notificationId} </td>
                            <td className="col-2 px-0 text-center text-wrap">{notification.secondaryId}</td>
                            <td className="col-2 px-0 text-center text-wrap">{notification.codeType?notification.codeType:<span>-</span>}</td>
                            <td className="col-1 px-0 text-center">{notification.count}</td>
                            {
                                this.props.isNotification&&
                                <td className="col-1 px-0 text-center text-wrap">{notification.notificationType}</td>
                            }
                            {
                                !this.props.isNotification&&
                                <td className="col-1 px-0 text-center text-wrap">{notification.alertType}</td>
                            }
                            <td className="col-1 px-0 text-center text-wrap">{notification.userType}</td>
                            <td className="col-2 px-0 text-center text-wrap">{notification.createdDateConvert}</td>
                            <td className="col-1 px-0 text-center text-wrap">{(notification.status==='ActionInitiated')?(<span >Action Initiated</span>):notification.status}</td>
                        </tr>                       
                )
            }
            {
                this.props.notifications && this.props.notifications.length===0&&
                <tr >
                <td className="text-center error" colSpan={7}>No records found ...</td>
                </tr>
            }
            </React.Fragment>
        )
    }
}


export {NotificationTableDetails};
