import * as React from "react";
import DatePicker from 'react-datepicker';
import {observer} from "mobx-react";
import * as ReactDOM from 'react-dom';

@observer
class Datepicker extends React.Component<any, {}> {

    readonly DATEFORMAT              = "yyyy-MMM-dd";
    readonly DATEFORMAT_CALENDER     = "MMMM";
    readonly PLACEHOLDER             = "yyyy-mmm-dd";
    readonly ITEM_LIST_YEAR_DROPDOWN = 15;
    readonly MINDATE = new Date('1950-01-01') ;
    readonly MAXDATE = new Date().setFullYear(new Date().getFullYear()+100) ;

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this.refs.myInput);
        if (node instanceof HTMLElement) {
            const child = node.querySelector('.date');
            if(child  instanceof HTMLElement){
                 child.insertAdjacentHTML("afterend", '<div class="invalid error"></div>');;
            }
        }
    }

    render() {
        return (
            <DatePicker ref = "myInput"  dateFormat={this.DATEFORMAT} placeholderText={this.PLACEHOLDER}
                        dateFormatCalendar={this.DATEFORMAT_CALENDER}
                        showYearDropdown
                        scrollableYearDropdown 
                        yearDropdownItemNumber={this.ITEM_LIST_YEAR_DROPDOWN}
                        className={this.props.className?"form-control date "+this.props.className:"form-control date"}
                        selected={this.props.value && new Date(this.props.value)}
                        maxDate={this.props.maxDate?this.props.maxDate:this.MAXDATE}
                        minDate={this.props.minDate?this.props.minDate:this.MINDATE}
                        onChange={this.props.inputChangeHandler}
                        disabled={this.props.disabled}
                        required={this.props.isRequired}
            />
        )
    };
}

export {Datepicker};
