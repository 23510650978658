import React from 'react';
import { observer } from 'mobx-react';
import { viewDateTimeFormat} from "../../constants/CommonMethods";
import {Stores} from "../../store/Stores";

@observer
class CustomMessageTableDetail extends React.Component<any, any> {

    render() {
        return(
            <React.Fragment>
            {
                this.props.customMessages && this.props.customMessages.map((customMessage,index)=>
                        <tr key={index} className="row mx-0 text-nowrap  cursor-pointer table-body-value" onClick={(event)=>this.props.viewCustomMessage(event,customMessage)}>
                            <td className='col-2 px-0 text-center text-wrap'>{customMessage.messageId} </td>
                            <td className="col-2 px-0 text-center text-wrap">{(customMessage.messageType==="PromotionalCampaign")?(<span>Promotional Campaign</span>):(<React.Fragment></React.Fragment>)}
                            {(customMessage.messageType==="CrossSelling")?(<span>Cross Selling </span>):(<React.Fragment></React.Fragment>)}
                            {((customMessage.messageType!=="CrossSelling") && (customMessage.messageType!=="PromotionalCampaign"))?(<span>{customMessage.messageType}</span>):(<React.Fragment></React.Fragment>)}</td>
                            <td className="col-2 text-center">{customMessage.sentCount}</td>
                            <td className="col-2 text-center">{customMessage.viewCount}</td>
                            <td className="col-2 px-0 text-center text-wrap">{viewDateTimeFormat(customMessage.createdDate)}</td>
                            <td className="col-2 px-0 text-center text-wrap" >{customMessage.status}
                            {customMessage.status==="Submitted"&&Stores.currentUser.userType.name ==="CUSTOMER"&&
                            <img  style={{width: '13px',marginLeft: '10px'}} className="cursor-pointer" alt="close"
                             onClick={(event) => this.props.deleteAlert(event,customMessage)}
                             src={require("../../assets/image/delete.jpg")} /> }</td>
                        </tr>
                )
            }
            {
                this.props.customMessages && this.props.customMessages.length===0&&
                <tr >
                <td className="text-center error" colSpan={7}>No records found ...</td>
                </tr>
            }
            </React.Fragment>
        )
    }
}


export {CustomMessageTableDetail};
