import React from 'react';
import { observer } from 'mobx-react';
import MaterialIcon from 'material-icons-react';

@observer
class FeedbackStar extends React.Component<any, any> {

    render() {
        return(
            <React.Fragment>
                {this.props.stars.map((star, key) => {
                    return <MaterialIcon icon={star.star}  size='tiny' color='#fcbc3f' key={star.key}/>;
                })}
            </React.Fragment>
        )
    }
}

export {FeedbackStar};

