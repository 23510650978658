import * as React from 'react'
import * as ReactDOM from 'react-dom';
import {observable} from "mobx";
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import ToggleLeft from '../../assets/image/collapse_left.png';
import ToggleRight from '../../assets/image/collapse_right.png';
import DownArrow from '../../assets/image/down_arrow.png';
import UpArrow from '../../assets/image/up_arrow.png';
import {BaseUser,cookies} from "../../config/HttpClients";
import {Feature} from "../../store/Feature";
import {Permission} from "../../store/Permission";
import {Auth} from "aws-amplify";
import {User} from "../../store/User";
import { Stores } from '../../store/Stores';
import { SubMenu } from './SubMenu';


@observer
class SideNav extends React.Component<any, any> {
    @observable classApplied:boolean = false;
    @observable permissions:Permission[] = [];
    @observable features:Feature[] = [];
    @observable user:User = new User();
    history:any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.getLoggedInUser();

    }
    componentWillUpdate(){
        this.setPermission(cookies.get('permission'));
    }
    getLoggedInUser=async()=> {
        await Auth.currentAuthenticatedUser().then(response => {
            BaseUser.get("/user/" + response.username).then(
                response => {
                    this.user = response.data.payload;
                    let roles:string[] = [];
                    this.user.roles.map(role=>{
                        roles.push(role.id);
                        return true;
                    });
                   this.getRolePermissions(roles);
                }
            )
        })
    }

    toggleModel = () => {
        this.classApplied = !this.classApplied;
        this.props.setClassApplied(this.classApplied);
    };

    navigateRoute = (url,permission) =>{
        this.history.push(url);
        cookies.set('permission', permission.id, { path: '/' })
    };

    isActiveMenu=(pathUrl,permission)=>{
        let paths =  window.location.pathname.split('/');
        let path = pathUrl.split('/');
        if(paths[1] === path[1]){
            cookies.set('permission', permission.id, { path: '/' })
        }
        return paths[1] === path[1];
    }
    getRolePermissions=(roles)=>{
        BaseUser.get("/permission/roles?roles="+roles).then(response=>{
            this.permissions = response.data.payload;
            Stores.permissions = this.permissions;
            //Reinitiate auth route
            const path = this.props.history.pathname;
            this.props.history.push(path);
        });
    }

    setPermission=(permissionId)=>{
        let permission = this.permissions.find(permission=>permission.id===permissionId);
        if(permission) {
            Stores.currentPermission = new Permission();
            Stores.currentPermission = permission;
        }
    }

    clickHandler = (index) => {
        if(this.permissions && this.permissions[index].feature) {
            this.permissions.map((permission, key) => (key !== index) ? permission.expanded = false : true);
            if(this.permissions[index].feature.hasSubMenu) {
                this.permissions[index].expanded = !this.permissions[index].expanded;
            } else {
                this.history.push(this.permissions[index].feature.pathUrl);
                cookies.set('permission', this.permissions[index].id, { path: '/' })
            }
        }
    }
    
    render() {
        let toggleImage;
        if (Stores.menu.expanded) {
            toggleImage = (
            <img src={ToggleRight} alt="ToggleRight" style={{width:"8px", height:"20px"}}  />
            )} else {  toggleImage = (
            <img src={ToggleLeft} alt="ToggleLeft" style={{width:"8px", height:"20px"}}/>  )}
        let upArrow = (<img src={UpArrow} alt="DownArrow" style={{width:"12px"}} />);
        let downArrow = (<img src={DownArrow} alt="UpArrow" style={{width:"12px"}} />);
        return (
              <div className="wrapper" style={{paddingTop:"50px"}}>
                <div id="sidebar" className={Stores.menu.expanded?'active custom-height custom-scroll':'custom-height custom-scroll'}>
                    <div id='nav1'>
                    <div className='sideNav'>
                        <ul style={{padding:"0"}}>
                            {
                                this.permissions&&this.permissions.map((permission, key) =>
                                    permission.feature.mobile===false&&permission.feature.name!=="Product Recall Management"&&permission.feature.name!=="Settings"&&permission.feature.name!=="Label Design & Config"&&permission.feature.name!=="Complaints & Feedback"&&
                                    
                                    <li key={key}>
                                        <div className={this.isActiveMenu(permission&&permission.feature&&permission.feature.pathUrl,permission) ? 'sidebar-item row mx-0 active': 'sidebar-item row mx-0'} onClick={() => this.clickHandler(key)}>
                                            <div style={{width: "24px"}}>
                                               <img src={require('../../assets/image/'+permission.feature.imageUrl)} alt="" style={{width:"20px", height:"20px"}}/>
                                            </div>
                                            <span className="font-weight-500" style={{width: "180px"}}> {permission&&permission.feature&&permission.feature.name} </span>
                                            {
                                                (!Stores.menu.expanded && permission&&permission.feature && permission.feature.hasSubMenu === true) ?
                                                <div>{(permission.expanded) ? upArrow : downArrow}</div>:null
                                            }
                                        </div>
                                        {(!Stores.menu.expanded && permission&&permission.feature&&permission.feature.hasSubMenu === true)?<SubMenu expanded={permission.expanded} feature={permission.feature}/>:null}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    {/*<div id='toggleButton' onClick={this.toggleModel}>
                        {toggleImage }
                    </div>*/}
                    </div>
                 </div>
            </div>
        );
    }
};

const SideNavComponent = withRouter(SideNav);
export {SideNavComponent as SideNav};

