import React from 'react';
import { observer } from 'mobx-react';
import {ViewImageModal} from "./ViewImageModal";
import {observable} from "mobx";

@observer
class ScannedImage extends React.Component<any, any> {

    @observable modalDialog:any;
    @observable showZoomIcon:boolean = false;

    showModal = () => {
        this.modalDialog.show();
    }

    setShown = (value) => {
        this.showZoomIcon = value;
    }

    render() {
        return(
            <div className="row mx-0">
                <div className="col-12 px-0" style={{minHeight:'200px'}}>
                    <div className="text-center" style={{border:'2px solid #CBCBCB',height:'180px',cursor:'pointer'}}>
                        {
                            this.props.imgUrl&&
                            <div className="zoom-container" onMouseEnter={() => this.setShown(true)} onMouseLeave={() => this.setShown(false)} onClick={() => this.showModal()}>
                                <img src={this.props.imgUrl} className="image"
                                    alt="scanned_image"/>
                                {this.showZoomIcon ? 
                                    <div className="zoom-overlay">
                                        <div className="zoom-icon" title="User Profile">
                                            <img className="float-right" style={{width: '50px'}}
                                                alt="Zoom Image" src={require("../../assets/image/zoom.png")}/>
                                        </div> 
                                    </div>
                                    : <React.Fragment></React.Fragment>}
                            </div>
                        }
                    </div>
                </div>
                {this.props.imgUrl ? <ViewImageModal imgUrl={this.props.imgUrl} ref={instance=>{this.modalDialog=instance}} /> : <React.Fragment></React.Fragment>}
            </div>
        )
    }
}

export {ScannedImage};
