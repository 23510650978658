import React from 'react';
import './styles.css';
import { Rating } from 'react-simple-star-rating';
import { BaseCustomer } from '../../config/HttpClients';
import { observable } from 'mobx';

const socialMediaImages = {
    Facebook: require("../../assets/image/fb-icon.png"),
    Youtube: require("../../assets/image/youtube-icon.png"),
    Instagram: require("../../assets/image/instagram-icon.png"),
    Website: require("../../assets/image/website.png"),
    LinkedIn: require("../../assets/image/linkedin-icon.png"),
    Twitter: require("../../assets/image/twitter-icon.png"),
}
export class ValidationScreenContainer extends React.Component<any, any> {
    @observable headerImageUrl: any = '';

    constructor(props: any) {
        super(props);
    }

    getHeaderImage(fileName) {
        BaseCustomer.get("/customer/image?fileName=" + fileName, {
            headers: { Accept: "*/*" },
            responseType: "blob"
        }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this.headerImageUrl = URL.createObjectURL(blob);
        });
    }

    openWarrantyLink = () => {
        window.open(this.props.warrantyRegistrationUrl, '_blank');
    }

    render() {
        let socialMedias = [];
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


        if (this.props.socialMedia) {
            socialMedias = JSON.parse(this.props.socialMedia);
        }

        return (
            <>
                <div className="p-10 container d-flex flex-column justify-content-center align-items-center grey-background mt-10" style={{ marginTop: '50px' }}>
                    {this.props.productImageUrl && <img className="product-image-container" src={this.props.productImageUrl} />}
                    <h6 className="text-align-center" style={{ fontWeight: 'bold' }}>{this.props.productName}</h6>
                    <span>from</span>
                    <h4>{this.props.companyName}</h4>
                    <p className="text-left m-0 p-2 pt-3 font-weight-bold" style={{ fontSize: '1rem' }}>
                        <img className="mr-3" src={require("../../assets/image/ic_success.png")} style={{ width: "40px" }} alt="success" />
                        Verification Successful!
                    </p>
                    <table>
                        <tbody>
                            {this.props.evdensCode && <tr>
                                <td>Evdens UID</td>
                                <td>:</td>
                                <td>{this.props.evdensCode}</td>
                            </tr>}
                            <tr>
                                <td>{this.props.marketLabel ? 'Order Number' : 'Brand'}</td>
                                <td>:</td>
                                <td>{this.props.marketLabel ? this.props.orderNo : this.props.brandName}</td>
                            </tr>
                            {this.props.marketLabel && <tr>
                                <td>Seller</td>
                                <td>:</td>
                                <td>Seller</td>
                            </tr>}
                            <tr>
                                <td>Production/Packing Location</td>
                                <td>:</td>
                                <td>{this.props.productionLocation}</td>
                            </tr>
                            {this.props.batchNo && <tr>
                                <td>Batch/Lot No</td>
                                <td>:</td>
                                <td>{this.props.batchNo}</td>
                            </tr>
                            }
                            {this.props.mfgDate && <tr>
                                <td> MFG/Packed Date </td>
                                <td>:</td>
                                <td>{this.props.mfgDate}</td>
                            </tr>
                            }
                            {this.props.expireDate && <tr>
                                <td>Use Before Date</td>
                                <td>:</td>
                                <td>{this.props.expireDate}</td>
                            </tr>
                            }
                            {
                                (this.props.attributes || []).map(attribute => (
                                    <tr key={attribute.label}>
                                        <td>{attribute.label}</td>
                                        <td>:</td>
                                        <td>{attribute.value}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                    { this.props.withOvd && <p style={{ margin: '20px 0', fontStyle: 'italic', fontSize: '.65rem' }}>
                        {"This just confirms the validity of the scanned Evdens Label. In order to confirm the originality of the Evdens Label and thereby the product, please use the Evdens App. Try Evdens App and witness how it helps in buying authentic products"}
                    </p> }
                    {this.props.warrantyDuration &&
                        <>
                            <p style={{ margin: '20px 0 0 0', fontSize: '1rem' }}>
                                This product comes with {this.props.warrantyDuration} months warranty
                            </p>
                            <button type="submit" className="btn btn-app-primary" onClick={() => this.props.showModal(4)}>Register Warranty</button>
                        </>
                    }
                    {(this.props.evdensCodes && this.props.evdensCodes.length > 0) &&
                        <>
                            <p style={{ margin: '20px 0 0 0', fontSize: '1rem', textAlign: 'center' }}>
                                {(this.props.packagingLevel == "Primary") ? "This product comes in case/box/cover carrying a unique Evdens Label. You may authenticate it" : "This package contains " + this.props.evdensCodes.length + " item(s), each carrying unique Evdens Label. You may verify them individually"}
                            </p>
                            <button type="submit" className="btn btn-app-primary" onClick={() => this.props.showModal(2)}>View Content</button>
                        </>
                    }
                    {(this.props.companyHeaderText || this.props.headerImageUrl) &&
                        <div className='d-flex flex-column align-items-center' style={{ margin: '20px 0', fontWeight: 'bold', fontSize: '1rem', marginBottom: '25px' }}>
                            {this.props.companyHeaderText && <p style={{ margin: 5, fontWeight: 'bold', width: '100%', textAlign: 'center' }}> {this.props.companyHeaderText}</p>}
                            {(this.props.companyHeaderUrl && this.props.headerImageUrl) &&
                                <a style={{ maxHeight: '290px', width: '100%' }} href={this.props.companyHeaderUrl} target="_blank">
                                    <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.headerImageUrl} />
                                </a>
                            }
                            {(!this.props.companyHeaderUrl && this.props.headerImageUrl) && <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.headerImageUrl} />}
                        </div>
                    }
                    {(this.props.customMessageHeader || this.props.customImageUrl) &&
                        <div className='d-flex flex-column align-items-center' style={{ margin: '20px 0', fontWeight: 'bold', fontSize: '1rem', marginBottom: 0 }}>
                            {this.props.customMessageHeader &&
                                <p style={{ margin: '5px 0', marginBottom: 0, fontWeight: 'bold', fontSize: '1rem', textAlign: 'center' }}>
                                    {this.props.customMessageHeader}
                                </p>
                            }
                            {this.props.customImageUrl && <img src={this.props.customImageUrl} style={{ width: "200px" }} alt="Message" />}
                            {this.props.customMessageBody && renderHTML(this.props.customMessageBody)}
                       </div>
                    }

                    {(this.props.branchText || this.props.branchImageURL) &&
                        <div className='d-flex flex-column align-items-center' style={{ margin: '20px 0', fontWeight: 'bold', fontSize: '1rem', marginBottom: 0 }}>

                            {this.props.branchText && <p style={{ margin: 5, fontWeight: 'bold', width: '100%', textAlign: 'center' }}> {this.props.branchText}</p>}
                            {(this.props.branchUrl && this.props.branchImageURL) &&
                                <a style={{ maxHeight: '290px', width: '100%' }} href={this.props.branchUrl} target="_blank">
                                    <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.branchImageURL} />
                                </a>
                            }
                            {(!this.props.branchUrl && this.props.branchImageURL) && <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.branchImageURL} />}
                        </div>
                    }
                    {this.props.customMessage && <p style={{ margin: '25px 0', fontWeight: 'bold', fontSize: '1rem' }}>
                        {this.props.customMessage}
                    </p>
                    }

                    <div style={{ margin: '20px 15px', width: 400, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: 0 }}>Rate Us</p>
                        <Rating
                            ratingValue={this.props.rating}
                            transition
                            onClick={this.props.onRatingChangeHandler}
                            size={25}
                        />
                        <button className="btn btn-app-primary" disabled={this.props.rating === 0 || this.props.onRateUsDisabled} onClick={this.props.onRateUsSubmit} style={{ marginTop: '10px', width: '100px' }}>Submit</button>
                        {this.props.onRateUsDisabled && <span style={{color: '#0cd40c'}}>Your rating has been submitted successfully</span>}
                    </div>

                    {(this.props.companyFooter1Text || this.props.footer1ImageUrl) &&
                        <div style={{ width: '100%', textAlign: 'center', marginBottom: '25px' }}>
                            {this.props.companyFooter1Text && <p style={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: 0, textAlign: 'center' }}>{this.props.companyFooter1Text}</p>}
                            {(this.props.companyFooter1Url && this.props.footer1ImageUrl) &&
                                <a style={{ maxHeight: '290px', width: '100%' }} href={this.props.companyFooter1Url} target="_blank">
                                    <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.footer1ImageUrl} />
                                </a>
                            }
                            {(!this.props.companyFooter1Url && this.props.footer1ImageUrl) && <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.footer1ImageUrl} />}
                        </div>
                    }

                    {(this.props.companyFooter2Text || this.props.footer2ImageUrl) &&
                        <div style={{ width: '100%', textAlign: 'center', marginBottom: '25px' }}>
                            {this.props.companyFooter2Text && <p style={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: 0, textAlign: 'center' }}>{this.props.companyFooter2Text}</p>}
                            {(this.props.companyFooter2Url && this.props.footer2ImageUrl) &&
                                <a style={{ maxHeight: '290px', width: '100%' }} href={this.props.companyFooter2Url} target="_blank">
                                    <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.footer2ImageUrl} />
                                </a>
                            }
                            {(!this.props.companyFooter2Url && this.props.footer2ImageUrl) && <img style={{ maxHeight: '290px', width: '100%' }} src={this.props.footer2ImageUrl} />}
                        </div>
                    }

                    <div style={{ margin: '20px', marginTop: 0, fontSize: '1rem', textAlign: 'center' }}>
                        <p style={{ margin: 2, fontWeight: 'bold' }}>{this.props.companyName}</p>
                        <p style={{ margin: 2, fontSize: '.75rem' }}>Phone: {this.props.customerCareNumber}</p>
                        <p style={{ margin: 2, fontSize: '.75rem' }}>Email: {this.props.customerCareEmailId}</p>
                        <div style={{ margin: 5 }}>
                            {socialMedias.map((socialMedia: any) =>
                                <a key={socialMedia.value} href={socialMedia.link} target="_blank">
                                    <img style={{ maxHeight: 28, maxWidth: 28, margin: '0 10px' }} src={socialMediaImages[socialMedia.value]} />
                                </a>)
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex align-item-center justify-content-center" style={{ width: '100%', background: '#a9e0ef' }}>
                    <div className="d-flex flex-column align-item-center justify-content-center" style={{ width: 350 }}>
                        <div className="d-flex align-items-center justify-content-center">
                            <p style={{ margin: 0, fontWeight: 'bold', paddingRight: 5 }}>Powered by</p>
                            <a href="https://evdens.com" target="_blank">
                                <img style={{ height: 25 }} src={require('../../assets/image/evdens_footer-logo.png')} />
                            </a>
                        </div>
                        <p style={{ textAlign: 'center' }}>
                            Anti-counterfeiting | Track-n-Trace | Warranty Protection | Asset Protection | Secured Delivery | Brand Promotion
                        </p>

                        <div className="d-flex justify-content-between" style={{ width: 350, margin: 5 }} >
                            <a href="https://evdens.com" target="_blank">https://evdens.com</a>
                            <a href="https://biz.evdens.com/privacyPolicy&amp;TermsofUse" target="_blank">Terms of use</a>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 350 }} >
                            <span style={{ margin: 0 }}>
                                Download Evdens App from
                            </span>
                            <a href="https://play.google.com/store/apps/details?id=com.vglens.evdens_con" target="_blank">
                                <img style={{ height: 25, marginLeft: 10 }} src={require('../../assets/image/get-it-on-playstore.png')} />
                            </a>
                        </div>
                        <p style={{ textAlign: 'center' }}>
                            Evdens helps consumers verify product authenticity
                        </p>
                    </div>
                </div>
            </>);

    }
}

