import {action, observable} from 'mobx';

class UserType {
    @observable id:string = '';
    @observable name:string = '';
    

    @action
    setProduct = (type: any) => {
        this.id = type.id;
        this.name = type.name;
        
        return this;
    }
    @action reset() {
        this.id = '';
        this.name = '';

    }
}

export {UserType};
