import * as React from "react";
import {observer} from "mobx-react";
import { observable } from "mobx";
import { BaseCustomer} from "../../config/HttpClients";
import Autosuggest from 'react-autosuggest';
import {Customer} from '../../store/Customer';
import { Stores } from '../../store/Stores';
import {getLoggedInUser} from '../../constants/CommonMethods';

@observer
class BranchSearch extends React.Component<any, any> {
    @observable branches : any = [];
    @observable customer : Customer = new Customer();
    @observable disabled : boolean = false;
    @observable branchType : string = '';
    @observable statusType : string = '';
    constructor(props){
        super(props);
        this.state = { nameValue: '', branches: [] };
        if(Stores.currentUser.userName === ""){
            getLoggedInUser().then(response=>{
                this.init();
            });
        } else {
            this.init();
        }
    }

    init=()=>{
        let paths =  window.location.pathname.split('/');
        if(Stores.currentUser&&Stores.currentUser.userType.name === "VGLENS") {
            if(paths[1] === "customers" || paths[1] === "dashboard" || paths[1] === "OpsManagement") { this.customer = Stores.customer; }
            if(paths[1] === "partners") { this.customer = Stores.partner; }
        } else {
            if(paths[1] === "dashboard" || paths[1] === "company" || paths[1] === "OpsManagement") { this.customer = Stores.currentCustomer; }
            if(paths[1] === "supplyChain" ) { this.customer = Stores.partner; }
        }
        if(!this.customer.id){
            this.disabled = true;
        }
        this.branchType = this.props.branchType;
        this.statusType = this.props.statusType;
    }

    setCustomer=()=>{
        this.disabled = false;
        this.customer = Stores.customer;
        if(!this.customer.id){
            this.disabled = true;
            this.setState({
                nameValue: '',
                branches: []
            });
        }
    }
    reset(){
        this.setState({
            nameValue: '',
            branches: []
        });
    }

    onSuggestionChange = (event, {newValue, method}) => {
        this.setState({ nameValue: newValue });
    };
    onSuggestionsFetchRequested = ({value}) => {
        let type = 'null';
        if (this.branchType){
            type = this.branchType;
        }
        let status = 'null';
        if (this.statusType) {
            status = this.statusType;
        }
        BaseCustomer.get(`/branch/searchByName?name=${value}&customer=${this.customer.id}&type=${type}&status=${status}`)
            .then(response =>{
                if(response.data.payload.length===0){
                    this.props.setNewBranch();
                }
                this.setState({ branches: response.data.payload})
            });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            //nameValue: '',
            //branches: []
        });
        let paths =  window.location.pathname.split('/');
        if( paths[1] === "dashboard" && this.state.nameValue === '') {
            this.props.setNewBranch();
        }
    }
    getSuggestion = (suggestion) => {
        return suggestion.name;
    }

    renderSuggestion = (suggestion) => {return ( <span>{suggestion.name}</span>);};

    onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
        this.props.selectedBranch(suggestion);

       // this.onSuggestionsClearRequested();
    }

    render() {
        const {nameValue, branches} = this.state;

        const branchInputProps =
            {
                placeholder: "Search Branch",
                value: nameValue,
                branches: branches,
                onChange: this.onSuggestionChange,
                disabled:this.disabled,
                style: this.props.inputStyle
            };

        const renderInputComponent = inputProps => (
            <div>
                <input {...inputProps} />
                 <div className={"react-autosuggest__suggestions-container--open text-center p-2 error"}
                      hidden={!(inputProps.branches.length === 0 && inputProps.value !== "")}>No branches found...!
                 </div>
            </div>
        );
        return (
            <Autosuggest
                suggestions={branches}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestion}
                renderSuggestion={this.renderSuggestion}
                inputProps={branchInputProps}
                renderInputComponent={renderInputComponent}
            />
        )
    };
}

export {BranchSearch};
