import { observable} from 'mobx';

class SuspendDto {
    @observable id:string = '';
    @observable customer:string = '';
    @observable comment:string = '';
    @observable updatedUser:string = '';
    @observable updatedBy:string = '';
}

export {SuspendDto};