import {action, observable} from 'mobx';

class Country  {
    @observable id:string = '';
    @observable name:string = '';
    @observable value:string = '';
    @observable label:string = '';

    @action
    setCountry = (country: any) => {
        this.id = country.id;
        this.name = country.name;
        return this;
    }

    @action reset() {
        this.id             = '';
        this.name           = '';
    }
}

export {Country};
