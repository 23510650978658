import * as React from "react";
import {Modal} from "react-bootstrap";
import {observable} from "mobx";
import {observer} from "mobx-react";

@observer
class OrderConfirmModal extends React.Component<any, {}> {
    @observable open:boolean = false;
    close=()=>{
        this.open = false;
    };

    show=()=>{
        this.open = true;
    };

    render() {
        return (
            <Modal
                show={this.open}
                onHide={this.close}
                dialogClassName={"modal-dialog-centered modal-md"}
            >
                <Modal.Body className="p-2 pt-3">
                    <div className="text-center">
                        <h6 className={"custom-alert-text"}>Review product selection before submitting the order</h6>                    
                        {
                            this.props.orderItems.map((orderItem,index) =>
                                <React.Fragment key={index}>
                                    <tr className="row table-body-value">
                                        <td className="col-2 text-center pr-0 py-1">{index+1}) </td>
                                        <td className="col-10 text-left pr-0 py-1">{orderItem.productName?(<span >{orderItem.productName}</span>):(<span>-</span>)}</td>                                                    
                                    </tr>                                                         
                                </React.Fragment>
                            )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-1 pb-3 text-center b-none">
                    <button className="btn btn-sm btn-app-secondary ml-auto" type="button" onClick={this.close}>Cancel</button>
                    <button className="btn btn-sm btn-app-primary mr-auto" type="button" onClick={this.props.submit}>Submit</button>
                </Modal.Footer>
        
            </Modal>
        )
    }
}

export {OrderConfirmModal};
