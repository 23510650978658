import * as React from "react";
import DatePicker from 'react-datepicker';
import {observer} from "mobx-react";
import dropdownIcon from '../../assets/image/profileDropdown.png'

@observer
class DatepickerSelect extends React.Component<any, {}> {

    readonly DATEFORMAT              = "yyyy-MMM-dd";
    readonly DATEFORMAT_CALENDER     = "MMMM";
    readonly PLACEHOLDER             = "yyyy-mmm-dd";
    readonly ITEM_LIST_YEAR_DROPDOWN = 15;
    readonly MINDATE = new Date('1950-01-01') ;
    readonly MAXDATE = new Date().setFullYear(new Date().getFullYear()+100) ;

    render() {
      const CustomInput = ({ value, onClick,className }) => (
        <button className={className} onClick={onClick}>
          {value}<span className="float-right"><img alt="dropdown" src={dropdownIcon}/></span>
        </button>
      );
       return (
           <DatePicker dateFormat={this.DATEFORMAT} placeholderText={this.PLACEHOLDER}
                        dateFormatCalendar={this.DATEFORMAT_CALENDER}
                        showYearDropdown
                        scrollableYearDropdown
                        customInput={<CustomInput className={this.props.className?"form-control date pl-0 text-nowrap "+this.props.className:"form-control date"} value={this.props.value && new Date(this.props.value)} onClick={this.props.onClick}/>}
                        yearDropdownItemNumber={this.ITEM_LIST_YEAR_DROPDOWN}
                        className={this.props.className?"form-control date pl-0 text-nowrap "+this.props.className:"form-control date pl-0 "}
                        selected={this.props.value && new Date(this.props.value)}
                        maxDate={this.props.maxDate?this.props.maxDate:this.MAXDATE}
                        minDate={this.props.minDate?this.props.minDate:this.MINDATE}
                        onChange={this.props.inputChangeHandler}
                        disabled={this.props.disabled}
                        required={this.props.isRequired}
            />
       )
    };
}

export {DatepickerSelect};
