import * as React from 'react'

import { Header} from  '../layout/Header';
import {SideNav} from  '../layout/SideNav';
import { withRouter} from "react-router";
import  { Auth } from 'aws-amplify';
import {BaseCustomer, BaseUser, cookies,clearCookies} from "../../config/HttpClients";
import {Dashboard} from '../dashboard/Dashboard';
import { AuthRoute } from '../../config/AuthRoute';
import { CustomerMainLayout } from '../customer/CustomerMainLayout';
import {PartnerMainLayout} from  '../partner/PartnerMainLayout';

import { Partners } from  '../partner/Partners';
import { Branch } from '../branch/Branches';
import { Users } from  '../user/Users';

import {Customers} from '../customer/Customers';
import {Products} from '../product/Products';

import { CreateUser } from '../manageUser/CreateUser';
import { CreateProfile } from '../manageUser/CreateProfile';

import {CustomerTypes} from '../customerType/CustomerTypes';
import {CustomerVerticals} from '../customerVertical/CustomerVerticals';
import {PartnerTypes} from '../partnerType/PartnerTypes';
import {ProductCategories} from '../productCategory/ProductCategories';
import {PackagingLevels} from '../packagingLevel/PackagingLevels';
import {EvdensContacts} from "../evdensContacts/EvdensContacts";

import { Notifications } from '../notificationAndAlert/Notifications';
import {Alerts} from "../notificationAndAlert/Alerts";

import { DispatchReport } from '../Reports/DispatchReport';
import { PackagedLabelReport } from '../Reports/PackagedLabelReport';

import { PushMessages } from '../consumerConnect/PushMessages';
import { CustomMessages } from '../consumerConnect/CustomMessages';
import { FeedBacks } from '../feedbackAndComplaints/FeedBacks';
import { Complaints } from '../feedbackAndComplaints/Complaints';
import { BrandReferrals } from '../feedbackAndComplaints/BrandReferrals';

import { CreateProduct } from '../manage-product/CreateProduct';
import { ProductDetails } from '../manage-product/ProductDetails';

import { CreateOrder } from '../labelOrder/CreateOrder';
import { ViewOrder } from '../labelOrder/ViewOrder';

import { SpecificCustomer } from '../generateLabel/SpecificCustomer';
import { OrderStatusView } from '../generateLabel/OrderStatusView';

import { ManageProduct } from '../manage-product/ManageProduct';
import { CompanyProfileMainLayout} from '../companyProfile/CompanyProfileMainLayout';
import {Stores} from "../../store/Stores";
import { ApproveNewProduct } from '../approveNewProduct/ApproveNewProduct';
import {observer} from "mobx-react";
import {observable} from "mobx";
import { Labelorders } from '../labelOrder/LabelOrders';
import {GenerateLabelLayout} from '../generateLabel/GenerateLabelLayout';
import { ManageUserLayout } from '../manageUser/ManageUserLayout';
import {ManageSupplyChainMainLayout} from '../manageSupplyChain/ManageSupplyChainMainLayout';
import {ManageMasterMainLayout} from "../manageMaster/ManageMasterMainLayout";
import { NotificationAndAlertMainLayout } from '../notificationAndAlert/NotificationAndAlertMainLayout';
import { ReportsMainLayout } from '../Reports/ReportsMainLayout';
import {MyProfileLayout} from '../myProfile/MyProfileLayout';
import {FeedbackAndComplaintsMainLayout} from "../feedbackAndComplaints/FeedbackAndComplaintsMainLayout";
import {ConsumerConnectMainLayout} from "../consumerConnect/ConsumerConnectMainLayout";
import {ManageLabel} from '../opsManagement/ManageLabel';

@observer
class MainLayout extends React.Component<any,any> {
   
    history:any;
    @observable toggleClass: any;
    delay:any = 1000 * 60 * 60;
    constructor(props: any) {
        super(props);
        Stores.reset(); 
        this.history = props.history;
        this.getLoggedInUser();
        setInterval(()=> {
            this.updateSession();
        }, this.delay );
    }

    updateSession =()=>{
        Auth.currentSession().then(response => { 
            cookies.set('token', response.getIdToken().getJwtToken(), { path: '/' })
        });
    }

    getLoggedInUser=async()=> {
        if(Stores.currentUser.userName === "") {
            await Auth.currentAuthenticatedUser().then(response=>{
                BaseUser.get("/user/"+response.username).then(
                    response=> {
                        Stores.currentUser = response.data.payload;
                        if(Stores.currentUser === null || Stores.currentUser.status==="INACTIVE"){
                            this.logout();
                        } else {
                            Stores.currentUserRole = Stores.currentUser.roles[0];
                            if(Stores.currentUser.userType.name !== "VGLENS") {
                                this.getCurrentCustomer();
                            }
                        }
                    }
                )
            });
        }
    };

    getCurrentCustomer=()=>{
        BaseCustomer.get("/customer/"+Stores.currentUser.customer)
        .then(response => {
            Stores.currentCustomer = response.data.payload;
            if(Stores.currentCustomer === null || Stores.currentCustomer.status==="INACTIVE"){
                this.logout();
            } else {
                this.getCurrentBranch();
            }
        });
    };

    getCurrentBranch=()=>{
        BaseCustomer.get("/branch/"+Stores.currentUser.branch).then(response => {
            Stores.currentBranch = response.data.payload ;
            if(Stores.currentBranch  === null || Stores.currentBranch.status==="INACTIVE"){
                this.logout();
            }
        });
    };

    toggleClassApplied=(status)=> {
        this.toggleClass = status;
    }

    logout=async ()=>{
        await Auth.signOut();
        clearCookies();
         this.navigateRoute('/login');
    };

    navigateRoute = (url:any) =>{
        this.history.push(url)
    };

    render() {
        return (
            <div>
                <Header classApplied={this.toggleClass}/>
                <div className='row mx-0'>
                    <SideNav setClassApplied={this.toggleClassApplied} />
                    <div className='main' ref = "mainDiv">
                        <div className='custom-height custom-scroll row mx-0'>

                            <AuthRoute exact path="/" component={Dashboard}/>
                            <AuthRoute exact path="/dashboard" component={Dashboard} />
                            
                            <AuthRoute path="/approveNewProduct" component={ApproveNewProduct} />

                            {/*<AuthRoute path="/partners" component={PartnerMainLayout}/>*/}
                            <AuthRoute exact path="/partners/partner" component={Partners} />
                            <AuthRoute exact path="/partners/branch" component={Branch} />
                            <AuthRoute exact path="/partners/user" component={Users} />

                            {/*<AuthRoute path="/customers" component={CustomerMainLayout} />*/}
                            <AuthRoute exact path="/customers/customer" component={Customers} />
                            <AuthRoute exact path="/customers/product" component={Products} />
                            <AuthRoute exact path="/customers/branch" component={Branch} />
                            <AuthRoute exact path="/customers/user" component={Users} />

                            {/*<AuthRoute path="/users" component={ManageUserLayout}/>*/}
                            <AuthRoute exact path="/users/permissions" component={CreateUser} />
                            <AuthRoute exact path="/users/profile" component={CreateProfile} />

                            {/*<AuthRoute path="/master" component={ManageMasterMainLayout} />*/}
                            <AuthRoute exact path="/master/customerType" component={CustomerTypes} />
                            <AuthRoute exact path="/master/customerVertical" component={CustomerVerticals} />
                            <AuthRoute exact path="/master/partnerType" component={PartnerTypes} />
                            <AuthRoute exact path="/master/productCategory" component={ProductCategories} />
                            <AuthRoute exact path="/master/packagingLevel" component={PackagingLevels} />
                            <AuthRoute exact path="/master/evdensContacts" component={EvdensContacts} />

                            {/*<AuthRoute path="/notifications" component={NotificationAndAlertMainLayout} />*/}
                            <AuthRoute exact path="/notifications/notification" component={Notifications} />
                            <AuthRoute exact path="/notifications/alert" component={Alerts} />

                            {/*<AuthRoute path="/reports" component={ReportsMainLayout} />*/}
                            <AuthRoute exact path="/reports/dispatch" component={DispatchReport} />
                            <AuthRoute exact path="/reports/packagedlabel" component={PackagedLabelReport} />

                            {/*<AuthRoute path="/consumerEngage" component={ConsumerConnectMainLayout} />*/}
                            <AuthRoute exact path="/consumerEngage/pushMessages" component={PushMessages} />
                            <AuthRoute exact path="/consumerEngage/customMessages" component={CustomMessages} />
                            <AuthRoute exact path="/consumerEngage/feedback" component={FeedBacks} />
                            <AuthRoute exact path="/consumerEngage/complaint" component={Complaints} />
                            <AuthRoute exact path="/consumerEngage/brandReferrals" component={BrandReferrals} />

                            {/*<AuthRoute path="/company" component={CompanyProfileMainLayout} />*/}
                            <AuthRoute exact path="/company/customer" component={Customers} />
                            <AuthRoute exact path="/company/branch" component={Branch} />
                            <AuthRoute exact path="/company/user" component={Users} />

                            {/*<AuthRoute path="/products" component={ManageProduct} />*/}
                            <AuthRoute exact path="/products/product" component={CreateProduct} />
                            <AuthRoute exact path="/products/productDetail" component={ProductDetails} />

                            {/*<AuthRoute path="/supplyChain" component={ManageSupplyChainMainLayout}/>*/}
                            <AuthRoute exact path="/supplyChain/partner" component={Partners} />
                            <AuthRoute exact path="/supplyChain/branch" component={Branch} />
                            <AuthRoute exact path="/supplyChain/user" component={Users} />

                            {/*<AuthRoute path="/labelOrder" component={Labelorders} />*/}
                            <AuthRoute exact path="/labelOrder/createOrder" component={CreateOrder} />
                            <AuthRoute exact path="/labelOrder/viewOrder" component={ViewOrder} />

                            {/*<AuthRoute path="/generateLabel" component={GenerateLabelLayout} />*/}
                            <AuthRoute exact path="/generateLabel/specificCustomer" component={SpecificCustomer} />
                            <AuthRoute exact path="/generateLabel/status" component={OrderStatusView} />
                            
                            <AuthRoute path="/myProfile" component={MyProfileLayout}/>
                            <AuthRoute path="/feedbackAndComplaints" component={FeedbackAndComplaintsMainLayout} />

                            <AuthRoute path="/OpsManagement/manageLabel" component={ManageLabel}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const MainLayoutComponent = withRouter(MainLayout);
export {MainLayoutComponent as MainLayout};

