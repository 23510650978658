import React from 'react';
import {observer} from 'mobx-react';
import {observable} from "mobx";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Button, ButtonToolbar,FormGroup,ControlLabel} from "react-bootstrap";
import { BaseCustomer } from "../../config/HttpClients";
import Form from "../../constants/Form";
import {Datepicker} from '../../constants/formfields/Datepicker';
import {stringToDateFormat,viewDateTimeFormat,showImage} from "../../constants/CommonMethods";
import {ErrorAlertModal} from "../../constants/modal/ErrorAlertModal";
import {Stores} from "../../store/Stores";
import {Customer} from "../../store/Customer";
import {StatusAudit} from "../../store/StatusAudit";
import {CustomMessageDetailForm} from "./CustomMessageDetailForm";
import {UploadImage} from "./UploadImage";

@observer
class CustomMessageForm extends React.Component<any, any> {
    @observable option:any = "PRODUCT";
    @observable messageOption:any = "";
    @observable message:string = "";
    @observable comment:string = "";
    @observable ckeditor:any;
    @observable customMessageDetailCom:any;
    @observable customMessageImageCom:any
    @observable suspendAlertModal:any;
    @observable messageReq:boolean = false;
    @observable detailsReq:boolean = false;
    @observable fileReq:boolean = false;
    @observable showAdmin:boolean = false;
    @observable disabled:boolean = true;
    @observable isView:boolean = false;
    @observable files: any[] = [];
    @observable imageUrl:any;
    @observable customer = new Customer();
    messages = '';
    history: any;

    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.init();
    }

    init=()=>{
        this.customer = Stores.currentCustomer;
        if(this.props.customMessage.status === "Submitted" ||this.props.customMessage.status === "" ){
            this.disabled = false;
        }
        if(this.props.customMessage.imageName){
            this.messageOption = "IMAGE";
            this.getCustomImage(this.props.customMessage.imageName);
        } else {
            this.messageOption = "MESSAGE";
        }
        if(Stores.currentUser.roles&&Stores.currentUser.roles[0].name === "ROLE_CUSTOMER_ADMIN") {
            this.showAdmin = true;
        }
        if(Stores.currentUser.userType.name  === "VGLENS" ){
            this.disabled = true;
            this.isView = true;
        }
    }

    calculateCount=(editor,data,event)=>{
        let maximumLength = 400;
        let messageText = data.replace(/<\/?[^>]+(>|$)/g, "");
        messageText.replace('/&nbsp;/g', ' ');
        if( messageText.length >= maximumLength ) {
            if(data !== this.messages && messageText.length >= maximumLength){
                this.ckeditor.editor.setData(this.messages);
            }
        } else {
            this.messages = data;
            this.message = data;
            this.props.customMessage.message = this.message;
        }
    }

    savePushMessage=()=>{
        if(this.messageOption==="MESSAGE"&&this.props.customMessage.message.length === 0 ){
            this.messageReq = true;
            return;
        }
        if(this.messageOption==="IMAGE"&&this.files.length === 0&&!this.props.customMessage.imageName ){
            this.fileReq = true;
            return;
        }
        if(this.props.customMessage.customMessageDetails.length === 0){
            this.detailsReq = true;
            return;
        }
        if(this.customer.id) {
            this.props.customMessage.customerId = this.customer.id;
        }
        if(this.props.customMessage.fromDate.length === 11) {
            this.props.customMessage.fromDate = stringToDateFormat(this.props.customMessage.fromDate);
        }
        if(this.props.customMessage.toDate.length === 11) {
            this.props.customMessage.toDate = stringToDateFormat(this.props.customMessage.toDate);
        }
        if(this.props.customMessage.customMessageDate) {
            this.props.customMessage.customMessageDate = stringToDateFormat(this.props.customMessage.customMessageDate);
        }
        if (this.props.customMessage.hasOwnProperty('id')){
            if(!this.props.customMessage.id){
                delete this.props.customMessage.id;
                this.saveCustomMessage();
            } else {
               this.updateCustomMessage();
            }
        } else {
           this.saveCustomMessage();
        }
    }

    saveCustomMessage=()=>{
        this.props.customMessage.status = "Submitted";
        let formData = new FormData();
        formData.append('customMessage', JSON.stringify(this.props.customMessage));
        this.files.map((file) => {
            formData.append('file', file);
             return true;
        });
        BaseCustomer.post("/customMessage", formData).then(response => {
            this.setNewMessage();
        });
    }

    updateCustomMessage=()=>{
        let formData = new FormData();
        formData.append('customMessage', JSON.stringify(this.props.customMessage));
        this.files.map((file) => {
            formData.append('file', file);
            return true;
        });
        BaseCustomer.post("/customMessage/update", formData).then(response => {
             this.setNewMessage();
             this.suspendAlertModal.close();
        });
    }

    deleteCustomMessage=()=>{
        BaseCustomer.delete("/customMessage?id="+this.props.customMessage.id).then(response => {
            this.setNewMessage();
            this.suspendAlertModal.close();
        });
    }

    approveOrDeActiveMessage=(status)=>{
        this.props.customMessage.status = status;
        let statusAudit = new StatusAudit();
        statusAudit.status = status;
        statusAudit.comment = this.comment;
        statusAudit.updatedUser = Stores.currentUser.firstName;
        statusAudit.createdAt = new Date().toISOString().replace('Z','');
        if(this.props.customMessage.statusAudits&&this.props.customMessage.statusAudits.length > 0) {
            this.props.customMessage.statusAudits.push(statusAudit);
        } else {
            let statusAudits:StatusAudit[] = [];
            statusAudits.push(statusAudit);
            this.props.customMessage.statusAudits = statusAudits;
        }
        this.savePushMessage();
    }

    setNewMessage=()=>{
        this.props.newCustomMessage();
        this.messageReq = false;
        this.imageUrl = '';
        this.files = [];
        this.customMessageDetailCom&&this.customMessageDetailCom.setNewDetail&&this.customMessageDetailCom.setNewDetail();
        if(this.customMessageImageCom) {
            this.customMessageImageCom.fileInput.value = "" ;
        }
    }

    getCustomImage = (fileName) => {
        BaseCustomer.get("/customMessage/image?fileName=" + fileName, {
                headers: {Accept: "*/*"},
                responseType: "blob"
            }).then(response => {
                let blob = new Blob([response.data], {type: response.headers["content-type"]});
                this.imageUrl = URL.createObjectURL(blob);
            });
    }

    handleBack = () => {
        this.props.setOption('VIEW');
    }

    handleUserInput = (targetObject, event) => {
        targetObject[event.target.id] = event.target.value;
    };

    handleMessageOption = (type,event)=>{
        this.messageOption = type;
        this.setEmptyFileAndMessage();
    };

    handleFromDate = (date) => {
        this.props.customMessage.fromDate = date;
    };

    handleToDate = (date) => {
        this.props.customMessage.toDate = date;
    };

    handleComment=(event)=>{
        this.comment = event.target.value;
    };

    deactivateAlert = () => {
        this.suspendAlertModal.show();
    };

    setEmptyFileAndMessage=()=>{
        if(this.ckeditor) {
            this.ckeditor.editor.setData('');
        }
        if(this.customMessageImageCom) {
            this.customMessageImageCom.fileInput.value = "" ;
        }
        this.messages = '';
        this.message = '';
        this.props.customMessage.message = '';
        this.files = [];
        this.imageUrl = '';
    }

    setNewCustomMessageDetail=()=>{
       this.props.customMessage.customMessageDetails = [];
    }

    setOption=(option)=>{
        this.option = option;
    }

    getImage = () => {
        showImage(this.files[0]).then(response => {
            this.imageUrl = response;
        });
    }

    setFilesEmpty = () => {
        this.imageUrl = '';
    }

    navigate = (url: any) => {
        this.history.push(url);
    };

    render() {
        return (
            <div className="col-12 pt-4 h-100 product-details">
                <div className="pb-2 row mx-0">
                    <div className='col-6  h-42'>
                        <b style={{letterSpacing: "1px", fontSize: "20px"}}>Details </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pl-0" style={{height:'calc(100% - 50px)'}}>
                    <div className="col-7 pl-0 pb-3 h-100">
                        <div className="card b-none h-100">
                            <div className="card-body h-100 pr-2" >
                                <div className="notification-details-height" style={{height:'calc(100% - 40px)'}}>
                                <Form submit={this.savePushMessage}>
                                    <div className="h-100 custom-scroll pr-2" style={{overflowY:'auto',overflowX:'hidden'}}>
                                        <div className='row mx-0'>
                                            <div className='col-6 pl-0'>
                                                <FormGroup controlId="name" bsSize="large">
                                                    <ControlLabel className="font-weight-bold">Message Type</ControlLabel>
                                                    <select className="form-control"value={this.props.customMessage.messageType}id="messageType" name="messageType"
                                                        onChange={this.handleUserInput.bind(this,this.props.customMessage)} required={true} disabled={this.disabled}>
                                                        <option value={''}>Select Message Type</option>
                                                        <option className="select-option" value={'Awareness'}>Awareness</option>
                                                        <option className="select-option" value={'PromotionalCampaign'}>Promotional Campaign</option>
                                                        <option className="select-option" value={'Offer'}>Offers</option>
                                                        <option className="select-option" value={'CrossSelling'}>Cross Selling</option>
                                                    </select>
                                                    <div className="invalid error"/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup controlId="name" bsSize="large">
                                                    <ControlLabel className="font-weight-bold">Message Header</ControlLabel>
                                                    <input type="text" id="header" name="header" placeholder="Message Header" className="form-control" onChange={this.handleUserInput.bind(this,this.props.customMessage)}
                                                     value={this.props.customMessage.header} required={true} maxLength={60} disabled={this.disabled}/>
                                                    <div className="invalid error"/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="col-12 align-self-center pl-0">
                                            <FormGroup controlId="status" bsSize="large">
                                             <ControlLabel className="font-weight-bold">Message Option</ControlLabel>
                                                <div className="row mx-0">
                                                    <div className="ml-1 custom-control custom-radio">
                                                        <input type="radio" id={'active'} checked={this.messageOption === 'MESSAGE'}  onChange={this.handleMessageOption.bind(this,'MESSAGE')} className="custom-control-input" name="status" required={true} disabled={this.disabled}/>
                                                        <label className="custom-control-label" htmlFor={"active"}> Message</label>
                                                    </div>
                                                    <div className="ml-2 custom-control custom-radio">
                                                        <input type="radio" id={'inActive'} checked={this.messageOption === 'IMAGE'}  onChange={this.handleMessageOption.bind(this,'IMAGE')} className="custom-control-input" name="status" disabled={this.disabled}/>
                                                        <label className="custom-control-label" htmlFor={"inActive"}> Image</label>
                                                        <div className="invalid error"/>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </div>
                                        {
                                            this.messageOption==="MESSAGE"&&
                                            <div className='row'>
                                                <div className='col-12'>
                                                <ControlLabel className="font-weight-bold">Message</ControlLabel>
                                                <CKEditor
                                                        onChange={( event, editor ) => {this.calculateCount(editor,editor.getData(),event);}}
                                                        disabled={this.disabled?true:false}
                                                        config={{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList' , 'undo' ]}}
                                                        editor={ ClassicEditor } data={this.props.customMessage.message}
                                                        ref={(instance) => { this.ckeditor = instance; }} />
                                                        {
                                                            this.messageReq&&(this.props.customMessage.message.length===0)&&
                                                            <div className="invalid error">This field is required.</div>
                                                        }
                                                </div>
                                            </div>
                                        }
                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <FormGroup controlId="packagingLevels" bsSize="large">
                                                    <ControlLabel className="w-100 font-weight-bold">Products</ControlLabel>
                                                    <Button className="btn button-grey-color" type="button"
                                                        onClick={()=>this.setOption('PRODUCT')}>VIEW/MODIFY</Button>
                                                    {
                                                        this.detailsReq&&(this.props.customMessage.customMessageDetails.length===0)&&
                                                            <div className="invalid error">This field is required.</div>
                                                    }
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                {
                                                this.messageOption==="IMAGE"&&
                                                    <FormGroup controlId="packagingLevels" bsSize="large">
                                                        <ControlLabel className="w-100 font-weight-bold">Image</ControlLabel>
                                                        <Button className="btn button-grey-color" type="button"
                                                            onClick={()=>this.setOption('IMAGE')}>VIEW/MODIFY</Button>
                                                    {
                                                        this.fileReq&&(this.files.length===0)&&
                                                       <div className="invalid error">Image required.</div>
                                                    }
                                                    </FormGroup>
                                                }
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <FormGroup controlId="packagingLevels" bsSize="large">
                                                    <ControlLabel className="w-100 font-weight-bold">From Date</ControlLabel>
                                                    <Datepicker value={this.props.customMessage.fromDate} minDate={new Date()}
                                                        inputChangeHandler={this.handleFromDate} isRequired={true} disabled={this.disabled}></Datepicker>
                                                </FormGroup>
                                            </div>
                                            <div className='col-6 pl-0'>
                                                <FormGroup controlId="packagingLevels" bsSize="large">
                                                    <ControlLabel className="w-100 font-weight-bold">To Date</ControlLabel>
                                                    <Datepicker value={this.props.customMessage.toDate} minDate={new Date()}
                                                        inputChangeHandler={this.handleToDate} isRequired={true} disabled={this.disabled}></Datepicker>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        {
                                            this.props.customMessage&&this.props.customMessage.statusAudits && this.props.customMessage.statusAudits.length > 0 &&
                                            <div className="row mx-0 mb-3 mt-2">
                                                <div className="col-12 px-0">
                                                    <div className="row mx-0">
                                                        <p className="mb-0 font-weight-bold">Activity Log</p>
                                                    </div>
                                                    <div className="row mx-0">
                                                    {
                                                        this.props.customMessage.statusAudits.map((comment,index) =>
                                                            <p className="mb-0 w-100" key={index}>{comment.status} {comment.comment?<span> - {comment.comment}</span>:<React.Fragment></React.Fragment>} - by {comment.updatedUser} on {viewDateTimeFormat(comment.createdAt)}</p>
                                                        )
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <ButtonToolbar className="justify-content-center mt-2">
                                        <Button className="btn btn-app-secondary" type="button"
                                                onClick={this.handleBack}> BACK</Button>
                                        <Button className="btn btn-app-primary ml-2" type="submit"
                                          hidden={((this.props.customMessage.status === '' || this.props.customMessage.status === 'Submitted') && !this.isView) ? false : true}> SUBMIT</Button>
                                        <Button className="btn btn-app-primary ml-2" type="button" onClick={()=>this.approveOrDeActiveMessage('Approved')}
                                            hidden={(this.props.customMessage.status === 'Submitted'&&this.showAdmin ) ? false : true}> APPROVE</Button>
                                        <Button className="btn btn-app-primary ml-2" type="button" onClick={this.deactivateAlert}
                                          hidden={((this.props.customMessage.status === 'Approved' || this.props.customMessage.status === 'InForce') && !this.isView) ? false : true}> DEACTIVATE</Button>
                                         <Button className="btn btn-app-primary ml-2" type="button" onClick={this.props.copyAndNewMessage}
                                          hidden={(!this.isView &&this.props.customMessage.status) ? false : true}> COPY & CREATE</Button>
                                    </ButtonToolbar>
                                </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 px-0">
                        <div className="card">
                            <div className="card-body px-3" style={{minHeight: "200px"}}>
                                <div className="pt-0">
                                {
                                    this.option==="PRODUCT"&&
                                    <CustomMessageDetailForm customMessageDetails={this.props.customMessage.customMessageDetails} setNewCustomMessageDetail={this.setNewCustomMessageDetail} ref={(instance) => { this.customMessageDetailCom = instance; }}
                                    disabled={this.disabled}/>
                                }
                                {
                                    this.option==="IMAGE"&&
                                    <UploadImage imgUrl={this.imageUrl} files={this.files} getImage={this.getImage} setFilesEmpty={this.setFilesEmpty} ref={(instance) => { this.customMessageImageCom = instance; }}
                                    disabled={this.disabled}/>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <ErrorAlertModal ref={instance => {this.suspendAlertModal = instance}}
                     type={"deactivate"} comment={this.comment} handleComment={this.handleComment} submit={()=>this.approveOrDeActiveMessage('Deactivated')}/>
            </div>
        )
    }
}
export {CustomMessageForm};
