import React from 'react';
import {observer} from 'mobx-react';
import {ScannedImage} from "../notificationAndAlert/ScannedImage";

@observer
class ComplaintView extends React.Component<any, any> {

    render() {
        return (
            <React.Fragment>
                {
                    this.props.complaint.id &&
                    <div className="product-details h-100">
                        <div className="row mb-2">
                            <div className="col-12 header" style={{fontSize: '18px'}}>
                                Complaint Details
                            </div>
                        </div>
                        <div className="custom-scroll" style={{height: 'calc(100% - 30px)',overflowY:'auto',overflowX:'hidden'}}>
                            <div className="row mb-2">
                                <span className='col-12 header'>Complaint Id</span>
                                <span className='col-12 header-value'> {this.props.complaint.complaintId ? this.props.complaint.complaintId :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Consumer Identity</span>
                                <span className='col-12 header-value'> {this.props.complaint.mobileNumber ? this.props.complaint.mobileNumber :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Consumer Name</span>
                                <span className='col-12 header-value'> {this.props.complaint.name ? this.props.complaint.name :
                                    <span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'> Email Id</span>
                                <span className='col-12 header-value'> {this.props.complaint.emailId ? this.props.complaint.emailId :
                                    <span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'> Location/Address</span>
                                <span className='col-12 header-value'> {this.props.complaint.address}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'> Store Name</span>
                                <span className='col-12 header-value'>{this.props.complaint.storeName}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'> Scan Location</span>
                                <span className='col-12 header-value'>{this.props.complaint.scanLocation ?
                                    <a href={'http://maps.google.com/?q=' + this.props.complaint.scanLocation}
                                       target="_blank" rel="noopener noreferrer">{this.props.complaint.scanLocation}</a> : <span>-</span>} </span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Brand Name</span>
                                <span className='col-12 header-value'> {this.props.complaint.brandName ? this.props.complaint.brandName :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'>Date</span>
                                <span className='col-12 header-value'> {this.props.complaint.createdDateConvert ? this.props.complaint.createdDateConvert :<span>-</span>}</span>
                            </div>
                            <div className="row mb-2">
                                <span className='col-12 header'> Complaint Text</span>
                                <span className='col-12 header-value'> {this.props.complaint.description}</span>
                            </div>
                            {
                                this.props.imgUrl &&
                                <React.Fragment>
                                    <p className="mb-0 mt-2 header">Image</p>
                                    <ScannedImage imgUrl={this.props.imgUrl}/>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}


export {ComplaintView};
