import React from 'react';
import { observer } from 'mobx-react';

@observer
class OrderItemTableDetails extends React.Component<any, any> {

    render() {
        return(
            <React.Fragment>
            {
                this.props.orderItems&&this.props.orderItems.map((orderItem,key)=>
                    <tr className="row mx-2 table-body-value" key={key}>
                        <td className='col-12' style={{border:'1px solid #E2E2E3', padding: '.65rem'}}>
                            <div className='row text-nowrap'>
                                {/* <div className='col-3 text-center'> {orderItem.labelSize}</div> */}
                                {/*<div className='col-3 text-center'> {orderItem.productName?(<span >{orderItem.productName}</span>):(<span>-</span>)}</div>*/}                               
                                <div className='col-3 text-center'> {(orderItem.ovd==='WithOVD')?(<span >evdensDigiSign</span>):(<React.Fragment></React.Fragment>)}{(orderItem.ovd==='WithoutOVD')?(<span >evdensCodes</span>):(<React.Fragment></React.Fragment>)}</div>
                                <div className='col-3 text-center'> {orderItem.quantity}</div>
                                <div className='col-3 text-center'> {orderItem.expectedDeliveryDate}</div>
                            </div>
                        </td>
                    </tr>
                )
            }
            </React.Fragment>
        )
    }
}


export {OrderItemTableDetails};
