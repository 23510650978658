import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Form from '../../constants/Form';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { LoadToast, ToastType } from "../../config/Toast";
import { showImage } from '../../constants/CommonMethods';
import { BaseCustomer } from '../../config/HttpClients';


@observer
class ResultPageForm extends React.Component<any, any> {

    @observable isFileTooLarge: any = false;
    @observable currentType: string = 'header';
    @observable itemDetails: any;
    @observable imgUrl: any = '';

    constructor(props: any) {
        super(props);
        this.init();
    }

    init = () => {
        this.imgUrl = '';
        this.itemDetails = this.props.details[this.currentType] || {};
        if (this.itemDetails.fileName && !this.itemDetails.files.length) {
            this.getCustomerImage(this.itemDetails.fileName);
        }
        if (this.itemDetails.files && this.itemDetails.files.length) {
            this.getImage();
        }
    }

    onTitleChangeHandler = (event) => {
        this.itemDetails.title = event.target.value;
    }

    onLinkChangeHandler = (event) => {
        this.itemDetails.link = event.target.value;
    }

    onSubmit = () => {

    }

    getCustomerImage = (fileName) => {
        BaseCustomer.get("/customer/image?fileName=" + fileName, {
            headers: { Accept: "*/*" },
            responseType: "blob"
        }).then(response => {
            let blob = new Blob([response.data], { type: response.headers["content-type"] });
            this.imgUrl = URL.createObjectURL(blob);
        });
    }

    getImage = () => {
        const files = this.itemDetails.files;
        if (files && files.length) {
            showImage(files[0]).then(response => {
                this.imgUrl = response;
            });
        } else {
            this.imgUrl = '';
        }
    }

    handleInputFile = (files, event) => {
        this.handleFileObject(event.target.files, files)
    };

    handleFileObject = (data, files) => {
        let dataObj = data.length !== 0 ? this.handleFile(data) : {};
        Object.keys(dataObj).forEach(key => {
            files[key] = data[key];
            return true;
        });
        if (!this.isFileTooLarge) {
            this.getImage();
        }
    };

    checkImageType = (files) => {
        let data;
        let allowedExtension = ['jpeg', 'jpg', 'png'];
        Object.keys(files).forEach(key => {
            data = allowedExtension.filter((data) => {
                if (Object.keys(files).length !== 0) {
                    return files[key].type.includes(data);
                }
            });
        });
        return data.length === 0;
    };

    checkFileSize = (files) => {
        let fileSize = 0;
        Object.keys(files).forEach(key => {
            fileSize += files[key].size;
            return true;
        });
        return fileSize < 102400;
    };

    handleFile = (files) => {
        this.isFileTooLarge = false;
        if (this.checkImageType(files)) {
            LoadToast(ToastType.WARN, "File Type is not allowed ,Fotmats jpeg, jpg, png");
        } else {
            if (!this.checkFileSize(files)) {
                this.isFileTooLarge = true;
                return {};
            }
            return files;
        }
        return {};
    };

    onTypeChangeHandler = (event) => {
        this.currentType = event.target.value;
        this.init();
    }

    render() {
        return (
            <div className="row mx-0">
                <div className="col-12 px-0">
                    <div className='w-100' style={{ height: '17px' }}>
                        {this.imgUrl && <img className="float-right" style={{ width: '13px', cursor: 'pointer' }}
                            alt="deleteRole" onClick={() => this.props.deleteItem(this.currentType)}
                            src={require("../../assets/image/delete.jpg")} />
                        }
                    </div>
                    <select style={{ width: 150, height: 32 }} value={this.currentType} onChange={this.onTypeChangeHandler}>
                        <option value={'header'}>Header</option>
                        <option value={'footer1'}>Footer 1</option>
                        <option value={'footer2'}>Footer 2</option>
                    </select>
                    <Form submit={this.onSubmit}>
                        <FormGroup controlId="manageLabelTo" bsSize="large">
                            <ControlLabel className="font-weight-bold">Title</ControlLabel>
                            <textarea disabled={this.props.disableInput} value={this.itemDetails.title || ''} onChange={this.onTitleChangeHandler} className="form-control" required={true} />
                            <div className="invalid error" />
                        </FormGroup>
                        <FormGroup controlId="manageLabelTo" bsSize="large">
                            <ControlLabel className="font-weight-bold">Link</ControlLabel>
                            <input disabled={this.props.disableInput} onChange={this.onLinkChangeHandler} value={this.itemDetails.link || ''} maxLength={255} className="form-control" />
                            <div className="invalid error" />
                        </FormGroup>
                        <div>
                            <FormGroup controlId="logo" bsSize="large">
                                <ControlLabel className="font-weight-bold">Upload Image</ControlLabel>
                                <label htmlFor="item-file-upload" className="btn button-grey-color ml-2">
                                    {'Browse File'}
                                </label>
                                <input
                                    id="item-file-upload"
                                    accept=".png, .jpg, .jpeg"
                                    type="file"
                                    name="files"
                                    disabled={this.props.disableInput}
                                    onChange={this.handleInputFile.bind(this, this.itemDetails.files)}
                                />
                                <p style={{ color: 'grey' }}>Max file size: 100KB. Recommended dimension 690 X 290</p>
                                <div className="invalid error" />
                            </FormGroup>
                            {
                                this.isFileTooLarge &&
                                <p className="mb-0 error">File is too large</p>
                            }
                        </div>
                    </Form>
                    {
                        this.imgUrl &&
                        <img src={this.imgUrl} style={{ maxHeight: '176px', maxWidth: '100%', }} alt="product" />

                    }
                </div>
            </div>
        )
    }
}


export { ResultPageForm };