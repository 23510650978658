import {action, observable} from 'mobx';


class PackagingLevel {
    @observable id:string = '';
    @observable name:string = '';
    @observable status:string = '';
    @observable createdBy:string = '';
    @observable updatedBy:string = '';

    @action
    setPackagingLevel = (packagingLevel: any) => {
        this.id = packagingLevel.id;
        this.name = packagingLevel.name;
        this.status = packagingLevel.status;
        this.createdBy = packagingLevel.createdBy;
        this.updatedBy = packagingLevel.updatedBy;
        return this;
    }
    @action reset() {
        this.id         ='';
        this.name       = '';
        this.status     = '';
        this.createdBy = '';
        this.updatedBy = '';
    }
}

export {PackagingLevel};
