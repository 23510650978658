import React from 'react';
import { observer } from 'mobx-react';
import { viewDateTimeFormat} from "../../constants/CommonMethods";
import {Stores} from "../../store/Stores";

@observer
class PushMessageTableDetails extends React.Component<any, any> {

    render() {
        return(
            <React.Fragment>
            {
                this.props.pushMessages && this.props.pushMessages.map((pushMessage,index)=>
                        <tr key={index} className="row mx-0 text-nowrap  cursor-pointer table-body-value" onClick={(event) => this.props.viewPushMessage(event,pushMessage)}>
                            <td className='col-2 px-0 text-center text-wrap'>{pushMessage.messageId} </td>
                            <td className="col-2 px-0 text-center text-wrap">{(pushMessage.messageType==="PromotionalCampaign")?(<span>Promotional Campaign</span>):(<span>{pushMessage.messageType}</span>)}</td>
                            <td className="col-2 text-center">{pushMessage.country}</td>
                            <td className="col-2 px-0 text-center text-wrap">{(pushMessage.group==="MyCustomer")?(<span>My Customers</span>):(<span>{pushMessage.group}</span>)}</td>
                            <td className="col-2 px-0 text-center text-wrap">{viewDateTimeFormat(pushMessage.createdDate)}</td>
                            <td className="col-2 px-0 text-center text-wrap">{pushMessage.status}
                            {pushMessage.status==="Submitted"&&pushMessage.userType===Stores.currentUser.userType.name&&
                            <img  style={{width: '13px',marginLeft: '10px'}} className="cursor-pointer" alt="close"
                             onClick={(event) => this.props.deleteAlert(event,pushMessage)}
                             src={require("../../assets/image/delete.jpg")} /> }</td>
                        </tr>
                )
            }
            {
                this.props.pushMessages && this.props.pushMessages.length===0&&
                <tr >
                <td className="text-center error" colSpan={7}>No records found ...</td>
                </tr>
            }
            </React.Fragment>
        )
    }
}


export {PushMessageTableDetails};
