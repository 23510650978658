import React from 'react';
import {withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Stores } from '../../store/Stores';
import { BaseCustomer } from '../../config/HttpClients';
import {EvdensContactForm} from "./EvdensContactForm";
import {EvdensContact} from "../../store/EvdensContact";
import {EvdensContactTableDetails} from "./EvdensContactTableDetails";

@observer
class EvdensContacts extends React.Component<any, any> {

    @observable evdensContact = new EvdensContact();
    @observable evdensContacts: EvdensContact[] = [];

    history:any;
    constructor(props: any) {
        super(props);
        this.history = props.history;
        this.getEvdensContacts();
    }
    save = () => {
        if(this.evdensContact.id){
            this.updateEvdensContact(this.evdensContact);
        } else {
            this.createEvdensContact();
        }
    }

    createEvdensContact=()=>{
        this.evdensContact.createdBy = Stores.currentUser.id;
        BaseCustomer.post("/evdensContact", this.evdensContact).then(response=>{
            this.setNewEvdensContact();
        });
    }

    updateEvdensContact=(evdensContact)=>{
        evdensContact.updatedBy = Stores.currentUser.id;
        BaseCustomer.put("/evdensContact", evdensContact).then(response=>{
            this.setNewEvdensContact();
        });
    }

    getEvdensContacts = () => {
        BaseCustomer.get("/evdensContact").then(response => {
            this.evdensContacts = response.data.payload;
        });
    }

    setNewEvdensContact=()=>{
        this.evdensContact = new EvdensContact();
        this.getEvdensContacts();
    }

    setSelectedEvdensContact=(evdensContact)=>{
        this.evdensContact = evdensContact;
    }

    render() {
        return(
            <div className="col-12 p-0 h-100">
                <div className="pb-2 pt-4 row ml-0 search-bg">
                    <div className='col-6 h-42'>
                        <b style={{letterSpacing:"1px",fontSize:"28px"}}> </b>
                    </div>
                    <div className='col-6'>
                        <div className='float-right'>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 py-3" style={{height:'calc(100% - 75px)'}}>
                    <div className="col-12 h-100">
                        <div className="card b-none h-100">
                            <div className="card-body h-100">
                                <div className="pt-2 h-100">
                                    <div className="col-12 px-0 h-100">
                                        <EvdensContactForm  save={this.save} evdensContact={this.evdensContact} />
                                        <div className="row mx-0 mt-2" style={{height:'calc(100% - 75px)'}}>
                                            <div className="col-12 h-100">
                                                <div>
                                                    <table className="table mb-0">
                                                        <thead>
                                                        <tr className="row table-header">
                                                            <th className="col-1 text-nowrap text-center">S.No</th>
                                                            <th className="col-3 text-nowrap text-center">Medium</th>
                                                            <th className="col-3 px-0 text-nowrap text-center">Value</th>
                                                            <th className="col-3 text-nowrap text-center">Current State</th>
                                                            <th className="col-2 text-nowrap text-center">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div className="row custom-scroll" style={{height:'calc(100% - 50px)',overflowY:'auto', overflowX:'hidden'}}>
                                                    <table className="table">
                                                        <tbody>
                                                            <EvdensContactTableDetails evdensContacts = {this.evdensContacts} updateEvdensContact = {this.updateEvdensContact}/>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const EvdensContactsComponent = withRouter(EvdensContacts);
export {EvdensContactsComponent as EvdensContacts};
