import React from 'react';
import {observer} from 'mobx-react';
import {ButtonToolbar, Button} from 'react-bootstrap';
import {observable} from "mobx";
import {Subset} from "../../store/Subset";
import {Permission} from "../../store/Permission";
import {Stores} from '../../store/Stores';

@observer
class WebPermissionForm extends React.Component<any, any> {

    @observable viewSubset: any;
    @observable subsets: Subset[] = [];
    @observable permissions: Permission[] = [];

    handlePermissionCreate = (permission, event, key) => {
        permission.create = !permission.create;
    }
    handlePermissionModify = (permission, event) => {
        permission.modify = !permission.modify;
    }
    handlePermissionView = (permission, event) => {
        permission.view = !permission.view;
    }
    handlePermissionSuspend = (permission, event) => {
        permission.suspend = !permission.suspend;
    }
    setSubset = (feature) => {
        this.viewSubset = feature.id;
    }

    render() {
        return (
            <div className="row mx-0">
                <div className="col-12 px-0" style={{minHeight: '300px'}}>
                    <div className="header">
                        <h5>Feature Permissions</h5>
                    </div>
                    <div>
                        <div className="row mx-0" style={{background: '#f2f2f2'}}>
                            <div className={"col-4 pl-0"}>
                                <p className="mb-0"><b>Feature</b></p>
                            </div>
                            <div className={"col-2 pl-0 text-center text-nowrap"}>
                                <p className="mb-0"><b>Create</b></p>
                            </div>
                            <div className={"col-2 pl-0 text-center text-nowrap"}>
                                <p className="mb-0"><b>Modify</b></p>
                            </div>
                            <div className={"col-2 pl-0 text-center text-nowrap"}>
                                <p className="mb-0"><b>Read</b></p>
                            </div>
                            <div className={"col-2 px-0 text-nowrap"}>
                                <p className="mb-0"><b>Suspend</b></p>
                            </div>
                        </div>
                        <div style={{height: '300px', overflow: 'auto'}} className={'custom-scroll'}>

                            {
                                this.props.permissions && this.props.permissions.map((permission, key) =>
                                    !permission.feature.mobile&&permission.feature.name!=="Product Recall Management"&&permission.feature.name!=="Settings"&&permission.feature.name!=="Label Design & Config"&&permission.feature.name!=="Reports"&&permission.feature.name!=="Complaints & Feedback"&&
                                    <div key={key}>
                                        <div className="row mx-0 py-1 cursor-pointer"
                                             onClick={this.setSubset.bind(this, permission.feature, key)}>
                                            <div className="col-6 row">
                                                <p className="mb-0">
                                                    <img className="cursor-pointer" alt="add"
                                                         style={{width: '8px', height: '8px'}}
                                                         src={require("../../assets/image/addIcon.png")}/>
                                                    <b className='pl-2'>{permission.feature && permission.feature.name}</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                this.viewSubset === permission.feature.id && permission.feature.subset.map((subset, key) =>
                                                    <div className='row dotted mx-0' key={key}>
                                                        <div
                                                            className="col-4 p-0 custom-control custom-checkbox wrapper">
                                                            <div className="line"></div>
                                                            <p className="mb-0 pl-2">{subset.name}</p>
                                                        </div>
                                                        <div
                                                            className="col-2 pl-0 custom-control custom-checkbox">
                                                            {
                                                                permission.hasOwnProperty('create') &&
                                                                <input type="checkbox"
                                                                       className="custom-control-input ml-3 mt-2"
                                                                       checked={subset.create}
                                                                       onChange={this.handlePermissionCreate.bind(this, subset, key)}/>
                                                            }
                                                        </div>
                                                        <div
                                                            className="col-2 pl-0 custom-control custom-checkbox">
                                                            {
                                                                permission.hasOwnProperty('modify') &&
                                                                <input type="checkbox"
                                                                       className="custom-control-input ml-3 mt-2"
                                                                       checked={subset.modify}
                                                                       onChange={this.handlePermissionModify.bind(this, subset, key)}/>
                                                            }
                                                        </div>
                                                        <div
                                                            className="col-2 pl-0 custom-control custom-checkbox">
                                                            {
                                                                permission.hasOwnProperty('view') &&
                                                                <input type="checkbox"
                                                                       className="custom-control-input ml-3 mt-2"
                                                                       checked={subset.view}
                                                                       onChange={this.handlePermissionView.bind(this, subset, key)}/>
                                                            }
                                                        </div>
                                                        <div
                                                            className="col-2 pl-0 custom-control custom-checkbox">
                                                            {
                                                                permission.hasOwnProperty('suspend') &&
                                                                <input type="checkbox"
                                                                       className="custom-control-input ml-3 mt-2"
                                                                       checked={subset.suspend}
                                                                       onChange={this.handlePermissionSuspend.bind(this, subset, key)}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                )}

                                        </div>
                                    </div>
                                )}
                        </div>
                        {
                            (Stores.currentUserRole.name === 'ROLE_VGLENS_ADMIN' || Stores.currentUserRole.name === 'ROLE_CUSTOMER_ADMIN' || Stores.currentUserRole.name === 'ROLE_PARTNER_ADMIN'
                                || Stores.currentUserRole.name === 'ROLE_SUPPLY_CHAIN_ADMIN') &&
                            <ButtonToolbar className="justify-content-end mt-2">
                                <Button className="btn button-sec-color btn-sm" type="button"
                                        onClick={() => this.props.updatePermissions(this.props.permissions)}> Update</Button>
                            </ButtonToolbar>
                        }

                    </div>
                </div>
            </div>
        )
    }
}


export {WebPermissionForm};
