import {action, observable} from 'mobx';

class UserScanData {
    @observable userId:string = '';
    @observable currentBranchCountry:string = '';
    @observable scannedDateTime:string = '';
    @observable actionType:string = '';
    @observable userName:string = '';

    @action
    setProduct = (userScanData: any) => {
        this.userId = userScanData.userId;
        this.currentBranchCountry = userScanData.currentBranchCountry;
        this.scannedDateTime = userScanData.scannedDateTime;
        this.actionType = userScanData.actionType;
        this.userName = userScanData.userName;
        return this;
    }
    @action reset() {
        this.userId = '';
        this.currentBranchCountry = '';
        this.scannedDateTime = '';
        this.actionType = '';
        this.userName = '';
    }
}

export {UserScanData};
