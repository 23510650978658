import {action, observable} from 'mobx';

class OrderItem   {
    @observable id:string = '';
    @observable quantity:string = '';
    @observable labelSize:string = '';
    @observable ovd:string = '';
    @observable expectedDeliveryDate:string = '';
    @observable status:string = '';
    @observable orderItemStatus:string = '';
    @observable product:string = '';
    @observable productName:string = '';

    @action
    setLabelOrderItem = (labelOrderItem: any) => {
        this.id = labelOrderItem.id;
        this.quantity = labelOrderItem.name;
        this.labelSize = labelOrderItem.labelSize;
        this.ovd= labelOrderItem.ovd;
        this.expectedDeliveryDate = labelOrderItem.expectedDeliveryDate;
        this.status = labelOrderItem.status;
        this.orderItemStatus = labelOrderItem.orderItemStatus;
        this.product = labelOrderItem.product;
        this.productName = labelOrderItem.productName;
        return this;
    }
    @action reset() {
        this.id       = '';
        this.quantity     = '';
        this.status   = '';
        this.labelSize = '';
        this.ovd = '';
        this.expectedDeliveryDate = '';
        this.product = '';
        this.productName = '';
    }
}

export {OrderItem};
