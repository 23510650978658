import React from 'react';
import { observer } from 'mobx-react';

@observer
class ComplaintTableDetails extends React.Component<any, any> {

    render() {
        return(
            <React.Fragment>
                {
                    this.props.complaints && this.props.complaints.map((complaint,index)=>
                        <tr key={index} className="row mx-0 text-nowrap table-body-value cursor-pointer" onClick={()=>this.props.viewComplaint(complaint)} >
                            <td className='col-3 text-center px-0 text-wrap'>{complaint.complaintId} </td>
                            <td className="col-3 text-center px-0 text-wrap">{complaint.mobileNumber}</td>
                            <td className="col-3 px-0 text-center text-wrap">{complaint.brandName}</td>
                            <td className="col-3 px-0 text-left text-wrap">{complaint.createdDateConvert}</td>
                        </tr>
                    )
                }
                {
                    this.props.complaints && this.props.complaints.length===0&&
                    <tr >
                        <td className="text-center error" colSpan={7}>No records found ...</td>
                    </tr>
                }
            </React.Fragment>
        )
    }
}


export {ComplaintTableDetails};
