import {action, observable} from 'mobx';

class CustomerPrintingPartner {
    @observable printingPartner :string= '';

    @action
    setCustomerPrintingPartner = (customerPrintingPartner: any) => {
        this.printingPartner = customerPrintingPartner.printingPartner;
        return this;
    }
    @action reset() {
        this.printingPartner = '' ;
    }
}

export {CustomerPrintingPartner};