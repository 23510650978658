import { observable} from 'mobx';

class ConsumerScannedReportDto   {

    @observable authentication:number = 0;
    @observable authenticationTotal:number = 0;
    @observable failure:number = 0;
    @observable failureTotal:number = 0;
    @observable authenticationTill:number = 0;
    @observable authenticationTillTotal:number = 0;
    @observable failureTill:number = 0;
    @observable failureTillTotal:number = 0;
    @observable suspicious:number = 0;
    @observable suspiciousTotal:number = 0;
    @observable suspiciousTill:number = 0;
    @observable suspiciousTillTotal:number = 0;
}

export {ConsumerScannedReportDto};
